import React from 'react'
import {action, makeAutoObservable} from 'mobx'
import {clear, errorMessage, successMessage, toastMessage} from '../utils/utils'
import AuthStore from './auth.store'
import DashboardStore from './dashboard.store'

class RootStore {
  isLoading = false
  listLoading = false
  constructor() {
    this.authStore = new AuthStore(this)
    this.dashboardStore = new DashboardStore(this)

    makeAutoObservable(this, {
      logout: action,
    })
  }

  createBtnClicked = (value) => (this.showCreateBtnClicked = value)

  logout = () => {
    clear()
    window.location.href = '/'
  }

  handleError = (error) => {
    const statusCode = error?.data?.statusCode
    if (statusCode === 401) this.logout()
    const isExists = [400, 403, 404, 406, 422, 423].includes(statusCode)
    this.setConfig({key: isExists ? `error${statusCode}` : 'error'}, 'danger', false)
  }

  setConfig = (message, color, isLoading) => {
    const msg = toastMessage(message.key, message.type)
    color === 'danger' ? errorMessage(msg) : successMessage(msg)
    this.isLoading = isLoading
  }
}

const StoreContext = React.createContext(new RootStore())

export const StoreProvider = ({children}) => {
  return <StoreContext.Provider value={StoreContext}>{children}</StoreContext.Provider>
}

export const useStore = () => React.useContext(StoreContext)
