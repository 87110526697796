import {FC} from 'react'
import {useIntl} from 'react-intl'
import {isWorkspaceGroweoAdmin} from '../../../utils/utils'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'

const LibrariesBreadCrumb: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const LibrariesBreadCrumb: Array<PageLink> = [
    {
      title: t('LIBRARY', {count: 1}),
      path: '/libraries',
      isSeparator: false,
      isActive: false,
      isShow: isWorkspaceGroweoAdmin(),
    },
  ]
  const filteredData = LibrariesBreadCrumb.filter((b) => b.isShow)

  return <PageTitle breadcrumbs={filteredData}>{`${t('LIBRARY', {count: 1})}`}</PageTitle>
}

export default LibrariesBreadCrumb
