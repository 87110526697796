import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import KTImageInput from '../../assets/scripts/image-input'

const ImageInputFeild = (props) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const [imgUrl, setImgUrl] = useState('')

  useEffect(() => {
    setImgUrl(props.url)
    KTImageInput.createInstances()
  }, [props.url])

  const getClasses = () => {
    return `btn btn-icon btn-circle btn-active-color-primary w-${props?.size?.icon || 25}px h-${
      props?.size?.icon || 25
    }px bg-body shadow`
  }

  return (
    <>
      <div
        className={`${!imgUrl ? 'image-input-empty' : ''} image-input image-input-${
          props.type || 'circle'
        }`}
        data-kt-image-input='true'
        style={{backgroundImage: props.placeholder ? props.placeholder : 'url(/media/svg/avatars/blank.svg)', backgroundColor: props.placeholder ? '#ccc' : 'none'}}
      >
        <div
          className={`image-input-wrapper w-${props?.size?.img || 125}px h-${
            props?.size?.img || 125
          }px`}
          style={{backgroundImage: imgUrl ? `url(${imgUrl})` : 'none'}}
        ></div>

        <label
          className={getClasses()}
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          data-bs-dismiss='click'
          title={props.title || t('AVATAR_CHANGE')}
        >
          <i className='bi bi-pencil-fill fs-7'></i>

          <input
            className='p-0'
            type='file'
            name='avatar'
            accept='.png, .jpg, .jpeg'
            onChange={(e) => props.onUpload(e, props.element)}
          />
          <input type='hidden' name='avatar_remove' />
        </label>

        <span
          className={getClasses()}
          data-kt-image-input-action='cancel'
          data-bs-toggle='tooltip'
          data-bs-dismiss='click'
          title={t('AVATAR_CANCEL')}
        >
          <i className='bi bi-x fs-2'></i>
        </span>

        <span
          className={getClasses()}
          data-kt-image-input-action='remove'
          data-bs-toggle='tooltip'
          data-bs-dismiss='click'
          title={t('AVATAR_REMOVE')}
          onClick={() => props.onRemove(props.element)}
        >
          <i className='bi bi-x fs-2'></i>
        </span>
      </div>
    </>
  )
}

export default ImageInputFeild
