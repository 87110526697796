import AdministrationBreadCrumb from './AdministrationBreadCrumb'
import WorkspaceContainer from '../../modules/admin/workspace/WorkspaceContainer'

const Workspaces = () => {

  
  return (
    <>
      <AdministrationBreadCrumb />
      <WorkspaceContainer />    
    </>
  )
}

export default Workspaces
