/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
type Props = {
  className: string
  activity: any
  title:string
  subTitle:string

}
const ActivityList : React.FC<Props> = ({className,activity,title,subTitle}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const lang = useLang()
  const currentLanguage = lang

  const renderRecentActivities = ({activity, moduleName}: any) => {
    if (currentLanguage === "en")
      if (activity === "UNIQUE-VISITOR") return "New visitor" 
      else if (activity === "START-SESSION") return `New session started for <span class="text-primary">${moduleName}</span> app`
      else if (activity === "SUCCESSFULL-CONVERSATION") return `Session completed successfully for <span class="text-primary">${moduleName}</span> app`
      else if (activity === "LEAD-CREATED") return `A new lead generated by <span class="text-primary">${moduleName}</span> app`
    if (currentLanguage === "fi")
      if (activity === "UNIQUE-VISITOR") return "Uusi vierailija" 
      else if (activity === "START-SESSION") return `Sovelluksen <span class="text-primary">${moduleName}</span> käyttö aloitettu`
      else if (activity === "SUCCESSFULL-CONVERSATION") return `Onnistunut keskustelu sovelluksessa <span class="text-primary">${moduleName}</span>`
      else if (activity === "LEAD-CREATED") return `Uusi liidi kerätty sovelluksessa <span class="text-primary">${moduleName}</span>`
  }


  const dateToTime = (createdDate:any) =>{
    moment.updateLocale('fi',{
      relativeTime : {
          past:   "%s sitten",
          s  : 'hetki',
          ss : 'hetki',
          m:  "1 min",
          mm: "%d min",
          h:  "tunti",
          hh: "%d tuntia",
          d:  "päivä",
          dd: "%d päivää",
          w:  "a viikko",
          ww: "%d viikkoa",
          M:  "kk",
          MM: "%d kk",
          y:  "a year",
          yy: "%d years"
      }});

    return moment.utc(createdDate).fromNow();
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark fs-3'>{title}</span>
          <span className='text-muted fw-bold fs-7'>{subTitle}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5' style={{height:"50px", overflow:"scroll"}}>
        {/* begin::Timeline */}
        <div className='timeline-label'>

          {activity.filter(
              (record: any) =>
                record.activity === 'START-SESSION' ||
                record.activity === 'LEAD-CREATED' ||
                record.activity === 'SUCCESSFULL-CONVERSATION' ||
                record.activity === 'UNIQUE-VISITOR'
            ).map((record: any,i: any)=>{
              let color = "";
              if(i%4===0)
                color="text-warning";
              else if(i%4===1)
                color="text-success";
              else if(i%4===2)
                color="text-danger";
              else
                color="text-primary";

              return(
                   //begin::Item
                    <div className='timeline-item' style={{marginBottom:'0.7rem'}} key={`Activity_${i}`}>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-8'>{dateToTime(record.timestamp)}</div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                        <i className={`fa fa-genderless ${color} fs-1`}></i>
                    </div>
                    {/* end::Badge */}
                  {/* begin::Text */}
                  {<div className='fw-mormal timeline-content text-muted ps-3' dangerouslySetInnerHTML={{ __html: `${renderRecentActivities(record)}`}}></div>}
                    
                    {/* end::Text */}
                    </div>
                    // end::Item
              )
          })}
          
        
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}
export default ActivityList