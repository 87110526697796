import React, {useMemo, useRef} from 'react'
import {Handle} from 'react-flow-renderer'
import '../../../../../../../_metronic/assets/sass/style.react.scss'
import '../../../../../../../_metronic/assets/sass/style.scss'
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js'
import './node.css'
import botStore from '../../../../../bots/botCreate/botTree/store/botStore'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useSmartFlowData } from '../../../StepperContext'
import { useIntl } from 'react-intl'

const targetStyle = {
  borderRadius: '20px',
  width: '4px',
  border: 'solid 1px black',
  backgroundColor: 'gray',
  height: '80%',
}

const sourceStyle = {
  width: '4px',
  borderRadius: '20px',
  backgroundColor: '#FF9E00',
  border: 'solid 1px black',
  height: '80%'
}

export const SlideNode = ({type, id, data}) => {
  const [el, setEl] = React.useState(null)
  const {smartFlow} = useSmartFlowData()
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const ref = useRef()

  React.useEffect(() => {
    if (el) new Tooltip(el)
  }, [data.text, el])

  const nodeStyle = {
    cursor: 'pointer',
    position: 'relative',
    width: '150px',
    height: '80px',
    border: '2px solid black',
    overflow: 'hidden',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  }

  const onCopyButtonClick = (ev) => {
    botStore.setCopyMessage({
      position: {
        left: ev.pageX-25,
        top: ev.pageY-25
      }
    })
    window.navigator.clipboard.writeText(JSON.stringify({ type, data})).then(() => {
      botStore.setCopyMessage({ show: true })
      setTimeout(() => {
        botStore.setCopyMessage({ show: false })
      }, 1000)
    }).catch((err) => {
    })
  }

  return (
    <div>
      {!data.start && <Handle type='target' position="left" style={targetStyle} />}
      <div className='slider-node' style={nodeStyle}>
        <div>{data?.name || 'Slide'}</div>
        {!data.start && !data.nodeCount && !data.percentageCount ? (
          <div className='hide-option position-absolute end-0 top-0'>
            <div className='d-flex align-items-center'>
              <span className='me-1' onClick={onCopyButtonClick}>
                <KTSVG
                  path='/media/icons/custom/copy_icon.svg'
                  className='svg-icon-2 svg-icon-primary clickable'
                ></KTSVG>
              </span>
              <i className='bi bi-x text-black fs-2x node-close' style={{cursor: 'pointer'}}></i>
            </div>
          </div>
        ) : <span title={`${data.nodeCount} ${t('VISITOR', {count: 1})}`} className="mx-2 fw-bolder position-absolute end-0 top-0">{data.percentageCount ? data.percentageCount : ""}</span>}
      </div>
        {type !== 'end' && <Handle type='source' position="right" style={sourceStyle} />}
    </div>
  )
}
  