import axios from 'axios'
import * as _ from 'lodash'
import {addEdge} from 'react-flow-renderer'

//import { addBotElement, removeBotElement } from './module.utils'


const firstElementData = {
  id: '1',
  type: 'text',
  className: 'select-node-start',
  data: {
    start: true,
    label: 'Start Node',
    text: 'Hello, how can I help you?',
    options: [],
  },
  position: {x: 10, y: 250},
}

const firstSliderData = {
  id: '1',
  type: 'text',
  data: {
    name: 'Slide 1',
    content: {},
    start: true,
  },
  position: {x: 10, y: 250},
}

export function getElements(newElements, elements) {
  return typeof newElements === 'function' ? newElements(elements) : newElements
}

export function addBotBuildHistory({buildHistory, undoRedoCursor}, data) {
  buildHistory = buildHistory.slice(0, buildHistory.length - 1 - undoRedoCursor)
  buildHistory.push(data)
  return {buildHistory: buildHistory, undoRedoCursor: 0}
}

export function removeElement(id, elements) {
  const elementsToRemove = elements.filter(
    (elm) =>  id.includes(elm.id) || id.includes(elm.source) || id.includes(elm.target)
  )
  elements = elements.filter((el) => !id.includes(el.id) && !id.includes(el.source) && !id.includes(el.target))
  return {elementsToRemove, elements}
}

export function updateOption(id, optionIndex, data, sildeNo, elements) {
  const {key, value} = data
  const foundIndex = elements.findIndex((elm) => elm.id === id)
  if (foundIndex === -1) return
  const opIndex = elements[foundIndex].type === 'carousel' ? sildeNo : optionIndex
  if (elements[foundIndex].type === 'carousel') {
    if (elements[foundIndex].data.slides[sildeNo].data.options[optionIndex]) {
      elements[foundIndex].data.slides[sildeNo].data.options[optionIndex][key] = value
    }
  }
  if (elements[foundIndex] && elements[foundIndex].data.options[opIndex]) {
    elements[foundIndex].data.options[opIndex][key] = value
  }
  return {foundIndex, elements}
}

export function addNewOption(id, sildeNo, elements) {
  const foundIndex = elements.findIndex((elm) => elm.id === id)
  if (foundIndex === -1) return
  if (elements[foundIndex].type === 'carousel') {
    if (!elements[foundIndex].data.slides[sildeNo].data.options) {
      elements[foundIndex].data.slides[sildeNo].data.options = []
    }
    elements[foundIndex].data.slides[sildeNo].data.options.push({
      text: `Option ${elements[foundIndex].data.slides[sildeNo].data.options.length}`,
    })
  }
  if (!elements[foundIndex].data.options) elements[foundIndex].data.options = []
  elements[foundIndex].data.options.push({
    text: `Option ${elements[foundIndex].data.options.length}`,
  })
  return {foundIndex, elements}
}

export function removeOption(id, index, sildeNo, elements) {
  const foundIndex = elements.findIndex((elm) => elm.id === id)
  if (foundIndex === -1) return
  if (elements[foundIndex].type === 'carousel') {
    if (
      elements[foundIndex].data.slides[sildeNo].data &&
      elements[foundIndex].data.slides[sildeNo].data.options
    ) {
      const edgeIndex = elements.findIndex(
        (el) => el.id === elements[foundIndex].data.options[index]?.edgeId
      )
      elements[foundIndex].data.slides[sildeNo].data.options.splice(index, 1)
      if (edgeIndex !== -1) elements.splice(edgeIndex, 1)
    }
  }
  if (elements[foundIndex].data && elements[foundIndex].data.options) {
    const edgeIndex = elements.findIndex(
      (el) => el.id === elements[foundIndex].data.options[index]?.edgeId
    )
    elements[foundIndex].data.options.splice(index, 1)
    if (edgeIndex !== -1) elements.splice(edgeIndex, 1)
  }
  return {foundIndex, elements}
}

export function addSlide(id, elements) {
  const foundIndex = elements.findIndex((elm) => elm.id === id)
  if (foundIndex === -1) return
  if (!elements[foundIndex].data.slides) {
    elements[foundIndex].data.slides = []
  }
  elements[foundIndex].data.slides.push({type: 'card', data: {text: ''}})
  return {foundIndex, elements}
}

export function removeSlide(id, index, elements) {
  const foundIndex = elements.findIndex((elm) => elm.id === id)
  if (foundIndex === -1) return
  if (elements[foundIndex].data.slides) elements[foundIndex].data.slides.splice(index, 1)
  return {foundIndex, elements}
}

export function removeLink(id, index, elements, selectedSlide) {
  const foundIndex = elements.findIndex((elm) => elm.id === id)
  if (foundIndex === -1) return
  if (selectedSlide === undefined) {
    if (elements[foundIndex].data.links) elements[foundIndex].data.links.splice(index, 1)
  } else {
    if (elements[foundIndex].data.slides[selectedSlide]?.data?.links)
      elements[foundIndex].data.slides[selectedSlide].data.links.splice(index, 1)
  }
  return elements
}

export function addLink(element) {
  if (!element.data.links) element.data.links = []
  element.data.links.push({text: '', destination: ''})
}

export function clearElements() {
  const el = _.cloneDeep(firstElementData)
  return [el]
}

export function clearSlider() {
  const el = _.cloneDeep(firstSliderData)
  return [el]
}

export function generateSimpleChatbotData(elements) {
  const steps = []
  let currentElement
  if (!elements) elements = clearElements()
  for (const element of elements) {
    if (!element.source) {
      currentElement = element
      let node = {
        id: currentElement.id,
        message: currentElement.data.text,
        hideInput: true,
        metadata: {name: currentElement.data.name, type: currentElement.type, gtmEventName: currentElement.data.gtmEventName},
      }

      if (currentElement.type === 'options' && !currentElement.data.text) node = null
      if (['card', 'linkk'].includes(currentElement.type)) {
        node = {id: currentElement.id, type: 'card', currentElement, hideInput: true}
      }
      if (currentElement.type === 'form') {
        node = {id: currentElement.id, type: 'form', currentElement, hideInput: true}
      }
      if (currentElement.type === 'carousel') {
        node = {id: currentElement.id, type: 'carousel', currentElement, hideInput: true}
      }
      if (currentElement.type === 'text') {
        if (currentElement.data?.markAsComplete) node.markAsComplete = currentElement.data?.markAsComplete
        else delete node.markAsComplete
      }
      if (node) steps.push(node)
      if (['email', 'input', 'inputt'].includes(currentElement.type)) {
        const nextNode = {
          id: currentElement.id + '-fw',
          user: true,
          hideInput: false,
          metadata: {
            name: currentElement.data.name,
            type: currentElement.type,
            gtmEventName: currentElement.data.gtmEventName,
          },
        }
        if (currentElement.data?.markAsComplete) nextNode.markAsComplete = currentElement.data?.markAsComplete
        else delete nextNode.markAsComplete
        if (node) node.trigger = nextNode.id
        if (currentElement.type === 'email') nextNode.validator = 'email'
        steps.push(nextNode)
      }
      if (currentElement.data.options.length) {
        const currentEl = currentElement
        let optionsStep = steps.find(
          (stp) => stp.id === (node ? 'options-' + currentEl.id : currentEl.id)
        )
        if (!optionsStep) {
          optionsStep = {
            id: node ? 'options-' + currentEl.id : currentEl.id,
            options: [],
            metadata: {name: currentEl.data.name, gtmEventName: currentElement.data.gtmEventName},
            hideInput: true,
          }
          if (!['card', 'linkk', 'carousel', 'form'].includes(currentEl.type) && currentElement.data?.markAsComplete) {
            optionsStep.markAsComplete = currentElement.data?.markAsComplete
          } else delete optionsStep.markAsComplete
          if (node) node.trigger = 'options-' + currentEl.id
          // else steps[steps.length - 1].trigger = currentEl.id
          steps.push(optionsStep)
        }
        
        currentEl.data.options.forEach((opt, index) => {

          optionsStep.options[index] = {
            value: `${opt?.emoji ? opt?.emoji + ' ' : ''} ${opt?.text || ''}`,
            label: `${opt?.emoji ? opt?.emoji + ' ' : ''} ${opt?.text || ''}`,
          }
          for (const element of elements) {
            if ( element.source === currentEl.id && element.sourceHandle===`${index}`) {
                optionsStep.options[index].trigger = element.target
              }
          }
          
          //const foundEdge = elements.find((item) => opt.edgeId === item.id)
          //const opIndex = foundEdge ? foundEdge.sourceHandle : index

          /*optionsStep.options[opIndex] = {
            value: (opt?.emoji || '') + opt?.text,
            label: (opt?.emoji || '') + opt?.text,
          }
          if (foundEdge) {
            optionsStep.options[opIndex].trigger = foundEdge.target
          }*/
        })
      }
      const filteredArr = elements.filter(
        (el) => el.source === currentElement.id && !el.sourceHandle
      )
      filteredArr.forEach((item) => {
        const lastNode = steps[steps.length - 1]
        if (!lastNode.trigger) lastNode.trigger = item.target
      })
    }
  }
  return steps
}

export function generateSimpleSlideData(elements) {
  const steps = []
  let currentSlide
  if (!elements) elements = clearElements()
  for (const element of elements) {
    if (!element.source) {
      currentSlide = JSON.parse(JSON.stringify(element))
      let node = {
        id: currentSlide.id,
      }

      if (['text', 'options', 'end'].includes(currentSlide.type)) {
        node = {id: currentSlide.id, type: currentSlide.type, currentSlide}
      }
      if (['content'].includes(currentSlide.type)) {
        node = {id: currentSlide.id, type: 'content', currentSlide}
      }
      if (node) steps.push(node)
      const filteredArr = elements.filter(
        (el) => el.source === currentSlide.id && (!el.sourceHandle || el.sourceHandle?.some((item) => item?.value))
      )
      delete node?.currentSlide?.data?.nodeCount
      delete node?.currentSlide?.data?.percentageCount
      if (filteredArr?.length)
        filteredArr.forEach((item) => {
          const lastSlide = steps[steps.length - 1]
          if (!lastSlide.trigger) lastSlide.trigger = item.target
          lastSlide.currentSlide.data.end = false;
        })
      else {
        const checkTrigger = currentSlide?.data?.content?.options?.some(opt => opt?.target);
        if (checkTrigger) steps[steps.length - 1].currentSlide.data.end = false;
        else steps[steps.length - 1].currentSlide.data.end = true;
      }
    }
  }
  return steps
}

export function botBuildUndo({buildHistory, undoRedoCursor}, elements, moduleId) {
  const item = buildHistory[buildHistory.length - 1 - undoRedoCursor]
  if (!item) return
  undoRedoCursor++
  console.log('item:', item)
  switch (item.type) {
    case 'newNode':
      console.log('newNode, removing id:', item.params.newNode.id)
      //removeBotElement(moduleId, item.params.newNode.id)
      elements = elements.filter((elm) => {
        return elm.id !== item.params.newNode.id
      })
      break

    case 'connect':
      elements = elements.filter(
        (elm) =>
          (!elm.source && !elm.target) ||
          elm.source !== item.params.source ||
          elm.target !== item.params.target ||
          elm.sourceHandle !== item.params.sourceHandle ||
          elm.targetHandle !== item.params.targetHandle
      )
      //removeBotElement(moduleId, item.params.id)
      break

    case 'remove':
      for (const elm of item.params.elementsToRemove) {
        //addBotElement(moduleId, elm)
      }
      elements = elements.concat(item.params.elementsToRemove)
      break

    default:
  }
  return {history: {buildHistory, undoRedoCursor}, elements}
}

export function botBuildRedo({buildHistory, undoRedoCursor}, elements, moduleId) {
  console.log('redo')
  const item = buildHistory[buildHistory.length - 1 - undoRedoCursor + 1]
  if (!item) return
  undoRedoCursor--
  console.log('item:', item)
  switch (item.type) {
    case 'newNode':
      //addBotElement(moduleId, item.params.newNode)
      console.log('newNode, removing id:', item.params.newNode.id)
      elements = elements.concat(item.params.newNode)
      break

    case 'connect':
      elements = addEdge(item.params, elements)
      //addBotElement(moduleId, elements[elements.length-1])
      break

    case 'remove':
      elements = elements.filter((elm) => {
        return !!item.params.elementsToRemove.find((elmR) => elmR.id === elm.id)
      })
      for (const elm of item.params.elementsToRemove) {
        //removeBotElement(moduleId, elm.id)
      }
      break

    default:
  }
  return {history: {buildHistory, undoRedoCursor}, elements}
}
