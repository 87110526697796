/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'


const SubscriptionToolbar: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const { classes } = useLayout()

  return (
    <div className='toolbar toolbar-pg-bg' style={{ position: 'inherit', border: 'none' }} id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')} style={{ padding: '0px' }}
      >
        <div>
          <SectionHeading title={t('SUBSCRIPTION', { count: 1 })} subTitle={t('SUBSCRIPTION_SUBTITLE', { count: 1 })} />
        </div>
        <div className='d-flex align-items-center py-1'>
          <a
            className='btn btn-primary text-uppercase'
            data-bs-toggle='modal'
            data-bs-target={`#gw_subscription_modal`}
            id='gw_subscription_modal_button'
          >
            <span className='svg-icon svg-icon svg-icon-1'>
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-hx' />
            </span>
            {t('CREATE')} {t('SUBSCRIPTION', { count: 0 })}
          </a>
        </div>
      </div>
    </div>
  )
}

export { SubscriptionToolbar }
