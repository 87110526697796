/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  DEFAULT_THEME,
  renderModuleForm,
  STYLE_LIST,
  THEME_LIST,
} from '../../../../../utils/module.utils'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import CustomizeForm from '../../shared/CustomizeForm'

type FormAppearanceProps = {
  template?: any
  setTemplate?: any
}

const FormAppearance: FC<FormAppearanceProps> = ({template, setTemplate}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [data, setData]: any = useState(DEFAULT_THEME)
  const [fieldChange, setFieldChange] = useState(false)
  const [customStyleUrl, setCustomStyleUrl]: any = useState([''])

  useEffect(() => {
    loadAppearance()
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!template._id) return
    loadAppearance()
    // eslint-disable-next-line
  }, [template._id])

  const onInputBlur = () => {
    if (fieldChange) {
      if (!template?.appearence)
        template.appearence = {theme: '', popup: 'click', delay: '', html: ''}
      template.appearence.theme = JSON.stringify(data)
      template.appearence.html = JSON.stringify(document.getElementById('form-content')?.innerHTML)
      setTemplate(template)
    }
    setFieldChange(false)
  }

  useEffect(() => {
    const colorEl: any = document.getElementById('custom-color')
    if (colorEl) colorEl.value = data.background || '#FFFFFF'
    const btncolorEl: any = document.getElementById('btn-custom-color')
    if (btncolorEl) btncolorEl.value = data.btnBackground || '#FFFFFF'
    // eslint-disable-next-line
  }, [data])

  const loadAppearance = () => {
    const {appearence} = template
    if (appearence?.html) renderModuleForm(appearence.html)
    if (appearence?.theme) {
      const prsTheme = JSON.parse(appearence.theme)
      setData(prsTheme)
      if (prsTheme?.cssFileUrl?.length) setCustomStyleUrl(prsTheme?.cssFileUrl)
    }
  }

  const onInputChange = (name: string, value: string) => {
    setFieldChange(true)
    if (['background', 'btnBackground'].includes(name)) setData({...data, [name]: value})
    else if (name === 'theme')
      setData({...data, [name]: value, background: name === 'theme' ? '' : data.background})
    else if (name === 'buttonColor')
      setData({
        ...data,
        [name]: value,
        btnBackground: name === 'buttonColor' ? '' : data.btnBackground,
      })
    else if (['title','subTitle'].includes(name))
      setData({
        ...data,
        successMessage: {...data?.successMessage, [name]: value},
      })
    else setData({...data, [name]: value})
  }

  const renderInput = (name: string, label: string) => (
    <>
      <label className='my-2'>{label}</label>
      <input
        value={data[name]}
        className='form-control form-control-solid'
        onChange={(e) => onInputChange(name, e.target.value)}
        onBlur={onInputBlur}
      />
    </>
  )

  const onAddUrl = (e: any, index: number) => {
    if (e) customStyleUrl[index] = e.target.value
    if (!e) customStyleUrl.splice(index, 1)
    setCustomStyleUrl([...customStyleUrl])
    data['cssFileUrl'] = customStyleUrl
    template.appearence.theme = JSON.stringify(data)
    setTemplate(template)
  }
  const renderTextarea = (name: string, label: string) => (
    <>
      <label className='my-2'>{label}</label>
      <textarea
        className='form-control form-control-solid'
        placeholder={t('TYPE_MESSAGE', {count: 0})}
        value={data?.successMessage?.[name]}
        onChange={(e) => onInputChange(name, e.target.value)}
        onBlur={onInputBlur}
        style={{height: '80px'}}
      ></textarea>
    </>
  )

  const renderMenu = (label: string, list: any, key: string) => (
    <>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>{label}</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 pt-5' data-kt-user-table-filter='form'>
        <div className=''>
          {list.map((item: any, index: number) => (
            <span key={`color_list_${index}`}>
              <label
                key={`st_${index}`}
                className='form-check form-check-sm form-check-custom form-check-solid me-5'
              >
                <input
                  className='form-check-input'
                  onChange={() => onInputChange(key, item.value)}
                  checked={item.value === data[key]}
                  type='radio'
                  onBlur={onInputBlur}
                />
                <span className='form-check-label'>{item.label}</span>
              </label>
              <br />
            </span>
          ))}
        </div>
      </div>
    </>
  )

  const getStyleName = (name: string, list: any, label: string) => {
    if (data[name]) {
      const foundItem = list.find((item: any) => item.value === data[name])
      if (!foundItem) return t(label)
      return foundItem.label
    }
    return t(label)
  }

  return (
    <div className='container-fluid'>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <div className='card mb-xl-8'>
            <div className='sidebar default'>
              <div className='sidebar-content'>
                <h4>{t('FORM_STYLE')}</h4>
                <button
                  type='button'
                  className='btn btn-default w-250 w-md-250px me-3 text-left'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-start'
                >
                  {getStyleName('styles', STYLE_LIST, 'FORM_STYLE')}
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250 w-md-250px'
                  data-kt-menu='true'
                >
                  {renderMenu(t('FORM_STYLE'), STYLE_LIST, 'styles')}
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-xl-8'>
            <div className='sidebar default'>
              <div className='sidebar-content'>
                <h4>{t('BUTTON_COLOR')}</h4>
                <div data-kt-menu-trigger='click' data-kt-menu-placement='bottom-start'>
                  <button
                    className={`btn btn-default w-250 w-md-250px me-3 text-left ${data.buttonColor}`}
                    style={{background: data.btnBackground}}
                  >
                    {data.btnBackground
                      ? t('CUSTOM_COLOR')
                      : getStyleName('buttonColor', THEME_LIST, 'BUTTON_COLOR')}
                  </button>
                </div>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250 w-md-250px'
                  data-kt-menu='true'
                >
                  {renderMenu(t('BUTTON_COLOR'), THEME_LIST, 'buttonColor')}
                  <>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5 px-5'>
                      <input
                        type='color'
                        id='btn-custom-color'
                        onChange={(e) => onInputChange('btnBackground', e.target.value)}
                        onBlur={onInputBlur}
                      />
                      <span className='form-check-label'>{t('CUSTOM_COLOR')}</span>
                    </label>
                    <br />
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-xl-8'>
            <div className='sidebar default'>
              <div className='sidebar-content'>
                <h4>{t('FORM_COLOR')}</h4>
                <div data-kt-menu-trigger='click' data-kt-menu-placement='bottom-start'>
                  <button
                    className={`btn btn-default w-250 w-md-250px me-3 text-left ${data.theme}`}
                    style={{background: data.background}}
                  >
                    {data.background
                      ? t('CUSTOM_COLOR')
                      : getStyleName('theme', THEME_LIST, 'FORM_COLOR')}
                  </button>
                </div>
                <div
                  className='menu menu-sub menu-sub-dropdown w-250 w-md-250px'
                  data-kt-menu='true'
                >
                  {renderMenu(t('FORM_COLOR'), THEME_LIST, 'theme')}
                  <>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5 px-5'>
                      <input
                        type='color'
                        id='custom-color'
                        onChange={(e) => onInputChange('background', e.target.value)}
                        onBlur={onInputBlur}
                      />
                      <span className='form-check-label'>{t('CUSTOM_COLOR')}</span>
                    </label>
                    <br />
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-xl-8'>
            <div className='sidebar default'>
              <div className='sidebar-content'>
                <h4>{t('CUSTOM_STYLE', {count: 0})}</h4>
                {customStyleUrl?.map((url: string, index: number) => (
                  <div key={index} className='sidebar-section border-0 d-flex align-items-center'>
                    <input
                      type={'url'}
                      name='cssFileUrl'
                      placeholder={t('PLACEHOLDER_URL')}
                      value={url}
                      className='form-control form-control-solid'
                      onChange={(e) => onAddUrl(e, index)}
                    />
                    <a className='btn btn-light-danger ms-1' onClick={() => onAddUrl('', index)}>
                      x
                    </a>
                  </div>
                ))}
                <div className='w-100 d-flex flex-colmun'>
                  <div className='m-auto'>
                    <a
                      className='btn btn-sm btn-light-primary'
                      onClick={() => {
                        customStyleUrl.push('')
                        setCustomStyleUrl([...customStyleUrl])
                      }}
                    >
                      + {t('ADD')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card mb-xl-8'>
            <div className='sidebar default'>
              <div className='sidebar-content'>
                <h4>{t('SIZE')}</h4>
                <div className='sidebar-section'>
                  {renderInput('height', t('HEIGHT'))}
                  {renderInput('width', t('WIDTH'))}
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-xl-8'>
            <div className='sidebar default'>
              <div className='sidebar-content'>
                <h4>{t('SUCCESS_MESSAGE')}</h4>
                <div className='sidebar-section'>
                  {renderTextarea('title', t('TITLE', {count: 0}))}
                  {renderTextarea('subTitle', t('SUB_TITLE', {count: 0}))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='form-content' className='col-xl-6'>
          <div className='col-12'>
            <CustomizeForm data={{...data}} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormAppearance
