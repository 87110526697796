/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useRef} from 'react'
import {useIntl} from 'react-intl'
import Tags from '@yaireo/tagify/dist/react.tagify'
import "@yaireo/tagify/dist/tagify.css" 
import {
  inPageCodeForBot,
  inPageCodeForForm,
  inPageCodeForSmartBlock,
  inPopupCodeForBot,
  inPageCodeForSmartPopup,
} from '../../../utils/code.utils'
import {getGlobalItem, LANGUAGES} from '../../../utils/utils'
import SelectInputFeild from '../../shared/SelectInputFeild'
import TextInputFeild from '../../shared/TextInputFeild'
import TextModal from '../common/modal/TextModal'
import { getPopupList, getShowingList, isCustomToolField } from '../../../utils/module.utils'

type CodeProps = {
  from: any
  template?: any
  setTemplate?: any
}

export const Code: FC<CodeProps> = ({from, template, setTemplate}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [active, setActive] = useState<Number>(0)
  const [moduleId, setModuleId] = useState('')
  const workspaceId = getGlobalItem('user')?.workspaceId
  const [selected, setSelected]: any = useState({})
  const [oldVersion, setOldVersion] = useState(false)
  const [showSwalModal, setShowSwalModal] = useState(false)
  const [language, setLanguage]: any = useState('fi')
  const [intentTab, setIntentTab]: any = useState(0)
  const [includeInpage, setIncludeInPage]:any = useState()
  const [excludeInpage, setExcludeInPage]:any = useState()
  const tagifyIncludeInpageRef: any = useRef()
  const tagifyExcludeInpageRef: any = useRef()
  
  useEffect(() => {
    setModuleId(template?._id)
    if (from === 'smartpopup') setActive(1)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?._id])

  useEffect(() => {
    onAppearanceLoad(template?.appearence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.appearence?.popup])

  useEffect(() => {
    onAppearanceLoad(template?.botCode?.appearance3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.botCode?.appearance3?.popup])

  useEffect(() => {
    onAppearanceLoad(template?.botCode?.appearance3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.botCode?.appearance3?.includeInpage, template?.botCode?.appearance3?.excludeInpage])

  useEffect(() => {
    const appearance = template?.botCode?.appearance3
    if (appearance && !appearance.includeInpage) onAppearanceLoad(template?.botCode?.appearance3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.botCode?.appearance3])

  useEffect(() => {
    onAppearanceLoad(template?.appearence)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.appearence?.includeInpage, template?.appearence?.excludeInpage])

  useEffect(() => {
    const appearance = template?.appearence
    if (appearance && !appearance.includeInpage) onAppearanceLoad(appearance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template?.appearence])

  useEffect(() => {
    if (includeInpage) onInputChange("includeInpage", includeInpage) 
    // eslint-disable-next-line
  }, [includeInpage])

  useEffect(() => {    
     if (excludeInpage) onInputChange("excludeInpage", excludeInpage) 
    // eslint-disable-next-line
  },[excludeInpage])

  const onAppearanceLoad = (appearance: any) => {
    if (!appearance) return
    const {delay, time_after_submit, time_before_submit, popup, includeInpage, excludeInpage, repeatedType, exitIntent} = appearance
    if (!appearance.hasOwnProperty('includeInpage')) setIncludeInPage(["/*"])
    setSelected({popup: popup || 'click', delay, time_after_submit, time_before_submit, includeInpage, excludeInpage, repeatedType, exitIntent})
  }

  const onCopy = async (id: string) => {
    if (navigator.clipboard.writeText) {
      const el: any = document.getElementById(id)
      await navigator.clipboard.writeText(el.value)
      setShowSwalModal(true)
    }
  }

  const onInputChange = (name: string, value: any) => {
    const appearance = {...selected, [name]: value}
    if (from === 'bot')
      template.botCode.appearance3 = {...template.botCode.appearance3, ...appearance}
    else {
      template.appearence = {...template.appearence, ...appearance}
      if (value === 'click' && appearance?.repeatedType) delete template.appearence.repeatedType
    }  
    setSelected(appearance)
  }

  const onTagifyInputChange =  (name: string, e: any) => {
    let data: any = []
    const arr = e.detail.value ? JSON.parse(e.detail.value) : []
    arr.forEach((item: any) => (data.push(item.value)))
    name === 'includeInpage' ? setIncludeInPage(data) : setExcludeInPage(data)
  }

  const onBlur = () => setTemplate(template)

  const getScriptCode = () => {
    if (from === 'bot') {
      return !active
        ? inPageCodeForBot(moduleId, workspaceId, false, oldVersion, language)
        : inPopupCodeForBot(moduleId, workspaceId, false, oldVersion, language)
    } else if (['form', 'file'].includes(from)) {
      return !active
        ? inPageCodeForForm(moduleId, workspaceId, false, active === 0, !oldVersion, language)
        : inPageCodeForForm(moduleId, workspaceId, false, active === 0, !oldVersion, language)
    } else if (from === 'smartblock') {
      const isCustomTool = template?.code ? isCustomToolField(JSON.parse(template.code)) : false;
      return inPageCodeForSmartBlock(moduleId, workspaceId, false, active === 0, language, isCustomTool)
    } else if (from === 'smartpopup') return inPageCodeForSmartPopup(moduleId, workspaceId, false, language);
    else {
      return ''
    }
  }

  const renderCodeInput = (id: string) => (
    <>
      {active === 0 && <label className='form-label '>{t('INSTALLATION_CODE')}</label>}
      {active === 1 &&
        (['smartblock', 'smartpopup'].includes(from) ? (
          <label className='form-label '>{t('INSTALLATION_CODE')}</label>
        ) : (
          <label
            className='form-label position-absolute text-muted'
            style={{zIndex: 1, top: '-45px'}}
          >
            {t('INSTALLATION_CODE')}
          </label>
        ))}
      <div>
        <textarea
          style={{
            height: '250px',
            resize: 'none',
            whiteSpace: 'pre',
            overflowWrap: 'normal',
            overflowX: 'scroll',
            border: 0,
          }}
          spellCheck='false'
          value={getScriptCode()}
          id={id}
          className='form-control'
          data-kt-autosize='true'
          readOnly
        ></textarea>
        <button onClick={() => onCopy(id)} className='btn btn-sm btn-primary mt-4'>
          {t('COPY_CODE')}
        </button>
      </div>
    </>
  )

  const renderLabel = (label: string) => (
    <label className='d-flex align-items-center fs-5 fw-bolder mb-2'>{label}</label>
  )

  const renderSwitchInput = () => (
    <label className='form-check form-check-custom form-check-solid form-switch m-1'>
      <input
        className='form-check-input'
        type='checkbox'
        name='layout-builder'
        checked={!oldVersion}
        style={{height: '1.75rem'}}
        onChange={() => setOldVersion(!oldVersion)}
      />
      <span className='form-check-label text-muted mx-3'>{t('IFRAME_VERSION')}</span>
    </label>
  )

  const renderTagifyInput = (name: string) => {
    if (name === 'includeInpage' && tagifyIncludeInpageRef && tagifyIncludeInpageRef.current) {
      tagifyIncludeInpageRef.current.settings.blacklist = selected.excludeInpage || []
    } else if (name === 'excludeInpage' && tagifyExcludeInpageRef && tagifyExcludeInpageRef.current) {
      tagifyExcludeInpageRef.current.settings.blacklist = selected.includeInpage || []
    }
    
    return <Tags
      className='form-control form-control-solid'
      tagifyRef={name === 'includeInpage' ? tagifyIncludeInpageRef : tagifyExcludeInpageRef}
      settings={{
        trim: true,
        pasteAsTags: true,
        pattern:/[/]/,
      }}
      value={selected[name]}
      onChange={(e)=> onTagifyInputChange(name, e)}
    />
  }

  const onChnageMobileIntent = (e: any) => {
    const {name, checked} = e.target;
    const intentList = [...selected.exitIntent.mobile]
    checked ? intentList.push(name) : intentList.splice(intentList.indexOf(name),1);
    onInputChange("exitIntent", {...selected.exitIntent, mobile: intentList })
  } 

  const onChangeDesktopIntent = (e: any) => {
    const {name, checked} = e.target;
    const intentList = {...selected.exitIntent.desktop}
    intentList[name] = checked 
    onInputChange("exitIntent", {...selected.exitIntent, desktop: {...intentList}})
  }

  const renderCheckbox = (name: string, label: string, type: string, onChange: any) => (
    <div className='mb-4'>
      <div className='d-flex align-items-center'>
        <input
          id={name}
          name={name}
          className='form-check-input m-0 me-2  '
          type='checkbox'
          checked={
            type === 'desktop'
              ? selected?.exitIntent?.desktop[name]
              : selected.exitIntent.mobile.includes(name)
          }
          onChange={(e) => { 
            onChange(e)
            onBlur()
          }}
        />
        <label className='fs-5 fw-semibold cursor-pointer' htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  )

  const renderTab = (label: any, index: any) => (
    <li className='nav-item' onClick={() => setIntentTab(index)}>
      <a
        className={`nav-link text-active-primary m-0 me-6 fs-5  cursor-pointer ${
          intentTab === index ? 'active' : ''
        }`}
      >
        {label}
      </a>
    </li>
  )

  return (
    <>
      {showSwalModal ? (
        <TextModal
          message={t('COPIED_MSG')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <h3 className='card-title align-items-start flex-column'>
        <span className='fw-bolder mb-2 text-dark'>{t('INSTALL')}</span>
        <p className='text-muted fw-bold fs-7'>{t('INSTALL_SUB_TITLE')}</p>
      </h3>
      <div className='rounded border d-flex flex-column'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
          {from !== 'smartpopup' && 
            <li className='nav-item' onClick={() => setActive(0)}>
              <a
                className={`nav-link ${active === 0 ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
              >
                {t('IN_PAGE')}
              </a>
            </li>
          }
          <li className='nav-item' onClick={() => setActive(1)}>
            <a
              className={`nav-link ${active === 1 ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_2'
            >
              {t('POPUP')}
            </a>
          </li>
        </ul>
        <div className='tab-content row' id='myTabContent'>
          {['form', 'smartblock', 'smartpopup', 'file', 'bot'].includes(from) && (
            <div className='card p-8 mb-2 col-4'>
              <h4>{active === 1 ? t('POPUP_SETTINGS') : t('INPAGE_SETTINGS')}</h4>
              <SelectInputFeild
                name='language'
                value={language}
                options={LANGUAGES}
                onChange={(_name: string, value: any) => setLanguage(value)}
              />
              <br/>
              <label className='fs-7 fw-bolder'>{t("INCLUDE_INPAGE")}</label>
              {renderTagifyInput('includeInpage')}
              <br/>
              <label className='fs-7 fw-bolder'>{t("EXCLUDE_INPAGE")}</label>
              {renderTagifyInput('excludeInpage')}
              <br/>
              {active === 1 && (
                <>
                  <SelectInputFeild
                    name='popup'
                    value={selected.popup}
                    options={getPopupList(t, from)}
                    onChange={(name: string, value: any) => {
                      const intentList = {
                        desktop: {
                          top: true,
                          sides: true,
                          sensitivity: 10,
                        },
                        mobile: ['upScroll', 'backClick', 'tabChange'],
                        scrollSpeed: -150,
                      }
                      if (value === "exitIntent") {
                        setIntentTab(0)
                        selected["exitIntent"] = intentList;
                      }
                      onInputChange(name, value)
                    }}
                    onBlur={onBlur}
                  />
                  <hr />
                  {['form', 'smartblock', 'smartpopup', 'file'].includes(from) && selected.popup === 'click' && (
                    <>
                      {renderLabel(t('BUTTON_ID'))}
                      <textarea
                        style={{
                          height: '115px',
                          resize: 'none',
                          whiteSpace: 'pre-wrap',
                          border: 0,
                        }}
                        spellCheck='false'
                        value={`button-${moduleId}-${workspaceId}-popup`}
                        id='copyButtonId'
                        className='form-control form-control-lg form-control-solid'
                        data-kt-autosize='true'
                        readOnly
                      ></textarea>
                      <button
                        onClick={() => onCopy('copyButtonId')}
                        className='btn btn-sm btn-primary mt-4'
                      >
                        {t('COPY')}
                      </button>
                    </>
                  )}
                  {['scroll', 'load'].includes(selected.popup) && (
                    <>
                      <div className='fv-row mb-5'>
                        {renderLabel(
                          selected.popup === 'scroll' ? t('HEIGHT_INFO') : t('TIME_INFO')
                        )}
                        <TextInputFeild
                          type='number'
                          name='delay'
                          value={selected.delay}
                          onChange={(name: string, value: any) => onInputChange(name, value)}
                          onBlur={onBlur}
                        />
                      </div>
                      {/* <div className='fv-row mb-5'>
                      {renderLabel(`${t('TIME_BEFORE_SUBMIT')} (${t('IN_MINUTES')})`)}
                      <TextInputFeild
                        type='number'
                        name='time_before_submit'
                        value={selected.time_before_submit}
                        onChange={(name: string, value: any) => onInputChange(name, value)}
                        onBlur={onBlur}
                      />
                    </div>
                    <div className='fv-row mb-5'>
                      {renderLabel(`${t('TIME_AFTER_SUBMIT')} (${t('IN_MINUTES')})`)}
                      <TextInputFeild
                        type='number'
                        name='time_after_submit'
                        value={selected.time_after_submit}
                        onChange={(name: string, value: any) => onInputChange(name, value)}
                        onBlur={onBlur}
                      />
                    </div> */}
                    </>
                  )}
                  {['exitIntent'].includes(selected.popup) && (
                    <>
                    <div className='w-100 d-flex align-items-center mb-4'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap'>
                          {renderTab(t('DESKTOP'), 0)}
                          {renderTab(t('MOBILE'), 1)}
                        </ul>
                      </div>
                      <div className='fv-row'>
                        {renderLabel(t('INTENT', {count: 1}))}
                        {intentTab === 1 ? (
                          <>
                            {renderCheckbox('upScroll', t("UP_SCROLL"), "mobile", onChnageMobileIntent)}
                            {renderCheckbox('backClick', t("BACK_CLICK"), "mobile", onChnageMobileIntent)}
                            {renderCheckbox('tabChange', t("TAB_CHANGE"), "mobile", onChnageMobileIntent)}
                            {selected?.exitIntent?.mobile.includes("upScroll") && 
                              <>
                                <label className='fs-5 fw-bolder mb-2'>{t('SENSITIVITY')}</label>
                                <label className='fs-6 fw-semibold ms-1'>({t("UP_SCROLL")})</label>
                                <SelectInputFeild
                                  name='scrollSpeed'
                                  classes="mb-5"
                                  value={selected?.exitIntent?.scrollSpeed}
                                  options={[{text: t("MEDIUM"), value: -150 }, {text: t("HIGH"), value: -80 }]}
                                  appendOption={{name: "LOW", value: -200 }}
                                  onChange={(name: any, value: any) => {
                                    selected.exitIntent[name] = Number(value) 
                                    onInputChange("exitIntent", {...selected.exitIntent})
                                  }}
                                  onBlur={onBlur}
                                />
                              </>
                            }
                          </>
                        ) : (
                          <>
                            {renderCheckbox('top', t("EXIT_INTENT_TOP"), "desktop", onChangeDesktopIntent)}
                            {renderCheckbox('sides', t("EXIT_INTENT_SIDES"), "desktop", onChangeDesktopIntent)}
                            {renderLabel(t('SENSITIVITY'))}
                            <SelectInputFeild
                              name='sensitivity'
                              classes="mb-5"
                              value={selected?.exitIntent?.desktop.sensitivity}
                              options={[{text: t("MEDIUM"), value: 25 }, {text: t("HIGH"), value: 50 }]}
                              appendOption={{name: "LOW", value: 1 }}
                              onChange={(name: any, value: any) => {
                                const intentList = {...selected.exitIntent.desktop}
                                intentList[name] = Number(value) 
                                onInputChange("exitIntent", {...selected.exitIntent, desktop: {...intentList}})
                              }}
                              onBlur={onBlur}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {['smartblock', 'smartpopup'].includes(from) && ['scroll', 'load', 'exitIntent'].includes(selected.popup) &&
                  <>
                    {renderLabel(t("REPEATED_SHOWING"))}
                    <SelectInputFeild
                      name='repeatedType'
                      value={selected.repeatedType}
                      appendOption={{name: 'ONCE_A_SESSION', value: ''}}
                      options={getShowingList(t)}
                      onChange={(name: string, value: any) => onInputChange(name, value)}
                      onBlur={onBlur}
                    />
                  </>
                  }
                </>
              )}
            </div>
          )}
          <div
            className={`tab-pane fade position-relative col-8 ${active === 0 ? 'show active' : ''}`}
            id='kt_tab_pane_1'
            role='tabpanel'
          >
            {renderSwitchInput()}
            {renderCodeInput('code-area')}
          </div>
          <div
            className={`tab-pane fade position-relative ${active === 1 ? 'show active' : ''} ${
              ['form', 'smartblock', 'smartpopup', 'file', 'bot'].includes(from) && active === 1 && 'col-8'
            }`}
            id='kt_tab_pane_2'
            role='tabpanel'
          >
            {renderSwitchInput()}
            {renderCodeInput('popup-area')}
          </div>
          <div
            className={`tab-pane fade ${active === 2 ? 'show active' : ''}`}
            id='kt_tab_pane_3'
            role='tabpanel'
          >
            Sint sit mollit irure quis est nostrud cillum consequat Lorem esse do quis dolor esse
            fugiat sunt do.
          </div>
        </div>
      </div>
    </>
  )
}
