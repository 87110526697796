/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from 'react'
import {useIntl} from 'react-intl'
import { getIcon } from '../../../../utils/utils'
type Props = {
  averageVisitor: any
  averageConverstation: any
  totalApps: any
  title: string,
  subTitle: string
}

const OverviewList: React.FC<Props> = ({title,subTitle,averageVisitor,averageConverstation,totalApps}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [visitorCount, setVisitorCount]:any = useState(0)
  const [conversationCount, setConversationCount]:any = useState(0)
 
  useEffect(()=>{   
    setVisitorCount(Math.ceil(averageVisitor))
    setConversationCount(Math.ceil(averageConverstation))
  },[averageVisitor,averageConverstation])
  

  return (
    <div className={`card card-xxl-stretch-100 mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5 align-items-center'>
        <h3 className='card-title align-items-start flex-column justify-content-between'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-bold fs-7'>{subTitle}</span>
        </h3>
      </div>
      <div className='card-body d-flex flex-column p-0'>
        

        <div
          className=' mx-6 px-4 my-1 position-relative z-index-1 bg-body'
         
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-2'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
              <img
                            src={
                              `/media/icons/custom/user.svg`
                            }
                            className='h-50 align-self-center'
                            alt=''
                          />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
              <div className='text-gray-800 fw-bolder text-hover-primary fs-5'>
                {t('DASHBOARD_AVERAGE_AVERAGE_VISITOR', {count: 0})}
                </div>
                <div className='text-gray-400 fw-semibold fs-7'>{t('DASHBOARD_AVERAGE_AVERAGE_VISITOR_SUBTITLE', {count: 0})}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='text-primary fw-bolder fs-4 me-3'>{visitorCount}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          <div className='d-flex align-items-center mb-3'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
              <img
                            src={
                              `/media/icons/custom/growth_apps_icon.svg`
                            }
                            className='h-50 align-self-center'
                            alt=''
                          />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <div className='text-gray-800 fw-bolder text-hover-primary fs-5'>
                {t('DASHBOARD_ACTIVE_APPS', {count: 0})}
                </div>
                <div className='text-gray-400 fw-semibold fs-7'>{t('DASHBOARD_ACTIVE_APPS_SUBTITLE', {count: 0})}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='text-primary fw-bolder fs-4 me-3'>{totalApps?totalApps:0}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-2'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
              <img
                            src={
                              `/media/icons/custom/sessions_icon.svg`
                            }
                            className='h-50 align-self-center'
                            alt=''
                          />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
              <div className='text-gray-800 fw-bolder text-hover-primary fs-5'>
                {t('DASHBOARD_AVERAGE_STARTED_CONVERSATION', {count: 0})}
                </div>
                <div className='text-gray-400 fw-semibold fs-7'>{t('DASHBOARD_AVERAGE_STARTED_CONVERSATION_SUBTITLE', {count: 0})}</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='text-primary fw-bolder fs-4 me-3'>{conversationCount}</div>
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}

        </div>
      </div>
    </div>


  )
}

export default OverviewList

