import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import TextInputFeild from '../../../shared/TextInputFeild'
import SelectInputFeild from '../../../shared/SelectInputFeild'
import { getWorkspaceStatus, LANGUAGES } from '../../../../utils/utils'
import { getSubscriptionPlan, getWorkspace, createWorkspace, updateWorkspace } from '../../../../service/workspace.service'
import { getAllPartner } from '../../../../service/partner.service'
import moment from 'moment'
type WorkspaceModalProps = {
  workspace?: any
  setWorkspace?: any
  setMapWorkspace?: any
}

interface Workspace {
  name: string,
  domain: string,
  subscriptionPlanId: string,
  language?: string,
  status?: string,
  config?: object,
  expireDate?: any,
}

const workspaceSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(`${t('REQUIRED', { field: t('CUSTOMER', { count: 0 }) })}`),
    domain: Yup.string().required(`${t('REQUIRED', { field: t('DOMAIN') })}`),
    subscriptionPlanId: Yup.string().required(`${t('REQUIRED', { field: t('SUBSCRIPTION_PLAN') })}`),
    language: Yup.string().required(`${t('REQUIRED', { field: t('LANGUAGE') })}`),
    config: Yup.object().shape({
      smartChatAction: Yup.boolean()
    }),
  })

const initialValues: Workspace = {
  name: '',
  domain: '',
  subscriptionPlanId: '',
  language: '',
  config: {
    smartChatAction: false
  },
  expireDate: undefined
}

const WorkspaceModal: FC<WorkspaceModalProps> = ({ workspace, setWorkspace, setMapWorkspace }) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [partner, setPartner] = useState([])
  useEffect(() => {
    getSubscriptionPlan().then((list) => {
      setSubscriptionPlan(list)
    })

    // getAllPartner().then((list) => {
    //   setPartner(list)
    // })
    const el = document.getElementById('gw_workspace_modal_button')
    if (el) {
      el.addEventListener('click', () => {
        formik.resetForm()
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const elEditBtn = document.getElementById('gw_workspace_edit_modal_button')
    if (elEditBtn) {
      elEditBtn.addEventListener('click', () => {
        formik.resetForm()
        formik.setValues(workspace)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace])

  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    validationSchema: workspaceSchema(t),
    onSubmit: (values) => {
      if (workspace) {
        const { name, domain, status, language, config, subscriptionPlanId,expireDate } = values
        updateWorkspace(workspace._id, { name, domain, status, language, config, subscriptionPlanId, expireDate:expireDate?expireDate:'' }).then((data) => {
          setWorkspace({...data,expireDate:data.expireDate?data.expireDate:""})
        })
      } else {
        createWorkspace(values).then(() => {
          getWorkspace({}).then((data)=> {
            setMapWorkspace(data)
          })
        })
        formik.resetForm()
      }
    },
  })

  const renderLabel = (label: string) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
      <span className='required'>{label}</span>
    </label>
  )

  const dateHandler = (e:any)=>{
    const {name, value} = e.target;
    !value?formik.setFieldValue(name,''):formik.setFieldValue(name,value)
  }
  const validationForm = ()=>{
    if(
      formik?.values.name===''||
      formik?.values.domain===''||
      formik?.values.subscriptionPlanId===''||
      formik?.values.language===''
    )
    return false
    else return true
  }
  return (
    <div className='modal fade' tabIndex={-1} id='gw_workspace_modal'>
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>
              {t(workspace ? 'EDIT' : 'CREATE')} {t('TITLE_WORKSPACE_MODAL', { count: 0 })}
            </h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label={t('CLOSE')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='gw_workspace_modal_form'
            >
              <div className='fv-row mb-5'>
                {renderLabel(`${t('CUSTOMER', { count: 0 })}`)}
                <TextInputFeild formik={formik} name='name' type='text' />
              </div>
              <div className='fv-row mb-5'>
                {renderLabel(`${t('DOMAIN')}`)}
                <TextInputFeild formik={formik} name='domain' type='text' />
              </div>
              {workspace && (
                <div className='fv-row mb-5'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>{`${t('STATUS')}`}</span>
                  </label>
                  <SelectInputFeild formik={formik} name='status' options={getWorkspaceStatus(t)} />
                </div>
              )}
              <div className='fv-row mb-5'>
                {renderLabel(`${t('SUBSCRIPTION_PLAN')}`)}
                <SelectInputFeild
                  formik={formik}
                  name='subscriptionPlanId'
                  options={subscriptionPlan}
                  format={{ value: '_id', text: 'name' }}
                />
              </div>
              <div className='fv-row mb-5'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>{`${t('EXPIRE_DATE')}`}</span>
                </label>
                <input 
                  type="date" 
                  name='expireDate' 
                  className='form-control form-control-lg form-control-solid'
                  onChange={dateHandler}
                  value={formik?.values['expireDate'] ? moment(formik?.values['expireDate']).format('YYYY-MM-DD') : formik?.values['expireDate']} 
                />
              </div>
              {
                // partner &&
                // <div className='fv-row mb-5'>
                //     {renderLabel(`${t('PARTNER')}`)}
                //     <SelectInputFeild
                //       formik={formik}
                //       name='partnerId'
                //       options={partner}
                //       format={{ value: '_id', text: 'name' }}
                //     />
                //   </div>
              }
              <div className='fv-row mb-5'>
                {renderLabel(`${t('LANGUAGE')}`)}
                <SelectInputFeild
                  formik={formik}
                  name='language'
                  options={LANGUAGES}
                />
              </div>
                <div className='fv-row mb-5'>
                  <label className='form-check form-check-custom form-check-solid form-switch'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span>{`${t('SMART_CHAT_ACTION')}`}</span>
                    </label>
                    <TextInputFeild
                      formik={formik}
                      type='checkbox'
                      name='config.smartChatAction'
                      checked={formik.getFieldProps('config.smartChatAction').value}
                    />
                  </label>
                </div>
              <div className='fv-row'>
                <div className='text-center '>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    onClick={() => formik.resetForm()}
                  >
                    {t('RESET')}
                  </button>
                  <button
                    type='submit'
                    data-bs-dismiss={`${!validationForm() ? '' : 'modal'}`}
                    className='c-button c-button--type-solid'
                  >
                    <span className='indicator-label'>
                      {t('SAVE')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkspaceModal
