import {Editor} from '@tinymce/tinymce-react'
import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Collapsible from 'react-collapsible'
import Option from './Option'
import Fields from './Fields'
import TextInputFeild from '../../../../../../shared/TextInputFeild'
import ColorPickerInput from '../../../../../../shared/ColorPickerInput'
import ImageInputFeild from '../../../../../../shared/ImageInputFeild'
import { updateImage } from '../../../../../../../service/module.service'
import { getFileData } from '../../../../../../../utils/utils'

type SlideTextTabProps = {
  selectedSlide?: any
  setSelectedSlide?: any
  onSave?: any
  moduleCode?: any
}

interface RouteParams {
  moduleId: string
}

const SlideTextTab: FC<SlideTextTabProps> = ({
  selectedSlide,
  setSelectedSlide,
  onSave,
}) => {
  const intl = useIntl()
  const t: any = (id: any, values: any) => intl.formatMessage({id}, values || {})
  const {moduleId} = useParams<RouteParams>()

  const onContentSave = (name: any, value: any) => {
    selectedSlide.data.content[name] = value
    setSelectedSlide({...selectedSlide})
  }

  const onContentStyleSave = (name: any, value: any) => {
    selectedSlide.data.style = {
      ...selectedSlide.data.style,
      [name]: value,
    }
    setSelectedSlide({...selectedSlide})
  }

  const onFieldSave = (name: string, value: any) => {
    selectedSlide.data.content[name] = value
    setSelectedSlide({...selectedSlide})
  }

  const CollapsibleTrigger = (props: any) => (
    <div className='w-100 p-2 bg-secondary'>
      <b className='fs-4 fw-semibold mb-2'>{props.text}</b>
    </div>
  )

  const renderInput = (name: string, label: string) => (
    <>
      <label className='fs-5 fw-semibold my-2'>{label}</label>
      <TextInputFeild
        type={'number'}
        name={name}
        value={selectedSlide.data.content[name]}
        onChange={(name: any, value: any) => onContentSave(name, Number(value))}
      />
    </>
  )

  const renderEditor = (key: string, label: any) => (
    <div className='panel-content'>
      <label className='fs-5 fw-semibold mb-2'>{label}</label>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={selectedSlide.data.content[key] || ''}
        onBlur={() => onSave()}
        init={{
          min_height: 150,
          menubar: false,
          branding: false, // hide only brand logo
          setup: (editor) => {
            editor.on('blur', () => {
              onContentSave(key, editor.getContent())
            })
          },
          toolbar: [
            'bold italic bullist numlist outdent indent alignleft aligncenter alignright alignjustify code',
          ],
          plugins: 'link image lists charmap preview code autoresize paste',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif }',
          paste_as_text: true,
        }}
      />
    </div>
  )

  const renderColorPicker = (label: any, name: any) => (
    <div className='panel-content d-flex justify-content-between align-items-center'>
      <label className='fs-5 fw-semibold my-2'>
        <div>{label}</div>
      </label>
      <ColorPickerInput
        name={name}
        value={selectedSlide.data?.style && selectedSlide.data?.style[name]}
        onChange={(key: string, value: string) => onContentStyleSave(key, value)}
      />
    </div>
  )

  const onFileRemove = (name: any) => onContentStyleSave(name, '');

  const onFileUpload = async (e: any, name: any) => {
    if (!e.target.files.length) return
    const data = await updateImage(moduleId, getFileData(e.target.files))
    onContentStyleSave(name, data)
  }

  return (
    <>
      <div>
        <Collapsible overflowWhenOpen={'visible'} trigger={<CollapsibleTrigger text={t('TITLE')} />}>
          {renderEditor('title', t('TITLE'))}
        </Collapsible>
        <br />
        <Collapsible overflowWhenOpen={'visible'} trigger={<CollapsibleTrigger text={t('SUB_TITLE')} />}>
          {renderEditor('paragraph1', t('SUB_TITLE'))}
        </Collapsible>
        <br />
        {['text', 'end'].includes(selectedSlide.type) && (
          <>
            <Collapsible overflowWhenOpen={'visible'} trigger={<CollapsibleTrigger text={t('CONTENT')} />}>
              {renderEditor('paragraph2', t('CONTENT'))}
            </Collapsible>
            <br />
          </>
        )}
        {selectedSlide.type === 'options' && (
          <Option
            selectedSlide={selectedSlide}
            setSelectedSlide={setSelectedSlide}
            onSave={onSave}
            onFieldSave={onFieldSave}
          />
        )}
        {selectedSlide.type === 'content' && (
          <Fields
            selectedSlide={selectedSlide}
            setSelectedSlide={setSelectedSlide}
            onSave={onSave}
            onFieldSave={onFieldSave}
          />
        )}
        {selectedSlide.type === 'end' && <Collapsible overflowWhenOpen={'visible'} trigger={<CollapsibleTrigger text={t('Time')} />}>
          {renderInput('timeDelay', t('TIME_INFO'))}
        </Collapsible>}
        {selectedSlide.type !== 'end' && <Collapsible overflowWhenOpen={'visible'} trigger={<CollapsibleTrigger text={t('BUTTON', {count:0})} />}>
          {renderEditor('buttonText', t('BTN_TEXT'))}
        </Collapsible>}
        <br/>
        <Collapsible
          overflowWhenOpen={'visible'}
          trigger={<CollapsibleTrigger text={t('FLOW_SLIDE', {count: 0})} />}
        >
          <div>
            {renderColorPicker(t('BACKGROUND_COLOR'), 'wrapperBgColor')}
            <div className='panel-content'>
              <label className='fs-5 fw-semibold mb-2'>{t('BACKGROUND_IMAGE', {count: 0})}</label>
              <div className='row'>
                <div  className='col-6'>
                  <label className='fs-5 fw-semibold mb-2'>{t('DESKTOP', {count: 0})}</label>
                  <div>
                    <ImageInputFeild
                      size={{icon: 15, img: 75}}
                      type='rounded'
                      title={t('BACKGROUND_IMAGE')}
                      url={selectedSlide.data?.style?.wrapperBgImage}
                      placeholder={'url(/media/svg/avatars/bgplaceholder.svg)'}
                      element='wrapperBgImage'
                      onUpload={(e: any, name: any) => onFileUpload(e, name)}
                      onRemove={(name: any) => onFileRemove(name)}
                    />
                  </div>
                </div>
                <div  className='col-6'>
                  <label className='fs-5 fw-semibold mb-2'>{t('MOBILE', {count: 0})}</label>
                  <div>
                    <ImageInputFeild
                      size={{icon: 15, img: 75}}
                      type='rounded'
                      title={t('BACKGROUND_IMAGE')}
                      url={selectedSlide.data?.style?.mobileWrapperBgImage}
                      placeholder={'url(/media/svg/avatars/mobileBgplaceholder.svg)'}
                      element='mobileWrapperBgImage'
                      onUpload={(e: any, name: any) => onFileUpload(e, name)}
                      onRemove={(name: any) => onFileRemove(name)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapsible>
      </div>
    </>
  )
}
export default SlideTextTab
