import {useState, FC, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'

type Step5Props = {
  template?: any
  setTemplate?: any
}

const Step5: FC<Step5Props> = ({template, setTemplate}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [emailAddress, setEmailAddress] = useState(null)
  const tagifyRef = useRef()

  useEffect(() => {
    if (emailAddress || emailAddress === '') setTemplate({...template, settings: {notifications: emailAddress}})
    // eslint-disable-next-line
  }, [emailAddress])

  const onChange = (e: any) => {
    let str: any = ''
    const arr = e.detail.value ? JSON.parse(e.detail.value) : []
    arr.forEach((item: any) => (str += `${item.value},`))
    setEmailAddress(str)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-10'>
        <h3 className='mb-9'>{t('SETTINGS_HEADER')}</h3>
        {/* <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
          <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
            <div className='mb-3 mb-md-0 fw-bold'>
              <h4 className='text-gray-900 fw-bolder'>{t('SETTINGS_CARD_HEADER')}</h4>
              <div className='fs-6 text-gray-700 pe-7'>{t('SETTINGS_CARD_SUB_TITLE')}</div>
            </div>
          </div>
        </div> */}
        <div className='my-10'>
          <label className='form-label'>{t('EMAIL_INPUT')}</label>
          <Tags
            className='form-control'
            tagifyRef={tagifyRef} // optional Ref object for the Tagify instance itself, to get access to  inner-methods
            settings={{
              placeholder: t('PLACEHOLDER_EMAIL'),
              trim: true,
              pasteAsTags: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            }}
            value={template?.settings?.notifications}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
}

export default Step5
