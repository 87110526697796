import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Stepper from '../../common/stepper'
import {getTemplateById} from '../../../../service/template.service'
import {getModuleById} from '../../../../service/module.service'

interface RouteParams {
  templateId: string
  moduleId: string
  step: string
}

let templateData: any = {}

const FormCreate: FC = () => {
  const [template, setTemplate] = useState(templateData)
  const {moduleId, step, templateId} = useParams<RouteParams>()

  const getTemplateData = async (type: string) => {
    let data
    if (type === 'template') data = await getTemplateById(templateId)
    if (type === 'module') data = await getModuleById(moduleId)
    if (data) setTemplate(data)
    if (templateData?._id !== data?._id) initTemplate(data)
  }

  useEffect(() => {
    if (templateData?._id !== moduleId) getTemplateData('module')
    // eslint-disable-next-line
  }, [moduleId])

  useEffect(() => {
    if (templateId && templateId !== 'create') getTemplateData('template')
    // eslint-disable-next-line
  }, [templateId])

  const setTemplateData = (value: any) => initTemplate(value)

  const initTemplate = (data: any) => {
    setTemplate(data)
    templateData = data
  }

  return (
    <>
      <Stepper
        from={'form'}
        moduleId={moduleId}
        templateId={templateId}
        step={step}
        templateData={template}
        setTemplateData={setTemplateData}
      />
    </>
  )
}

export default FormCreate
