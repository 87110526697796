/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {getLimitConfig, isBotAccessible} from '../../../../utils/utils'
import GrowthAppFilterMenu from '../../../../app/modules/common/shared/filter/GrowthAppFilterMenu'
import {getModule} from '../../../../service/module.service'
import {useStore} from '../../../../store'
import {observer} from 'mobx-react'
import { filterType, getFilterAppType } from '../../../../utils/module.utils'

type AppsProps = {
  from?: any
  title?: string
  modalId?: string
  isPreview?: boolean
  appView?: string
  setAppView?: any
  setModuleList?: any
  type?: any
  setType?: any
  sortBySession?: any
}

const AppsToolbar: FC<AppsProps> = ({
  from,
  title = 'App',
  modalId,
  isPreview,
  appView,
  setAppView,
  setModuleList,
  type,
  sortBySession,
  setType
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  const {authStore} = useStore()
  const {
    setModuleAccessLimit,
    moduleAccessLimit: {totalCount},
  } = authStore

  const [filterData, setFilterData]: any = useState('')

  const disableBot = from === 'bot' && isBotAccessible()

  const fetchList = async (data: any) => {
    const list = await getModule(data)
    if (list) {
      const {records, totalCount, publishCount} = list
      setModuleList(sortBySession? sortBySession(records):records)
      setModuleAccessLimit(totalCount, publishCount)
    }
  }

  useEffect(() => {
    if (!from) setFilterData({...filterData, type: filterType(type)})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (from) setFilterData({...filterData, type: from})
    // eslint-disable-next-line
  }, [from])

  useEffect(() => {
    if (filterData) fetchList({...filterData})
    // eslint-disable-next-line
  }, [filterData])

  const onSearch = (e: any) => setFilterData({...filterData, search: e.target.value})

  const onApply = () => {
    let filter = filterData
    if (!filter) filter = {}
    if (filterType(type)) filter.type = filterType(type)
    else filter.type = filterType(getFilterAppType)
    setFilterData({...filter})
  }

  const onReset = () => {
    setType(getFilterAppType)
    setFilterData({...filterData, type: filterType(getFilterAppType)})
  }

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        style={{padding: '0px'}}
      >
        <div>
          <SectionHeading
            title={
              title === 'App'
                ? t('GROWTH') + ' ' + t('APP', {count: 1})
                : t(title, {count: 0})
            }
            subTitle={t(`${title.toUpperCase()}_SUB_TITLE`)}
          />
        </div>

        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center position-relative mx-2'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              className='form-control form-control-solid w-300px ps-14 bg-white'
              placeholder={t('SEARCH')}
              onChange={onSearch}
            />
          </div>
          {!isPreview && (
            <>
              <span
                className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                title={t('CARD_VIEW')}
                onClick={() => setAppView('cardView')}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen008.svg'
                  className={`svg-icon-${appView === 'cardView' ? 'primary' : 'dark'} svg-icon-hx`} />
              </span><span
                className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                title={t('TABLE_VIEW')}
                onClick={() => setAppView('tableView')}
              >
                <KTSVG
                  path='/media/icons/duotune/abstract/abs015.svg'
                  className={`svg-icon-${appView === 'tableView' ? 'primary' : 'dark'} svg-icon-hx`} />
              </span>
            </>
          )}

          {!from && (
            <GrowthAppFilterMenu
              type={type}
              setType={setType}
              onApply={onApply}
              onReset={onReset}
              appType={getFilterAppType}
            />
          )}

          {!isPreview && (
            <a
              className={`btn btn-sm me-2 btn-${
                getLimitConfig('total_app', totalCount) ? 'secondary' : 'primary'
              } ${disableBot ? 'pe-none' : ''} text-uppercase`}
              data-bs-toggle={`${getLimitConfig('total_app', totalCount) ? 'tooltip' : 'modal'}`}
              data-bs-target={`${!getLimitConfig('total_app', totalCount) ? `#${modalId}` : ''}`}
              title={`${getLimitConfig('total_app', totalCount) ? t('PUBLISH_TITLE') : ''}`}
              id='kt_toolbar_primary_button'
            >
              <span className='svg-icon svg-icon svg-icon-1'>
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-hx' />
              </span>
              {t('CREATE')} {title === 'App' ? t('App',{count: 0}) : t(title, {count: 0})}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(AppsToolbar)
