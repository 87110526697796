/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {getAppTypePerformanceReport} from '../../../../../service/performanceReport.service'
import {getDayDifference, getDecimal, sortNumber, sortString} from '../../../../../utils/utils'
import ReportTable from '../../../widgets/tables/ReportTable'

interface RouteParams {
  workspaceId: string
}

type Props = {
  dateRange: any
}
const AppTypePerformance: FC<Props> = ({dateRange}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {workspaceId} = useParams<RouteParams>()
  const [toggleSorting, setToggleSorting]: any = useState({
    sortBy: 'type',
    sortDirection: true,
  })
  const [filterData, setFilterData]: any = useState([])
  const [pageData, setPageData]: any = useState([])
  const [activePage, setActivePage]: any = useState(1)

  const onSort = (filterData: any, records: any) => {
    setToggleSorting({...filterData})
    const {sortBy} = filterData
    let sortData =
      sortBy === 'type'
        ? sortString(records, filterData.sortDirection, sortBy)
        : sortNumber(records, filterData.sortDirection, sortBy)
    setFilterData([...sortData])
  }

  useEffect(() => {
    const getAppsData = async () => {
      let records = await getAppTypePerformanceReport({workspaceId, ...dateRange})
      if(!records) return;
      const appsReport = records?.map((data: any) => {
        const visitorVSstarted = data['UNIQUE-VISITOR']
          ? getDecimal((data['SESSION'] / data['UNIQUE-VISITOR']) * 100 || 0)
          : 0
        const startedVSsuccessful = data['SESSION']
          ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['SESSION']) * 100 || 0)
          : 0
        const startedVScontact = data['SESSION']
          ? getDecimal((data['CONTACT'] / data['SESSION']) * 100 || 0)
          : 0
        const visitorsVScontact = data['UNIQUE-VISITOR']
          ? getDecimal((data['CONTACT'] / data['UNIQUE-VISITOR']) * 100 || 0)
          : 0
        const daysSinceInstalled = data['installationDate']
          ? `${getDayDifference(data['installationDate'])}`
          : 0
        const visitorsVSsuccessful = data['UNIQUE-VISITOR']
          ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['UNIQUE-VISITOR']) * 100 || 0)
          : 0

        return {
          ...data,
          visitorVSstarted,
          startedVSsuccessful,
          startedVScontact,
          visitorsVScontact,
          daysSinceInstalled,
          visitorsVSsuccessful
        }
      })
      onSort(toggleSorting, appsReport)
    }
    getAppsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  useEffect(() => {
    const startIndex = activePage * 100 - 100
    const endIndex = activePage * 100
    setPageData([...filterData.slice(startIndex, endIndex)])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, activePage])

  return (
    <div className='card' style={{backgroundColor: '#f5f8fa'}}>
      <div className='card-body' style={{padding: '1rem'}}>
        <div className='d-flex justify-content-between align-items-start flex-wrap'>
          <h4 className='card-title align-items-start flex-column'>
            <span className='text-dark fs-2 fw-bolder me-3 text-capitalize'>
              {t('APPLICATION_TYPE_REPORT', {count: 1})}
            </span>
            <br />
            <span className='text-muted fw-bold fs-7'>{t('APPLICATION_TYPE_REPORT_SUBTITLE', {count: 1})}</span>
          </h4>
        </div>
      </div>
      <ReportTable
        onSort={onSort}
        pageData={pageData}
        filterData={filterData}
        toggleSorting={toggleSorting}
        type={'app_type_report'}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </div>
  )
}
export default AppTypePerformance
