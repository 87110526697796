/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {useHistory} from 'react-router-dom'

const ProfilePageToolbar: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  const history = useHistory()

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack p-0')}
      >
        <div>
          <SectionHeading
            title={t('PROFILE')}
            subTitle={t('PROFILE_SUBTITLE')}
          />
        </div>
        <div className='d-flex align-items-center py-1'>
          <a className='btn btn-primary text-uppercase' onClick={() => history.push('/dashboard')}>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon svg-icon-2' />
            {t('BACK')}
          </a>
        </div>
      </div>
    </div>
  )
}

export {ProfilePageToolbar}
