import axios from 'axios'
import {handleError} from '../utils/utils'

export const sendMail = async (data: any) => {
  try {
    const response = await axios.post(`/api/sendMail`, data)
    return response?.data
  } catch ({response}) {
    handleError(response)
  }
}
