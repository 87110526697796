/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useContext} from 'react'
import { SMARTFLOW_APPEARANCE } from '../../../../utils/module.utils'

export interface PublishEvent {
  time: Date
  text: string
}

export interface SmartFlow {
  _id?: string
  name?: string
  type?: string
  status?: string
  moduleCode?: {
    appearance?: {
      flowSettings?: object,
      style: {
        wrapperHeight: number,
        wrapperFontFamily: string,
        wrapperWidth: string,
        wrapperBgColor: string,
        wrapperBgImage: string,
        mobileWrapperBgImage: string,
        titleColor: string,
        titleFontSize: number,
        titleFontWeight: number,
        titleLineHeight: number,
        titleFontStyle: string,
        titleTop: number,
        titleWrapPadding: string,
        subtitleColor: string,
        subtitleFontSize: number,
        subtitleFontWeight: number,
        subtitleLineHeight: number,
        subtitleFontStyle: string,
        subtitleTop: number,
        subtitleWrapPadding: string,
        contentColor: string,
        contentFontSize: number,
        contentFontWeight: number,
        contentLineHeight: number,
        contentFontStyle: string,
        contentTop: number,
        contentWrapPadding: string,
        optionBgColor: string,
        opTitleColor: string,
        opTitleFontSize: number,
        opTitleFontWeight: number,
        opTitleLineHeight: number,
        opTitleFontStyle: string,
        opSubtitleColor: string,
        opSubtitleFontSize: number,
        opSubtitleFontWeight: number,
        opSubtitleLineHeight: number,
        opSubtitleFontStyle: string,
        opImgOutline: string,
        opImgOutlineColor: string,
        opImgHoverOutlineColor: string,
        formLabelColor: string,
        formLabelFontSize: number,
        formLabelFontWeight: number,
        formLabelLineHeight: number,
        formLabelFontStyle: string,
        fieldTextColor: string,
        formMultiColumn: number,
        formFieldBGPercentage: number,
        footerTop: number,
        submitColor: string,
        submitFontSize: number,
        submitBgColor: string,
        submitHoverColor: string,
        groweoFontColor: string,
        groweoIconColor: string,
        navigationBtnColor: string,
        badgeColor: string,
        badgeDisableColor: string,
        fieldPlaceholderColor: string,
        closeBgColor: string,
        closeIconColor: string,
        closeBorderRadius: string,
        closeMargin: string,
        mobileZoom: string,
      },
    }
    nodes?: Array<[]>,
    elements: Array<[]>,
  }
  publishEvent?: Array<PublishEvent>
  workspaceId?: string
}

export const DefaultSmartFlow: SmartFlow = {
  name: '',
  type: '',
  status: '',
  moduleCode: {
    appearance: SMARTFLOW_APPEARANCE,
    nodes: [],
    elements: [],
  },
  publishEvent: []
}

export interface StepperContextModel {
  smartFlow?: SmartFlow,
  setSmartFlow: (_smartFlow: SmartFlow) => void
}

const StepperContext = createContext<StepperContextModel>({
  smartFlow: DefaultSmartFlow,
  setSmartFlow: function (_smartFlow: SmartFlow): void {
    throw new Error('Function not implemented.')
  }
})

const StepperProvider: React.FC<any>  = ({children, smartFlow, setSmartFlow}) => {
  const value: StepperContextModel = {
    smartFlow,
    setSmartFlow,
  }
  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
}

function useSmartFlowData() {
  return useContext(StepperContext)
}

export {StepperProvider, useSmartFlowData}
