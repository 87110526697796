import {FC} from 'react'
import {useIntl} from 'react-intl'
import {getModulePath} from '../../../../utils/module.utils'
import {isWorkspaceReadonly} from '../../../../utils/utils'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

type Props = {
  data?: any
}

const StepperBreadCrumb: FC<Props> = ({data}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {name, from, moduleId} = data
  const updateStep = (step: string) => {
    return `${getModulePath(from)}/${moduleId}/${step}`
  }

  const stepperBreadCrumb = [
    {
      title: t('SLIDE', {count: 1}),
      path: `${updateStep('slides')}`,
      isActive: true,
    },
    {
      title: t('FLOW_BUILDER'),
      path: `${updateStep('design')}`,
      isActive: true,
    },
    {
      title: t('INSTALL'),
      path: `${updateStep('code')}`,
      isActive: false,
    },
  ]

  return <PageTitle breadcrumbs={stepperBreadCrumb}>{name}</PageTitle>
}

export default StepperBreadCrumb
