/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useParams, useHistory } from 'react-router-dom'

const NewPasswordSet = () => {
  const history = useHistory();
  const [password, setPassword] = useState('')
  const [repassword, setRePassword] = useState('')
  const [textType, setTextType] = useState('password')
  const [loading, setLoading] = useState(false)

  let params = useParams<any>();
  const token = params.token

  const submitBut = async () => {
    try {
      setLoading(true)
      await axios.post('/api/password', { token, password })
      setLoading(false)
      Swal.fire({
        text: "successfully reset your password, Try with new password",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      }).then(function () {
        history.push('/auth/login')
      });
    } catch (err) {
      setLoading(false)
      Swal.fire({
        text: "Your token is expired, please try with new token.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      });
    }
  }

  const submitButtonDisable = !password || !repassword ? true : false
  const confirm = password && repassword && repassword !== password ? true : false

  const UpdateTextType = () => {
    const type = textType === 'password' ? 'text' : 'password'
    setTextType(type)
  }

  return (
    <div className="d-flex flex-column">
      <div className="mb-10">
        <h1 className='text-light mb-3'>Setup New Password</h1>
      </div>
      <div className="w-400px mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
        <label className='form-label fw-bolder text-light fs-6 mb-0'>Password</label>
          <div className="position-relative mb-3">
            <input
              type={textType}
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control form-control-lg form-control-solid"
            />
            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={UpdateTextType}>
              <i className="bi bi-eye-slash fs-2"></i>
              <i className="bi bi-eye fs-2 d-none"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="w-400px fv-row mb-10">
      <label className='form-label fw-bolder text-light fs-6 mb-0'>Confirm Password </label>
        <input
          type="password"
          value={repassword}
          name="confirm-password"
          onChange={(e) => setRePassword(e.target.value)}
          className="form-control form-control-lg form-control-solid"
        />
        {confirm && <p style={{ color: 'red' }}>Your password and confirmation password do not match</p>}
      </div>
      <div className="text-center">
        <button
          type="button"
          onClick={submitBut}
          id="kt_new_password_submit"
          disabled={submitButtonDisable || confirm}
          className="c-button c-button--type-solid w-100 mb-5"
        >
          {!loading && (
            <span className="indicator-label">Submit</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default NewPasswordSet
