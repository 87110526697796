import {FC, useState} from 'react'
import {toJS} from 'mobx'
import {useIntl} from 'react-intl'
import Statistics from '../../stepper/steps/Statistics'
import {KTSVG} from '../../../../../_metronic/helpers'

type ModuleLibraryCardProps = {
  templateList: any
  from?: any
  template?: any
  setTemplate?: any
  onSearch?: any
}

const ModuleLibraryCard: FC<ModuleLibraryCardProps> = ({
  templateList,
  from,
  setTemplate,
  onSearch,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [current, setCurrent] = useState('')

  const onClickUse = (e: any) => {
    setCurrent(e._id)
    if(!e?.json) return
    const data: any = JSON.parse(e.json)
    if(data?.content) delete data.content
    setTemplate({ ...data })
  }

  return (
    <>
      <div className='d-flex justify-content-between me-10 my-2 align-items-center mt-2'>
        <span className='align-items-center'>
          <h4 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-2 mb-1'>{t('LIBRARY', {count:1})}</span>
            <br />

            <span className='text-muted fw-bold fs-7'>{t(`LIBRARY_SUBTITLE`)}</span>
          </h4>
        </span>
        <div className='d-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            className='form-control form-control-solid w-225px ps-14'
            placeholder={t('SEARCH')}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className='w-100 flex row' style={{ maxHeight: "235px", overflow: "scroll"}}>
        <div className='row align-items-center'>
          {templateList.length ? (
            toJS(templateList).map((tl: any) => (
              <div key={`template_card_list_${tl._id}`} className='col-xl-3 mt-2'>
                <div className='card card-custom overlay overflow-hidden border border-4 border-gray-200'>
                  <div className='card-body p-0'>
                    <div className='overlay-wrapper'>
                      <div style={{height: 100}}>
                        <Statistics
                          image='abstract-4.svg'
                          title={tl.name}
                          className={`card-xl-stretch mb-xl-8 ${
                            tl._id === current ? 'bg-secondary' : ''
                          }`}
                          description=''
                        />
                      </div>
                    </div>
                    {tl._id !== current && (
                      <div className='overlay-layer bg-dark bg-opacity-10'>
                        <p
                          onClick={() => onClickUse(tl)}
                          className='btn btn-light-primary btn-shadow'
                        >
                          {t('USE')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className='text-muted text-center mt-5'>Not Found</p>
          )}
        </div>
      </div>
    </>
  )
}

export default ModuleLibraryCard
