/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'

import {isBotAccessible, isWorkspaceReadonly} from '../../../../../utils/utils'
import {KTSVG} from '../../../../../_metronic/helpers'

type AppTableProps = {
  name: string
  type: string
  sessions?: number
  leads?: number
  childModule?: any
  edit: any
  archive: any
  duplicate: any
  status: string
  published: any
}

const AppTable: FC<AppTableProps> = ({
  status = 'draft',
  name,
  type,
  sessions = 0,
  leads = 0,
  childModule,
  edit,
  archive,
  duplicate,
  published,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const renderIcon = (type: string) =>
    `/media/icons/custom/${
      type === 'bot'
      ? 'smart_chat_menu_icon.svg'
      : type === 'form'
      ? 'smart_form_menu_icon.svg'
      :type === "smartblock"
      ?'smart_block_menu_icon.svg'
      :type === "smartbar"
      ?'smart_bar_menu_icon.svg'
      :type === "smartflow"
      ?'smart_flow_menu_icon.svg'
      :'download_tools_menu_icon.svg'
    }`

  const disableBot = type === 'bot' && isBotAccessible()

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5' style={{width:"50px",height:"50px",padding:"12px",borderRadius:"50%",backgroundColor:"#1A175E"}}>
              <KTSVG path={renderIcon(type)} className='svg-icon text-bold text-white svg-icon-2x' svgClassName='text-bold'/>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
              {name}
            </a>
            {status === 'draft' && (
              <div className='mb-0 lh-1'>
                {' '}
                <span className='badge badge-secondary badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-muted'>In Progress</span>
              </div>
            )}

            {status === 'active' && (
              <div className='mb-0 lh-1'>
                {' '}
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-muted'>Published</span>{' '}
              </div>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center me-2'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-3'>
            <div className='symbol-label bg-light-success'>
              {/* begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg */}
              <span className='menu-icon svg-icon svg-icon-1 svg-icon-success'>
                <KTSVG path='/media/icons/duotune/finance/fin008.svg' className='svg-icon-2x' />
              </span>
              {/* end::Svg Icon */}
            </div>
          </div>
          {/* end::Symbol */}
          {/* begin::Title */}
          <div>
            <div className='fs-4 text-dark fw-bolder'>{ childModule?.length ? childModule[0].sessionCount : sessions}</div>
            <div className='fs-7 text-muted fw-bold'>{t('SESSION', {count: 1})}</div>
          </div>
          {/* end::Title */}
        </div>
      </td>
      <td className='text-end'>
        <div className='d-flex justify-content-end align-items-center me-2'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-3'>
            <div className='symbol-label bg-light-primary'>
              {/* begin::Svg Icon | path: icons/duotune/ecommerce/ecm010.svg */}
              <span className='menu-icon svg-icon svg-icon-1 svg-icon-primary'>
                <KTSVG
                  path='/media/icons/duotune/communication/com014.svg'
                  className='svg-icon-2x'
                />
              </span>
              {/* end::Svg Icon */}
            </div>
          </div>
          {/* end::Symbol */}
          {/* begin::Title */}
          <div>
            <div className='fs-4 text-dark fw-bolder'>{ childModule?.length ? childModule[0].leadCount : leads}</div>
            <div className='fs-7 text-muted fw-bold'>{t('LEAD', {count: 1})}</div>
          </div>
          {/* end::Title */}
        </div>
      </td>
      <td>
        <div className={`d-flex justify-content-end flex-shrink-0 ${disableBot ? 'pe-none' : ''}`}>
          {isWorkspaceReadonly() ? (
            <a
              onClick={() => edit()}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr036.svg' className='svg-icon-3' />
            </a>
          ) : (
            <>
              <a
                onClick={() => published()}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                data-bs-toggle='modal'
                data-bs-target='#kt_publish_event_modal'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
              </a>
              <a
                onClick={() => duplicate()}
                data-bs-toggle='modal'
                data-bs-target={`#gw_modal_duplicate_name`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen028.svg' className='svg-icon-3' />
              </a>
              <a
                onClick={() => edit()}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </a>
              <a
                onClick={() => archive()}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              >
                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
              </a>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}

export default AppTable
