import {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import Home from '../pages/growth-engine/Home'
import Sessions from '../pages/data-platform/Sessions'
import Apps from '../pages/growth-engine/Apps'
import {isModulePermitted, isWorkspaceGroweoAdmin, isGroweoContentadmin} from '../../utils/utils'
import ProfilePage from '../pages/profile/ProfilePage'
import Libraries from '../pages/libraries/Libraries'
import ChangeLog from '../pages/change-log/ChangeLog'
import PerformanceReport from '../pages/growth-engine/PerformanceReport'

export function PrivateRoutes() {
  const FormsContainer = lazy(() => import('../modules/formBuilder/FormsContainer'))
  const Bots = lazy(() => import('../pages/growth-apps/Bots'))
  const Forms = lazy(() => import('../pages/growth-apps/Forms'))
  const SmartBlock = lazy(() => import('../pages/growth-apps/SmartBlock'))
  const SmartPopup = lazy(() => import('../pages/growth-apps/SmartPopup'))
  const SmartFlow = lazy(() => import('../pages/growth-apps/SmartFlow'))
  const SmartBar = lazy(() => import('../pages/growth-apps/SmartBar'))
  const Files = lazy(() => import('../pages/growth-apps/Files'))
  const Preview = lazy(() => import('../pages/growth-apps/Preview'))
  const Leads = lazy(() => import('../pages/data-platform/Leads'))
  const Segments = lazy(() => import('../pages/data-platform/Segments'))
  const ListsContainer = lazy(() => import('../pages/data-platform/Lists'))
  const Campaigns = lazy(() => import('../pages/automations/Campaigns'))
  const Journeys = lazy(() => import('../pages/automations/Journeys'))
  const Triggers = lazy(() => import('../pages/automations/Triggers'))
  const Notifications = lazy(() => import('../pages/automations/Notifications'))


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Redirect from='/auth' to='/dashboard' />
        <Route path='/profile' component={ProfilePage} />
        {isModulePermitted('DASHBOARD') && <Redirect exact from='/' to='/dashboard' />}
        {isModulePermitted('DASHBOARD') && <Route path='/dashboard' component={Home} />}
        {isModulePermitted('PERFORMANCE_REPORT') && <Route path='/performance-reports' component={PerformanceReport} />}
        {isModulePermitted('GROWTH_APPS') && <Route path='/gapps' component={Apps} />}

        {isModulePermitted('SMART_BOT') && <Route path='/apps/bots' component={Bots} />}
        {isModulePermitted('SMART_FORM') && <Route path='/apps/forms' component={Forms} />}
        {isModulePermitted('SMART_BLOCK') && <Route path='/apps/smartblock' component={SmartBlock} />}
        {isModulePermitted('SMART_POPUP') && <Route path='/apps/smartpopup' component={SmartPopup} />}
        {isModulePermitted('SMART_POPUP') && <Route path='/apps/smartflows' component={SmartFlow} />}
        {isModulePermitted('SMART_BAR') && <Route path='/apps/smartbars' component={SmartBar} />}
        {isModulePermitted('DOWNLOAD_TOOL') && <Route path='/apps/files' component={Files} />}
        {(isModulePermitted('PREVIEW')) && (
          <Route path='/apps/preview' component={Preview} />
        )}

        {isModulePermitted('') && <Route path='/bots' component={Bots} />}
        {isModulePermitted('') && <Route path='/forms' component={FormsContainer} />}

        {isModulePermitted('SESSION') && <Route path='/cdp/sessions' component={Sessions} />}
        {isModulePermitted('CONTACT') && <Route path='/cdp/leads' component={Leads} />}
        {isModulePermitted('SEGMENT') && <Route path='/cdp/segments' component={Segments} exact />}
        {isModulePermitted('SEGMENT') && <Route path='/cdp/segments/edit/:id' component={Segments} exact />}
        {isModulePermitted('SEGMENT') && <Route path='/cdp/segments/view/:id' component={Segments} exact />}
        {isModulePermitted('LIST') && <Route path='/cdp/lists' component={ListsContainer} />}
        {isModulePermitted('CAMPAIGN') && (
          <Route path='/automations/campaigns' component={Campaigns} />
        )}
        {isModulePermitted('') && <Route path='/automations/journeys' component={Journeys} />}
        {isModulePermitted('TRIGGER') && (
          <Route path='/automations/triggers' component={Triggers} />
        )}
        {isModulePermitted('NOTIFICATION') && (
          <Route path='/automations/notifications' component={Notifications} />
        )}
        {isWorkspaceGroweoAdmin() && (
          <Route path='/libraries' component={Libraries} />
        )}
        {(isWorkspaceGroweoAdmin() || isGroweoContentadmin()) && (
          <Route path='/changelog' component={ChangeLog} />
        )}
        <Redirect to='/' />
      </Switch>
    </Suspense>
  )
}
