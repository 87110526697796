import {FC, useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'
import {KTSVG} from '../../../../../_metronic/helpers'
import TextInputFeild from '../../../../shared/TextInputFeild'
import SelectInputFeild from '../../../../shared/SelectInputFeild'
import {getRoles,  groweoRoles} from '../../../../../utils/utils'
import {createUser, existsUser, getUser, updateUser} from '../../../../../service/user.service'
import { getAllPartner } from '../../../../../service/partner.service'
import { getWorkspace } from '../../../../../service/workspace.service'
import clsx from 'clsx'
import Select from 'react-select'

type UserModalProps = {
  setUserList?: any
  modalId: string
  user?: any
  setUser?: any
  workspace: any
}
interface Params {
  workspaceId: string
}

interface User {
  name: string
  email: string
  role: string
  workspaceId?: string
  status?: boolean
  assignWorkspace?: any
}
const workspaceSchema = (t: any, isEdit: any) =>
  Yup.object().shape({
    name: Yup.string().required(`${t('REQUIRED', {field: t('NAME')})}`),
    email: Yup.string()
      .email(`${t('VALID_TEXT', {field: t('EMAIL', {count: 0})})}`)
      .required(`${t('REQUIRED', {field: t('EMAIL', {count: 0})})}`),
    role: Yup.string().required(`${t('REQUIRED', {field: t('ROLE')})}`),
  })

const initialValues: User = {
  name: '',
  email: '',
  role: '',
  workspaceId: '',
  assignWorkspace: []
}

const UserModal: FC<UserModalProps> = ({setUserList, modalId, user, setUser, workspace}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const defaultOption = { id: null, value: null, label: t("ALL") }
  const {workspaceId} = useParams<Params>()
  const [existEmail, setExistEmail] = useState(false)
  const [partner, setPartner] = useState([])
  const [workspaceList, setWorkspaceList] = useState([])
  const [tagifyWorkspaceList, setTagifyWorkspaceList]:any = useState([])
  const [selected, setSelected]: any = useState()
  const [workspaceListName, setWorkspaceListName]: any = useState([])
  const tagifyRef:any = useRef()
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await getWorkspace({})
      if (data) setWorkspaceList(data)
    }
    fetchData()
  }, [])

  useEffect(() =>{ 
    const dataList: any = [defaultOption];
    workspaceList.map((obj:any) => {
      if (obj?.domain !== 'engine.groweo.com') dataList.push({ id: obj._id, value: obj.name, label: obj?.name })
    })
    setTagifyWorkspaceList(dataList)
  },[workspaceList])

  useEffect(() => {
    const el = document.getElementById('gw_user_modal_button')
    if (el) {
      el.addEventListener('click', () => {
        formik.resetForm()
        if (user) setUser({})
        setExistEmail(false)
      })
    }
    // eslint-disable-next-line
  }, [])
  // useEffect(() => {
   
  //     getAllPartner().then((list) => {
  //       setPartner(list)
  //     })
  //       const el = document.getElementById('gw_user_modal_button')
  //       if (el) {
  //         el.addEventListener('click', () => {
  //           formik.resetForm()
  //         })
  //       }
    
   
  //   // eslint-disable-next-line
  // }, [])
  
  useEffect(() => {
    if (!user) return; 
    formik.setValues(user)
    setWorkspaceListName([defaultOption])
    if(!user?.assignWorkspace?.length) return
    const getWorkspaceName = tagifyWorkspaceList?.filter((obj: any) => user.assignWorkspace.includes(obj.id))  
    setWorkspaceListName(getWorkspaceName)  
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const formik = useFormik({
    initialValues,
    validationSchema: workspaceSchema(t, !!user),
    onSubmit: (values) => {
      Object.assign(values, {assignWorkspace: selected})       
      const getList = () => {
        getUser(workspaceId).then((list) => {
          setUserList(list)
        })
      }
      if (user) {
        const {name, status, role, assignWorkspace } = values
        updateUser(user._id, {name, status, role, assignWorkspace }).then(() => {
          getList()
          setUser({})
        })
      } else {
        values.workspaceId = workspaceId
        delete values.status
        createUser(values).then(() => {
          getList()
        })
      }
      formik.resetForm()
    },
  })
  
  useEffect(() => {
    if(workspace?.domain === 'engine.groweo.com') formik.setFieldValue('role', 'groweo-subadmin')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.role, workspace])

  useEffect(() => {
    if (formik.values.email) checkExistUser(formik.values.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.email])

  const checkExistUser = async (email: any) => {
    const res = await existsUser({email})
    setExistEmail(res)
  }

  const renderLabel = (label: string) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
      <span className='required'>{label}</span>
    </label>
  )

  const onTagifyInputChange =  (e: any) => {
    const arr = e.detail.value ? JSON.parse(e.detail.value) : []
    const data = arr.map((item: any) => (item.id))
    setSelected(data) 
  }

  const onWorkspaceChange = (selectedOption: any, newItem: any) => {
    const isWorkspace = newItem?.id ?? typeof newItem === 'undefined'
    if (isWorkspace) {
      const option = selectedOption.map((item: any) => item.id)
      setSelected(option)
    } else setSelected([])
    setWorkspaceListName(isWorkspace ? selectedOption : [defaultOption])
  }

  const isOptionDisabled = (option: any) => {
    // Disable all options except all when it is selected
    return !!(workspaceListName && workspaceListName?.find((item: any) => !item.id) && option.id)
  };
  
  return (
    <div className='modal fade' tabIndex={-1} id={modalId}>
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>
              {t(user ? 'EDIT' : 'CREATE')} {t('USER', {count: 0})}
            </h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label={t('CLOSE')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            {(existEmail && !user) && (
              <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
                <div className='d-flex flex-column'>
                  <span>{t('EMAIL_ALREADY_EXISTS')}</span>
                </div>
              </div>
            )}

            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='gw_workspace_modal_form'
            >
              <div className='fv-row mb-5'>
                {renderLabel(`${t('NAME')}`)}
                <TextInputFeild formik={formik} name='name' type='text' />
              </div>
              <div className='fv-row mb-5'>
                {renderLabel(`${t('EMAIL', {count: 0})}`)}
                <TextInputFeild formik={formik} name='email' type='email' disabled={!!user} />
              </div>
              <div className='fv-row mb-5'>
                {renderLabel(`${t('ROLE')}`)}
                <SelectInputFeild formik={formik}  name='role' options={workspace?.domain === 'engine.groweo.com' ?  groweoRoles(t) : getRoles(t)} disabled={workspace?.domain === 'engine.groweo.com'} />
              </div>
              {
                // partner.length>0 &&
                // <div className='fv-row mb-5'>
                //     {renderLabel(`${t('PARTNER')}`)}
                //     <SelectInputFeild
                //       formik={formik}
                //       name='partnerId'
                //       options={partner}
                //       format={{ value: '_id', text: 'name' }}
                //     />
                //   </div>
              }
              {formik.values.role === "groweo-subadmin" && (
                <div className='fv-row mb-5'>
                  {renderLabel(`${t('ASSIGN_WORKSPACE',{ count: 1 })}`)}
                  {/* <Tags
                   className='form-control form-control-solid'
                   tagifyRef={tagifyRef}
                    settings={{
                      whitelist:tagifyWorkspaceList,
                      dropdown: {
                        maxItems:Infinity,
                        searchKeys: ['value'],
                        classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
                        enabled: 0,             // <- show suggestions on focus
                        closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
                      },
                      enforceWhitelist: true
                    }}
                    onChange={(e)=> onTagifyInputChange(e)}
                    value={workspaceListName}
                  /> */}
                  <Select 
                    className='form-control form-control-solid border-0 p-1'
                    options={tagifyWorkspaceList}
                    closeMenuOnSelect={false}
                    onChange={(selectedOption: any, newValue: any)=> onWorkspaceChange(selectedOption, newValue.option)}
                    isMulti={true}
                    isOptionDisabled={isOptionDisabled}
                    value={workspaceListName}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        background: '#f5f8fa',
                        border: 'transparent',
                      }),
                    }}
                  />
                </div>
              )}
              {user && (
                <div className='fv-row mb-5'>
                  <label className='form-check form-check-custom form-check-solid form-switch'>
                    {renderLabel(`${t('STATUS')}`)}
                    <TextInputFeild
                      formik={formik}
                      type='checkbox'
                      name='status'
                      checked={formik.getFieldProps('status').value}
                    />
                  </label>
                </div>
              )}
              <div className='fv-row'>
                <div className='text-center '>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    onClick={() => {
                      formik.resetForm()
                      if (user) setUser({})
                    }}
                  >
                    {t('RESET')}
                  </button>
                  <button
                    type='submit'
                    data-bs-dismiss={`${formik.isSubmitting || !formik.isValid ? '' : 'modal'}`}
                    className='c-button c-button--type-solid'
                    disabled= { !user && existEmail}
                  >
                    <span className='indicator-label' style={{zIndex: 0}}>
                      {t('SUBMIT')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserModal
