/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {useStore} from '../../../../store'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'

type MutedProps = {
  title: string
  subTitle: string
}

const MutedToolbar: FC<MutedProps> = ({title,subTitle}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()




  return (
    <div className='toolbar toolbar-pg-bg' style={{position:'inherit', border:'none'}} id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')} style={{padding:'0px'}}
      >
        <div>
          
          <SectionHeading title={t(title, {count: 1})} subTitle={t(subTitle, {count: 0})} />
        </div>

      </div>
    </div>
  )
}

export {MutedToolbar}
