/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG } from '../../../_metronic/helpers'

type Props = {
 image?: string
 title?: string
 progress?: number
 statusDesc?: string
 statusValue?: number
 description?: string
 status?: 'up' | 'down'
 progressType?: string
}

const Card: FC<Props> = ({
 image,
 title,
 description,
 status,
 statusValue,
 statusDesc,
 progress,
 progressType,
}) => {
 return (
  <div className='card h-100'>
   <div className='card-header flex-nowrap border-0 p-1'>
    <div className='card-title m-0'>
     <div className='symbol symbol-25px w-25px bg-light me-3 ms-3'>
     </div>

     <a href='#' className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
      {title}
     </a>
    </div>
   </div>

   <div className='card-body d-flex flex-column px-3 pt-1 pb-1'>
    <div className='fs-2tx fw-bolder mb-3'>{description}</div>

    <div className='d-flex align-items-center flex-wrap mb-5 mt-auto fs-6'>
     {status === 'up' && (
      <KTSVG
       path='/media/icons/duotune/arrows/arr007.svg'
       className='svg-icon-3 me-1 svg-icon-success'
      />
     )}

     {status === 'down' && (
      <KTSVG
       path='/media/icons/duotune/arrows/arr006.svg'
       className='svg-icon-3 me-1 svg-icon-danger'
      />
     )}

     <div className={`fw-bolder me-2 ` + (status === 'up' ? 'text-success' : 'text-danger')}>
      {status === 'up' ? '+' : '-'}
      {statusValue}%
     </div>

     <div className='fw-bold text-gray-400'>{statusDesc}</div>
    </div>

    {/* <div className='d-flex align-items-center fw-bold'>
     <span className='badge bg-light text-gray-700 px-3 py-2 me-2'>{progress}%</span>
     <span className='text-gray-400 fs-7'>{progressType}</span>
    </div> */}
   </div>
  </div>
 )
}

export default Card
