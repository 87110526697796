import React,{useEffect} from 'react'
import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import Dashboard from '../app/modules/dashboard';
import {useStore} from '../store'
import {getGlobalItem, qs} from '../utils/utils'

export interface Dashboard{
  activity:{totalItemCount:number, records:{_id: string,activity:string,type: string,trackingId: string,workspaceId:string,moduleId: string,created: string}[]}
  visitorsData : {count:string,average:string,data:number[]}
  sessionData : {count:string,average:string,data:number[]}
  leadsData : {count:string,average:string,data:number[]}
  durationsData : {count:string,average:string,data:number[]}
  totalSessions : {bots:number[], forms : number[], files:number[]}
  mostUsedApps : {image:string,name:string,subTitle:string,total:number}[]
  popularApps:{visitorsCount:number, year:string,month:string, datas:{image:string, name:string,  subTitle:string, averageCount:number, average:string}[]}
  applicationStatistics :{value:number,category:string}[]
  customerServiceLeads : {name:string,data:{x:number,y:number,z:number,name:string}[]}[]
  audience:{totalContacts:number, datas:number[]}
  recentAudience:{title:string, subTitle:string, status:string}[]
  facebookCompaign : {newFollowers:number, followersGoal:number, datas:number[]}
  channels : {image:string, title:string, subTitle:string, average:number}[]
}
const mock = new MockAdapter(axios,{ onNoMatch: "passthrough" });



/* Mock API calls end */
export const mockApi = () =>{
  /* Mock API Calls - Start */
  mock.onGet("/api/kpiTrend/activity").reply(200, {
    message:"Fetched successfully",
    data:{
    totalItemCount: 50,
    records: [
      {
          _id: "62219ebcefe4e9a51849c851",
          activity: 'END-SESSION',
        
          type: "bot",
          duration: 20,
          trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
          workspaceId: "61ee53655f89011595f49baf",
          moduleId: {
            "_id": "6230ac3624b5042cc52a1edf",
            "name": "Myyntipalvelu"
          },
          created: "2022-03-03T01:08:12.261Z"
      },
      {
          _id: "62219ebbefe4e9a51849c845",
          activity: 'VISIT-PAGE',
          type: "bot",
          duration: 20,
          trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
          workspaceId: "61ee53655f89011595f49baf",
          moduleId: {
            "_id": "6230ac3624b5042cc52a1edf",
            "name": "Rekrytointiassistentti"
          },
          created: "2022-03-03T01:20:11.390Z"
      },
      {
        _id: "62219ebcefe4e9a51849c851",
        activity: 'LEAD-CREATED',
        type: "bot",
        duration: 20,
        trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
        workspaceId: "61ee53655f89011595f49baf",
        moduleId: {
          "_id": "6230ac3624b5042cc52a1edf",
          "name": "kontakti"
        },
        created: "2022-03-03T02:08:12.261Z"
    },
      {
          _id: "62219ebaefe4e9a51849c83d",
          activity: "END-SESSION",
          type: "bot",
          duration: 20,
          trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
          workspaceId: "61ee53655f89011595f49baf",
          moduleId:  {
            "_id": "6230ac3624b5042cc52a1edf",
            "name": "Huoltovaraukset"
          },
          created: "2022-03-04T02:30:10.805Z"
      },
      {
        _id: "62219ebaefe4e9a51849c83d",
        activity: 'VISIT-PAGE',
        type: "bot",
        duration: 20,
        trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
        workspaceId: "61ee53655f89011595f49baf",
        moduleId:  {
          "_id": "6230ac3624b5042cc52a1edf",
          "name": "Myyntipalvelu"
        },
        created: "2022-03-04T02:45:10.805Z"
    },
    {
      _id: "62219ebaefe4e9a51849c83d",
      activity: 'LEAD-CREATED',
      type: "bot",
      duration: 20,
      trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
      workspaceId: "61ee53655f89011595f49baf",
      moduleId: {
        "_id": "6230ac3624b5042cc52a1edf",
        "name": "kontakti"
      },
      created: "2022-03-04T03:00:10.805Z"
  },{
    _id: "62219ebaefe4e9a51849c83d",
    activity: 'END-SESSION',
      type: "bot",
    duration: 20,
    trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
    workspaceId: "61ee53655f89011595f49baf",
    moduleId:  {
      "_id": "6230ac3624b5042cc52a1edf",
      "name": "Myyntipalvelu"
    },
    created: "2022-03-04T03:05:10.805Z"
  },{
    _id: "62219ebaefe4e9a51849c83d",
    activity: "VISIT-PAGE",
    type: "bot",
    duration: 20,
    trackingId: "5bbdb563-d92d-4c86-bcd5-ada1b07c9dd4",
    workspaceId: "61ee53655f89011595f49baf",
    moduleId:  {
      "_id": "6230ac3624b5042cc52a1edf",
      "name": "Rekrytointiassistentti"
    },
    created: "2022-03-04T03:38:10.805Z"
  }
    ]
    }
  });

  mock.onGet("/api/kpiTrend/visitor/count").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 12345,
      
      "records": [
        {
          "date": "2022-03-14",
          "count": 35
        },
        {
          "date": "2022-03-15",
          "count": 25
        },
        {
          "date": "2022-03-16",
          "count": 45
        },
        {
          "date": "2022-03-17",
          "count": 15
        },
        {
          "date": "2022-03-18",
          "count": 60
        },
        {
          "date": "2022-03-19",
          "count": 50
        },
        {
          "date": "2022-03-20",
          "count": 57
        },
        {
          "date": "2022-03-21",
          "count": 35
        },
        {
          "date": "2022-03-22",
          "count": 70
        },
        {
          "date": "2022-03-23",
          "count": 40
        },
        {
          "date": "2022-03-24",
          "count": 45
        },
        {
          "date": "2022-03-25",
          "count": 25
        },
        {
          "date": "2022-03-26",
          "count": 45
        },
        {
          "date": "2022-03-27",
          "count": 30
        },
        {
          "date": "2022-03-28",
          "count": 70
        }
      ]
    }
  });
  
  mock.onGet("/api/kpiTrend/session/count").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 9500,
     
      "records": [
        {
          "date": "2022-03-15",
          "count": 35
        },
        {
          "date": "2022-03-16",
          "count": 70
        },
        {
          "date": "2022-03-17",
          "count": 40
        },
        {
          "date": "2022-03-18",
          "count": 45
        },
        {
          "date": "2022-03-19",
          "count": 25
        },
        {
          "date": "2022-03-20",
          "count": 45
        },
        {
          "date": "2022-03-21",
          "count": 30
        },
        {
          "date": "2022-03-22",
          "count": 70
        },
        {
          "date": "2022-03-23",
          "count": 35
        },
        {
          "date": "2022-03-24",
          "count": 25
        },
        {
          "date": "2022-03-25",
          "count": 45
        },
        {
          "date": "2022-03-26",
          "count": 15
        },
        {
          "date": "2022-03-27",
          "count": 60
        },
        {
          "date": "2022-03-28",
          "count": 50
        },
        {
          "date": "2022-03-29",
          "count": 57
        }
      ]
    }
  });
  mock.onGet("/api/kpiTrend/contact/count").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 2501,
      
      "records": [
        {
          "date": "2022-03-15",
          "count": 35
        },
        {
          "date": "2022-03-16",
          "count": 25
        },
        {
          "date": "2022-03-17",
          "count": 45
        },
        {
          "date": "2022-03-18",
          "count": 15
        },
        {
          "date": "2022-03-19",
          "count": 60
        },
        {
          "date": "2022-03-20",
          "count": 50
        },
        {
          "date": "2022-03-21",
          "count": 57
        }, 
        {
          "date": "2022-03-22",
          "count": 70
        },
        {
          "date": "2022-03-23",
          "count": 40
        },
        {
          "date": "2022-03-24",
          "count": 45
        },
        {
          "date": "2022-03-25",
          "count": 25
        },
        {
          "date": "2022-03-26",
          "count": 45
        },
        {
          "date": "2022-03-27",
          "count": 30
        },
        {
          "date": "2022-03-28",
          "count": 70
        },
        {
          "date": "2022-03-29",
          "count": 35
        }
      ]
    }
  });
  
  mock.onGet("/api/kpiTrend/duration/count").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 335000,
      
      "records": [
        {
          "date": "2022-03-15",
          "count": 35
        },
        {
          "date": "2022-03-16",
          "count": 25
        },
        {
          "date": "2022-03-17",
          "count": 45
        },
        {
          "date": "2022-03-18",
          "count": 15
        },
        {
          "date": "2022-03-19",
          "count": 60
        },
        {
          "date": "2022-03-20",
          "count": 50
        },
        {
          "date": "2022-03-21",
          "count": 57
        },
        {
          "date": "2022-03-22",
          "count": 70
        }
        ,
        {
          "date": "2022-03-23",
          "count": 40
        },
        {
          "date": "2022-03-24",
          "count": 45
        },
        {
          "date": "2022-03-25",
          "count": 25
        },
        {
          "date": "2022-03-26",
          "count": 45
        },
        {
          "date": "2022-03-27",
          "count": 30
        },
        {
          "date": "2022-03-28",
          "count": 70
        },
        {
          "date": "2022-03-29",
          "count": 35
        }
      ]
    }
  });
  
  mock.onGet("/api/kpiTrend/session/count?type=bot").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 506,
      "records": [
        {
          "date": "Feb",
          "count": 35
        },
        {
          "date": "Mar",
          "count": 25
        },
        {
          "date": "Apr",
          "count": 45
        },
        {
          "date": "May",
          "count": 15
        },
        {
          "date": "Jun",
          "count": 60
        },
        {
          "date": "Jul",
          "count": 50
        }
      ]
    }
 
  });
  
  mock.onGet("/api/kpiTrend/session/count?type=form").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 506,
      "records": [
        {
          "date": "Feb",
          "count": 45
        },
        {
          "date": "Mar",
          "count": 25
        },
        {
          "date": "Apr",
          "count": 45
        },
        {
          "date": "May",
          "count": 30
        },
        {
          "date": "Jun",
          "count": 70
        },
        {
          "date": "Jul",
          "count": 35
        }
      ]
    }

  });

  mock.onGet("/api/kpiTrend/session/count?type=file").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 506,
      "records": [
        {
          "date": "Feb",
          "count": 60
        },
        {
          "date": "Mar",
          "count": 50
        },
        {
          "date": "Apr",
          "count": 57
        },
        {
          "date": "May",
          "count": 70
        },
        {
          "date": "Jun",
          "count": 40
        },
        {
          "date": "Jul",
          "count": 45
        }
      ]
    }
  })
  
  mock.onGet("/api/kpiTrend/session/count").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 506,
      "records": [
        {
          "date": "Feb",
          "count": 70
        },
        {
          "date": "Mar",
          "count": 60
        },
        {
          "date": "Apr",
          "count": 67
        },
        {
          "date": "May",
          "count": 80
        },
        {
          "date": "Jun",
          "count": 50
        },
        {
          "date": "Jul",
          "count": 55
        }
      ]
    }
  })
  
  
  mock.onGet("/api/kpiTrend/topApp/session").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 16,
      "records": [
        {
          "moduleId": "/media/svg/brand-logos/plurk.svg",
          "moduleName": "Etukampanja -15%",
          "moduleType": "file",
          "description":"Kuukausittainen alennuskampanja",
          "count": 916
        },
        {
          "moduleId": "/media/svg/brand-logos/plurk.svg",
          "moduleName": "Rekrytointiassistentti",
          "moduleType": "bot",
          "description":"Successful Fellas",
          "count": 346
        },
        {
          "moduleId": "/media/svg/brand-logos/plurk.svg",
          "moduleName": "Myyntipalvelu",
          "moduleType": "bot",
          "description":"Tampere, Savonlinna, Vantaa",
          "count": 296
        },
        {
          "moduleId": "/media/svg/brand-logos/plurk.svg",
          "moduleName": "Asiakaspalvelu",
          "moduleType": "bot",
          "description" :"Marja ja Liisa vastaavat",
          "count": 204
        },
        {
          "moduleId": "/media/svg/brand-logos/plurk.svg",
          "moduleName": "Huoltovaraukset",
          "moduleType": "form",
          "description" :"Kaikki huoltovaraukset",
          "count": 83
        }
      ]
    }
  }
    
  );
  
  mock.onGet("/api/kpiTrend/topApp/visitor").reply(200,{
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 20003,
      "records": [
        {
          "moduleId": "/media/svg/brand-logos/atica.svg",
          "moduleName": "Etukampanja nopeille",
          "moduleType": "bot",
          "description" :"Kvartaalikampanja",
          "count": 8153
        },
        {
          "moduleId": "/media/svg/brand-logos/atica.svg",
          "moduleName": "Rekrytointiassistentti",
          "moduleType": "bot",
          "description" :"HR-yksikkö / Jaana",
          "count": 1254
        },
        {
          "moduleId": "/media/svg/brand-logos/atica.svg",
          "moduleName": "Myyntipalvelu",
          "moduleType": "form",
          "description" :"Tampere, Savonlinna, Vantaa",
          "count": 579
        },
        {
          "moduleId": "/media/svg/brand-logos/atica.svg",
          "moduleName": "Abstergo Ltd",
          "moduleType": "form",
          "description" :"Community",
          "count": 579
        }
       
      ]
    }
});
  

  mock.onGet("/api/kpiTrend/visitor/count?type=bot").reply(200,{
    
      "message": "Record fetch successfully",
      "data": {
        "totalItemCount": 32,
        "records": [
          {
            "date": "2022-03-17",
            "count": 0
          },
          {
            "date": "2022-03-18",
            "count": 0
          },
          {
            "date": "2022-03-19",
            "count": 0
          },
          {
            "date": "2022-03-20",
            "count": 0
          },
          {
            "date": "2022-03-21",
            "count": 0
          },
          {
            "date": "2022-03-22",
            "count": 0
          },
          {
            "date": "2022-03-23",
            "count": 0
          }
        ]
      }
  })

  mock.onGet("/api/kpiTrend/visitor/count?type=form").reply(200,{
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 48,
      "records": [
        {
          "date": "2022-03-17",
          "count": 0
        },
        {
          "date": "2022-03-18",
          "count": 0
        },
        {
          "date": "2022-03-19",
          "count": 0
        },
        {
          "date": "2022-03-20",
          "count": 0
        },
        {
          "date": "2022-03-21",
          "count": 0
        },
        {
          "date": "2022-03-22",
          "count": 0
        },
        {
          "date": "2022-03-23",
          "count": 0
        }
      ]
    }
  })

  mock.onGet("/api/kpiTrend/visitor/count?type=file").reply(200,{
    
    "message": "Record fetch successfully",
    "data": {
      "totalItemCount": 20,
      "records": [
        {
          "date": "2022-03-17",
          "count": 0
        },
        {
          "date": "2022-03-18",
          "count": 0
        },
        {
          "date": "2022-03-19",
          "count": 0
        },
        {
          "date": "2022-03-20",
          "count": 0
        },
        {
          "date": "2022-03-21",
          "count": 0
        },
        {
          "date": "2022-03-22",
          "count": 0
        },
        {
          "date": "2022-03-23",
          "count": 0
        }
      ]
    }
  })

  mock.onGet("/api/kpiTrend/countByTags").reply(200, {
    "message": "Record fetch successfully",
    "data": {
      "records": [
        {
          "tag": "Asiakaspalvelu",
          "count": {
            "visitors": 1162,
            "leads": 30,
            "sessions": 606
          }
        },
        {
          "tag": "Avainasiakkaat",
          "count": {
            "visitors": 1162,
            "leads": 40,
            "sessions": 606
          }
        },
        {
          "tag": "Työnhakijat",
          "count": {
            "visitors": 1162,
            "leads": 30,
            "sessions": 606
          }
        },
        {
          "tag": "Remontointi",
          "count": {
            "visitors": 1162,
            "leads": 30,
            "sessions": 606
          }
        },
        {
          "tag": "Uudisrakentaminen",
          "count": {
            "visitors": 1162,
            "leads": 30,
            "sessions": 606
          }
        },
        {
          "tag": "Maalaustyöt",
          "count": {
            "visitors": 1162,
            "leads": 30,
            "sessions": 606
          }
        }
      ]
    }
  });
  
  mock.onGet("/audience").reply(200, {
     totalContacts: 2501, datas: [30, 40, 40, 90, 90, 70, 70,30, 40, 40, 90, 90]
    }
  );
  
  mock.onGet("/recentAudience").reply(200, [
    {
      title: "Etukampanja nopeille", subTitle: "Kvartaalikampanja", average:70
      },
      {
        title: "Rekrytointiassistentti", subTitle: "HR-yksikkö / Jaana", average:60
        },
        {
          title: "Myyntipalvelu", subTitle: "Tampere, Savonlinna, Vantaa", average:34
          },
          {
            title: "Abstergo Ltd.", subTitle: "Community", average:12
            }
  ]
  );
  
  
  mock.onGet("/facebookCompaign").reply(200, {
    newFollowers:2400,
    followersGoal:39203,
    datas : [30, 25, 45, 30, 55, 55]
   }
  );
  
  mock.onGet("/channels").reply(200, [
    {
      image:"/media/icons/custom/smart_chat_menu_icon.svg",
      title:"Asiakaspalvelu",
      subTitle:"Marja ja Liisa vastaavat",
      average:46
    },
    {
      image:"/media/icons/custom/smart_chat_menu_icon.svg",
      title:"Myyntipalvelu",
      subTitle:"Tampere, Savonlinna, Vantaa",
      average:23
    },
    {
      image:"/media/icons/custom/smart_chat_menu_icon.svg",
      title:"Rekrytointiassistentti",
      subTitle:"Successful Fellas",
      average:68
    },
    {
      image:"/media/icons/custom/smart_form_menu_icon.svg",
      title:"Huoltovaraukset",
      subTitle:"Kaikki huoltovaraukset",
      average:100
    }
  
  ]);
  
  mock.onGet("/allCampaign").reply(200,
    [
      {
        title:"Jatkokampanja - automaattinen",
        subTitle:"Luotu 07.01.2022",
        startDate:"07.01.2022",
        endDate:"30.01.2022",
        status:"Past",
        channel:'demo@gmail.com',
        audience:'segment',
        statistics : {sent:1200,delivered:1100,opened:1020,clicked:910},
        chartData:[30, 25, 45, 30, 55, 55]
      },
      {
        title:"Avainasiakkaat 2022",
        subTitle:"Luotu 01.01.2022",
        startDate:"01.01.2022",
        endDate:"30.01.2022",
        status:"Past",
        channel:'demo@gmail.com',
        audience:'segment',
        statistics : {sent:4500,delivered:4200,opened:3920,clicked:3450},
        chartData: [30, 25, 30, 55, 55,45]
      },
    {
      title:"Esitekampanja - 2022 uutuudet",
      subTitle:"Luotu 24.12.2021",
      startDate:"24.01.2022",
      endDate:"06.01.2022",
      status:"Live",
      channel:'demo@gmail.com',
      audience:'segment',
      statistics : {sent:250,delivered:234,opened:200,clicked:134},
      chartData:[25, 45, 30,30, 55, 55]
    },
    {
      title:"Kuponkikampanja - 15%",
      subTitle:"Luotu 07.12.2021",
      startDate:"07.12.2021",
      endDate:"17.12.2021",
      status:"Past",
      channel:'demo@gmail.com',
      audience:'segment',
      statistics : {sent:1400,delivered:1370,opened:1330,clicked:1010},
      chartData: [30, 55,25, 45, 30, 55]
    },
    {
      title:"Huollon lisämyynti - renkaat",
      subTitle:"Luotu 27.11.2021",
      startDate:"27.11.2021",
      endDate:"31.12.2021",
      status:"Live",
      channel:'demo@gmail.com',
      audience:'segment',
      statistics : {sent:600,delivered:600,opened:420,clicked:310},
      chartData: [30, 25, 45, 30, 55, 55]
    },
    {
      title:"Aktivointimarkkinointi, yhteisöpalvelut",
      subTitle:"Luotu 21.11.2021",
      startDate:"27.11.2021",
      endDate:"27.12.2022",
      status:"Live",
      channel:'demo@gmail.com',
      audience:'segment',
      statistics : {sent:600,delivered:570,opened:20,clicked:10},
      chartData:[30, 25, 30, 55, 55,45]
    },
   {
      title:"Timon oma prosess",
      subTitle:"Luotu 17.11.2021",
      startDate:"17.11.2021",
      endDate:"30.11.2021",
      status:"Live",
      channel:'demo@gmail.com',
      audience:'segment',
      statistics : {sent:500,delivered:500,opened:20,clicked:10},
      chartData: [30, 55,25, 45, 30, 55]
    },
    {
      title:"Porin kanta-asiakashankinta",
      subTitle:"Luotu 07.11.2021",
      startDate:"07.11.2021",
      endDate:"30.11.2021",
      status:"Live",
      channel:'demo@gmail.com',
      audience:'segment',
      statistics : {sent:100,delivered:100,opened:20,clicked:10},
      chartData:[25, 45, 30,30, 55, 55]
    },
    // {
    //   title:"Weekly Newsletter",
    //   subTitle:"Created On 24 Dec 21",
    //   startDate:"24 Dec 21",
    //   endDate:"06 Jan 22",
    //   status:"Past",
    //   channel:'demo@gmail.com',
    //   audience:'segment',
    //   statistics : {received:2370,delivered:20,opened:10}
    // },
  ]

    )
  mock.onGet(`/api/contact?isUnseen=false`).reply(200,{
      "message": "Record fetch successfully",
      "data": {
            "totalItemCount": 1634,
            "records": [
              {
                email:"smith@kpmg.com",
                sourceType:'SMART_BOT',
                automation:'EMAIL',
                interest: 'Asiakaspalvelu',
                created:'2022-02-04T03:15:10.805Z'
            },
            {
                email:"jussi@altbox.com",
                sourceType:'SMART_FORM',        
                automation:'EMAIL',
                interest: 'Työnhakijat',
                created:'2022-02-04T03:50:10.805Z'
            },
            {
                email:"olivia@corpmail.com",
                sourceType:'SMART_FORM',
                automation:'DEMO_BTN',
                interest: 'Avainasiakkaat',
                created:'2022-02-04T04:00:10.805Z'
            },
            {
                email:"mikko@pexere.fi",
                sourceType:'SMART_BOT',
                automation:'FILE',
                interest: 'Uudisrakentaminen',
                created:'2022-03-04T02:00:10.805Z'
            },
            {
                email:"dam@consilting.com",
                sourceType:'SMART_BOT',
                automation:'NOTIFICATION',
                interest: 'Avainasiakkaat',
                created:'2022-03-04T03:05:10.805Z'
            },
            {
                email:"owen.neil@yeilit.fi",
                sourceType:'SMART_BOT',
                automation:'EMAIL',
                interest: 'Avainasiakkaat',
                created:'2022-03-04T07:25:10.805Z'
            },
            ]
    }
})
}


export const getActivity = async () =>{
       
        const res = await axios.get("/api/kpiTrend/activity");
        
        return res.data.data
    }

export const getUniqueVisitorsData = async (params:any) => {
   const res =await axios.get(`/api/kpiTrend/unique_visitor/count?${qs(params)}`);
   return res.data.data
}

export const getVisitorsData = async () =>{
       let res = await axios.get("/api/kpiTrend/visitor_success/count")
       return res.data.data
}

export const getActiveAppsData = async () =>{
  let res = await axios.get(`/api/kpiTrend/activeApps`)
  return res.data.data
}
export const getSessionsData = async (params:any) =>{
   let res = await axios.get(`/api/kpiTrend/session/count?${qs(params)}`)
   return res.data.data
}
export const getSuccessfullConversationData = async (params:any) =>{
       let res =await axios.get(`/api/kpiTrend/successfull_conversation/count?${qs(params)}`)
       return res.data.data
}

export const getMostUserApps = async() =>{
       let res = await axios.get(`/api/kpiTrend/topApp/session`)
       return res.data.data
}

export const getPopularApps = async() =>{
       let res = await axios.get("/api/kpiTrend/topApp/successfull")
       return res.data.data
}

export const getAppPerformance = async (params:any) =>{
  const res = await axios.get(`/api/kpiTrend/successfull_conversation/count?${qs(params)}`)
  return res.data.data.totalItemCount
}

export const getTotalSessions = async (type: string,dateRage:any) => {
  let res;
  if (type !== '')
  {
    res = await axios.get(`/api/kpiTrend/session/count?type=${type}&${qs(dateRage)}`)
  } else
  {
    res = await axios.get(`/api/kpiTrend/session/count?${qs(dateRage)}`)
    }
    return res.data.data
}

// export const getCustomerServiceLeads = async () =>{
//         let res = await axios.get("/api/kpiTrend/countByTags")
//         return res.data.data.records
// }

export const getAudiences = async () =>{
        let res = await axios.get("/audience")
        return res.data
}

export const getRecentAudiences = async () =>{
        let res = await axios.get("/recentAudience")
        return res.data
}

// export const getFacebookCompaigns = async () =>{
//         let res = await axios.get("/facebookCompaign")
//         return res.data
// }

export const getChannels = async () =>{
        let res = await axios.get("/channels")
        return res.data
}    

export const getAllCampaigns = async () =>{
        let res = await axios.get("/allCampaign")
        return res.data
}    

export const getLeadsGenerated = async (filter: any) => {
  let res = await axios.post(`/api/get_contacts`, filter)
  return res.data.data.records
}    

