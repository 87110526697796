/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import { useIntl } from 'react-intl'
type Props = {
  className: string
  startedConversationsCount: any
  visitorsCount: any
  visitorVsStartedDate: any
}
const ChartsWidget11: React.FC<Props> = ({className, startedConversationsCount, visitorsCount, visitorVsStartedDate}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const chartRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, t, startedConversationsCount, visitorsCount, visitorVsStartedDate))
    if (chart) {
      chart.render()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, startedConversationsCount, visitorsCount, visitorVsStartedDate])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark fs-3 mb-1'>{t('UNIQUE_VISITOR_VS_STARTED')}</span>
          <span className='text-gray-400 mt-1 fw-bold fs-6'>{t('VISITOR_VS_STARTED_SUBTITLE')}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_11_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
      <div className="card-footer align-items-center justify-content-center text-center">
        <span className='text-gray-400 mt-1 fw-bold fs-6 text-center'>{t('VISITOR_VS_STARTED_CHART_DESCRIPTION')}</span>
      </div>
    </div>
  )
}
export {ChartsWidget11}
function getChartOptions(height: number, t: any, startedConversationsCount: any, visitorsCount: any, visitorVsStartedDate: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const baseprimaryColor = getCSSVariableValue('--bs-primary')
  const lightprimaryColor = getCSSVariableValue('--bs-primary')
  const basesuccessColor = getCSSVariableValue('--bs-success')
  const lightsuccessColor = getCSSVariableValue('--bs-success')
  const startedConversationColor = getCSSVariableValue('--bs-blue')
  const visitorColor = getCSSVariableValue('--bs-red')
  return {
    series: [
      {
        name: t('VISITOR_COLUMN'),
        data: visitorsCount
      },
      {
        name: t('STARTED_CONVERSATION'),
        data: startedConversationsCount
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center', 
      offsetX: 0,
      offsetY: 10, 
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      markers: {
          width: 30,
          height: 6,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
      },
      itemMargin: {
        horizontal: 30,
        vertical: 5
      },
      formatter: function (val, opts) {
        return val
      }
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.2,
        stops: [15, 120, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [visitorColor, startedConversationColor],
    },
    xaxis: {
      type:"datetime",
      categories: visitorVsStartedDate,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 6,
      labels: {
        rotate: 0,
        rotateAlways: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseprimaryColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        title: {
          text: t('VISITOR_COLUMN'),
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        }
      },
      {
        opposite: true,
        title: {
          text: t('STARTED_CONVERSATION'),
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        }
      }
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    colors: [visitorColor, startedConversationColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: [visitorColor, startedConversationColor],
      strokeWidth: 3,
    },
  }
}
