import {FC} from 'react'
import {useIntl} from 'react-intl'
import {isModulePermitted, isGroweoContentadmin} from '../../../utils/utils'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {getGlobalItem} from '../../../utils/utils'

const GrowthEngineBreadCrumb: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const contentAdmin = isGroweoContentadmin()
  const workspaceId = getGlobalItem('user')?.workspaceId

  const growthEngineBreadCrumbs: Array<PageLink> = [
    {
      title: t('DASHBOARD'),
      path: '/dashboard',
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('DASHBOARD'),
    },
    {
      title: t('PERFORMANCE_REPORT'),
      path: `/performance-reports/${workspaceId}`,
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('PERFORMANCE_REPORT'),
    },
    {
      title: `${t('GROWTH_APPS_MENU', {count: 1})}`,
      path: '/gapps',
      isSeparator: false,
      isActive: false,
      isShow: isModulePermitted('GROWTH_APPS'),
    },
  ]
  const filteredData = growthEngineBreadCrumbs.filter((b) => b.isShow)

  return (
    <PageTitle breadcrumbs={filteredData}>{`${t('GROWTH', {coun: 0})} ${t('ENGINE', {
      coun: 0,
    })} `}</PageTitle>
  )
}

export default GrowthEngineBreadCrumb
