import React from 'react'
import {useIntl} from 'react-intl'
import { useLocation } from 'react-router-dom'
import { checkIsActive } from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import {isModulePermitted} from '../../../../utils/utils'
export function AdminAsideMenu() {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const {pathname} = useLocation()
  const administrationActive =
  checkIsActive(pathname, 'templates') ||
  checkIsActive(pathname, 'workspaces')

  return (
    <>
      <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span
              className={`menu-section ${
                administrationActive ? 'text-white' : 'text-muted'
              } text-uppercase fs-8 ls-1 `}
            >
              {t('ADMINISTRATION')}
            </span>
          </div>
      </div>
      {/* <AsideMenuItem to='/templates' title='Templates' /> */}
      {/* <AsideMenuItem to='#' title='Themes' /> */}
      <AsideMenuItem to='/workspaces' title={t('CUSTOMER', { count: 1 })} isShow={isModulePermitted('CUSTOMER')}/>
      <AsideMenuItem to='/subscriptions' title={t('SUBSCRIPTION', {count: 1})} isShow={isModulePermitted('SUBSCRIPTION')}/>
      <AsideMenuItem to='/performance-reports' title={t('PERFORMANCE_REPORT', {count: 1})} isShow={isModulePermitted('PERFORMANCE_REPORT')}/>
    </>
  )
}
