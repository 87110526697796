/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import PerformanceReport from './PerformanceReport'
import PerformanceView from './view/PerformanceView'
import CustomerReportPageView from './view/CustomerReportPageView';

const PerformanceContainer: FC = () => {
  return (
    <Switch>
      <Route path='/performance-reports' component={PerformanceReport} exact />
      <Route path='/performance-reports/:workspaceId' component={PerformanceView} exact />
      <Route path='/performance-reports/:workspaceId/customer-report/:workspaceName' component={CustomerReportPageView} exact />
    </Switch>
  )
}

export default PerformanceContainer
