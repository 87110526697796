import {useEffect, useState, useContext} from 'react'
import moment from 'moment'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import AccordionContext from 'react-bootstrap/AccordionContext'
import {useAccordionButton} from 'react-bootstrap/AccordionButton'
import {useLayout} from '../../../_metronic/layout/core'
import ChangeLogFlyout from '../../../_metronic/layout/components/toolbar/ChangeLogFlyout'
import {changeLogData} from '../../../service/changelogMD.service'
import ChangeLogToolbar from '../../../_metronic/layout/components/toolbar/ChangeLogToolbar'

const ChangeLogList = () => {
  const [changeLog, setChangeLog] = useState([{}])
  const [changeLogVal, setChangeLogVal] = useState([{}])
  const [pdfLink, setPDFLink] = useState<any>()
  const updatePDFLink = (link: any) => {
    setPDFLink(link)
  }

  const ContextAwareToggle = ({children, eventKey, callback}: any) => {
    const {activeEventKey} = useContext(AccordionContext)
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))
    const isCurrentEventKey = activeEventKey === eventKey

    return (
      <div className='d-flex align-items-baseline toggle-Arrow' onClick={decoratedOnClick}>
        <span>
          <i
            className={`${isCurrentEventKey ? 'text-primary' : ''} fas fa-arrow-right fs-3`}
            style={{
              transform: isCurrentEventKey ? 'rotate(90deg)' : 'rotate(0deg)',
              transitionDelay: '0s',
              transitionDuration: '0.25s',
            }}
          ></i>
        </span>
        {children}
      </div>
    )
  }

  useEffect(() => {
    async function makeAsync() {
      let data = await changeLogData()
      let sortedLogData = data.sort(function (a, b) {
        var keyA = parseFloat(a.version),
          keyB = parseFloat(b.version)
        if (keyA > keyB) return -1
        if (keyA < keyB) return 1
        return 0
      })
      setChangeLog(sortedLogData)
      setChangeLogVal(sortedLogData)
    }
    makeAsync()
  }, [changeLogData])

  const onSearch = (e: any) => {
    const search = e.target.value.toLowerCase()
    changeLogSearch(changeLog, search)
  }

  const changeLogSearch = async (data: any, search: string) => {
    if (!search) {
      setChangeLogVal(changeLog)
      return;
    }
    const filteredChangeLog: any = [];

    data.forEach((item: any) => {
      const newChangeLog: any = {}

      for (const changeType in item.changes) {
        const filteredChanges = item.changes[changeType].filter(
          (change: any) =>
            change.title.toLowerCase().includes(search) ||
            change.description.toLowerCase().includes(search)
        )
        if (filteredChanges.length > 0) {
          newChangeLog[changeType] = filteredChanges
        }
      }
      if(Object.keys(newChangeLog).length) {
        filteredChangeLog.push({
          ...item,
          changes: newChangeLog,
        })
      }
    })
    setChangeLogVal(filteredChangeLog)
  }

  return (
    <>
      <ChangeLogToolbar title='CHANGE_LOG' onSearch={onSearch} />
      <div className='row gy-5 g-xl-12'>
              <div className='card card-docs flex-row-fluid mb-2' id='kt_docs_content_card'>
                <div className='card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700'>
                  <Accordion defaultActiveKey='0'>
                    {changeLogVal.map((data: any, index: any) => {
                      return (
                        <Card>
                          <Card.Header className={`accordion-icon${index} border-0`}  style={{minHeight:'50px'}}>
                            <ContextAwareToggle eventKey={index}>
                              <h3 className={`fs-2 text-gray-800 fw-bold mb-0 ms-4`}>
                                Groweo v{data.version} -{' '}
                                {data.date
                                  ? `${moment(data.date).format('DD MMMM, yyyy')}`
                                  : '24 March, 2023'}
                              </h3>
                            </ContextAwareToggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={index}>
                            <Card.Body className='pt-0'>
                              { data?.changes?.New?.length > 0? (
                                <div className='mb-5'>
                                  <h3 className='fs-6 fw-bold mb-1'>New Feature:</h3>
                                  {data?.changes?.New?.map((feat: any) => {
                                    return (
                                      <ul className='my-0 py-0'>
                                        <li className='py-2'>
                                          <code className='ms-0'>{feat.title}</code>{' '}
                                          {feat.description}
                                          {feat.link?  (<button
                                            onClick={() => updatePDFLink(feat.link)}
                                            className='bg-light rounded border-0 text-primary fw-bold app-layout-builder-toggle'
                                            id='kt_app_layout_builder_toggle'
                                          >
                                          -{' '} Doc
                                         </button>): null}
                                          .
                                        </li>
                                      </ul>
                                    )
                                  })}
                                </div>
                              ): null}
                              {data?.changes?.Changed?.length > 0? (
                                <div className='mb-5'>
                                 <h3 className='fs-6 fw-bold mb-1'>Changed:</h3>
                                 {data?.changes?.Changed?.map((feat: any) => {
                                   return (
                                     <ul className='my-0 py-0'>
                                       <li className='py-2'>
                                         <code className='ms-0'>{feat.title}</code>{' '}
                                         {feat.description}
                                         {feat.link? (<button
                                           onClick={() => updatePDFLink(feat.link)}
                                           className='bg-light rounded border-0 text-primary fw-bold app-layout-builder-toggle'
                                           id='kt_app_layout_builder_toggle'
                                         >
                                          -{' '} Doc
                                         </button>): null}
                                         .
                                       </li>
                                     </ul>
                                   )
                                 })}
                               </div>
                              ):null}
                              {data?.changes?.Fixed?.length > 0? (
                                <div className='mb-5'>
                                 <h3 className='fs-6 fw-bold mb-1'>Fixed:</h3>
                                 {data?.changes?.Fixed?.map((feat: any) => {
                                   return (
                                     <ul className='my-0 py-0'>
                                       <li className='py-2'>
                                         <code className='ms-0'>{feat.title}</code>{' '}
                                         {feat.description} 
                                         {feat.link? (<button
                                           onClick={() => updatePDFLink(feat.link)}
                                           className='bg-light rounded border-0 text-primary fw-bold app-layout-builder-toggle'
                                           id='kt_app_layout_builder_toggle'
                                         >
                                          -{' '} Doc
                                         </button>): null}
                                         .
                                       </li>
                                     </ul>
                                   )
                                 })}
                               </div>
                              ): null}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                    })}
                  </Accordion>
                </div>
          <ChangeLogFlyout pdfLink={pdfLink} />
        </div>
      </div>
    </>
  )
}

export default ChangeLogList
