/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import moment from 'moment'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import SelectInputFeild from '../../../../app/shared/SelectInputFeild'
import {getDateOptions, getReportOptions} from '../../../../utils/utils'
import PerformanceReportFilter from '../../../../app/modules/common/shared/filter/PerformanceReportFilter'
import { filterStatus, getFilterAppType, getWorkspaceStatus } from '../../../../utils/module.utils'
import GrowthAppFilterMenu from '../../../../app/modules/common/shared/filter/GrowthAppFilterMenu'

type PerformanceReportToolbarProps = {
  dateRange: any
  setDateRange: any
  selected: any
  setSelected: any
  downloadReportCsv:any
  filter: any
  setFilter: any
  appFilter: any
  setAppFilter: any
  reportType: string
  setReportType: any
}

const PerformanceReportToolbar: FC<PerformanceReportToolbarProps> = ({
  setDateRange,
  selected,
  setSelected,
  downloadReportCsv,
  filter,
  setFilter,
  appFilter,
  setAppFilter,
  reportType,
  setReportType
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  const [workspaceStatus, setWorkspaceStatus]: any = useState(filter?.status || getWorkspaceStatus);
  const [partnerId, setPartnerId]: any = useState(filter?.partnerId || '');
  const [type, setType]: any = useState(getFilterAppType)

  const onSelectDateRange = (_name: any, days: any) => {
    const startDate = moment().subtract(days, 'd').format('ll')
    const endDate = Number(days) === 1 ? moment().format('ll') : moment().add(1, 'd').format('ll')
    setSelected(days)
    setDateRange(days === 'all' ? {} : {startDate, endDate})
  }

  const onReset = () => {
    setWorkspaceStatus(getWorkspaceStatus)
    setPartnerId('')
    setFilter({...filter, status: {...getWorkspaceStatus, inactive: true}, partnerId})
  }

  const onApply = () => {
    setFilter({...filter, status: workspaceStatus, partnerId})
  }

  const onAppFilterApply = () => {
    setAppFilter({...appFilter, type: filterStatus(type)})
  }

  const onAppFilterReset = () => {
    setType(getFilterAppType)
    setAppFilter({...appFilter, type: filterStatus(getFilterAppType)})
  }

  const onSearch = (reportType: string, search: string) => {
    if (reportType === 'customer') {
      setFilter({...filter, search})
    } else if (reportType === 'app') {
      setAppFilter({...appFilter, search})
    }
  }

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        style={{padding: '0px'}}
      >
        <div>
          <SectionHeading
            title={t('PERFORMANCE_REPORT', {count: 1})}
            subTitle={t('ALL_CUSTOMER_PERFORMANCE', {count: 1})}
          />
        </div>
        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center'></div>
          {['customer', 'app'].includes(reportType) && (
            <div className='d-flex align-items-center position-relative me-2'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                className='form-control form-control-solid w-300px ps-14 bg-white'
                placeholder={t('SEARCH')}
                value={ reportType === 'app' ? appFilter?.search || "" : filter?.search || ""}
                onChange={(e) => onSearch(reportType, e.target.value)}
              />
            </div>
          )}
          <div className='d-flex align-items-center me-2'>
            <SelectInputFeild
              hideDefault={true}
              name='reportType'
              classes='bg-white'
              value={reportType}
              options={getReportOptions(t)}
              onChange={(name: string, value: string) => setReportType(value)}
            />
          </div>
          <div className='d-flex align-items-center me-2'>
            <SelectInputFeild
              hideDefault={true}
              name='date'
              classes='bg-white'
              value={selected}
              options={getDateOptions(t)}
              onChange={onSelectDateRange}
            />
          </div>
          {reportType === 'customer' && (
             <div className='d-flex align-items-center me-2'>
              <PerformanceReportFilter
                status={workspaceStatus}
                setStatus={setWorkspaceStatus}
                onApply={onApply}
                onReset={onReset}
                partnerId={partnerId}
                setPartnerId={setPartnerId}
              />
            </div>
          )}
          {reportType === 'app' && (
            <div className='d-flex align-items-center me-2'>
              <GrowthAppFilterMenu
                type={type}
                setType={setType}
                onApply={onAppFilterApply}
                onReset={onAppFilterReset}
                appType={getFilterAppType}
              />
            </div>
          )}
          <div className='d-flex align-items-center me-2'>
            <a
            className='btn btn-primary text-uppercase'
            onClick={downloadReportCsv}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr091.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
              />
              {t('DOWNLOAD', {count: 0})}
            </a>
          </div>
          <div className='d-flex align-items-center'>
            <a
              className='btn btn-sm p-1'
              data-bs-toggle='modal'
              data-bs-target={`#gw_performance_settings_modal`}
              id='gw_performance_settings_modal_button'
            >
              <KTSVG
                path='/media/icons/duotune/coding/cod011.svg'
                className='svg-icon svg-icon-gray-900 svg-icon-3x'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PerformanceReportToolbar}
