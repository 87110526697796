import axios from 'axios'
import {handleError, qs} from '../utils/utils'

export const createContact = async (data: any) => {
  try {
    const response = await axios.post(`/api/contact`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const updateContact = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/contact/${id}`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const getContact = async (params: any) => {
  try {
    const response = await axios.get(`/api/contact?${qs(params)}`)
    return response?.data?.data?.records
  } catch ({response}) {
    handleError(response)
  }
}

export const updateContactSeen = async (id: string) => {
  try {
    const response = await axios.put(`/api/contact/seen/${id}`)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const getContactWithSessionById = async (id: string) => {
  try {
    const response = await axios.get(`/api/contact/session/${id}`)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const getContactById = async (id: string) => {
  try {
    const response = await axios.get(`/api/contact/${id}`)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const deleteContactBtId = async (id: string) => {
  try {
    const response = await axios.delete(`/api/contact/${id}`)
    return response.data?.data?._id
  } catch ({ response }) {
    handleError(response)
  }
}

export const downloadContactCSV = async (body: any, fileName: string) => {
  try {
    const response = await axios.post(`/api/contact/download`, body, { responseType: 'arraybuffer' as 'json' })
    const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch ({response}) {
    handleError(response)
  }
}
