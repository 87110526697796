/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import BotsDetailContainer from '../bots/BotsDetailContainer'
import FormCreate from '../formBuilder/formCreate'
import TemplateContainer from './TemplateContainer'

const FormsContainer: FC = () => {
  return (
    <Switch>
      <Route path='/templates/forms/:templateId/:step' component={FormCreate} exact />
      <Route path='/templates/bots/:templateId/:step' component={BotsDetailContainer} exact />
      <Route path='/templates/:module' component={TemplateContainer} exact />
    </Switch>
  )
}

export default FormsContainer
