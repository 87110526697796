import axios from "axios"
import { isFilterModulePermitted } from "./utils"
import moment from "moment"

export const DEFAULT_THEME = {
  background: '',
  width: 'auto',
  height: 'auto',
  theme: 'white',
  styles: 'none-style',
  btnBackground: '',
  buttonColor: '',
}

export const THEME_LIST = [
  {value: 'white', label: 'White'},
  // {value: 'faded-light', label: 'Faded Light'},
  {value: 'faded-dark', label: 'Faded Dark'},
  {value: 'dark', label: 'Dark'},
  {value: 'purple', label: 'Purple'},
  {value: 'red', label: 'Red'},
  {value: 'green', label: 'Green'},
  {value: 'blue', label: 'Blue'},
  {value: 'groweo', label: 'Groweo'},
]

export const STYLE_LIST = [
  {value: 'underline-style', label: 'Underline'},
  {value: 'classic-style', label: 'Classic'},
  {value: 'classic-rounded-style', label: 'Classic Rounded'},
  {value: 'modern-style', label: 'Modern'},
  {value: 'modern-rounded-style', label: 'Modern Rounded'},
  {value: 'none-style', label: 'None'},
]

export const FORM_STYLE = t => {
  return [
    {value: 'classic-style', text: t('CLASSIC')},
    {value: 'classic-rounded-style', text: `${t('CLASSIC')} ${t('ROUNDED')}`},
    {value: 'modern-style', text: t('MODERN')},
    {value: 'modern-rounded-style', text: `${t('MODERN')} ${t('ROUNDED')}`},
    {value: 'none-style', text: t('NONE')},
  ]
}

export const FLOW_BTN_STYLE = t => {
  return [
    {value: 'classic-rounded-btn', text: `${t('CLASSIC')} ${t('ROUNDED')}`},
    {value: 'modern-btn', text: t('MODERN')},
  ]
}

export const formFields = [
  // {type: 'date', name: 'birthday', label: 'Birthday' , require: false, showlabel:false},
  // {type: 'text', name: 'company', label: 'Company', placeholder: 'Company', require: false, showlabel:false},
  {type: 'text', name: 'name' , label: 'Name', placeholder: 'Name', require: false, showlabel:false, column: 1, row: 1},
  {type: 'email', name: 'email', label: 'Email', placeholder: 'Email', require: false, showlabel:false, column: 1, row: 2},
  {type: 'tel', name: 'phone', label: 'Phone Number', placeholder: 'Phone', require: false, showlabel:false, column: 1, row: 3},
  // {type: 'url', name: 'website', label: 'Website', placeholder: 'Website', require: false, showlabel:false},
  // {type: 'text', name: 'zipcode', label: 'Zipcode', placeholder: 'Zipcode', require: false, showlabel:false},
]

export const TYPE_NAMES = {
  text: 'Text',
  inputt: 'Question',
  options: 'Choices',
  form: 'Form',
  email: 'Email',
  card: 'Card',
  carousel: 'Gallery',
  linkk: 'Link',
  end: 'End',
}

export const FLOW_TYPE_NAMES = {
  text: 'Text',
  options: 'Choices',
  content: 'Content',
}

export const STEPS = [
  {id: '1', message: 'What is your name?', trigger: '2'},
  {id: '2', message: 'Bob', usr: true, trigger: '3'},
  {id: '3', message: 'Hi Bob, nice to meet you!', end: true},
]

export const BOT_COLORS = [
  {name: 'Red', color: '#ff0000', class: 'red'},
  {name: 'Dark Blue', color: '#1A175E', class: ''},
  {name: 'Blue', color: '#454eff', class: 'blue'},
]

export const BOT_APPEARANCE = {
  primaryColor: '#1A175E',
  hideHeader: false,
  hideBotAvatar: false,
  mode: 'dark',
  headerTitle: '',
  height: '550',
  width: '500',
  avatar: '',
  flowSettings: {x: 0, y: 0, zoom: 1},
}

export const BOT_APPEARANCE3 = {
  primaryColor: '#1A175E',
  hideHeader: false,
  hideBotAvatar: false,
  botType: 'inPage',
  mode: 'dark',
  headerTitle: '',
  height: '550',
  width: '500',
  inPageHeight: 'auto',
  inPageWidth: 'auto',
  avatar: '',
  iconBgColor: '#1A175E',
  iconBgImage: '',
  iconColor: '#FFFFFF',
  headerFontColor: '#FFFFFF',
  headerBgColor: '#1A175E',
  contentBgType: 'color',
  iconBgType: 'color',
  background:'#FFFFFF',
  botFontColor: '#4F4F4F',
  botBubbleColor: '#1A175E',
  userFontColor: '#ffffff',
  userBubbleColor: '#1A175E',
  onOptionHoverTextColor: '#1A175E',
  onOptionHoverBgColor:'#FFFFFF',
  optionFontColor: '#1A175E',
  optionBubbleColor: '#ffffff',
  botFormLabelColor: '#000',
  botFormButtonTextColor: '#FFF',
  botFormButtonBgColor: '#1A175E',
  botFormButtonHoverTextColor: '#1A175E',
  botFormButtonHoverBgColor: '#fff',
  chatNotificationTextColor:'#4F4F4F',
  chatNotificationBgColor: '#fff',
  cardBtnTextColor: '#fff',
  cardBtnBgColor: '#1A175E',
  cardBtnHoverTextColor: '#1A175E',
  cardBtnHoverBgColor: '#fff',
  flowSettings: {x: 0, y: 0, zoom: 1},
  showMobileNotification: true,
}

export const SMARTFLOW_APPEARANCE = {
  flowSettings: {x: 0, y: 0, zoom: 1.5},
  style: {
    wrapperHeight: 650,
    isGoogleFont: false,
    googleFontUrl: '',
    wrapperFontFamily: '"Proxima Nova","proxima-nova","proxima-nova-webfont",sans-serif',
    wrapperWidth: 'auto',
    wrapperBgColor: '#ffffff',
    wrapperBgImage: '',
    mobileWrapperBgImage: '',
    titleColor: '#000000',
    titleFontSize: 28,
    titleFontWeight: 400,
    titleLineHeight: 140,
    titleFontStyle: 'heading',
    titleTop: 5,
    titleWrapPadding: '5px',
    subtitleColor: '#000000',
    subtitleFontSize: 16,
    subtitleFontWeight: 400,
    subtitleLineHeight: 140,
    subtitleFontStyle: 'normal',
    subtitleTop: 10,
    subtitleWrapPadding: '20px 5px 5px 5px',
    contentColor: '#000000',
    contentFontSize: 20,
    contentFontWeight: 400,
    contentLineHeight: 140,
    contentFontStyle: 'large',
    contentTop: 30,
    contentWrapPadding: '20px',
    opTitleColor: '#000000',
    opTitleFontSize: 20,
    opTitleFontWeight: 600,
    opTitleLineHeight: 140,
    opTitleFontStyle: 'small',
    opSubtitleColor: '#000000',
    opSubtitleFontSize: 18,
    opSubtitleFontWeight: 400,
    opSubtitleLineHeight: 140,
    opSubtitleFontStyle: 'small',
    opImgOutline: '1px solid',
    opImgOutlineColor: '#000000',
    opImgHoverOutlineColor: '#41B35C',
    optionCheckmarkColor: '#41b35c',
    badgeColor: '#41B35C',
    optionBgColor: '#00000024', 
    opSelectionColor: '#bec0eb',
    badgeDisableColor: '#dbdbdb',
    submitDisableColor: '#dbdbdb',
    dotsDisableColor: '#000000',
    dotsColor: '#1A175E',
    formStyle: 'underline-style',
    formLabelColor: '#000000',
    formLabelFontSize: 14,
    formLabelFontWeight: 400,
    formLabelLineHeight: 100,
    formLabelFontStyle: 'small',
    formFieldBGPercentage: 0,
    fieldBGColor: '#f4f4f4',
    fieldTextColor: 'rgb(0,0,0)',
    formMultiColumn: 100,
    formWrapPadding: '10px',
    col1Alignment: 'center',
    col2Alignment: 'center',
    footerTop: 80,
    footerMargin: '40px 0px 40px 0px',
    footerMode: false,
    buttonStyle: 'modern-btn',
    submitColor: '#fff',
    submitFontSize: 14,
    submitBgColor: '#4F4F4F',
    submitHoverColor: '#fff',
    submitHoverBgColor: '#000',
    groweoFontColor: '#4F4F4F',
    groweoIconColor: '#4F4F4F',
    fieldPlaceholderColor: '#999',
    navigationBtnColor: '#000000',
    closeBgColor: '#DDDDDD',
    closeIconColor: '#000000',
    closeBorderRadius: '0px',
    closeMargin: '0px',
    mobileZoom: '100',
  }
}

export function getTheme(primaryColor) {
  return {
    light: {
      botBubbleColor: '#F4F4F4',
      avatarColor: primaryColor,
      smallAvatarColor: primaryColor,
      background: '#fff',
      headerFontSize: '18px',
      headerFontColor: primaryColor,
      userBubbleColor: primaryColor,
      headerBgColor: '#fff',
      userFontColor: '#fff',
    },
    dark: {
      botBubbleColor: '#F4F4F4',
      avatarColor: '#fff',
      smallAvatarColor: primaryColor,
      background: '#fff',
      headerFontSize: '18px',
      headerFontColor: '#fff',
      userBubbleColor: primaryColor,
      headerBgColor: primaryColor,
      userFontColor: '#fff',
    },
  }
}

export function getTheme3(primaryColor) {
  return {
    light: {
      primaryColor: primaryColor || '#1A175E',
      hideHeader: false,
      hideBotAvatar: false,
      mode: 'light',
      headerTitle: '',
      height: '700',
      width: '500',
      avatar: '',
      iconBgColor: primaryColor || '#1A175E',
      iconColor: '#FFFFFF',
      headerFontColor: primaryColor,
      headerBgColor: '#FFFFFF',
      contentBgType: 'color',
      background:'#FFFFFF',
      botFontColor: '#4F4F4F',
      botBubbleColor: '#F4F4F4',
      userFontColor: '#FFFFFF',
      userBubbleColor: primaryColor,
      onOptionHoverTextColor: primaryColor,
      onOptionHoverBgColor:'#FFFFFF',
      optionFontColor: '#FFFFFF',
      optionBubbleColor: primaryColor,
      smallAvatarColor: primaryColor,
      smallAvatarTextColor: "#4F4F4F",
      botFormLabelColor: '#000',
      botFormButtonTextColor: '#FFF',
      botFormButtonBgColor: primaryColor,
      botFormButtonHoverTextColor: primaryColor,
      botFormButtonHoverBgColor: '#fff',
      chatNotificationTextColor:'#000',
      chatNotificationBgColor: '#fff',
      cardBtnTextColor: '#fff',
      cardBtnBgColor: primaryColor,
      cardBtnHoverTextColor: primaryColor,
      cardBtnHoverBgColor: '#fff',
      flowSettings: {x: 0, y: 0, zoom: 1},
      inPageHeight: 'auto',
      inPageWidth: 'auto',
    },
    dark: {
      primaryColor: primaryColor || '#1A175E',
      hideHeader: false,
      hideBotAvatar: false,
      mode: 'dark',
      headerTitle: '',
      height: '700',
      width: '500',
      avatar: '',
      iconBgColor: primaryColor || '#1A175E',
      iconColor: '#FFFFFF',
      headerFontColor: "#FFFFFF",
      headerBgColor: primaryColor,
      contentBgType: 'color',
      background:'#FFFFFF',
      botFontColor: '#4F4F4F',
      botBubbleColor: '#F4F4F4',
      userFontColor: '#FFFFFF',
      userBubbleColor: primaryColor,
      onOptionHoverTextColor: primaryColor,
      onOptionHoverBgColor:'#FFFFFF',
      optionFontColor: '#FFFFFF',
      optionBubbleColor: primaryColor,
      smallAvatarColor: primaryColor,
      smallAvatarTextColor: "#4F4F4F",
      botFormLabelColor: '#000',
      botFormButtonTextColor: '#FFF',
      botFormButtonBgColor: primaryColor,
      botFormButtonHoverTextColor: primaryColor,
      botFormButtonHoverBgColor: '#fff',
      chatNotificationTextColor:'#4F4F4F',
      chatNotificationBgColor: '#fff',
      cardBtnTextColor: '#fff',
      cardBtnBgColor: primaryColor,
      cardBtnHoverTextColor: primaryColor,
      cardBtnHoverBgColor: '#fff',
      flowSettings: {x: 0, y: 0, zoom: 1},
      inPageHeight: 'auto',
      inPageWidth: 'auto',
    },
  }
}

export const BAR_APPEARANCE = {
  width : '400px',
  height: '500px',
  direction: 'right',
  barTitle: 'Smart Bar',
  barFontColor : '#1A175E',
  barBgColor: '#fff',
  barTitleFontSize: 1,
  contentTitle: `<p>Hey you, <br>let's get started!</p>`,
  contentSubTitle: '<p>Signing up only takes a less <br>then one minute.</p>',
  contentBgColor:'#ffffff',
  contentBgImage: '',
  contentTitleFontColor : '#1A175E',
  contentSubTitleFontColor : '#1A175E',
  footerFontColor : '#1A175E',
  barFormSubmitBtnText : 'submit',
  barFormLabelColor: '#000',
  barFormInputTextColor: '#000',
  barFormInputBgColor: 'rgba(245, 248, 250, 1)',
  barFormInputPlaceholderColor: '#999',
  invertBarColor: true,
  autoBarHeight: true,
  barFieldBGPercentage: 100,
  barFormPadding: '5px',
  barFormButtonTextColor: '#FFF',
  barFormButtonBgColor: '#1A175E',
  barFormButtonHoverTextColor: '#1A175E',
  barFormButtonHoverBgColor: '#fff',
  successMsgTitle: '<p>Great thanks!</p>',
  successMsgSubTitle: '<p>We have successfully received the information you sent.</p>',
  successTitleFontColor : '#1A175E',
  successSubTitleFontColor : '#1A175E',
  SwitchToMobileMode: true,
  iconBgColor: '#1A175E',
  iconColor: '#FFFFFF',
  iconBgImage: '',
  iconBgType: 'bar',
  iconColorPosition: 'middle',
  iconImagePosition: 'middle',
  imageBgColor: '#1A175E',
  closeBgColor: 'transparent',
  closeIconColor: '#000000',
  closeBorderRadius: '0px',
  closeMargin: '0px',
  formButtonType: 'Form Submit',
  barFormSubmitBtnUrl: '',
  barFormSubmitBtnPhone: '',
  barFormSubmitBtnEmail: '',
  barFormSubmitBtnTextUrl: 'Submit',
  barFormSubmitBtnTextEmail: 'Submit',
  barFormSubmitBtnTextPhone: 'Submit',
  barFormAutoWidth: true,
  barFormAutoWidthRange: 100,
  barFormButtonPosition: 'left'
}

export function renderModuleForm(content) {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = JSON.parse(content)
  let formContent = wrapper.getElementsByClassName('customForm')
  if (!formContent.length) formContent = wrapper.getElementsByClassName('simple-form')
  const el = document.getElementById('form-appearance')
  if (formContent.length && el) el.innerHTML = formContent[0].innerHTML
}

export function renderModuleBot(content) {
  const rSteps = content?._renderedSteps
  for (let i = 0; i < rSteps?.length; i++) {
    if (rSteps[i].value !== undefined) rSteps[i].usr = true
    if (i === rSteps.length - 1) rSteps[i].end = true
    else rSteps[i].trigger = rSteps[i + 1].id
  }
  return rSteps
}

export function setModuleForm(data, iDocument) {
  const elDocument = iDocument || document
  const form = elDocument.querySelector('form')
  if (!form) return
  const submitEl = elDocument.querySelectorAll('[type=submit]')
  for (let i = 0; i < submitEl.length; i++) submitEl[i].disabled = true
  form.reset()
  Object.keys(data).forEach((key) => {
    const el = elDocument.getElementsByName(key)
    if (!el.length) {
      if (key === 'email' || key === 'phone') {
        const el = elDocument.querySelector(`input[type=${key === 'email' ? 'email' : 'tel'}]`)
        if (el) el.value = data[key]
      } else {
        const el = elDocument.getElementsByName(`${key}[]`)
        if(!el?.length) return
        for (let i = 0; i < el.length; i++) el[i].checked = data[key].includes(el[i].value)
      }
      return
    }
    if (el[0].type === 'checkbox' || el[0].type === 'radio') {
      for (let i = 0; i < el.length; i++) el[i].checked = data[key].includes(el[i].value)
    } else if (el[0].type === 'submit') el[0].disabled = true
    else if (el[0].type === 'file') el[0].src = data[key]
    else el[0].value = data[key]
  })
}

export function getModulePath(from) {
  const blockApp = ['smartblock', 'smartpopup']
  const {pathname} = window.location
  const pathArr = pathname.split(`/${ blockApp.includes(from) ? from : `${from}s` }`)
  return `${pathArr[0]}/${ blockApp.includes(from) ? from : `${from}s` }`
}

export function resetInteractive(isOpen) {
  const el = document.getElementsByClassName('react-flow__controls-interactive')
  if (el.length) el[0].click()
  const elStepper = document.getElementById('kt_create_account_stepper')
  if (elStepper) elStepper.style.pointerEvents = isOpen ? 'none' : 'auto'
}

export function getCustomMailTemplateFields(code) {
  const fields = []
  if (!code) return
  // eslint-disable-next-line array-callback-return
  JSON.parse(code).map((e) => {
    if (e.type === 'text') {
      const name = e.name.replace(/([A-Z])/g, ' $1')
      fields.push({name: name.charAt(0).toUpperCase() + name.slice(1), value: `{{${e.name}}}`})
    }
  })
  return fields
}

export function addBotElement(moduleId, element) {
  return axios.post(`/api/module/${moduleId}/bot_element/add`, {
    element
  })
}

export function removeBotElement(moduleId, elementId) {
  return axios.post(`/api/module/${moduleId}/bot_element/remove`, {
    elementId
  })  
}

export function removeBotConnection(moduleId, { source, target, sourceHandle, targetHandle }) {
  return axios.post(`/api/module/${moduleId}/bot_element/remove_connection`, {
    source,
    target,
    sourceHandle,
    targetHandle
  })    
}

export function updateBotElement(moduleId, element) {
  return axios.put(`/api/module/${moduleId}/bot_element/update`, {
    element
  })    
}
export function getRGB(c) {
  return parseInt(c, 16) || c;
}

export function getsRGB(c) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

export function getLuminance(hexColor) {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
}

export function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor) {
  const whiteContrast = getContrast(bgColor, "#ffffff");
  const blackContrast = getContrast(bgColor, "#000000");

  return whiteContrast > blackContrast ? "#ffffff" : "#000000";
}

export const getGrowthAppTypes = t => {
  return [
    {value: 'bot', text: t('SMART_BOT', {count: 0})},
    {value: 'form', text: t('SMART_FORM', {count: 1})},
    {value: 'smartblock', text: t('SMART_BLOCK', {count: 1})},
    {value: 'smartbar', text: t('SMART_BAR', {count: 1})},
    {value: 'smartflow', text: t('SMART_FLOW', {count: 1})},
    {value: 'smartpopup', text: t('SMART_POPUP', {count: 1})},
    {value: 'file', text: t('DOWNLOAD_TOOL', {count: 1})},
    {value: 'list', text: t('LIST', {count: 1})},
  ]
}

export const getFilterAppType = {
  bot: isFilterModulePermitted('SMART_BOT'),
  form: isFilterModulePermitted('SMART_FORM'),
  smartblock: isFilterModulePermitted('SMART_BLOCK'),
  smartbar: isFilterModulePermitted('SMART_BAR'),
  smartflow: isFilterModulePermitted('SMART_FLOW'),
  smartpopup: isFilterModulePermitted('SMART_POPUP'),
  file: isFilterModulePermitted('DOWNLOAD_TOOL'),
}

export const getFilterLibraryType = {
  bot: isFilterModulePermitted('SMART_BOT'),
  form: isFilterModulePermitted('SMART_FORM'),
  smartblock: isFilterModulePermitted('SMART_BLOCK'),
  smartbar: isFilterModulePermitted('SMART_BAR'),
  smartflow: isFilterModulePermitted('SMART_FLOW'),
  smartpopup: isFilterModulePermitted('SMART_POPUP'),
  file: isFilterModulePermitted('DOWNLOAD_TOOL'),
  segment: isFilterModulePermitted('SEGMENT'),
  campaign: isFilterModulePermitted('CAMPAIGN'),
  trigger: isFilterModulePermitted('TRIGGER'),
  notification: isFilterModulePermitted('NOTIFICATION'),
}

export const getTypeFilterForNotification = {
  bot: isFilterModulePermitted('SMART_BOT'),
  form: isFilterModulePermitted('SMART_FORM'),
  smartblock: isFilterModulePermitted('SMART_BLOCK'),
  smartbar: isFilterModulePermitted('SMART_BAR'),
  smartflow: isFilterModulePermitted('SMART_FLOW'),
  smartpopup: isFilterModulePermitted('SMART_POPUP'),
  file: isFilterModulePermitted('DOWNLOAD_TOOL'),
}

export const getTypeForSegment = {
  bot: isFilterModulePermitted('SMART_BOT'),
  form: isFilterModulePermitted('SMART_FORM'),
  smartblock: isFilterModulePermitted('SMART_BLOCK'),
  smartbar: isFilterModulePermitted('SMART_BAR'),
  smartflow: isFilterModulePermitted('SMART_FLOW'),
  smartpopup: isFilterModulePermitted('SMART_POPUP'),
  file: isFilterModulePermitted('DOWNLOAD_TOOL'),
  list: isFilterModulePermitted('LIST')
}

export const filterType = (data) =>
  Object.keys(data)
    .filter((key) => data[key])
    .toString()

export const getWorkspaceStatus = {
  active: true,
  terminated: true,
  expired: true,
  frozen: true,
  notInstalled: true
}


export const filterStatus = (data) =>
  Object.keys(data)
    .filter((key) => data[key])

export const getAppTypesOption = t => {
  const typeList = []
  if(isFilterModulePermitted('SMART_BOT')) typeList.push({value: 'bot', label: t('SMART_BOT', {count: 0})})
  if(isFilterModulePermitted('SMART_FORM')) typeList.push({value: 'form', label: t('SMART_FORM', {count: 0})})
  if(isFilterModulePermitted('SMART_BLOCK')) typeList.push({value: 'smartblock', label: t('SMART_BLOCK', {count: 0})})
  if(isFilterModulePermitted('SMART_BAR')) typeList.push({value: 'smartbar', label: t('SMART_BAR', {count: 0})})
  if(isFilterModulePermitted('SMART_FLOW')) typeList.push({value: 'smartflow', label: t('SMART_FLOW', {count: 0})})
  if(isFilterModulePermitted('SMART_POPUP')) typeList.push({value: 'smartpopup', label: t('SMART_POPUP', {count: 0})})
  if(isFilterModulePermitted('DOWNLOAD_TOOL')) typeList.push({value: 'file', label: t('DOWNLOAD_TOOL', {count: 0})})
  return typeList
}

export const getSmartBlockFields = (code) => {
  const fields = []
  const recursion = (value) => {
    if(Array.isArray(value) && value)
    {
        value.forEach((val, key) => {
            value[key] = recursion(val)
        })
    }else if(typeof value === 'object' && value){
        Object.keys(value).forEach((key) => {
            if(key === 'type' && value[key] === 'form') {
              fields.push(...value?.values?.fields)
            }
            value[key] = recursion(value[key])
        })
    }
    return value
  }
  recursion(code)
  return fields
}

export const isCustomToolField = (code) => {
  let isCustomTool = false;
  const recursion = (value) => {
    if(Array.isArray(value) && value)
    {
        value.forEach((val, key) => {
            value[key] = recursion(val)
        })
    }else if(typeof value === 'object' && value){
        Object.keys(value).forEach((key) => {
            if(key === 'type' && value[key] === 'custom') {
              isCustomTool = true;
            }
            value[key] = recursion(value[key])
        })
    }
    return value
  }
  recursion(code)
  return isCustomTool
}

export const getCountByDateWise = (dataList, startDate, endDate) => {
  const response = [];
  for (const m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
    const date = m.format('YYYY-MM-DD');
    const findObj = dataList?.find((obj) => new Date(obj.date).getTime() === new Date(date).getTime());
    response.push({
      date,
      count: findObj ? findObj.count : 0,
    });
  }
  return response;
};

export const getAppTitle = (type, t) => {
  let appTitle = '';
  if (type === 'bot') appTitle = t('SMART_BOT', {count: 0});
  if (type === 'form') appTitle = t('SMART_FORM', {count: 0});
  if (type === 'smartblock') appTitle = t('SMART_BLOCK', {count: 0});
  if (type === 'smartpopup') appTitle = t('SMART_POPUP', {count: 0});
  if (type === 'smartflow') appTitle = t('SMART_FLOW', {count: 0});
  if (type === 'smartbar') appTitle = t('SMART_BAR', {count: 0});
  if (type === 'file') appTitle = t('DOWNLOAD_TOOL', {count: 0});
  return appTitle;
}


export const getPopupList = (t, from) => {
  const appLoadType = [
    {value: 'click', text: t('ON_CLICK')},
    {value: 'scroll', text: t('ON_SCROLL')},
    {value: 'load', text: t('ON_LOAD')},
  ]
  if (from === 'bot') return [{value: 'load', text: t('ON_LOAD')}]
  if (from === 'smartpopup') appLoadType.push({value: 'exitIntent', text: t('EXIT_INTENT')})
  return appLoadType
}

export const getShowingList = (t) => {
  return [
    {value: 'onceAnHour', text: t('ONCE_AN_HOUR')},
    {value: 'onceAday', text: t('ONCE_A_DAY')},
  ]
}

