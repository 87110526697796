/* eslint-disable jsx-a11y/iframe-has-title */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'
import SlideBuilder from 'groweo-slide-builder'
import shadow from "react-shadow";
import ChatBot from 'react-simple-chatbot'
import SmartBar from 'groweo-smartbar'
import CustomizeForm from '../CustomizeForm'
import SessionList from './SessionList'
import {
  DEFAULT_THEME,
  renderModuleBot,
  renderModuleForm,
  setModuleForm,
} from '../../../../../utils/module.utils'
import { FallbackView } from '../../../../../_metronic/partials';
// import SessionSearch from '../../common/shared/sessions/SessionSearch'

type ListProps = {
  sessionList: any
}

const List: FC<ListProps> = ({sessionList = []}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [currentSession, setCurrentSession]: any = useState({})
  const [sessions, setSessions]: any = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filteredList, setFilteredList]: any = useState([])
  const [nodes, setNodes] = useState([])
  // const [value, setValue] = useState('')

  useEffect(() => {
    setSessions([])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let list = sessionList
    if (sessionList.length) {
      list = sessionList.map((item: any) => {
        return {
          ...item,
          label: item.data?.email?.trim() || 'Visitor',
          relativeTime: moment(item.created).fromNow(),
        }
      })
    }
    setSessions(list)
    setFilteredList(list)
    // eslint-disable-next-line
  }, [sessionList])

  useEffect(() => {
    if (sessions.length) setCurrentSession(sessions[0])
  }, [sessions])

  useEffect(() => {
    if (!currentSession) return
    if (currentSession.type === 'bot') loadBot()
    else if (['smartblock', 'smartpopup'].includes(currentSession.type)) loadSmartBlock()
    else if (['form', 'file', 'smartbar'].includes(currentSession.type)) loadForm()
    if (['smartflow'].includes(currentSession.type)) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 0)
    }
    // eslint-disable-next-line
  }, [currentSession])

  const loadForm = () => {
    if (!currentSession?.data || !currentSession.moduleId?.content) return
    renderModuleForm(currentSession.moduleId?.content)
    setModuleForm(currentSession.data)
  }

  const loadSmartBlock = () => {
    if (!currentSession?.data || !currentSession.moduleId?.content) return
    // const templateHTML = new DOMParser().parseFromString(JSON.parse(currentSession.moduleId?.content), 'text/html')
    const iframeEl: any = document.getElementById('iframeEmailTemplate')
    
    iframeEl.srcdoc = JSON.parse(currentSession.moduleId?.content)
    iframeEl.addEventListener('load', () => {
      const iDocument = iframeEl.contentWindow.document;
      if (currentSession.type === 'smartpopup') {
        const closeBtn  = iDocument.getElementsByClassName('u-close-button');
        if(closeBtn.length) closeBtn[0].style.pointerEvents = "none";
      }
      setModuleForm(currentSession.data, iDocument)
    })
  }

  const loadBot = () => {
    const rSteps = renderModuleBot(currentSession.data)
    setNodes([])
    setTimeout(() => {
      setNodes(rSteps)
    }, 10)
  }

  // const onSearch = (e: any) => {
  //   setValue(e.target.value)
  //   const value = e.target.value?.toLowerCase()
  //   const list = sessions.filter((item: any) => {
  //     return (
  //       item.label.toLowerCase().match(value) ||
  //       item.relativeTime.toLowerCase().match(value) ||
  //       item.moduleId.name.toLowerCase().match(value)
  //     )
  //   })
  //   setFilteredList(list)
  // }

  const getData = () => {
    let theme = currentSession?.moduleId?.appearence?.theme
    theme = !theme ? DEFAULT_THEME : JSON.parse(theme)
    return {...theme}
  }

  const renderSession = () => {
    if (['form', 'file'].includes(currentSession.type)) {
      return ( <div className='card' id='kt_chat_messenger'>
      {currentSession?.moduleId?.appearence && <CustomizeForm data={getData()} />}
    </div>)
    } else if (currentSession && nodes?.length && currentSession.type === 'bot') {
      return (<ChatBot height='540px' width='100%' botDelay={0} steps={nodes} isPreview={true} isSession={true}></ChatBot>)
    } else if(['smartblock', 'smartpopup'].includes(currentSession.type)) {
      return ( <iframe id="iframeEmailTemplate" height="100%" width="100%" frameBorder="0" scrolling="auto"></iframe>)
    } else if (['smartbar'].includes(currentSession.type) && currentSession?.moduleId?.content) {
      return (<SmartBar
        {...JSON.parse(currentSession.moduleId.content)}
        isPreview={true}
        isSession={true}
        formData={currentSession.data}
      />)
    } else if (['smartflow'].includes(currentSession.type) && currentSession?.moduleId?.content) {
      const content = JSON.parse(currentSession?.moduleId?.content)
      return (
        <shadow.div className='shadow-div'>
          <SlideBuilder
            steps={currentSession?.data?._renderedSteps}
            appearance={content?.appearance}
            isPreview={true}
            isEditor={false}
            isSession={true}
            showPopup={false} 
            setShowPopup={false}
            inPage={true}
            details={currentSession?.data}
          />
        </shadow.div>
      )
    } else return '';
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
          <div className='card card-flush'>
            <div className='card-body pt-5' id='kt_chat_contacts_body'>
              <div
                className='scroll-y pe-5 h-lg-auto'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                data-kt-scroll-offset='0px'
              >
                <SessionList
                  list={filteredList}
                  session={currentSession}
                  setCurrentSession={setCurrentSession}
                  setFilteredList={setFilteredList}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
          <div className='card h-100'>
            {!sessionList.length ? (
              <div className='fw-bolder fs-5 d-flex h-100 align-items-center justify-content-center'>
                {t('SESSION_EMPTY_TEXT')}
              </div>
            ) : (
              <>
                <div className='card-header pt-7' id='kt_chat_contacts_header'>
                  <div className='card-title'>
                    <div className='d-flex justify-content-center flex-column me-3'>
                      <span className='fw-bolder fs-4 mb-2 text-dark'>{currentSession?.label}</span>
                      <span className='text-muted fw-bold fs-6'>
                        {currentSession?.moduleId?.name}
                      </span>
                    </div>
                  </div>

                  <div className='card-toolbar m-0 text-muted'>
                    <div className='d-flex align-items-center flex-column'>
                      <span className='mb-2 text-muted'>{currentSession?.created ? moment(currentSession.created).format('DD.MM.YYYY') : ''}</span>
                      <span className='mb-2 text-muted'>{currentSession?.created ? moment(currentSession.created).format('HH:mm:ss') : ''}</span>
                    </div>  
                  
                  </div>
                </div>
                <div className='card-body session-body p-5' id='kt_chat_messenger_body'>
                 {isLoading ? <FallbackView/> :renderSession()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default List
