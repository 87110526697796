import axios from 'axios'
import { handleError, qs} from '../utils/utils'
import { getCampaignStats } from './campaign.service' 

export const createTrigger = async (data: any) => {
  try {
    const response = await axios.post(`/api/trigger`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const updateTrigger = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/trigger/${id}`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const deleteTrigger = async (id: string) => {
  try {
    const response = await axios.delete(`/api/trigger/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getTrigger = async (params: any) => {
  try {
    const response = await axios.get(`/api/trigger?${qs(params)}`)
    const triggers = response?.data?.data?.records || []
    const campaignStats = await getCampaignStats()
    triggers.map( (trigger: any) => {
      if(campaignStats.hasOwnProperty(trigger._id)) {
        trigger.stats = campaignStats[trigger._id]
      }
    })
    return triggers
  } catch ({response}) {
    handleError(response)
  }
}

export const getTriggerById = async (id: string) => {
  try {
    const response = await axios.get(`/api/trigger/${id}`)
    const trigger = response?.data?.data
    const campaignStats = await getCampaignStats()
    if(campaignStats.hasOwnProperty(trigger._id)) {
      trigger.stats = campaignStats[trigger._id]
    } 
    return trigger
  } catch ({response}) {
    handleError(response)
  }
}
