import {FC} from 'react'
import {useIntl} from 'react-intl'
import {isModulePermitted} from '../../../utils/utils'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'

const AdministrationBreadCrumb: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const AdministrationBreadCrumbs: Array<PageLink> = [
    {
      title: t('CUSTOMER', {count: 1}),
      path: '/workspaces',
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('CUSTOMER'),
    },
    {
      title: t('SUBSCRIPTION', {count: 1}),
      path: '/subscriptions',
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('SUBSCRIPTION'),
    },
    {
      title: t('PERFORMANCE_REPORT', {count: 0}),
      path: '/performance-reports',
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('PERFORMANCE_REPORT'),
    },
  ]
  const filteredData = AdministrationBreadCrumbs.filter((b) => b.isShow)

  return <PageTitle breadcrumbs={filteredData}>{`${t('ADMINISTRATION')}`}</PageTitle>
}

export default AdministrationBreadCrumb
