/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {KTSVG} from '../../../../_metronic/helpers'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { getFormattedTime } from '../../../../utils/utils'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title: string
  subTitle: string
  type?: string
  averageCount: number
  records: {date: string; count: number}[]
}

const ActivityChart: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  title,
  subTitle,
  averageCount,
  records,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [average, setAverage] = useState<number | string>(averageCount)
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setAverage(averageCount)
  }, [averageCount])

  useEffect(() => {
    if (!chartRef.current) return

    const tooltip = {
      style: {
        fontSize: '12px',
      },
      x: {
        format: 'dd.M.yyyy'
      }
    }
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, chartHeight, records, title,tooltip)
    )
    if (chart) chart.render()

    return () => {
      if (chart) chart.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, records, title])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5 align-items-center'>
        <h3 className='card-title align-items-start flex-column justify-content-between'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-bold fs-7'>{subTitle}</span>
        </h3>
        <span className='fw-bolder text-gray-800 me-2 lh-1'  style={{ fontSize:'1.8rem' }} >{title?average:''}</span>
      </div>
      <div className='card-body d-flex flex-column p-0'>
        {title?
        <div ref={chartRef} className='mixed-widget-7-chart card-rounded-bottom'></div>
        :
        <div style={{height:"200px"}}></div>
        }
      </div>
    </div>
  )
}

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  records: {date: string; count: number}[],
  title: string,
  tooltip: any
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  return {
    series: [
      {
        name: title,
        data: records.map((record:any) =>{return {x:record.date,y:record.count}})
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom:{
        enabled:false
      }
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.2,
        stops: [15, 120, 100],
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      type:"datetime",
      categories: records.map((record:any) => record.date),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount:6,
      labels:{
          format:'dd.M.'
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: tooltip,
    colors: [baseColor],
    grid: {
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export default ActivityChart
