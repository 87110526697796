/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import { getIcon } from '../../../../utils/utils'
import { getAppTitle } from '../../../../utils/module.utils'
type Props = {
  records: any
  title: string
  subTitle: string
  metric: string
}

const AppTable: React.FC<Props> = ({records, title, subTitle,metric}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  return (
    <div className='card card-flush h-xl-100'>
      <div className='card-header pt-7'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-gray-800'>{title}</span>
          <span className='text-muted fw-bold fs-7'> {subTitle}</span>
        </h3>
      </div>

      <div className='card-body pt-4'>
        <div className='tab-content'>
          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center me-5'>
              <div className='me-5'>
                <a href='#' className='text-muted fw-bolder text-hover-primary fs-6'>
                  {t('APP_NAME', {count: 0})}
                </a>
                <span className='text-gray-400 fw-bold fs-7 d-block text-start ps-0'></span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <span className='text-muted  text-hover-primary fw-bolder fs-4 me-3'></span>
              <div className='text-muted  text-hover-primary fw-bolder fs-7 m-0'>
                {t(metric, {count: 0})}
              </div>
            </div>
          </div>
          <div className=' my-4'></div>

          <div
            className='tab-pane fade show active'
            id='kt_list_widget_18_tab_content_2'
            style={{height: '370px', overflow: 'scroll'}}
          >
            {records.map((data: any, i: number) => (
                <>
                  <div className='d-flex flex-stack' key={`${title}_${i}`}>
                    <div className='d-flex align-items-center me-5'>
                      <div className='symbol symbol-50px me-8'>
                        <span className='symbol-label'>
                          <img
                            src={
                              `/media/icons/custom/${getIcon(data.moduleType)}`
                            }
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                      <div className='me-5'>
                        <a className='text-gray-800 fw-bolder text-hover-primary fs-6'>
                          {data.moduleName}
                        </a>

                        <span className='text-gray-400 fw-bold fs-7 d-block text-start ps-0'>
                          {data.description ? data.description : getAppTitle(data.moduleType, t)}
                        </span>
                      </div>
                    </div>

                    <div className='d-flex align-items-center'>
                      <span className='text-primary fw-bolder fs-4 me-3'>{data.count}</span>
                    </div>
                  </div>
                  <div className='separator separator-dashed my-4'></div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppTable
