import React, { useRef } from 'react'
import {Handle} from 'react-flow-renderer'
import { useIntl } from 'react-intl'
import {Option} from './Option'
import {HandleCss} from '../jscss/Css'
import '../../../../../../_metronic/assets/sass/style.scss'
import '../../../../../../_metronic/assets/sass/style.react.scss'
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js'
import {TYPE_NAMES} from '../../../../../../utils/module.utils'
import botStore from '../store/botStore'
import { KTSVG } from '../../../../../../_metronic/helpers'
import './node.css'
import { isWorkspaceReadonly } from '../../../../../../utils/utils'

const style = {
  backgroundColor: 'aliceblue',
  color: 'black',
  padding: 0,
  borderRadius: '5px',
  borderWidth: '1px',
  borderStyle: 'solid',
}

const headerStyle = {
  padding: '5px',
  color: 'white',
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'space-between',
}

const textStyle = {
  margin: '5px',
  width: '150px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

export const TextNode = ({type, id, data}) => {
  const [el, setEl] = React.useState(null)
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})

  React.useEffect(() => {
    if (el) new Tooltip(el)
  }, [data.text, el])

  const bgType = data.start ? 'start' : type
  const headerText = data.start ? 'Start' : data.name ? data.name : TYPE_NAMES[type]
  const [contextMenu,setContextMenu] = React.useState({ show: false, left: '0px', top: '0px' })
  const ref = useRef()

  const onContextMenu = (ev) => {
    // const componentPosition = ref.current.getBoundingClientRect();
    // setContextMenu({ show: true, left: (ev.pageX-componentPosition.x)+'px', top: (ev.pageY-componentPosition.y)+'px'})
    setTimeout(() => {
      botStore.setCopyMenu({ open: false })
    }, 5000)
    // ev.stopPropagation()
    if (!data.start) {
      botStore.setCopyMenu({
        open: true,
        element: id,
        position: {
          left: ev.pageX,
          top: ev.pageY
        }
      })
    }
    ev.preventDefault()
  }

  const onCopyButtonClick = (ev) => {
    const componentPosition = ref.current.getBoundingClientRect();
    document.getElementById("kt_copynode_form").reset();
    botStore.setCopyMessage({
      position: {
        left: ev.pageX-25,
        top: ev.pageY-25
      }
    })
    window.navigator.clipboard.writeText(JSON.stringify({ type, data, id })).then(() => {
      botStore.setCopyMessage({ show: true })
    }).catch((err) => {

    })
  }

  const renderTitle = htmlData => {
    const div = document.createElement('div')
    div.innerHTML = htmlData
    return div.innerText
  }

  return (
    <div style={style} ref={ref} className={isWorkspaceReadonly() ? 'pe-none' : ''}>
      {!data.start && (
        <Handle
          type='target'
          position='left'
          style={{
            borderRadius: 0,
            width: '8px',
            backgroundColor: 'gray',
            height: '100%',
          }}
        />
      )}

      <div style={headerStyle} className={`font-title fs-5 text-center select-node-${bgType}`}>
      <div className='d-flex align-items-center'>
          {headerText}{' '}
        </div>
        {!data.start && !data.nodeCount && !data.percentageCount ? (
          <div className='d-flex align-items-center' data-bs-toggle='modal' data-bs-target='#kt_bot_operation_modal'>
            <div onClick={onCopyButtonClick}>
              <KTSVG
                path='/media/icons/custom/copy_icon.svg'
                className='svg-icon-3 svg-icon-primary clickable'
              ></KTSVG>
            </div>
            <i
              className='bi bi-x text-white fs-2x node-close'
              style={{cursor: 'pointer'}}
            ></i>
          </div>
        ) : id !== "1" ? <span title={`${data.nodeCount} ${t('VISITOR', {count: 1})}`} className="mx-2 fw-bolder">{data.percentageCount}</span> : ""}
      </div>
      <div
        className='node-content'
        ref={(e) => setEl(e)}
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        title={renderTitle(data.text || '<i>Empty</i>')}
        style={textStyle}
        dangerouslySetInnerHTML= {{__html: data.text || '<i>Empty</i>'}}
      >
      </div>

      {data.options.length
        ? data.options.map((option, index) => (
            <Option
              key={`opt-${index}`}
              option={option}
              index={index}
              length={data.options.length}
            />
          ))
        : ''}
      {!data.options.length && type !== 'end' && (
        <Handle type='source' position='right' style={Object.assign({height: '100%'}, HandleCss)} />
      )}
    </div>
  )
}
