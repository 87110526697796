/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {filterType} from '../../../../../utils/module.utils'
import {isFilterModulePermitted} from '../../../../../utils/utils'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'

type LibraryFilterMenuProps = {
  type?: any
  setType?: any
  onReset?: any
  onApply?: any
}

const LibraryFilterMenu: FC<LibraryFilterMenuProps> = ({type, setType, onReset, onApply}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [type])

  const renderInput = (name: any, label: string) => (
    <label className='form-check form-check-sm form-check-custom form-check-solid m-2'>
      <input
        className='form-check-input'
        type='checkbox'
        checked={type[name]}
        onChange={() => setType({...type, [name]: !type[name]})}
      />
      <span className='form-check-label'>{label}</span>
    </label>
  )

  return (
    <div className='me-2'>
      <a
        className='btn btn-sm btn-flex btn-light btn-success fw-bolder text-uppercase'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className='svg-icon-5 svg-icon-gray-500 me-1'
        />
        {t('FILTER')}
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px position-fixed m-0'
        data-kt-menu='true'
        style={{
          zIndex: 105,
          inset: '0px 0px auto auto',
          transform: 'translate3d(-115px, 110px, 0px)',
        }}
        data-popper-placement='bottom-end'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('FILTER_CHOICE')}</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>
              {t('SELECT')} {t('GROWTH_APPS', {count: 1})}:
            </label>
            <div className='d-flex flex-column'>
              {isFilterModulePermitted('SMART_BOT') &&
                renderInput('bot', t('SMART_BOT', {count: 1}))}
              {isFilterModulePermitted('SMART_FORM') &&
                renderInput('form', t('SMART_FORM', {count: 1}))}
              {isFilterModulePermitted('SMART_BLOCK') &&
                renderInput('smartblock', t('SMART_BLOCK', {count: 1}))}
              {isFilterModulePermitted('SMART_POPUP') &&
                renderInput('smartpopup', t('SMART_POPUP', {count: 1}))}
              {isFilterModulePermitted('SMART_BAR') &&
                renderInput('smartbar', t('SMART_BAR', {count: 1}))}
              {isFilterModulePermitted('SMART_FLOW') &&
                renderInput('smartflow', t('SMART_FLOW', {count: 1}))}
              {isFilterModulePermitted('DOWNLOAD_TOOL') &&
                renderInput('file', t('DOWNLOAD_TOOL', {count: 1}))}
              {isFilterModulePermitted('SEGMENT') &&
                renderInput('segment', t('SEGMENT', {count: 1}))}
              {isFilterModulePermitted('CAMPAIGN') &&
                renderInput('campaign', t('CAMPAIGN', {count: 1}))}
              {isFilterModulePermitted('TRIGGER') &&
                renderInput('trigger', t('TRIGGER', {count: 1}))}
              {isFilterModulePermitted('NOTIFICATION') &&
                renderInput('notification', t('NOTIFICATION', {count: 1}))}
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={onReset}
            >
              {t('RESET')}
            </button>
            <button
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={onApply}
              disabled={!filterType(type)}
            >
              {t('APPLY')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LibraryFilterMenu
