/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useStore } from '../../../../store'
import { observer } from 'mobx-react-lite'
import TextInputFeild from '../../../shared/TextInputFeild'
import {useIntl} from 'react-intl'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const Login = () => {
  const [loading, setLoading] = useState(false)
  const {
    authStore: { loginUser },
    isLoading,
  } = useStore()
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  useEffect(() => {
    setLoading(isLoading)
    if (loading) formik.setSubmitting(false)
    // eslint-disable-next-line
  }, [isLoading])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => loginUser(values),
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='mb-10'>
        <h1 className='text-light mb-3'>{t('SIGN_IN')}</h1>
      </div>
    
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-light font-title'>{t('EMAIL', {count: 0})}</label>
        <TextInputFeild formik={formik} placeholder={t('EMAIL', {count: 0})} name='email' type='email' />
      </div>
    
      <div className='fv-row mb-10'>
         <div className="d-flex flex-stack mb-2">
            <label className='form-label fw-bolder text-light fs-6 mb-0'>{t('PASSWORD')}</label>
            <a href='/auth/forgetpassword' className=" fs-8 fw-bolder" style={{ color: '#A10338' }}>{t('FORGOT_PASSWORD')} ?</a>
        </div>
        <TextInputFeild formik={formik} placeholder={t('PASSWORD')} name='password' type='password' />

      </div>
      <div className="text-center ">
                <button
                  type="submit"
                  id='kt_sign_in_submit'
                  className="c-button c-button--type-solid w-100 mb-5"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <span className="indicator-label">{t('LOGIN_BTN')}<span className="svg-icon svg-icon-3 ms-2 me-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                      <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                      <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path></svg></span></span>
                </button>
       </div>

{/*       <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div> */}
    </form>
  )
}

export default observer(Login)
