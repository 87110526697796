/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
type Props = {
  className: string
  datas: number[]
  chartDate: string[]
  title: string
  subTitle: string
  setSessionType: any
  tooltip: any
}

const TotalSessionChart: React.FC<Props> = ({
  className,
  datas,
  chartDate,
  title,
  subTitle,
  setSessionType,
  tooltip,
}) => {
  const [data, setData] = useState(datas)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const chartHandler = (name: string) => setSessionType(name)

  useEffect(() => {
    setData(datas)
  }, [datas])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, chartDate, tooltip))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data, chartDate])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-bold fs-7'>{subTitle}</span>
        </h3>
      </div>
      <ul className='nav nav-pills nav-pills-custom mb-3' style={{margin: '20px'}}>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('bot')}>
          <a
            className='nav-link btn  btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_1'
            href='#kt_charts_widget_12_tab_content_1'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/smart_chat_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>

        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('form')}>
          <a
            className='nav-link btn  btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_2'
            href='#kt_charts_widget_12_tab_content_2'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/smart_form_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>

            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('smartblock')}>
          <a
            className='nav-link btn btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_3'
            href='#kt_charts_widget_12_tab_content_3'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/smart_block_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('smartpopup')}>
          <a
            className='nav-link btn btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_3'
            href='#kt_charts_widget_12_tab_content_3'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/smart_popup_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('smartflow')}>
          <a
            className='nav-link btn btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_3'
            href='#kt_charts_widget_12_tab_content_3'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/smart_flow_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('smartbar')}>
          <a
            className='nav-link btn btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_3'
            href='#kt_charts_widget_12_tab_content_3'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/smart_bar_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('file')}>
          <a
            className='nav-link btn btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_3'
            href='#kt_charts_widget_12_tab_content_3'
          >
            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light-info'>
                <KTSVG
                  path='/media/icons/custom/download_tools_menu_icon.svg'
                  className='svg-icon-2x svg-icon-info'
                />
              </span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
        <li className='nav-item mb-3 me-3 me-lg-3' onClick={() => chartHandler('')}>
          <a
            className='nav-link btn btn-flex btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active'
            data-bs-toggle='pill'
            id='kt_charts_widget_12_tab_3'
            href='#kt_charts_widget_12_tab_content_3'
          >
            <div className='symbol symbol-50px '>
              <span className='symbol-label bg-light-info text-uppercase'>{t('ALL')}</span>
            </div>
            <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
          </a>
        </li>
      </ul>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '300px'}}></div>
      </div>
    </div>
  )
}

function getChartOptions(height: number, data: number[], date: string[], tooltip: string): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: tooltip,
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type:"datetime",
      categories: date,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        format:"dd.M."
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      x: {
        format: 'dd.MM.yyyy'
      }
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export default TotalSessionChart
