import toastr from 'toastr'
import moment from 'moment'
import '../../node_modules/toastr/build/toastr.min.css'
import { toAbsoluteUrl } from '../_metronic/helpers'

toastr.options = {
  hideDuration: 300,
  timeOut: 1000,
}

export function setGlobalItem(name, value) {
  window.sessionStorage.setItem(name, JSON.stringify(value))
}

export function getGlobalItem(name) {
  try {
    const result = JSON.parse(window.sessionStorage.getItem(name))
    return result
  } catch (e) {
    console.log(e)
    return null
  }
}

export function clear() {
  window.sessionStorage.clear()
}

export function qs(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')
}

export function handleError(error) {
  const statusCode = error?.data?.statusCode
  if (statusCode === 401) {
    clear()
    window.location.href = '/'
  }
}

export function errorMessage(message) {
  toastr.error(message)
}

export function successMessage(message) {
  // toastr.success(message)
}

export function toastMessage(key, type) {
  const message = {
    successMessage: `${type} successfully`,
    createMessage: `${type} created successfully`,
    updateMessage: `${type} updated successfully`,
    deleteMessage: `${type} deleted successfully`,
    error400: 'Invalid request',
    error401: 'Invalid credentials',
    error403: 'Resouce is restricted',
    error404: 'Not found',
    error406: 'Record already exists',
    error422: 'Unprocessable entity',
    error423: 'Access to this resource is denied',
  }
  return message[key]
}

export function tableDisplayDate(parameter) {
  return !parameter ? '-' : moment(parameter).local().format('DD MMM YYYY hh:mm a')
}

export function getDecimal(number) {
  // return number === Math.floor(number) ? number : number.toFixed(2)
  return Number(number) ? number.toFixed(2) : 0;
}

export function displayDate(parameter) {
  return !parameter ? '-' : moment(parameter).local().format('DD MMM YYYY')
}

export function getFileData(files) {
  const formData = new FormData()
  formData.append('file', files[0])
  formData.append('filename', files[0].name)
  return formData
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const LANGUAGES = [
  { value: 'en', text: 'English', flag: toAbsoluteUrl('/media/flags/united-states.svg'), },
  { value: 'fi', text: 'Suomi', flag: toAbsoluteUrl('/media/flags/finland.svg') },
]

export function getLimitConfig(key, count) {
  const user = getGlobalItem('user')
  const newLimit = user?.workspace?.subscriptionPlan?.accessConfig?.limits?.[key]
  const oldLimit = user?.workspace?.subscriptionPlan?.accessConfig?.[`${key}_count`]
  const configCount = newLimit || oldLimit

  return count >= configCount
}

export function isModulePermitted(key) {
  const user = getGlobalItem('user')
  const modulesPermission = user?.workspace?.subscriptionPlan?.accessConfig?.modulesPermission || []

  if (user?.role === 'workspace-readonly') {
    const readonlyModule = ["DASHBOARD", "SESSION", "GROWTH_APPS", "SMART_BOT", "SMART_FORM", "SMART_BLOCK", "SMART_POPUP", "DOWNLOAD_TOOL", "PREVIEW", "CONTACT", "SEGMENT"];
    return modulesPermission.length ? modulesPermission.includes(key) && readonlyModule.includes(key) : readonlyModule.includes(key)
  }
  else if (user?.role === 'groweo-admin') {
    const readonlyModule = ['CUSTOMER', 'SUBSCRIPTION', 'PERFORMANCE_REPORT']
    return readonlyModule.includes(key);
  }
  else if (['partner-admin'].includes(user?.role)) {
    const readonlyModule = ['CUSTOMER']
    return readonlyModule.includes(key);
  }
  else if (['groweo-subadmin'].includes(user?.role)) {
    const readonlyModule = ['CUSTOMER', 'PERFORMANCE_REPORT']
    return readonlyModule.includes(key);
  }
  if (user?.role === 'workspace-preview') {
    const previewModule = ["PREVIEW"];
    return modulesPermission.length ? modulesPermission.includes(key) && previewModule.includes(key) : previewModule.includes(key)
  }

  return !modulesPermission.length ? true : modulesPermission.includes(key)
}

export function isFilterModulePermitted(key) {
  const user = getGlobalItem('user')
  const modulesPermission = user?.workspace?.subscriptionPlan?.accessConfig?.modulesPermission || []
  return !modulesPermission.length ? true : modulesPermission.includes(key)
}

export function isWorkspacePreview() {
  const user = getGlobalItem('user')
  return user?.role === 'workspace-preview'
}

export function isWorkspaceReadonly() {
  const user = getGlobalItem('user')
  return user?.role === 'workspace-readonly'
}
export function isWorkspaceGroweoAdmin() {
  const user = getGlobalItem('user')
  return user?.role === 'workspace-groweo-admin'
}

export function isGroweoPartner() {
  const user = getGlobalItem('user')
  return user?.role === 'partner-admin'
}

export function isGroweoSubadmin() {
  const user = getGlobalItem('user')
  return user?.role === 'groweo-subadmin'
}

export function isGroweoContentadmin() {
  const user = getGlobalItem('user')
  return user?.contentAdminRole === 'groweo-subadmin'
}

export const getRoles = t => {
  return [
    { value: 'workspace-admin', text: t('W_ADMIN') },
    { value: 'workspace-user', text: t('W_USER') },
    { value: 'workspace-preview', text: t('W_PREVIEW') },
    { value: 'workspace-readonly', text: t('W_READONLY') },
  ]
}

export const getDateOptions = t => {
  return [
    { value: 0, text: t('TODAY') },
    { value: 1, text: t('YESTERDAY') },
    { value: 7, text: t('7_DAYS') },
    { value: 30, text: t('30_DAYS') },
    { value: 90, text: t('90_DAYS') },
    { value: 180, text: t('180_DAYS') },
    { value: 'all', text: t('ALL_HISTORY') },
  ]
}

export const getReportOptions = t => {
  return [
    { value: 'customer', text: t('CUSTOMER', {count:0}) },
    { value: 'appType', text: t('APP_TYPE', {count:0}) },
    { value: 'app', text: t('APP', {count:0}) },
  ]
}

export const getMonthOptions = t => {
  return [
    { value: 0, text: `${t('JANUARY')} 2023` },
    { value: 1, text: `${t('DECEMBER')} 2022` },
    { value: 7, text: `${t('NOVEMBER')} 2022` },
  ]
}
export const groweoRoles = t => {
  return [
    { value: 'groweo-subadmin', text: t('G_SUBADMIN') },
  ]
}

export const rolesText = t => {
  return {
    'workspace-admin': t('W_ADMIN'),
    'workspace-user': t('W_USER'),
    'workspace-preview': t('W_PREVIEW'),
    'workspace-readonly': t('W_READONLY'),
    'groweo-subadmin': t('G_SUBADMIN'),
    'groweo-admin': t('G_ADMIN')
  }
}

export const getWorkspaceStatus = t => {
  return [
    { value: 'notInstalled', text: t('W_INACTIVE', { count: 0 }) },
    { value: 'active', text: t('W_ACTIVE', { count: 0 }) },
    { value: 'terminated', text: t('W_TERMINATED', { count: 0 }) },
    { value: 'expired', text: t('W_EXPIRED', { count: 0 }) },
    { value: 'frozen', text: t('W_FROZEN', { count: 0 }) },
  ]
}

export const getSubscriptionStatus = t => {
  return [
    { value: 'active', text: t('ACTIVE', { count: 0 }) },
    { value: 'draft', text: t('DRAFT', { count: 0 }) },
    { value: 'inactive', text: t('INACTIVE', { count: 0 }) },
  ]
}

export function downloadFile(url) {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  return;
}

export const modulePermissions = [
  "DASHBOARD",
  "SESSION",
  "GROWTH_APPS",
  "SMART_BOT",
  "SMART_FORM",
  "SMART_BLOCK",
  "SMART_POPUP",
  "SMART_BAR",
  "SMART_FLOW",
  "DOWNLOAD_TOOL",
  "PREVIEW",
  "CONTACT",
  "LIST",
  "SEGMENT",
  "CAMPAIGN",
  "TRIGGER",
  "NOTIFICATION",
  "PERFORMANCE_REPORT",
]

export function getFormattedTime(count) {
  const duration = Number(count);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor(duration % 3600 / 60);
  return `${hours > 0 ? hours + 'h' : ''} ${minutes + 'm'}`
}

export const getIcon = (type) => {
  if (type === 'bot') return 'smart_chat_menu_icon.svg'
  else if (type === 'form') return 'smart_form_menu_icon.svg'
  else if (type === 'smartblock') return 'smart_block_menu_icon.svg'
  else if (type === 'smartpopup') return 'smart_popup_menu_icon.svg'
  else if (type === 'smartbar') return 'smart_bar_menu_icon.svg'
  else if (type === 'smartflow') return 'smart_flow_menu_icon.svg'
  else if (type === 'file') return 'download_tools_menu_icon.svg'
  else if (type === 'segment') return 'segments_icon.svg'
  else if (type === 'campaign') return 'campaigns_icon.svg'
  else if (type === 'trigger') return 'triggers_icon.svg'
  else if (type === 'notification') return 'notifications_menu_icon.svg'
}

export const sortNumber = (records, isAsc, key) => {
  return records.sort((r1, r2) => {
    return isAsc
      ? Number(r1[key]) < Number(r2[key])
        ? -1
        : 1
      : Number(r1[key]) > Number(r2[key])
      ? -1
      : 1
  })
}

export const sortString = (records, isAsc, key) => {
  return records.sort((r1, r2) => {
    return isAsc
      ? r1[key].trim() < r2[key].trim()
        ? -1
        : 1
      : r1[key].trim() > r2[key].trim()
      ? -1
      : 1
  })
}

export const getDayDifference = (date) => {
  const localMoment = moment()
  const today = moment(localMoment.format())
  const endDate = moment(date)
  return today.diff(endDate, 'days')
}

export const isBotAccessible = () => {
  const user = getGlobalItem('user')
  const disableBot =
  !user?.workspace?.config?.smartChatAction && user?.role !== 'workspace-groweo-admin'
  return disableBot
}

export const FONT_FAMILY = [
  {
    text: "Proxima Nova",
    value: '"Proxima Nova","proxima-nova","proxima-nova-webfont",sans-serif',
  },
  {
    text: "Groweo Graphie",
    value: '"Graphie-Regular","sans-serif"',
    url: `${process.env.REACT_APP_FRONTEND_URL}/fonts/graphie.css`
  },
  {
    text: "Andale Mono",
    value: "Andale Mono Regular",
    url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/andale-mono/andale-mono.css`
  },
  {
    text: "Arial",
    value: "arial,helvetica,sans-serif"
  },
  {
    text: "Arial Black",
    value: "arial black,avant garde,arial"
  },
  {
    text: "Book Antiqua",
    value: "book antiqua,palatino"
  },
  {
    text: "Comic Sans MS",
    value: "Comic Sans MS,sans-serif",
    url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/comic-sans-ms/comic-sans-ms.css`
  },
  {
    text: "Courier New",
    value: "courier new,courier"
  },
  {
    text: "Georgia",
    value: "georgia,palatino"
  },
  {
    text: "Helvetica",
    value: "helvetica,sans-serif"
  },
  {
    text: "Impact",
    value: "impact,chicago"
  },
  {
    text: "Symbol",
    value: "symbol",
    url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/symbol/symbol.css`
  },
  {
    text: "Tahoma",
    value: "Tahoma,arial,helvetica,sans-serif",
    url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/tahoma/tahoma.css`
  },
  {
    text: "Terminal",
    value: "terminal,monaco"
  },
  {
    text: "Times New Roman",
    value: "times new roman,times"
  },
  {
    text: "Trebuchet MS",
    value: "trebuchet ms,geneva"
  },
  {
    text: "Verdana",
    value: "verdana,geneva"
  },
  {
    text: "Lobster Two",
    value: "'Lobster Two',cursive",
    url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
  },
  {
    text: "Playfair Display",
    value: "'Playfair Display',serif",
    url: "https://fonts.googleapis.com/css?family=Playfair+Display:400,700"
  },
  {
    text: "Rubik",
    value: "'Rubik',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Rubik:400,700"
  },
  {
    text: "Source Sans Pro",
    value: "'Source Sans Pro',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700"
  },
  {
    text: "Open Sans",
    value: "'Open Sans',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700"
  },
  {
    text: "Crimson Text",
    value: "'Crimson Text',serif",
    url: "https://fonts.googleapis.com/css?family=Crimson+Text:400,700"
  },
  {
    text: "Montserrat",
    value: "'Montserrat',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Montserrat:400,700"
  },
  {
    text: "Old Standard TT",
    value: "'Old Standard TT',serif",
    url: "https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700"
  },
  {
    text: "Lato",
    value: "'Lato',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Lato:400,700"
  },
  {
    text: "Raleway",
    value: "'Raleway',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Raleway:400,700"
  },
  {
    text: "Cabin",
    value: "'Cabin',sans-serif",
    url: "https://fonts.googleapis.com/css?family=Cabin:400,700"
  },
  {
    text: "Pacifico",
    value: "'Pacifico',cursive",
    url: "https://fonts.googleapis.com/css?family=Pacifico"
  }
]

export const FONT_WEIGHT = [
  { text: 'Regular', value: 400 },
  { text: 'Semi-bold', value: 600 },
  { text: 'Bold', value: 800 },
]

export const FONT_STYLE = t => {
  return [
    { text: t('X_LARGE'), value: 'xlarge' },
    { text: t('LARGE'), value: 'large' },
    { text: t('MEDIUM'), value: 'normal' },
    { text: t('SMALL'), value: 'small' },
    { text: t('X_SMALL'), value: 'xysmall' },
  ]
}
