/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {getAllPartner} from '../../../../../service/partner.service'
import {filterType} from '../../../../../utils/module.utils'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import SelectInputFeild from '../../../../shared/SelectInputFeild'

type AppsProps = {
  status?: any
  setStatus?: any
  onReset?: any
  onApply?: any
  partnerId?: any
  setPartnerId?: any
}

const PerformanceReportFilter: FC<AppsProps> = ({
  status,
  setStatus,
  onReset,
  onApply,
  partnerId,
  setPartnerId,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [partnerList, setPartnerList] = useState([])

  useEffect(() => {
    fetchPartnerList()
  }, [])

  const fetchPartnerList = async () => {
    const data = await getAllPartner()
    if (data.length) setPartnerList(data)
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [status])

  const renderInput = (name: any, label: string) => (
    <label className='form-check form-check-sm form-check-custom form-check-solid m-2'>
      <input
        className='form-check-input'
        type='checkbox'
        checked={status[name]}
        onChange={() => setStatus({...status, [name]: !status[name]})}
      />
      <span className='form-check-label'>{label}</span>
    </label>
  )

  return (
    <div>
      <a
        // className='btn btn-sm btn-flex btn-light btn-success fw-bolder text-uppercase'
        className='btn btn-flex btn-light btn-success text-uppercase'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className='svg-icon-5 svg-icon-gray-500 me-1'
        />
        {t('FILTER')}
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px position-fixed m-0'
        data-kt-menu='true'
        style={{
          zIndex: 105,
          inset: '0px 0px auto auto',
          transform: 'translate3d(-115px, 110px, 0px)',
        }}
        data-popper-placement='bottom-end'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('FILTER_CHOICE')}</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>
              {t('SELECT')} {t('STATUS', {count: 1})}:
            </label>
            <div className='d-flex flex-column'>
              {renderInput('notInstalled', t('W_INACTIVE', {count: 0}))}
              {renderInput('active', t('W_ACTIVE', {count: 0}))}
              {renderInput('terminated', t('W_TERMINATED', {count: 0}))}
              {renderInput('expired', t('W_EXPIRED', {count: 0}))}
              {renderInput('frozen', t('W_FROZEN', {count: 0}))}
            </div>
            <label className='form-label fw-bold mt-5'>{t('PARTNER', {count: 0})}:</label>
              <div className='fv-row'>
                <div className='col-12'>
                  <SelectInputFeild
                    name={'partnerId'}
                    value={partnerId}
                    options={partnerList}
                    onChange={(name: string, value: string) => setPartnerId(value)}
                    format={{value: '_id', text: 'name'}}
                    appendOption={{name: 'ALL', value: ''}}
                  />
                </div>
              </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={onReset}
            >
              {t('RESET')}
            </button>
            <button
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={onApply}
              disabled={!filterType(status)}
            >
              {t('APPLY')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PerformanceReportFilter
