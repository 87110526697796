import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Stepper from '../stepper'
import {getModuleById} from '../../../../service/module.service'
import { StepperProvider } from '../stepper/StepperContext'

interface RouteParams {
  moduleId: string
  step: string
}

const SmartBarCreate: FC = () => {
  const {moduleId, step} = useParams<RouteParams>()
  const [smartBar, setSmartBar] = useState({})

  const getTemplateData = async (type: string) => {
    let data
    if (type === 'module') data = await getModuleById(moduleId)
    if (data) setSmartBar(data)
  }

  useEffect(() => {
    getTemplateData('module')
    // eslint-disable-next-line
  }, [moduleId])

  return (
    <>
      <StepperProvider smartBar={smartBar} setSmartBar={setSmartBar}>
        <Stepper
          from={'smartbar'}
          moduleId={moduleId}
          step={step}
        />
      </StepperProvider>
    </>
  )
}

export default SmartBarCreate
