/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {PerformanceExplorerToolbar} from '../../../../../_metronic/layout/components/toolbar/PerformanceExplorerToolbar'
import AppsPerformance from './AppsPerformance'
import PerformanceExplorer from './PerformanceExplorer'
import {getWorkspaceReportById} from '../../../../../service/performanceReport.service'
import {getDayDifference, getDecimal, getFormattedTime} from '../../../../../utils/utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import ReportTable from '../../../widgets/tables/ReportTable'

interface RouteParams {
  workspaceId: string
}

interface PerformanceViewProps {
  isHideBack: boolean;
}

const PerformanceView: FC<PerformanceViewProps> = ({ isHideBack }) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {workspaceId} = useParams<RouteParams>()
  const {state}: any = useLocation()
  const [selected, setSelected]: any = useState(state ? state.dateRange : 30)
  const [report, setReport]: any = useState({})
  const [active, setActive]: any = useState(0)
  const defaultRange = {
    startDate: moment()
      .subtract(Number(state?.dateRange || 30), 'd')
      .format('ll'),
    endDate:
      Number(Number(state?.dateRange)) === 1
        ? moment().format('ll')
        : moment().add(1, 'd').format('ll'),
  }
  const [dateRange, setDateRange]: any = useState(state?.dateRange === 'all' ? {} : defaultRange)

  useEffect(() => {
    const getAppSpeicificReport = async () => {
      let records = await getWorkspaceReportById(workspaceId, dateRange)
      if(!records) return;
      const visitorVSstarted = records['UNIQUE-VISITOR']
        ? getDecimal((records['SESSION'] / records['UNIQUE-VISITOR']) * 100 || 0)
        : 0
      const startedVSsuccessful = records['SESSION']
        ? getDecimal((records['SUCCESSFULL-CONVERSATION'] / records['SESSION']) * 100 || 0)
        : 0
      const startedVScontact = records['SESSION']
        ? getDecimal((records['CONTACT'] / records['SESSION']) * 100 || 0)
        : 0
      const visitorsVScontact = records['UNIQUE-VISITOR']
        ? getDecimal((records['CONTACT'] / records['UNIQUE-VISITOR']) * 100 || 0)
        : 0
      const daysSinceInstalled = records['installationDate']
        ? `${getDayDifference(records['installationDate'])}`
        : 0
      const visitorsVSsuccessful = records['UNIQUE-VISITOR']
        ? getDecimal((records['SUCCESSFULL-CONVERSATION'] / records['UNIQUE-VISITOR']) * 100 || 0)
        : 0

      const appsReport = {
        ...records,
        visitorVSstarted,
        startedVSsuccessful,
        startedVScontact,
        visitorsVScontact,
        visitorsVSsuccessful,
        daysSinceInstalled,
      }
      setReport(appsReport)
    }
    getAppSpeicificReport()
  }, [dateRange])

  // const Card = ({label, value}: any) => (
  //   <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
  //     <div className='d-flex justify-content-center'>
  //       <div className='text-gray-800 text-hover-primary fs-2 fw-bolder text-capitalize'>
  //         {value}
  //       </div>
  //     </div>
  //     <div className='fw-semibold fs-6 text-gray-400 text-center'>{label}</div>
  //   </div>
  // )

  return (
    <>
      <PerformanceExplorerToolbar
        from='performance-report'
        title='PERFORMANCE_EXPLORER'
        setDateRange={setDateRange}
        dateRange={dateRange}
        selected={selected}
        setSelected={setSelected}
        isHideBack={isHideBack}
      />
      <ReportTable
        onSort={()=> {}}
        pageData={[report]}
        filterData={[report]}
        toggleSorting={{
          sortBy: 'workspaceName',
          sortDirection: true,
        }}
        dateRange={selected}
        type={'customer_report'}
      />
      {/* <div className='card mb-xxl-8'>
        <div className='card-body' style={{padding: '1rem'}}> */}
          {/* <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3 text-capitalize'>
                      {report?.workspaceName}
                    </span>
                    <a
                      target='_blank'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      href={`${report?.domain?.startsWith('http') ? '' : 'https://'}${
                        report?.domain
                      }`}
                      rel='noreferrer'
                    >
                      <KTSVG path={`/media/icons/custom/popup_icon.svg`} className='svg-icon-3' />
                    </a>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <Card
                      label={`${t('TOTAL')} ${t('APP', {count: 0})}`}
                      value={report?.['TOTAL-APP']}
                    />
                    <Card label={t('VISITOR_COLUMN')} value={report?.['UNIQUE-VISITOR']} />
                    <Card label={t('STARTED_CONVERSATION')} value={report?.['SESSION']} />
                    <Card label={t('VISITOR_VS_STARTED')} value={report?.visitorVSstarted} />
                    <Card
                      label={t('SUCCESSFULL_CONVERSATION')}
                      value={report?.['SUCCESSFULL-CONVERSATION']}
                    />
                    <Card label={t('STARTED_VS_SUCCESSFUL')} value={report?.startedVSsuccessful} />
                    <Card
                      label={t('VISITORS_VS_SUCCESSFUL')}
                      value={report?.visitorsVSsuccessful}
                    />
                    <Card label={t('VISITORS_VS_CONTACTS')} value={report?.visitorsVScontact} />
                    <Card label={t('STARTED_VS_CONTACT')} value={report?.startedVScontact} />
                    <Card label={t('PERFORMANCE_CONTACT_COLUMN')} value={report?.['CONTACT']} />
                    <Card
                      label={t('DURATION', {count: 0})}
                      value={getFormattedTime(report?.['DURATION'])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
            <li className='nav-item mt-2'>
              <a
                href='#'
                className={`nav-link text-active-primary pb-4 ${active === 0 && `active`}`}
                onClick={() => setActive(0)}
              >
                <KTSVG
                  path='/media/icons/custom/growth_apps_icon.svg'
                  className='svg-icon-3 me-2'
                />
                {t('APP', { count: 1 })}
              </a>
            </li>
            {isExplore === 'true' && (
              <li className='nav-item mt-2'>
                <a
                  href='#'
                  className={`nav-link text-active-primary pb-4 ${active === 1 && `active`}`}
                  onClick={() => setActive(1)}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen002.svg'
                    className='svg-icon-3 me-2'
                  />
                  {t('EXPLORE', { count: 0 })}
                </a>
              </li>
            )}
          </ul> */}
        {/* </div>
      </div> */}

      <div className='row'>
        {active === 0 && <AppsPerformance dateRange={dateRange} />}{' '}
        {active === 1 && <PerformanceExplorer />}
      </div>
    </>
  )
}

export default PerformanceView
