import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
type SettingsModalProps = {
    settingObject:any,
    setSettingObject:any
    settingType:string
    setSettingType:any
    settingColorStatus:boolean
    setSettingColorStatus:any
    performanceSettingHandler:any
}

const SettingsModal: FC<SettingsModalProps> = (
    {
        settingObject,
        settingType,
        setSettingType,
        settingColorStatus,
        setSettingColorStatus,
        performanceSettingHandler
    }) => {
  const intl = useIntl()
  const {state}: any = useLocation()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const formik = useFormik({
    initialValues : settingObject,
    enableReinitialize: true,
    onSubmit: (values) => {
        performanceSettingHandler(values)
        setSettingType("visitorVSstarted")
    },
  })

  const settingHandler = (e:any)=>{
        const {name,value} = e.target;
        formik.setFieldValue(name, Number(value))
  }

  const tabHandler = (type:string, title:string)=>(
          <a onClick={()=>setSettingType(type)} className={`nav-link ${settingType===type?'active':''}`} >
              {t(title)}                    
          </a>
  )

  const inputFieldHandler =(condition:string,color:string,colorKey:string)=>(
                <div className='d-flex align-items-center py-1 mt-7'>
                    <div className="me-xl-15 mb-xl-0">
                            <div className="position-relative">
                                <button className="btn p-7 w-200px" style={{cursor:"default", backgroundColor:color}}/>
                            </div>
                    </div>
                    <div className="me-xl-15 mb-xl-0">
                            <div className="position-relative">
                                <label className="fw-bolder fs-3 fs-lg-base px-3 mt-3" >
                                {condition}                     
                                </label>
                            </div>
                    </div>
                    <div className="flex-column flex-lg-row-auto w-100 mw-lg-100px mw-xxl-300px">
                            <div className="position-relative">
                                <input type="number" name={`${settingType}.${colorKey}`} value={`${formik.values[settingType][colorKey]}`} onChange={settingHandler} className="form-control form-control-lg form-control-solid ps-5" style={{marginLeft:colorKey==="redCount"?"15px":""}}/>
                            </div>
                    </div>
                </div>
  )
  const settingColorHandler = (status:boolean)=>{
    setSettingColorStatus(status)
  }
  return (
    <div className='modal fade' tabIndex={-1} id='gw_performance_settings_modal'>
      <div className='modal-dialog modal-dialog-centered' style={{maxWidth:"1200px"}}>
        <div className='modal-content'>
          <div className='modal-header' style={{padding:"15px"}}>
            <h4 className='modal-title'>
              {t('PERFORMANCE_REPORT_SETTING', { count: 1 })}
            </h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label={t('CLOSE')}
              onClick={()=>settingColorHandler(state?state.settingColorStatus:false)}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body py-lg-10 px-lg-10'>
            <label onClick={()=>settingColorHandler(!settingColorStatus?true:false)} className={`btn btn-outline mw-100px btn-outline-dashed d-flex text-start mb-2 p-2 ${settingColorStatus?'btn-active-light-primary active':''}`}>
                    <span className='d-flex'>
                    <span className="ms-4">
                        <span className="fs-3 fw-bold text-gray-600 d-block">{settingColorStatus?t('ON'):t('OFF')}</span>
                    </span>
                    </span>
            </label>
            <form
             className='form w-100'
             onSubmit={formik.handleSubmit}
             noValidate
            >
                
                    <ul className="nav nav-tabs cursor-pointer nav-line-tabs nav-line-tabs-2x mb-5 fs-6 border-0">
                        <li className="nav-item my-1">
                            {tabHandler("visitorVSstarted","VISITOR_VS_STARTED")}
                        </li>
                        <li className="nav-item my-1">
                            {tabHandler("startedVSsuccessful","STARTED_VS_SUCCESSFUL")}
                        </li>
                        <li className="nav-item my-1">
                            {tabHandler("visitorsVSsuccessful","VISITORS_VS_SUCCESSFUL")}
                        </li>
                        <li className="nav-item my-1">
                            {tabHandler("visitorsVScontact","VISITORS_VS_CONTACTS")}
                        </li>
                    </ul>
                
                {inputFieldHandler("> =","#58D68D","greenCount")}
                {inputFieldHandler("> =","#F5B041","orangeCount")}
                {inputFieldHandler("<","#EC7063","redCount")}
                <div className='text-right m-5'>
                <button
                    type='submit'
                    data-bs-dismiss={`modal`}
                    className='c-button c-button--type-solid'
                  >
                    <span className='indicator-label'>
                      {t('SAVE')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
            </form>
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default SettingsModal