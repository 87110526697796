import {action, makeAutoObservable, observable, runInAction} from 'mobx'
import { all, bot, form, pdf } from '../MockData'

class DashboardStore {
  DashboardCardData = []
  DashboardChartData = {}

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      DashboardCardData: observable,
      DashboardChartData: observable,
      logoutUser: action,
    })
  }

  getDashboardCard = async (type) => {
    //Mock data start
    const dataType = type === 'all' ? all : type === 'bot' ? bot : type === 'pdf' ? pdf : form
    runInAction(() => {
      this.DashboardCardData = dataType.cardData
      this.DashboardChartData = dataType.chartData
    })
   }

}

export default DashboardStore
