/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {
  getSubscriptionPlanById,
  updateSubscriptionPlan,
} from '../../../../../service/subscription.service'
import {SubscriptionDetailsToolbar} from '../../../../../_metronic/layout/components/toolbar/SubscriptionDetailsToolbar'
import {getSubscriptionStatus, modulePermissions} from '../../../../../utils/utils'
import TextModal from '../../../common/modal/TextModal'
import ModuleLists from './ModuleLists'
import WorkspaceLists from './WorkspaceLists'
import SubscriptionModal from '../SubscriptionModal'

interface RouteParams {
  subscriptionId: string
}

interface AccessConfig {
  limits?: {publish_app: number; total_app: number}
  modulesPermission?: string[] | undefined
}

interface Subscription {
  name?: string
  price?: number
  description?: string
  status?: string
  _id?: string
  accessConfig?: AccessConfig
  created?: Date
}

const SubscriptionView: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {subscriptionId} = useParams<RouteParams>()
  const [subscription, setSubscription] = useState<Subscription>({})
  const [active, setActive] = useState(0)
  const [showSwalModal, setShowSwalModal] = useState(false)

  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionPlanById(subscriptionId).then((data) => {
        setSubscription(data)
      })
    }
  }, [subscriptionId])

  const navigateToEdit = (event: any, index: any, moduleName: any) => {
    if (event.target.checked) {
      let modules = subscription.accessConfig
        ? subscription.accessConfig?.modulesPermission?.map((modules) => {
            return modules
          })
        : []
      modules?.splice(index, 0, moduleName)
      setSubscription((prevState) => ({
        ...prevState,
        accessConfig: {
          ...prevState.accessConfig,
          modulesPermission: modules,
        },
      }))
    } else {
      let newArr: any = subscription.accessConfig?.modulesPermission
      const index: any = newArr?.indexOf(moduleName)

      if (index > -1) {
        newArr.splice(index, 1) // 2nd parameter means remove one item only
      }
      setSubscription((prevState) => ({
        ...prevState,
        accessConfig: {
          ...prevState.accessConfig,
          modulesPermission: newArr,
        },
      }))
    }
  }

  const updateSubscription = () => {
    const {accessConfig} = subscription
    updateSubscriptionPlan(subscriptionId, {accessConfig}).then((data) => {
      setSubscription(data)
      if (data) setShowSwalModal(true)
    })
  }

  const renderSubscriptionStatus = (status: any) => {
    const statusList = getSubscriptionStatus(t)
    return statusList.find((item) => item.value === status)?.text
  }
  return (
    <>
      <SubscriptionDetailsToolbar />
      <div className='card card-flush pt-3 mb-5'>
        <div className='card-header'>
          <div className='card-title'>
            <a
              href='#'
              className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3 text-capitalize'
            >
              {subscription?.name}
            </a>
            <span
              className={`badge ${
                subscription?.status === 'active' ? 'badge-light-success' : 'badge-light-danger'
              } me-auto text-capitalize`}
            >
              {renderSubscriptionStatus(subscription?.status)}
            </span>
          </div>
        </div>

        <div className='card-body pt-0'>
          {subscription.price && (
            <div className='text-gray-500 fw-bold'>{t('PRICE', {count: 0})} : {subscription.price}</div>
          )}

          <div className='text-gray-500 fw-bold fs-5 mb-5'>
            {t('DESCRIPTION', {count: 0})} : {subscription.description}
          </div>

          <ul className='nav nav-tabs nav-line-tabs my-5 fs-6'>
            <li className='nav-item' onClick={() => setActive(0)}>
              <a
                className={`nav-link text-active-primary py-5 me-6 ${active === 0 ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#kt_modules'
              >
                {t('MODULE', {count: 1})}
              </a>
            </li>

            <li className='nav-item' onClick={() => setActive(1)}>
              <a
                className={`nav-link text-active-primary py-5 me-6 ${active === 1 ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#kt_customers'
              >
                {t('CUSTOMER', {count: 1})}
              </a>
            </li>
          </ul>
        </div>
      </div>
      {active === 0 ? (
        <ModuleLists
          modulePermissions={modulePermissions}
          updateSubscription={updateSubscription}
          subscription={subscription}
          navigateToEdit={navigateToEdit}
          setSubscription={setSubscription}
        />
      ) : (
        <WorkspaceLists subscriptionId={subscriptionId} />
      )}
      {showSwalModal ? (
        <TextModal
          message={t('COMPLETE_MODAL_TEXT')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <SubscriptionModal subscription={subscription} setSubscription={setSubscription} />
    </>
  )
}

export default SubscriptionView
