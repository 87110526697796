import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import Step1 from './steps/step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import {observer} from 'mobx-react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useStore} from '../../../../store'
import {getModulePath} from '../../../../utils/module.utils'
import {useSmartFlowData} from './StepperContext'
import StepperBreadCrumb from './StepperBreadCrumb'
import {getLimitConfig, isWorkspaceReadonly} from '../../../../utils/utils'
import {getModuleCount, updateModule} from '../../../../service/module.service'
import _ from 'lodash'
import TextModal from '../../common/modal/TextModal'
import {Header} from '../../../../_metronic/layout/components/header/Header'
import ConfirmModal from '../../common/modal/ConfirmModal'
import AppPublishModal from '../../common/modal/AppPublishModal'
import {generateSimpleSlideData} from '../../../../utils/template.utils'

const Stepper: FC<any> = ({from, moduleId, step}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {smartFlow, setSmartFlow} = useSmartFlowData()
  const {authStore} = useStore()
  const {
    setModuleAccessLimit,
    moduleAccessLimit: {publishCount},
  } = authStore
  const history = useHistory()
  // const [botSteps, setBotSteps]: any = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [isClear, setIsClear]: any = useState(false)
  const [type, setType]: any = useState('')
  const [showSuccessModal, setShowSuccessModal]: any = useState(false)
  const [isShowNodeCounts, setIsShowNodeCounts] = useState(false)
  const [isGrid, setIsGrid]: any = useState(true)

  const onSave = async (publish?: string) => {
    // In readonly mode saving not allowed
    if (isWorkspaceReadonly()) return

    // const {appearance, elements} = smartFlow.moduleCode || smartFlow.code || {}
    let appearance = {}
    let elements: Array<[]> = []

    if (smartFlow?.moduleCode?.appearance) {
      //  Lets check for  appearence exist in bot code
      appearance = smartFlow.moduleCode?.appearance
    }
    if (smartFlow?.moduleCode?.elements?.length) {
      //  Lets check for elements exist in bot code
      elements = smartFlow.moduleCode?.elements
    }
    const nodes = generateSimpleSlideData(elements)
    const code = {nodes, elements, appearance, scriptVersion: 1}
    const data: any = {
      name: smartFlow?.name,
      type: from,
      moduleCode: {...code},
      status: 'draft',
    }
    if (smartFlow?.publishEvent?.length) data.publishEvent = smartFlow.publishEvent
    if (publish) {
      setType(publish)
      data.content = publish === 'publish' ? JSON.stringify({...code}) : null
      data.status = publish === 'publish' ? 'active' : 'draft'
    }

    await updateModule(moduleId, data)

    if (publish) setShowPublishModal(true)
    else setShowSuccessModal(true)
  }

  const closeModal = async () => {
    setSmartFlow({})
    history.push(`${getModulePath(from)}`)
  }

  const renderSave = () => (
    <div onClick={() => onSave()} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/icons/custom/save_icon.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('SAVE')}</p>
    </div>
  )

  const renderClear = () => (
    <div onClick={() => setShowConfirmModal(true)} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/stepper/prohibition.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('CLEAR')}</p>
    </div>
  )

  const onConfirm = () => {
    setIsClear(true)
  }

  const onPublish = (publishData: {time: Date; text: string}) => {
    // Do limit validation in separate and add comments
    if (!getLimitConfig('publish_app', publishCount)) {
      // Show error up if reached limit
      const publishEvent = smartFlow?.publishEvent || []
      publishEvent.push(publishData)
      setSmartFlow({...smartFlow, publishEvent})
      onSave('publish')
    } else onSave()
  }

  const onUnpublish = () => onSave('unpublish')

  const renderGrid = () => (
    <div onClick={() => setIsGrid(!isGrid)} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/icons/custom/smart_block_menu_icon.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('GRID')}</p>
    </div>
  )

  return (
    <>
      {showPublishModal ? (
        <TextModal
          message={t(`${type?.toUpperCase()}_COMPLETE_MODAL_TEXT`)}
          close={() => {
            setType('')
            setShowPublishModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      {showSuccessModal ? (
        <TextModal
          message={t('COMPLETE_MODAL_TEXT')}
          close={() => {
            setShowSuccessModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <StepperBreadCrumb data={{name: smartFlow?.name, from, moduleId}} />
      <div className='modal d-block' tabIndex={-1} id='kt_modal_22'>
        <div className='modal-dialog modal-fullscreen'>
          <div className='modal-content shadow-none'>
            <div
              id='kt_create_account_stepper'
              className='stepper stepper-links d-flex flex-column h-100'
            >
              <div className='d-flex align-items-center justify-content-between px-4'>
                <Header editable setName={(name: string) => setSmartFlow({...smartFlow, name})} />
                <div className='d-flex'>
                  {!isWorkspaceReadonly() && (
                    <div className='d-flex align-items-center pe-8'>
                      {/* {renderGrid()} */}
                      {step === 'design' && <label className='form-check form-check-custom form-check-solid form-switch m-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={isShowNodeCounts}
                          onChange={() => { 
                            setIsShowNodeCounts(!isShowNodeCounts) 
                          }
                          }
                        />
                        <span className='form-check-label text-muted mx-3'>{t('VISITOR', {count: 1})}</span>
                      </label>}
                      {renderSave()}
                      <div
                        onClick={async() => {
                          const moduleCount: any = await getModuleCount();
                          setModuleAccessLimit(moduleCount?.totalCount, moduleCount?.publishCount) 
                        }}
                        className={`cursor-pointer m-3 text-center ${
                          getLimitConfig('publish_app', publishCount) ? 'disabled' : ''
                        }`}
                        data-bs-toggle='modal'
                        data-bs-target='#kt_publish_event_modal'
                        title={`${
                          getLimitConfig('publish_app', publishCount) ? t('PUBLISH_TITLE') : ''
                        }`}
                      >
                        <KTSVG path='/media/stepper/cloud-upload.svg' className='svg-icon-2x' />
                        <p className='text-muted m-0 fw-bold fs-7'>{t('PUBLISH')}</p>
                      </div>
                      <div onClick={onUnpublish} className='cursor-pointer m-3 text-center'>
                        <KTSVG
                          path='/media/icons/custom/cloud_upload_icon.svg'
                          className='svg-icon-2x'
                        />
                        <p className='text-muted m-0 fw-bold fs-7'>{t('UNPUBLISH')}</p>
                      </div>
                    </div>
                  )}
                  <div className='d-flex align-items-center'>
                    {!isWorkspaceReadonly() && renderClear()}
                    <div onClick={closeModal} className='cursor-pointer m-3 text-center'>
                      <KTSVG className='svg-icon-2x' path='/media/icons/custom/close_icon.svg' />
                      <p className='text-muted m-0 fw-bold fs-7'>{t('CLOSE')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal-body ${step !== 'code' ? 'p-1' : ''}`}
                style={{background: '#eff2f5'}}
              >
                {step === 'design' && <Step1 isShowNodeCounts={isShowNodeCounts}/>}
                {step === 'slides' && <Step2 isGrid={isGrid} />}
                {step === 'code' && <Step3 />}
              </div>
              {showConfirmModal && (
                <ConfirmModal
                  message={t('SETTINGS_CONFIRM')}
                  show={showConfirmModal}
                  close={setShowConfirmModal}
                  onConfirm={onConfirm}
                />
              )}
              <AppPublishModal onPublish={onPublish} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Stepper)
