import React from 'react'
import clsx from 'clsx'
import moment from 'moment'

const TextInputFeild = (props: any) => {
  const valid = (key: string) => {
    let name = props?.formik[key]
    props.name.split('.').forEach((item: any) => (name = name?.[item]))
    return name
  }

  const getClasses = () => {
    return props?.type === 'checkbox'
      ? 'form-check-input mx-3' : props?.type === 'range' ? 'p-0'
      : 'form-control form-control-lg form-control-solid'
  }

  const onChange = (e: any) => {
    const {name, value} = e.target
    props.onChange(name, value)
  }

  return (
    <>
      {props?.formik ? (
        <>
          <input
            placeholder={props.placeholder}
            {...props?.formik?.getFieldProps(props.name)}
            className={clsx(
              `${getClasses()} `,
              {'is-invalid': valid('touched') && valid('errors')},
              {
                'is-valid': valid('touched') && !valid('errors'),
              }
            )}
            style={props?.style}
            name={props.name}
            type={props.type || 'text'}
            required={props?.required}
            autoComplete='off'
            disabled={props?.disabled}
            checked={props?.checked}
            defaultValue={props?.defaultValue}
            onBlur={props?.onBlur}
            value={props.type === 'date' && props?.formik?.values[props.name] ? moment(props?.formik?.values[props.name]).format('YYYY-MM-DD') : props?.formik?.values[props.name] }
          />
          {valid('touched') && valid('errors') && (
            <div className='text-danger'>{valid('errors')}</div>
          )}
        </>
      ) : (
        <input
          className={`${getClasses()}`}
          style={props.style}
          placeholder={props.placeholder}
          name={props.name}
          type={props.type || 'text'}
          required={props.required}
          autoComplete='off'
          disabled={props.disabled}
          checked={props.checked}
          value={props.value}
          defaultValue={props?.defaultValue}
          min={props.min}
          max={props.max}
          onBlur={props.onBlur}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default TextInputFeild
