import GrowthEngineBreadCrumb from './GrowthEngineBreadCrumb'
import PerformanceReportIndex from '../../modules/performanceReport'

const PerformanceReport = () => {
  return (
    <>
      <GrowthEngineBreadCrumb />
      <PerformanceReportIndex />
    </>
  )
}

export default PerformanceReport
