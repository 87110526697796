/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import ProfileBreadCrumb from './ProfileBreadCrumb'
import {ProfilePageToolbar} from '../../../_metronic/layout/components/toolbar/ProfilePageToolbar'
import {getFileData, getGlobalItem, LANGUAGES, setGlobalItem} from '../../../utils/utils'
import ImageInputFeild from '../../shared/ImageInputFeild'
import {updateImage} from '../../../service/module.service'
import ProfileModal from './ProfileModal'
import {updateUser} from '../../../service/user.service'
// import ProfileTab from './ProfileTab'
import {useStore} from '../../../store'
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

const ProfilePage = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [data, setData]: any = useState({})
  const {
    authStore: {setUser},
  } = useStore()

  useEffect(() => {
    const user = getGlobalItem('user')
    if (user) setData(user)
    // eslint-disable-next-line
  }, [])

  const onFileUpload = async (e: any) => {
    if (!e.target.files.length) return
    const userData = await updateImage(data._id, getFileData(e.target.files))
    if (userData) {
      setData({...data, avatar: userData})
      onSave({avatar: userData})
    }
  }

  const onFileRemove = () => {
    setData({...data, avatar: ''})
    onSave({avatar: ''})
  }

  const onFieldSave = (name: string, value: any) => setData({...data, [name]: value})

  const onBlur = async () => {
    if (!data.name || !data.language) return

    const {name, language, avatar} = data
    onSave({name, language, avatar})
  }

  const onSave = async (values: any) => {
    const res = await updateUser(data._id, values)
    if (res) {
      const userData = getGlobalItem('user')
      setUser({...userData, ...values})
      if (data.language !== userData.language) {
        setGlobalItem(I18N_CONFIG_KEY, {selectedLang: values.language})
        window.location.reload()
      }
    }
  }

  const currentLanguage: any = LANGUAGES.find((item) => item.value === data.language)

  return (
    <>
      <ProfileBreadCrumb />
      <ProfilePageToolbar />
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-flush h-lg-100'>
            <div className='card-body pt-5'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex gap-7 align-items-center'>
                  <div className='symbol symbol-circle symbol-100px'>
                    <ImageInputFeild
                      url={data.avatar}
                      onUpload={onFileUpload}
                      onRemove={onFileRemove}
                    />
                  </div>
                  <div className='d-flex flex-column gap-2'>
                    <h4 className='mb-0'>{data.name}</h4>
                    <div className='d-flex align-items-center gap-2'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon svg-icon-2'
                      />
                      <a className='text-muted text-hover-primary'>{data.email}</a>
                    </div>
                    {data.language && (
                      <div className='d-flex align-items-center gap-2'>
                        <KTSVG path={currentLanguage?.flag} className='svg-icon svg-icon-2' />
                        <a className='text-muted text-hover-primary'>{currentLanguage.text}</a>
                      </div>
                    )}
                  </div>
                </div>
                <div className='d-flex my-4'>
                  <div className='card-toolbar m-0'>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen024.svg'
                        className='svg-icon-3 svg-icon-primary'
                      />
                    </button>

                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                          {t('OPTION',{count:0})}
                        </div>
                      </div>
                      <div className='separator mb-3 opacity-75'></div>
                      <div className='menu-item px-3'>
                        <a
                          data-bs-toggle='modal'
                          data-bs-target='#gw_profile_modal'
                          className='menu-link px-3'
                        >
                          {t('EDIT')}
                        </a>
                      </div>
                      <div className='separator mt-3 opacity-75'></div>
                    </div>
                  </div>
                </div>
              </div>
              <ProfileModal user={data} onFieldSave={onFieldSave} onUserSave={onBlur} />
              {/* <ProfileTab user={data} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfilePage
