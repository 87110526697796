import {useState, useRef, useEffect} from 'react'
import * as uuid from 'uuid'
import { observer } from 'mobx-react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  ConnectionLineType,
  Background,
} from 'react-flow-renderer'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useSmartFlowData } from '../../StepperContext'
import botStore from '../../../../bots/botCreate/botTree/store/botStore'
import { getElements } from '../../../../../../utils/template.utils'
import { getModuleStats } from '../../../../../../service/module.service'
import { FallbackView } from '../../../../../../_metronic/partials'
import { SlideNode } from './node/SlideNode'
import { isWorkspaceReadonly } from '../../../../../../utils/utils'
import { getModulePath } from '../../../../../../utils/module.utils'
import { NodeDropdown } from './dropdown/NodeDropdown'
import OptionModal from './settings/OptionModal'
import ConfirmModal from '../../../../common/modal/ConfirmModal'


const getId = () => uuid.v4()

const DnDFlow = ({isShowNodeCounts}) => {
  const intl = useIntl()
  const history = useHistory();
  const {moduleId} = useParams()
  const {smartFlow, setSmartFlow} = useSmartFlowData()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false)
  const [nodePosition, setNodePosition] = useState({x: 0, y: 0})
  const [showOptionModal, setShowOptionModal] = useState(false)
  const [option, setOption] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [edge, setEdge] = useState({})
  const [statsCount, setStatsCount] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [moduleCode, setModuleCode] = useState({})
  const [flag, setFlag] = useState(false)
  const [deleteNodeId, setDeleteNodeId] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const defaultField = [
    {type: 'date', name: 'birthday', label: t('BIRTHDAY') , require: false, showlabel:false},
    {type: 'text', name: 'company', label: t('COMPANY'), placeholder: t('COMPANY'), require: false, showlabel:false},
    {type: 'email', name: 'email', label: t('EMAIL', {count:0}), placeholder: t('EMAIL', {count:0}), require: false, showlabel:false},
    {type: 'text', name: 'name' , label: t('NAME'), placeholder: t('NAME'), require: false, showlabel:false},
    {type: 'tel', name: 'phone', label: t('PHONE_NUMBER'), placeholder: t('PHONE_NUMBER'), require: false, showlabel:false},
    {type: 'url', name: 'website', label: t('WEBSITE'), placeholder: t('WEBSITE'), require: false, showlabel:false},
    {type: 'text', name: 'zipcode', label: t('ZIPCODE'), placeholder: t('ZIPCODE'), require: false, showlabel:false},
  ]

  const getStateData = async () => {
    const sData = await getModuleStats(moduleId)
    const statsData = sData || []
    setStatsCount(statsData)
  }

  useEffect(() => {
    getStateData()
  }, [])

  const ref = useRef()
  const createNode = () => {
    const id = getId()
    const slide = {
      id,
      type: 'text',
      data: {
        name: '',
        content: {},
        start: false,
        end: false,
      },
      position: {},
    }
    return slide
  }
  const [selectedNode, setSelectedNode] = useState(createNode())

  useEffect(() => {
    if (smartFlow?.moduleCode) setModuleCode(smartFlow.moduleCode)
  }, [smartFlow.moduleCode])

  useEffect(() => {
    if (!flag) return
    setSmartFlow({...smartFlow, moduleCode })
    setFlag(false)
    // eslint-disable-next-line
  }, [flag])

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 0);
  //   // eslint-disable-next-line
  }, [isShowNodeCounts])

  const getStartNodeDetails = () => {
    let startNodeDetails = moduleCode?.nodes.find((node) => node?.currentSlide?.data?.start === true)
    if (!startNodeDetails) startNodeDetails = moduleCode?.nodes[0]
    return startNodeDetails
  }

  const getstatById = (id) => {
    return statsCount.find((stat) => stat.id === id)
  }

  const convertedIntoPercentage = (statCount, totalStatsCount) => {
    const counts = parseInt(statCount) * 100 / parseInt(totalStatsCount)
    return counts
  }

  useEffect(() => {
    if (moduleCode?.elements?.length) {
      /*Get the initial or first step details */
      const getFirstStepDetails = getStartNodeDetails()
      /*Get the first step stat details */
      const statInfo = getstatById(getFirstStepDetails?.id)
      moduleCode.elements.map((item, i) => {
        const foundItem = statsCount.find((stat) => stat.id === item.id)
        if (foundItem && isShowNodeCounts && statInfo?._col1 && foundItem?._col1) {
          const nodePercentage = convertedIntoPercentage(foundItem._col1, statInfo._col1)
          item.data.percentageCount = `${parseInt(nodePercentage)} %`
          item.data.nodeCount = foundItem._col1 || 0
        } else if (item.data) {
          item.data.percentageCount = isShowNodeCounts ? `0%` : 0
          item.data.nodeCount = 0
        }
        return item
      })
      setModuleCode(moduleCode)
    }
    // eslint-disable-next-line
  }, [isShowNodeCounts, moduleCode])

  const copyContainerStyle = {
    borderRadius: "15px",
    backgroundColor: "white",
    // padding: "5px",
    position: "absolute",
    width: "100px",
    zIndex: 10
  }

  const contextMenuStyle = {
    borderRadius: "15px",
    backgroundColor: "white",
    // padding: "5px",
    position: "absolute",
    width: "100px",
    zIndex: 10
  }

  useEffect(() => {
    if (!reactFlowInstance) return
    if (moduleCode?.appearance?.flowSettings) {
      const {x, y, zoom} = moduleCode.appearance.flowSettings
      reactFlowInstance.setTransform({x, y, zoom})
    }
    // eslint-disable-next-line
  }, [moduleCode?.appearance?.flowSettings])

  const onConnect = (params) => {
    params.id = `${params.source}_${params.target}_${uuid.v4()}`
    params.type = 'smart'
    const foundIndex = moduleCode?.elements?.findIndex((ele) => ele.id === params.source)
    if (foundIndex !== -1 && moduleCode?.elements[foundIndex].data?.content?.options?.length) {
      const getOption = [{title: t("DEFAULT"), value: "default"}];
      const checkDefault = moduleCode?.elements?.some(
        (edge) =>
          edge.type === 'smart' &&
          edge.source === params.source &&
          edge?.sourceHandle?.some((item) => item?.value)
      )
      if (checkDefault) getOption[0].checked = checkDefault;
      moduleCode?.elements[foundIndex].data.content.options.forEach((opt, index) => {
        if (!opt?.target) getOption.push({...opt, index})
        else getOption.push({...opt, checked: true})
      })
      if (getOption) setOption(getOption)
      setShowOptionModal(true)
      setEdge({params, foundIndex})
    } else {
      const findEdge = moduleCode?.elements?.find((ele) => ele.type === 'smart' && params.source === ele.source)
      if(!findEdge) addSmartEdge(params)
    }
  }

  const addOptionEdge = () => {
    if (!selectedOption.length) {
      setShowOptionModal(false);
      return;
    }
    edge.params.sourceHandle = selectedOption
    edge.params.label = selectedOption.map(s => !s.value ? s.title : "" ).toString();
    for (let i = 0; i < selectedOption.length; i++) {
      if (!selectedOption[i].value)
        moduleCode.elements[edge.foundIndex].data.content.options[selectedOption[i].index].target =
          edge.params.target
    }
    addSmartEdge(edge.params)
  }

  const addSmartEdge = (params) => {
    const elements = getElements((els) => addEdge(params, els), moduleCode.elements)
    setModuleCode({...moduleCode, elements})
    setSelectedOption([])
    setShowOptionModal(false);
    setFlag(true)
  }

  const onElementsRemove = (elementsToRemove) => {
    console.log('onElementsRemove')
    const isStart = elementsToRemove.some((el) => el?.data?.start)
    if (isStart) return;
    if (elementsToRemove?.length === 1 && elementsToRemove[0].source) {
      const findIndex = moduleCode.elements?.findIndex(
        (ele) => ele?.type !== 'smart' && ele?.id === elementsToRemove[0].source
      )
      if (moduleCode.elements[findIndex].data?.content?.options?.length)
        moduleCode.elements[findIndex].data?.content?.options.forEach((opt) => {
          if (opt?.target === elementsToRemove[0].target) delete opt.target
        })
    }
    const elements = getElements((els) => removeElements(elementsToRemove, els), moduleCode.elements)
    setModuleCode({...moduleCode, elements})
    setFlag(true)
    return elements
  }

  const onContextMenu = (ev) => {
    const componentPosition = ref.current.getBoundingClientRect()
    const left = ev.pageX - componentPosition.x
    const top = ev.pageY - componentPosition.y
    botStore.setContextMenu({
      open: true,
      ev,
      position: {
        left,
        top,
      },
    })
    setTimeout(() => {
      botStore.setContextMenu({
        open: false,
      })
    }, 5000)
    ev.preventDefault()
  }

  const contextMenu = () => {
    const { left, top } = botStore.contextMenu.position
    return (<div style={{...contextMenuStyle, left, top }}>
      <div onClick={onPasteButtonClick} style={{padding: '10px', cursor: 'pointer'}}>Paste</div>
    </div>)
  }

  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance)

  const getPosition = (event) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    return reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
  }

  const nodeTypes = {
    text: SlideNode,
    options: SlideNode,
    content: SlideNode,
    end: SlideNode,
  }

  const changeType = (event, currentType, dt) => {
    event.preventDefault()
    const slides = moduleCode?.elements.filter((slide) => slide.type !== 'smart')
    selectedNode.position = getPosition(event)
    selectedNode.type = currentType
    selectedNode.data.name = `Slide ${slides.length}`
    const edge = {
      source: slides[slides.length - 1].id,
      sourceHandle: null,
      target: selectedNode.id,
      targetHandle: null,
      id: `${slides[slides.length - 1].id}_${selectedNode.id}_${getId()}`,
      type: "smart"
    }
    if (currentType === 'content') selectedNode.data.content.fields = defaultField;
    if (dt) {
      if (currentType === 'options')
        dt?.content?.options?.forEach((option) => {
          if (option.target) {
            delete option.target
          }
        })
      selectedNode.data = dt
    }
    setSelectedNode(selectedNode)
    const elements = getElements((es) => {
      return es.concat(selectedNode)
    }, moduleCode.elements)
    const slideEdge = getElements((els) => addEdge(edge, els), elements)
    setModuleCode({...moduleCode, elements: dt ? elements : slideEdge})
    setFlag(true)
    setTypeDropdownOpen(false)
    setSelectedNode(createNode())
    window.navigator.clipboard.writeText({}).then(() => {})
  }

  const onPasteButtonClick = () => {
    const ev = botStore.contextMenu.ev
    botStore.setContextMenu({
      open: false
    })
    window.navigator.clipboard.readText().then((text) => {
      try {
        const nodeObj = JSON.parse(text);
        console.log('nodeObj:', nodeObj)
        console.log(nodeObj);
        changeType(ev, nodeObj.type, nodeObj.data)
      } catch(err) {

      }
    })
  }

  const OnDoubleClick = (event) => {
    if (isWorkspaceReadonly()) return
    event.preventDefault()
    setNodePosition({x: event.clientX, y: event.clientY - 92})
    setTypeDropdownOpen(!typeDropdownOpen)
  }

  function removeElement(id, elements) {
    const elementsToRemove = elements.filter(
      (elm) => id === elm.id || id === elm.source || id === elm.target
    )
    const edges = elementsToRemove.filter((elm) => elm?.type === 'smart')
    if (edges.length) {
      edges.forEach((edge) => {
        const parent = elements?.find(
          (ele) => ele?.type === 'options' && ele?.id === edge?.source 
        )
        if (parent) {
          // delete target for options when delete node 
          parent?.data?.content?.options.forEach(
            (opt) => opt?.target === id && delete opt?.target
          )
        } 
      });
    }
    elements = elements.filter((el) => id !== el.id && id !== el.source && id !== el.target)
    setModuleCode({...moduleCode, elements})
    setFlag(true)
  }

  const onMoveEnd = ({x, y, zoom}) => {
    setModuleCode({...moduleCode, appearance: {...moduleCode.appearance, flowSettings: {x, y, zoom}}})
    setFlag(true)
  }
  
  const onNodeDragStop = (event, node) => {
    const foundIndex = moduleCode.elements.findIndex((item) => item.id === node.id)
    moduleCode.elements[foundIndex].position = getPosition(event)
    setModuleCode({...moduleCode, elements: moduleCode.elements})
    setFlag(true)
    console.log("onNodeDragStop")

  }

  const onElementClick = (event, node) => {
    if (event.target.classList?.contains('node-close')) {
      console.log('onNodeRemove')
      setDeleteNodeId(node.id)
      setShowConfirmModal(true)
    }
  }

  const copyMessage = () => {
    const componentPosition = ref.current.getBoundingClientRect()
    const left = botStore.copyMessage.position.left - componentPosition.x + 'px'
    const top = botStore.copyMessage.position.top - componentPosition.y + 'px'
    return (
      <div style={{...copyContainerStyle, left, top}} className='disappear-element'>
        <div style={{padding: '10px', cursor: 'pointer'}}>Copied!</div>
      </div>
    )
  }

  const updateStep = () => {
    return `${getModulePath('smartflow')}/${smartFlow._id}/slides`
  }

  const onNodeDoubleClick = (event, node) => {
    if (isWorkspaceReadonly()) return
    event.stopPropagation()
    const foundElement = moduleCode.elements.find((item) => item.id === node.id)
    if (foundElement) history.push(updateStep(), foundElement)
    // resetInteractive(true)
  }

  return (
    <div ref={ref} onContextMenu={onContextMenu}>
      {botStore.copyMessage.show ? copyMessage() : ''}
      {botStore.contextMenu.open ? contextMenu() : ''}
      <div className='dndflow' style={{height: `calc(100vh - 120px)`}}>
        {isLoading ? <FallbackView/> : <ReactFlowProvider>
            <div className='reactflow-wrapper' ref={reactFlowWrapper}>
              <ReactFlow
                nodeTypes={nodeTypes}
                elements={moduleCode.elements}
                onConnect={onConnect}
                onElementsRemove={onElementsRemove}
                onLoad={onLoad}
                connectionLineType={ConnectionLineType.SmoothStepEdge}
                onNodeDragStop={onNodeDragStop}
                zoomOnDoubleClick={false}
                onMoveEnd={onMoveEnd}
                // onDoubleClick={OnDoubleClick}
                onNodeDoubleClick={onNodeDoubleClick}
                onElementClick={onElementClick}
                defaultPosition={[
                  moduleCode?.appearance?.flowSettings?.x || 0,
                  moduleCode?.appearance?.flowSettings?.y || 0,
                ]}
                defaultZoom={moduleCode?.appearance?.flowSettings?.zoom || 1.5}
              >
                <Background variant='lines' gap={15} size={2} />
                <Controls />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        }
        {typeDropdownOpen && (
          <div
            className='dropdown position-absolute d-flex align-items-center'
            style={{top: nodePosition.y, left: nodePosition.x, zIndex: 10}}
          >
            <NodeDropdown changeType={changeType} />
          </div>
        )}
      </div>
      {showOptionModal && (
        <OptionModal
          setShowOptionModal={setShowOptionModal}
          option={option}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          addOptionEdge={addOptionEdge}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          message={'Are you sure you want to delete this node?'}
          show={showConfirmModal}
          close={setShowConfirmModal}
          onConfirm={() => removeElement(deleteNodeId, moduleCode.elements)}
        />
      )}
    </div>
  )
}

export default observer(DnDFlow)
