/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {observer} from 'mobx-react'
import {useStore} from '../../../../store'
import {getGlobalItem, isGroweoContentadmin} from '../../../../utils/utils'
import {getLeadsGenerated, mockApi} from '../../../../service/dashboard'
import {useHistory} from 'react-router-dom'
import {deleteContactBtId, updateContactSeen} from '../../../../service/contact.service'
import moment from 'moment'
import ConfirmModal from '../../common/modal/ConfirmModal'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'

type Props = {
  filter?: any
  setFilter?: any
  defaultPageSize?: number
  hideHeader?: boolean
  hideColumns?: {[x: string]: boolean}
}
const LeadsGeneratedTable: FC<Props> = ({filter, setFilter, defaultPageSize, hideHeader, hideColumns }) => {
  const user = getGlobalItem('user')
  const contentAdmin = isGroweoContentadmin()
  const [myList, setMyList] = useState([
    {
      email: '',
      sourceType: '',
      automation: '',
      interest: '',
      created: '',
      seen: '',
      phoneNumber: '',
    },
  ])

  hideColumns = hideColumns || {};

  const pageLimit = defaultPageSize || 10
  const [offSet, setOffSet] = useState(pageLimit)
  const [active, setActive] = useState<number>(1)
  const perPage = Math.ceil(myList.length / pageLimit)
  const [pageArray, setPageArray] = useState<Array<number | string>>([])
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {
    authStore: {getUser},
  } = useStore()
  const history = useHistory()
  const [tab, setTab] = useState<number>(0)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [myList, pageArray])

  useEffect(() => {
    getUser()
  }, [getUser])

  if (user.email === 'demo@groweo.com') {
    mockApi()
  }

  const getContactData = async (filter: any) => {
    let response = await getLeadsGenerated({...filter})
    setMyList(response)
    setActive(1)
  }

  useEffect(() => {
    setFilter({...filter, isUnseen: !!tab})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  useEffect(() => {
    if (filter) getContactData(filter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  //pagination functionality
  useEffect(() => {
    var totPages: number = parseInt(perPage.toString())
    var currentPage: number = parseInt(active.toString())
    var pageArr: Array<number | string> = []
    setOffSet(parseInt(active.toString()) * pageLimit)
    if (totPages > 1) {
      if (totPages <= 9) {
        var i = 1
        while (i <= totPages) {
          pageArr.push(i)
          i++
        }
      } else {
        if (currentPage <= 5) pageArr = [1, 2, 3, 4, 5, 6, 7, 8, '', totPages]
        else if (totPages - currentPage <= 4)
          pageArr = [
            1,
            '',
            totPages - 7,
            totPages - 6,
            totPages - 5,
            totPages - 4,
            totPages - 3,
            totPages - 2,
            totPages - 1,
            totPages,
          ]
        else
          pageArr = [
            1,
            '',
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            '',
            totPages,
          ]
      }
    }
    setPageArray(pageArr)
  }, [perPage, active, pageLimit])

  const pageClicked = (pageNo: any) => {
    setActive(pageNo)
  }

  const navigateToDetail = (contact: any) => history.push(`/cdp/leads/${contact._id}`)

  const onContactClick = async (contact: any) => {
    const foundIndex = myList.findIndex((c: any) => c._id === contact._id)
    if (myList[foundIndex].seen) return

    const data = await updateContactSeen(contact._id)
    if (data) {
      myList[foundIndex].seen = data.seen
      setMyList([...myList])
    }
  }

  const showDeleteModel = async (id: string) => {
    setDeleteId(id)
    setShowConfirmModal(true)
  }

  const onDelete = async () => {
    if (!deleteId) return
    const id = await deleteContactBtId(deleteId)
    if (id) getContactData(filter)
  }

  const getTabClass = (value: number) =>
    value === tab ? 'border-bottom border-3 border-primary' : 'text-muted'

  const convertMasked = (number: any) => {
    let string = String(number)
    let sliced = string.slice(string.length);	
    let mask = String(sliced).padStart(string.length, "x")
    return mask;
  }

  return (
    <div id='kt_content_container' className=''>
      <div className='card'>
        {!hideHeader ? <div className='card-header border-0 pt-6'>
          {/* <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{subTitle}</span>
        </h3>  */}

          <div className='card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0'>
            <div
              className={`fs-4 fw-bolder pb-3 cursor-pointer ${getTabClass(0)}`}
              onClick={() => setTab(0)}
            >
              {t('ALL')} {t('CONTACT', {count: 1}).toLowerCase()}
            </div>
            <div
              className={`fs-4 fw-bolder pb-3 cursor-pointer ${getTabClass(1)}`}
              onClick={() => setTab(1)}
            >
              {t('UNPROCESSED')} {t('CONTACT', {count: 1}).toLowerCase()}
            </div>
          </div>
        </div>: ''}

        <div className='card-body pt-0'>
          <div
            id='kt_subscriptions_table_wrapper'
            className='dataTables_wrapper dt-bootstrap4 no-footer'
          >
            <div className='table-responsive'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                id='kt_subscriptions_table'
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {/* {!hideColumns || !hideColumns.checkbox ? <th className='w-10px pe-2 sorting_disabled' rowSpan={1} colSpan={1}>
                      <div className='form-check form-check-sm form-check-custom form-check-solid px-4'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          data-kt-check='true'
                          data-kt-check-target='#kt_subscriptions_table .form-check-input'
                          value='1'
                        />
                      </div>
                    </th> : ''} */}
                    <th
                      className='min-w-125px sorting'
                      tabIndex={0}
                      aria-controls='kt_subscriptions_table'
                      rowSpan={1}
                      colSpan={1}
                      aria-label='Customer: activate to sort column ascending'
                      style={{width: '171.354px'}}
                    >
                      {t('EMAIL', {count: 0})}
                    </th>
                    <th
                      className='min-w-125px sorting'
                      tabIndex={0}
                      aria-controls='kt_subscriptions_table'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '171.354px'}}
                    >
                      {t('PHONE')}
                    </th>
                    <th
                      className='min-w-125px sorting'
                      tabIndex={0}
                      aria-controls='kt_subscriptions_table'
                      rowSpan={1}
                      colSpan={1}
                      aria-label='Status: activate to sort column ascending'
                      style={{width: '169.201px'}}
                    >
                      {t('GROWTH', {count: 0})} {t('APP', {count: 0})}
                    </th>
                    <th
                      className='min-w-125px sorting'
                      tabIndex={0}
                      aria-controls='kt_subscriptions_table'
                      rowSpan={1}
                      colSpan={1}
                      aria-label='Created Date: activate to sort column ascending'
                      style={{width: '169.201px'}}
                    >
                      {t('CREATED_TIME', {count: 0})}
                    </th>
                    {!hideColumns || !hideColumns.actions ? <th
                      className='text-end min-w-70px sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      aria-label='Actions'
                      style={{width: '75.986px'}}
                    >
                      {t('CONTACT_ACTION')}
                    </th>: ''}
                  </tr>
                </thead>

                <tbody className='text-gray-600 fw-bold'>
                  {myList.slice(offSet - pageLimit, offSet).map((contact: any, id: number) => {
                    return (
                      <tr className='cursor-pointer' key={id}>
                        {/* {!hideColumns || !hideColumns.checkbox ? <td onClick={() => !contentAdmin ? navigateToDetail(contact): ''}>
                          <div className='form-check form-check-sm form-check-custom form-check-solid px-4'>
                            <input className='form-check-input' type='checkbox' value='1' />
                          </div>
                        </td> : ''} */}

                        <td onClick={() => !contentAdmin ? navigateToDetail(contact): ''}>
                          <a
                            className={`text-${
                              !contact?.seen ? 'primary' : 'gray-800'
                            } text-hover-primary mb-1`}
                          >
                            {contentAdmin ? convertMasked(contact?.email) : contact?.email || '-'}
                          </a>
                        </td>

                        <td
                          className={`text-${!contact?.seen ? 'primary' : 'gray-800'}`}
                        >
                          {contentAdmin ? convertMasked(contact?.phoneNumber) : contact?.phoneNumber || '-'}
                        </td>

                        <td onClick={() => !contentAdmin ? navigateToDetail(contact): ''}>
                          <div className='badge badge-light-success'>
                            {contact.sourceType
                              ? t(contact.sourceType?.toUpperCase(), {count: 0})
                              : ''}
                          </div>
                        </td>

                        <td
                          className={`text-${!contact?.seen ? 'primary' : 'gray-800'}`}
                          onClick={() => !contentAdmin ? navigateToDetail(contact): ''}
                        >
                           <div className='d-flex flex-column'>
                            <span className='mb-2'>{contact.created ? moment(contact.created).format('DD.MM.YYYY') : ''}</span>
                            <span className='mb-2'>{contact.created ? moment(contact.created).format('HH:mm:ss') : ''}</span>
                          </div>  
                        </td>

                        {!hideColumns || !hideColumns.actions ? <td className='text-end'>
                          <a
                            className='btn btn-light btn-active-light-primary btn-sm'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            <span>{t('SELECT')}</span>
                            <span className='svg-icon svg-icon-5 m-0'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <path
                                  d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                                  fill='black'
                                ></path>
                              </svg>
                            </span>

                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              <div
                                className='menu-item px-3'
                                onClick={() => onContactClick(contact)}
                              >
                                <span className='menu-link px-3'>{t('SEEN')}</span>
                              </div>
                              <div
                                className='menu-item px-3'
                                onClick={() => showDeleteModel(contact?._id)}
                              >
                                <span className='menu-link px-3'>{t('DELETE')}</span>
                              </div>
                            </div>
                          </a>
                        </td> : ''}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_subscriptions_table_paginate'
                >
                  <ul className='pagination'>
                    {pageArray.map((page, i) => {
                      const toReturn = []

                      if (i === 0) {
                        toReturn.push(
                          <li
                            className='paginate_button page-item previous disabled'
                            id='kt_subscriptions_table_previous'
                          >
                            <div
                              key={'prevpage'}
                              onClick={
                                parseInt(active.toString()) === 1
                                  ? () => {}
                                  : () => {
                                      pageClicked(active - 1)
                                    }
                              }
                              aria-controls='kt_subscriptions_table'
                              data-dt-idx='0'
                              tabIndex={0}
                              className='page-link'
                            >
                              <i className='previous'></i>
                            </div>
                          </li>
                        )
                      }
                      if (page === '')
                        toReturn.push(
                          <div style={{textOverflow: 'ellipsis'}} key={i}>
                            ...
                          </div>
                        )
                      else
                        toReturn.push(
                          <>
                            <li
                              className={`paginate_button page-item ${
                                parseInt(active.toString()) === page ? 'active' : ''
                              }`}
                            >
                              <div
                                key={i}
                                onClick={
                                  parseInt(active.toString()) === page
                                    ? () => {}
                                    : () => {
                                        pageClicked(page)
                                      }
                                }
                                aria-controls='kt_subscriptions_table'
                                data-dt-idx='1'
                                tabIndex={0}
                                className='page-link'
                              >
                                {page}
                              </div>
                            </li>
                          </>
                        )
                      if (i === pageArray.length - 1) {
                        toReturn.push(
                          <li
                            className='paginate_button page-item next'
                            id='kt_subscriptions_table_next'
                          >
                            <div
                              key={'nextpage'}
                              onClick={
                                parseInt(active.toString()) === page
                                  ? () => {}
                                  : () => {
                                      pageClicked(parseInt(active.toString()) + 1)
                                    }
                              }
                              aria-controls='kt_subscriptions_table'
                              data-dt-idx='3'
                              tabIndex={0}
                              className='page-link'
                            >
                              <i className='next'></i>
                            </div>
                          </li>
                        )
                      }
                      return toReturn
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='modal fade'
        id='kt_subscriptions_export_modal'
        tabIndex={-1}
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Export Subscriptions</h2>

              <div
                id='kt_subscriptions_export_close'
                className='btn btn-icon btn-sm btn-active-icon-primary'
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    ></rect>
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    ></rect>
                  </svg>
                </span>
              </div>
            </div>

            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form
                id='kt_subscriptions_export_form'
                className='form fv-plugins-bootstrap5 fv-plugins-framework'
                action='#'
              >
                <div className='fv-row mb-10'>
                  <label className='fs-5 fw-bold form-label mb-5'>Select Export Format:</label>

                  <select
                    data-control='select2'
                    data-placeholder='Select a format'
                    data-hide-search='true'
                    name='format'
                    className='form-select form-select-solid select2-hidden-accessible'
                    data-select2-id='select2-data-22-5ncc'
                    tabIndex={-1}
                    aria-hidden='true'
                  >
                    <option value='excell' data-select2-id='select2-data-24-1clh'>
                      Excel
                    </option>
                    <option value='pdf'>PDF</option>
                    <option value='cvs'>CVS</option>
                    <option value='zip'>ZIP</option>
                  </select>
                  <span
                    className='select2 select2-container select2-container--bootstrap5'
                    dir='ltr'
                    data-select2-id='select2-data-23-4unm'
                    style={{width: '100%'}}
                  >
                    <span className='selection'>
                      <span
                        className='select2-selection select2-selection--single form-select form-select-solid'
                        role='combobox'
                        aria-haspopup='true'
                        aria-expanded='false'
                        tabIndex={0}
                        aria-disabled='false'
                        aria-labelledby='select2-format-wh-container'
                        aria-controls='select2-format-wh-container'
                      >
                        <span
                          className='select2-selection__rendered'
                          id='select2-format-wh-container'
                          role='textbox'
                          aria-readonly='true'
                          title='Excel'
                        >
                          Excel
                        </span>
                        <span className='select2-selection__arrow' role='presentation'>
                          <b role='presentation'></b>
                        </span>
                      </span>
                    </span>
                    <span className='dropdown-wrapper' aria-hidden='true'></span>
                  </span>
                </div>

                <div className='fv-row mb-10 fv-plugins-icon-container'>
                  <label className='fs-5 fw-bold form-label mb-5'>Select Date Range:</label>

                  <input
                    className='form-control form-control-solid flatpickr-input'
                    placeholder='Pick a date'
                    name='date'
                    type='hidden'
                  />
                  <input
                    className='form-control form-control-solid form-control input'
                    placeholder='Pick a date'
                    tabIndex={0}
                    type='text'
                  />

                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>

                <div className='row fv-row mb-15'>
                  <label className='fs-5 fw-bold form-label mb-5'>Payment Type:</label>

                  <div className='d-flex flex-column'>
                    <label className='form-check form-check-custom form-check-sm form-check-solid mb-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        checked={true}
                        name='payment_type'
                        readOnly
                      />
                      <span className='form-check-label text-gray-600 fw-bold'>All</span>
                    </label>

                    <label className='form-check form-check-custom form-check-sm form-check-solid mb-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='2'
                        checked={true}
                        name='payment_type'
                        readOnly
                      />
                      <span className='form-check-label text-gray-600 fw-bold'>Visa</span>
                    </label>

                    <label className='form-check form-check-custom form-check-sm form-check-solid mb-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='3'
                        name='payment_type'
                      />
                      <span className='form-check-label text-gray-600 fw-bold'>Mastercard</span>
                    </label>

                    <label className='form-check form-check-custom form-check-sm form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='4'
                        name='payment_type'
                      />
                      <span className='form-check-label text-gray-600 fw-bold'>
                        American Express
                      </span>
                    </label>
                  </div>
                </div>

                <div className='text-center'>
                  <button
                    type='reset'
                    id='kt_subscriptions_export_cancel'
                    className='btn btn-light me-3'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    id='kt_subscriptions_export_submit'
                    className='btn btn-primary'
                  >
                    <span className='indicator-label'>Submit</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                </div>

                <div></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmModal
          icon={''}
          show={showConfirmModal}
          close={setShowConfirmModal}
          onConfirm={onDelete}
        />
      )}
    </div>
  )
}

export default observer(LeadsGeneratedTable)
