/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'
import Papa from 'papaparse'
import {getDayDifference, getDecimal, getGlobalItem, sortNumber, sortString} from '../../../../utils/utils'
import {useLocation,useHistory} from 'react-router-dom'
import {getAppTypePerformanceReport, getPerformanceReport, getAllAppPerformanceReport} from '../../../../service/performanceReport.service'
import {PerformanceReportToolbar} from '../../../../_metronic/layout/components/toolbar/PerformanceReportToolbar'
import ReportTable from '../../widgets/tables/ReportTable'
import { filterStatus, getFilterAppType } from '../../../../utils/module.utils'
import SettingsModal from './view/SettingsModal'
import { getWorkspaceById, updateWorkspace } from '../../../../service/workspace.service'
import { FallbackView } from '../../../../_metronic/partials'

export default function PerformanceReport() {
  const {state}: any = useLocation()
  const history = useHistory()
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const [toggleSorting, setToggleSorting]: any = useState(
    state
      ? state.toggleSorting
      : {
         sortBy: 'workspaceName',
         sortDirection: true,
      }
  )
  const defaultRange = {
    startDate: moment()
      .subtract(Number(state?.dateRange || 30), 'd')
      .format('ll'),
    endDate:
      Number(Number(state?.dateRange)) === 1
        ? moment().format('ll')
        : moment().add(1, 'd').format('ll'),
  }
  const [dateRange, setDateRange]: any = useState(state?.dateRange === 'all' ? {} : defaultRange)
  const [activePage, setActivePage]: any = useState(state?.activePage || 1)
  const [pageData, setPageData]: any = useState([])
  const [filterData, setFilterData]: any = useState([])
  const [reports, setReports]: any = useState([])
  const [selected, setSelected]: any = useState(state?.dateRange || 30)
  const [reportType, setReportType]: any = useState(state?.reportType || 'customer')
  const [workspaceFilter, setWorkspaceFilter]: any = useState(state?.workspaceFilter || {})
  const [appFilter, setAppFilter]: any = useState(state?.appFilter || {})
  const {workspaceId,workspace:{config}} = getGlobalItem("user");
  const [settingObject, setSettingObject]:any = useState({
    visitorVSstarted:{
      greenCount:10,
      orangeCount:7,
      redCount:7
    },
    startedVSsuccessful:{
      greenCount:50,
      orangeCount:30,
      redCount:30
    },
    visitorsVSsuccessful:{
      greenCount:5,
      orangeCount:2.5,
      redCount:2.5
    },
    visitorsVScontact:{
      greenCount:4,
      orangeCount:2,
      redCount:2
    }
  })
  const [settingColorStatus, setSettingColorStatus] = useState(state?state.settingColorStatus:false)
  const [settingType, setSettingType]:any = useState("visitorVSstarted");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (reportType === 'customer') {
      getWorkspacesReport()
    } else if (reportType === 'appType') {
      getAppTypeReport()
    } else if (reportType === 'app') {
      getAllAppReport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, reportType])

  //get workspace data by workspaceId
  const getWorkspace = ()=>{
    getWorkspaceById(workspaceId).then((res)=>{
      if(res?.config?.performanceSetting)setSettingObject(res.config.performanceSetting)
    })
  }
  useEffect(()=>{
    getWorkspace()
  },[])

  useEffect(() => {
    if (reportType === 'customer') {
      setToggleSorting({
        sortBy: 'workspaceName',
        sortDirection: true,
      })
    } else if (reportType === 'appType') {
      setToggleSorting({
        sortBy: 'type',
        sortDirection: true,
      })
    } else if (reportType === 'app') {
      setToggleSorting({
        sortBy: 'moduleName',
        sortDirection: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  //add performance setting object in workspace config
  const performanceSettingHandler = (values:any)=>{
    updateWorkspace(workspaceId, { config:{...config,
      ['performanceSetting']:values
    } }).then(() => {
      getWorkspace()
    })
    history.push({
      state: {...state,settingColorStatus},
    })
  }
  const getWorkspacesReport = async () => {
    const reports = await getPerformanceReport(dateRange)
    const workspacesReport = reports?.map((data: any) => {
      const visitorVSstarted = data['UNIQUE-VISITOR']
        ? getDecimal((data['SESSION'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0 
      const startedVSsuccessful = data['SESSION']
        ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['SESSION']) * 100 || 0)
        : 0
      const startedVScontact = data['SESSION']
        ? getDecimal((data['CONTACT'] / data['SESSION']) * 100 || 0)
        : 0
      const visitorsVScontact = data['UNIQUE-VISITOR']
        ? getDecimal((data['CONTACT'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0
      const daysSinceInstalled = data['installationDate']
        ? `${getDayDifference(data['installationDate'])}`
        : 0
      const visitorsVSsuccessful = data['UNIQUE-VISITOR']
        ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0

      return {
        ...data,
        visitorVSstarted,
        startedVSsuccessful,
        startedVScontact,
        visitorsVScontact,
        daysSinceInstalled,
        visitorsVSsuccessful
      }
    })
    setReports(workspacesReport)
    setLoading(false)
  }

  const getAppTypeReport = async () => {
    const reports = await getAppTypePerformanceReport(dateRange)
    const appTypeReport = reports?.map((data: any) => {
      const visitorVSstarted = data['UNIQUE-VISITOR']
        ? getDecimal((data['SESSION'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0 
      const startedVSsuccessful = data['SESSION']
        ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['SESSION']) * 100 || 0)
        : 0
      const startedVScontact = data['SESSION']
        ? getDecimal((data['CONTACT'] / data['SESSION']) * 100 || 0)
        : 0
      const visitorsVScontact = data['UNIQUE-VISITOR']
        ? getDecimal((data['CONTACT'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0
      const visitorsVSsuccessful = data['UNIQUE-VISITOR']
        ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0

      return {
        ...data,
        visitorVSstarted,
        startedVSsuccessful,
        startedVScontact,
        visitorsVScontact,
        visitorsVSsuccessful
      }
    })
    setReports(appTypeReport)
    setLoading(false)
  }

  const getAllAppReport = async () => {
    const records = await getAllAppPerformanceReport(dateRange)
    const appsReport = records?.map((data: any) => {
      const visitorVSstarted = data['UNIQUE-VISITOR']
        ? getDecimal((data['SESSION'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0
      const startedVSsuccessful = data['SESSION']
        ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['SESSION']) * 100 || 0)
        : 0
      const startedVScontact = data['SESSION']
        ? getDecimal((data['CONTACT'] / data['SESSION']) * 100 || 0)
        : 0
      const visitorsVScontact = data['UNIQUE-VISITOR']
        ? getDecimal((data['CONTACT'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0
      const daysSinceInstalled = data['installationDate']
        ? `${getDayDifference(data['installationDate'])}`
        : 0
      const visitorsVSsuccessful = data['UNIQUE-VISITOR']
        ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['UNIQUE-VISITOR']) * 100 || 0)
        : 0

      return {
        ...data,
        visitorVSstarted,
        startedVSsuccessful,
        startedVScontact,
        visitorsVScontact,
        daysSinceInstalled,
        visitorsVSsuccessful
      }
    })
    setReports([...appsReport])
    setAppFilter({ ...appFilter, type: filterStatus(getFilterAppType) })
    setLoading(false)
  }

  useEffect(() => {
    const startIndex = activePage * 100 - 100
    const endIndex = activePage * 100
    setPageData([...filterData.slice(startIndex, endIndex)])
  }, [filterData, activePage])

  useEffect(() => {
    if (reportType === 'app') {
      onAppFilter()
    } else {
      onFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceFilter, appFilter, reports])

  const onFilter = () => {
    const { search, status, partnerId } = workspaceFilter;
    if (!reports?.length) return;
    const existRecord = reports.filter((workspace: any) =>{
      const isSearch = search && reportType === 'customer' ? workspace?.workspaceName?.toLowerCase().includes(search?.toLowerCase()) : true;
      const isStatus = status ? statusFilter(workspace)  : true;
      const isPartnerId = partnerId ? workspace?.partnerId === partnerId  : true;
      return isSearch && isStatus && isPartnerId;
    })
    setFilterData([...existRecord])
    onSort(toggleSorting, existRecord)
  }

  const onAppFilter = () => {
    const { type, search } = appFilter
    if (!reports?.length) return;
    const existRecord = reports.filter((record: any) => { 
      const isType = type ? type.includes(record.moduleType) : true;
      const isSearch = search ? record.moduleName.toLowerCase().includes(search?.toLowerCase()) || record.workspaceName.toLowerCase().includes(search?.toLowerCase()) : true;
      return isType && isSearch;  
    })
    setFilterData([...existRecord])
    onSort(toggleSorting, existRecord)
  }

  const statusFilter = (workspace: any) => {
    if (filterStatus(workspaceFilter?.status).includes('notInstalled')){
      return workspace?.installationDate ? workspaceFilter?.status[workspace?.workspaceStatus] : true;
    }
    return workspaceFilter?.status[workspace?.workspaceStatus];
  }

  const onSort = (filterData: any, records: any) => {
    setActivePage(1)
    setToggleSorting({...filterData})
    const {sortBy} = filterData
    let sortData =
      ['workspaceName', 'type', 'moduleName', 'moduleType'].includes(sortBy)
        ? sortString(records, filterData.sortDirection, sortBy)
        : sortNumber(records, filterData.sortDirection, sortBy)
    setFilterData([...sortData])
  }

  /* download performance report in csv */
  const downloadReportCsv = () => {
    let fileName = ''
    
    let customFields: any = []
    if (reportType === 'customer') {
      customFields = [
        t('CUSTOMER_COLUMN'),
        t('APP', { count: 1 }),
        t('DAYS_SINCE_INSTALLED'),
        t('VISITOR_COLUMN'),
      ]
      fileName = `${t('CUSTOMER', {count:0})}`
    } else if (reportType === 'appType') {
      customFields = [
        t('APP_TYPE'),
        t('APP', { count: 1 }),
      ]
      fileName = `${t('APP_TYPE', {count:0})}`
    } else if (reportType === 'app') {
      customFields = [
        t('APP_NAME', { count: 1 }),
        t('APP', { count: 0 }),
        t('CUSTOMER_COLUMN'),
        t('DAYS_SINCE_INSTALLED')
      ]
      fileName = `${t('APP', {count:0})}`
    }

    fileName = `${fileName} ${t('PERFORMANCE_REPORT', { count: 1 })} - ${moment(new Date()).format("DD-MM-YYYY")}.csv`

    const csv = Papa.unparse(
      {
        "fields": [ // set formated headers
          t('ROW_NO'),
          ...customFields,
          t('VISITOR_VS_STARTED'),
          t('STARTED_CONVERSATION'),
          t('STARTED_VS_SUCCESSFUL'),
          t('STARTED_VS_CONTACT'),
          t('SUCCESSFULL_CONVERSATION'),
          t('VISITORS_VS_SUCCESSFUL'),
          t('VISITORS_VS_CONTACTS'),
          t('PERFORMANCE_CONTACT_COLUMN')
        ],
        "data": [ // set csv data
          ...filterData.map((data: any, index: number) => {
            let customValue: any = []
            if (reportType === 'customer') {
              customValue = [
                data['workspaceName'],
                data['TOTAL-APP'],
                data['daysSinceInstalled'],
                data['UNIQUE-VISITOR'],
              ]
            } else if (reportType === 'appType') {
              customValue = [
                t(data['type']?.toUpperCase(), {count: 0}),
                data['TOTAL-APP'],
              ]
            } else if (reportType === 'app') {
              customValue = [
                data['moduleName'],
                t(data['moduleType']?.toUpperCase(), {count: 0}),
                data.workspaceName,
                data['daysSinceInstalled'],
              ]
            }

            return [
              1 + index,
              ...customValue,
              `${data['visitorVSstarted']}%`.replace('.', ','),
              data['SESSION'],
              `${data['startedVSsuccessful']}%`.replace('.', ','),
              `${data['startedVScontact']}%`.replace('.', ','),
              data['SUCCESSFULL-CONVERSATION'],
              `${data['visitorsVSsuccessful']}%`.replace('.', ','),
              `${data['visitorsVScontact']}%`.replace('.', ','),
              data['CONTACT']
            ]
        })
        ]
      }
    );
    // "\ufeff" formated utf-8 in csv file
    const csvData = new Blob(["\ufeff",csv], { type: 'text/csv;charset=utf-8;'});
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  }

  return (
    <>
      <PerformanceReportToolbar
        setSelected={setSelected}
        selected={selected}
        setDateRange={setDateRange}
        dateRange={dateRange}
        downloadReportCsv={downloadReportCsv}
        filter={workspaceFilter}
        setFilter={setWorkspaceFilter}
        appFilter={appFilter}
        setAppFilter={setAppFilter}
        reportType={reportType}
        setReportType={setReportType}
      />
      { loading ? <FallbackView /> :
        <ReportTable
          onSort={onSort}
          pageData={pageData}
          filterData={filterData}
          toggleSorting={toggleSorting}
          dateRange={selected}
          type={reportType === 'appType' ? 'app_type_report' : reportType === 'app' ? 'all_app_report' : 'customer_report'}
          activePage={activePage}
          setActivePage={setActivePage}
          workspaceFilter={workspaceFilter}
          appFilter={appFilter}
          settingObject={settingObject}
          reportType={reportType}
        />
      }
      <SettingsModal 
      settingObject={settingObject}
      setSettingObject={setSettingObject}
      settingType={settingType}
      setSettingType={setSettingType}
      settingColorStatus={settingColorStatus}
      setSettingColorStatus={setSettingColorStatus}
      performanceSettingHandler={performanceSettingHandler}
      />
    </>
  )
}