import {useEffect, useState} from 'react'
import {Handle} from 'react-flow-renderer'
import {HandleCss} from '../jscss/Css'
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js'

const style = {
  background: '#1C9EED',
  borderColor: 'black',
  color: 'white',
  padding: '5px',
  marginTop: '5px',
  marginBottom: '5px',
  borderRadius: '0px',
  // borderWidth: '1px',
  // borderStyle: 'solid',
}

export const Option = ({option, index}) => {
  const [el, setEl] = useState(null)

  useEffect(() => {
    if (el) new Tooltip(el)
  }, [option.text, el])

  return (
    <div
      ref={(e) => setEl(e)}
      style={style}
      key={`${index}`}
      data-bs-toggle='tooltip'
      data-bs-placement='top'
      title={option.text}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '150px',
          whiteSpace: 'nowrap',
        }}
      >
        {(option.emoji || '') + option.text}
      </div>
      <Handle
        type='source'
        position='right'
        id={`${index}`}
        style={Object.assign({top: `${82 + 34.5 * index}px`, height: '29px'}, HandleCss)}
      ></Handle>
    </div>
  )
}
