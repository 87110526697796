import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import Step1 from './steps/Step1'
import {observer} from 'mobx-react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useStore} from '../../../../store'
import { getModulePath } from '../../../../utils/module.utils'
import StepperBreadCrumb from './StepperBreadCrumb'
import {getLimitConfig, isWorkspaceReadonly} from '../../../../utils/utils'
import {getModuleCount, updateModule} from '../../../../service/module.service'
import _ from 'lodash'
import TextModal from '../../common/modal/TextModal'
import {Header} from '../../../../_metronic/layout/components/header/Header'
import ConfirmModal from '../../common/modal/ConfirmModal'
import AppPublishModal from '../../common/modal/AppPublishModal'
import { useSmartBarData } from './StepperContext'

const Stepper: FC<any> = ({
  from,
  moduleId,
  step,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const { smartBar, setSmartBar } = useSmartBarData()
  const {authStore} = useStore()
  const {
    setModuleAccessLimit,
    moduleAccessLimit: {publishCount},
  } = authStore
  const history = useHistory()
  // const [botSteps, setBotSteps]: any = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [isClear, setIsClear]: any = useState(false)
  const [type, setType]: any = useState('')
  const [showSuccessModal, setShowSuccessModal]: any = useState(false)

  const onSave = (publish?: string) => {
    const data: any = prepareData()
    if (smartBar?.moduleCode) {
      data.moduleCode = smartBar.moduleCode
      data.moduleCode.scriptVersion = 1
    }
    if (smartBar?.publishEvent?.length) data.publishEvent = smartBar.publishEvent
    setType(publish)
    data.content =
      publish === 'publish' ? JSON.stringify(smartBar?.moduleCode) : publish === 'unpublish' ? null : smartBar?.content
    data.status = publish === 'publish' ? 'active' : 'draft'
    if (isWorkspaceReadonly()) return
    updateModule(moduleId, data).then(() => {
      if (publish) setShowPublishModal(true)
      else setShowSuccessModal(true)
    })
  }

  const prepareData = () => {
    //TODO: change smartBar to module everywhere
    const {name, content, code}: any = smartBar
    return {name, content, code, type: from}  
  }

  const closeModal = async () => {
    setSmartBar({})
    history.push(`${getModulePath(from)}`)
  }

  const renderSave = () => (
    <div onClick={() => onSave()} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/icons/custom/save_icon.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('SAVE')}</p>
    </div>
  )

  const renderClear = () => (
    <div onClick={() => setShowConfirmModal(true)} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/stepper/prohibition.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('CLEAR')}</p>
    </div>
  )

  const onConfirm = () => {
    setIsClear(true)
  }

  const onPublish = (publishData: {time: Date, text: string}) => {
    // Do limit validation in separate and add comments
    if (!getLimitConfig('publish_app', publishCount)) {
      // Show error up if reached limit
      const publishEvent = smartBar?.publishEvent || []
      publishEvent.push(publishData)
      setSmartBar({...smartBar, publishEvent})
      onSave('publish')
    } else onSave()
  }

  const onUnpublish = () => onSave('unpublish')

  return (
    <>
      {showPublishModal ? (
        <TextModal
          message={t(`${type?.toUpperCase()}_COMPLETE_MODAL_TEXT`)}
          close={() => {
            setType('')
            setShowPublishModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      {showSuccessModal ? (
        <TextModal
          message={t('COMPLETE_MODAL_TEXT')}
          close={() => {
            setShowSuccessModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <StepperBreadCrumb data={{name: smartBar?.name}} />
      <div className='modal d-block' tabIndex={-1} id='kt_modal_22'>
        <div className='modal-dialog modal-fullscreen'>
          <div className='modal-content shadow-none'>
            <div
              id='kt_create_account_stepper'
              className='stepper stepper-links d-flex flex-column h-100'
            >
              <div className='d-flex align-items-center justify-content-between px-4'>
                <Header editable setName={(name: string) => setSmartBar({...smartBar, name})} />
                <div className='d-flex'>
                  {!isWorkspaceReadonly() && (
                    <div className='d-flex align-items-center pe-8'>
                      {renderSave()}
                      <div
                        onClick={async() => {
                          const moduleCount: any = await getModuleCount();
                          setModuleAccessLimit(moduleCount?.totalCount, moduleCount?.publishCount) 
                        }}
                        className={`cursor-pointer m-3 text-center ${
                          getLimitConfig('publish_app', publishCount) ? 'disabled' : ''
                        }`}
                        data-bs-toggle='modal'
                        data-bs-target='#kt_publish_event_modal'
                        title={`${
                          getLimitConfig('publish_app', publishCount) ? t('PUBLISH_TITLE') : ''
                        }`}
                      >
                        <KTSVG path='/media/stepper/cloud-upload.svg' className='svg-icon-2x' />
                        <p className='text-muted m-0 fw-bold fs-7'>{t('PUBLISH')}</p>
                      </div>
                      <div onClick={onUnpublish} className='cursor-pointer m-3 text-center'>
                        <KTSVG
                          path='/media/icons/custom/cloud_upload_icon.svg'
                          className='svg-icon-2x'
                        />
                        <p className='text-muted m-0 fw-bold fs-7'>{t('UNPUBLISH')}</p>
                      </div>
                    </div>
                  )}
                  <div className='d-flex align-items-center'>
                    {!isWorkspaceReadonly() && renderClear()}
                    <div onClick={closeModal} className='cursor-pointer m-3 text-center'>
                      <KTSVG className='svg-icon-2x' path='/media/icons/custom/close_icon.svg' />
                      <p className='text-muted m-0 fw-bold fs-7'>{t('CLOSE')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal-body`}
                style={{background: '#eff2f5'}}
              >
                {step === 'design' && (
                  <>
                    <Step1 />
                  </>
                )}
              </div>
              {showConfirmModal && (
                <ConfirmModal
                  message={t('SETTINGS_CONFIRM')}
                  show={showConfirmModal}
                  close={setShowConfirmModal}
                  onConfirm={onConfirm}
                />
              )}
              <AppPublishModal onPublish={onPublish} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Stepper)
