import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import clsx from 'clsx'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {useLayout} from '../../core'

type ChangeLogToolbarProps = {
  title: string
  onSearch?: any
}

const ChangeLogToolbar: FC<ChangeLogToolbarProps> = ({title, onSearch}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        style={{padding: '0px'}}
      >
        <div>
          <SectionHeading title={t(title, {count: 1})} subTitle={t(`${title}_SUBTITLE`)} />
        </div>

        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center position-relative mx-2'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              className='form-control form-control-solid w-300px ps-14 bg-white'
              placeholder={t('SEARCH')}
              onChange={onSearch}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeLogToolbar
