import {FC} from 'react'
import {useIntl} from 'react-intl'
import {isModulePermitted, isGroweoContentadmin} from '../../../utils/utils'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'

const DataPlatformBreadCrumb: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const contentAdmin = isGroweoContentadmin()

  const dataPlatformBreadCrumbs: Array<PageLink> = [
    {
      title: t('SESSION', {count: 1}),
      path: '/cdp/sessions',
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('SESSION') && !contentAdmin,
    },
    {
      title: t('CONTACT', {count: 1}),
      path: '/cdp/leads',
      isSeparator: false,
      isActive: true,
      isShow: isModulePermitted('CONTACT'),
    },
    {
      title: t('SEGMENT', {count: 1}),
      path: '/cdp/segments',
      isSeparator: false,
      isActive: false,
      isShow: isModulePermitted('SEGMENT'),
    },
    {
      title: t('LIST', {count: 1}),
      path: '/cdp/lists',
      isSeparator: false,
      isActive: false,
      isShow: isModulePermitted('LIST'),
    },
  ]
  const filteredData = dataPlatformBreadCrumbs.filter((b) => b.isShow)

  return <PageTitle breadcrumbs={filteredData}>{`${t('PLATFORM')}`}</PageTitle>
}

export default DataPlatformBreadCrumb
