import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import Template from '../modules/admin'
import Workspaces from '../pages/administration/Workspaces'
import Subscriptions from "../pages/administration/Subscriptions"
import PerformanceReport from "../pages/administration/PerformanceReport"
export function AdminRoutes() {
 return (
  <Suspense fallback={<FallbackView />}>
   <Switch>
    <Redirect from='/auth' to='/workspaces' />
    <Redirect exact from='/' to='/workspaces' />
    <Redirect from='/templates' to='/templates/bots' exact /> 
    <Route path='/templates' component={Template} />
    <Route path='/workspaces' component={Workspaces} />
    <Route path='/subscriptions' component={Subscriptions} />
    <Route path='/performance-reports' component={PerformanceReport} />
    <Redirect to='/' />
   </Switch>
  </Suspense>
 )
}