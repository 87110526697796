import React, {FC} from 'react'
import BotTree from '../../../bots/botCreate/botTree'
import botStore from '../../../bots/botCreate/botTree/store/botStore'
import EmailBuilderJs from '../../../emailTemplate/editor/EmailBuilderJs'
import FormPage from '../../../formBuilder/formCreate/FormPage'

type Step2Props = {
  formik?: any
  from: string
  templateId?: string
  template?: any
  setIsPreview?: any
  setIsClear?: any
  isClear?: boolean
  isPreview?: boolean
  setTemplate?: any
  botHistory?: any
  setBotHistory?: any
  moduleId: string
  isSmartEdge?: boolean
  setIssmartEdge?: any
  isShowNodeCounts?: boolean
  statsData? : any
}

const Step2: FC<Step2Props> = ({
  from,
  templateId,
  template,
  setIsPreview,
  setIsClear,
  isClear,
  isPreview,
  setTemplate,
  botHistory,
  setBotHistory,
  moduleId,
  isSmartEdge,
  setIssmartEdge,
  isShowNodeCounts,
  statsData,
}) => {
  return (
    <div>
      {from === 'bot' && (
        <BotTree
          store={botStore}
          spacing={120}
          template={template}
          moduleId={moduleId}
          setTemplate={setTemplate}
          botHistory={botHistory}
          setBotHistory={setBotHistory}
          isSmartEdge={isSmartEdge}
          setIssmartEdge={setIssmartEdge}
          isShowNodeCounts={isShowNodeCounts}
          statsData={statsData}
        />
      )}
      {['form', 'file'].includes(from) && (
        <FormPage
          from={from}
          templateId={templateId}
          template={template}
          setTemplate={setTemplate}
          setIsPreview={setIsPreview}
          setIsClear={setIsClear}
          isClear={isClear}
          isPreview={isPreview}
        />
      )}
      {['smartblock'].includes(from) && template?._id && (
        <EmailBuilderJs template={template} onChange={setTemplate} />
      )}
      {['smartpopup'].includes(from) && template?._id && (
        <EmailBuilderJs template={template} onChange={setTemplate} smartpopup={true}/>
      )}
    </div>
  )
}

export default Step2
