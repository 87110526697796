import axios from 'axios'
import {makeAutoObservable, runInAction} from 'mobx'
import {errorMessage, getGlobalItem, setGlobalItem} from '../utils/utils'

class AuthStore {
  user = {} || getGlobalItem('user')
  moduleAccessLimit = {totalCount: 0, publishCount: 0}

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getUser = () => {
    this.user = getGlobalItem('user')
  }

  setUser = (data) => {
    setGlobalItem('user', data)
    this.user = data
  }

  loginUser = async (user) => {
    runInAction(() => {
      this.rootStore.isLoading = true
    })
    try {
      const response = await axios.post(`/api/login`, user)
      this.user = response.data.data
      setGlobalItem('user', response.data.data)
      runInAction(() => {
        this.rootStore.setConfig({key: 'successMessage', type: 'Login'}, 'success', false)
      })
      document.location.replace('/')
    } catch ({response}) {
      this.rootStore.isLoading = false
      if (response?.data?.statusCode === 422) return errorMessage(response?.data?.message)
      this.rootStore.handleError(response)
    }
  }

  logoutUser = async () => {
    runInAction(() => {
      this.rootStore.isLoading = true
    })
    try {
      await axios.get(`/api/logout`)
      runInAction(() => {
        this.rootStore.setConfig({key: 'successMessage', type: 'Logout'}, 'success', false)
        this.rootStore.logout()
      })
    } catch ({response}) {
      this.rootStore.handleError(response)
    }
  }

  signupWorkspace = async (data) => {
    runInAction(() => {
      this.rootStore.isLoading = true
    })
    try {
      const response = await axios.post(`/api/signup`, data)
      console.log('create customer:', response)
      runInAction(() => {
        const config = {key: 'createMessage', type: 'Customer'}
        this.rootStore.setConfig(config, 'success', false)
      })
    } catch ({response}) {
      this.rootStore.handleError(response)
    }
  }

  setModuleAccessLimit = (totalCount, publishCount) => {
    this.moduleAccessLimit = {totalCount, publishCount}
  }

  resendTokenEmail = async (data) => {
    runInAction(() => {
      this.rootStore.isLoading = true
    })
    try {
      const response = await axios.post(`/api/resendToken`, {...data})
      return response?.data?.data?._id
     
    } catch ({response}) {
      this.rootStore.handleError(response)
    }
  }
}

export default AuthStore
