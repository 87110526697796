/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Picker, {SKIN_TONE_NEUTRAL} from 'emoji-picker-react'
import {Sortable} from '@shopify/draggable'
import { Editor } from '@tinymce/tinymce-react';
import {resetInteractive, TYPE_NAMES} from '../../../../../../utils/module.utils'
import ImageInputFeild from '../../../../../shared/ImageInputFeild'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {getFileData} from '../../../../../../utils/utils'
import {updateImage} from '../../../../../../service/module.service'
import { FallbackView } from '../../../../../../_metronic/partials'
import SelectInputFeild from '../../../../../shared/SelectInputFeild';
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components';
import {
  addLink,
  addNewOption,
  addSlide,
  getElements,
  removeLink,
  removeOption,
  removeSlide,
  updateOption,
} from '../../../../../../utils/template.utils'

export const SettingsFlyout = ({
  template,
  setTemplate,
  flyoutShown,
  setFlyoutShown,
  selectedElement,
  setSelectedElement,
  // updateBotElement,
}) => { 
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const [emojiPicker, setEmojiPicker] = useState({open: false, index: 0, type: ''})
  const [selectedSlide, setSelectedSlide] = useState()
  const [slideDropdownOpen, setSlideDropdownOpen] = useState(false)
  const [botCode, setBotCode] = useState({})
  const [flag, setFlag] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [toggleEditor, setToggleEditor] = useState(true)
  const [formFields, setFormFields] = useState([])
  const fieldTypes = [
    {value: 'text', text: t('TEXT_ONE_LINE')},
    {value: 'textarea', text: t('TEXT_BOX_MULTIPLE_LINE')},
    {value: 'email', text: t('EMAIL', {count: 0})},
    {value: 'number', text: t('NUMBER')},
    {value: 'date', text: t('DATE')},
    {value: 'password', text: t('PASSWORD')},
    {value: 'tel', text: t('PHONE_NUMBER')},
    {value: 'url', text: t('WEBSITE')},
    {value: 'radio', text: t('RADIO_BUTTON')},
    {value: 'checkbox', text: t('CHECKBOX')},
    {value: 'dropdwon', text: t('DROPDWON')},
    {value: 'file', text: t('FILE_UPLOAD')},
  ]

  const defaultField = [
    {type: 'date', name: 'birthday', label: t('BIRTHDAY') , require: false, showlabel:false},
    {type: 'text', name: 'company', label: t('COMPANY'), placeholder: t('COMPANY'), require: false, showlabel:false},
    {type: 'email', name: 'email', label: t('EMAIL', {count:0}), placeholder: t('EMAIL', {count:0}), require: false, showlabel:false},
    {type: 'text', name: 'name' , label: t('NAME'), placeholder: t('NAME'), require: false, showlabel:false},
    {type: 'tel', name: 'phone', label: t('PHONE_NUMBER'), placeholder: t('PHONE_NUMBER'), require: false, showlabel:false},
    {type: 'url', name: 'website', label: t('WEBSITE'), placeholder: t('WEBSITE'), require: false, showlabel:false},
    {type: 'text', name: 'zipcode', label: t('ZIPCODE'), placeholder: t('ZIPCODE'), require: false, showlabel:false},
    {type: 'text', name: '', label: '', placeholder: '', require: false, showlabel:false}
  ]

  useEffect(() => {
    const options = {
      draggable: '.draggable',
      handle: '.draggable .draggable-handle',
    }
    new Sortable(document.querySelectorAll('.draggable-zone-options'), options)
    new Sortable(document.querySelectorAll('.draggable-zone-links'), options)
    new Sortable(document.querySelectorAll('.draggable-zone-fields'), options)
    if (selectedElement?.type === 'form') 
      setFormFields(selectedElement?.data?.fields || [])
    if (selectedElement?.data?.slides?.length) setSelectedSlide(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // eslint-disable-next-line
  }, [selectedElement])

  useEffect(() => {
    if (template.botCode) setBotCode(template.botCode)
  }, [template.botCode])

  useEffect(() => {
    if(toggleEditor) return
    setToggleEditor(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlide])

  useEffect(() => {
    if (!flag) return
    //console.log('setTemplate in SettingsFlyout on flag change')
    setTemplate({...template, botCode}, true)
    setFlag(false)
    setFlyoutShown(false)
    // eslint-disable-next-line
  }, [flag])

  useEffect(() => {
    const foundIndex = botCode?.elements?.findIndex((el) => selectedElement?.id === el.id)
    if (!foundIndex || foundIndex === -1) return
    botCode.elements[foundIndex] = selectedElement
    setBotCode(botCode)
    // eslint-disable-next-line
  }, [selectedElement])

  const hideTypeDropDown = (e) => {
    const {nodeName, innerText} = e.target
    if (nodeName !== 'BUTTON' && emojiPicker.open && innerText !== '+')
      setEmojiPicker({open: false, index: 0, type: ''})
  }

  const onClose = async () => {
    const {type} = selectedElement
    if (['options', 'card', 'linkk', 'form'].includes(type)) {
      const updateSelectedElement = (key) => {
        const draggableZoneEl = document.getElementsByClassName(`draggable-zone-${key}`)
        if (draggableZoneEl.length) {
          const draggableEl = draggableZoneEl[0].getElementsByClassName('draggable')
          const clonedArr = Object.assign([], selectedElement.data[key])
          for (let index = 0; index < draggableEl.length; index++) {
            selectedElement.data[key][index] = clonedArr[draggableEl[index].dataset.index]
          }
        }
        if (type !== 'linkk' && type !== 'form') {
          selectedElement.data.options.forEach((item, index) => {
            if (item.edgeId) {
              const foundIndex = botCode.elements.findIndex((s) => s.id === item.edgeId)
              if (foundIndex !== -1) botCode.elements[foundIndex].sourceHandle = String(index)
            }
          })
        }
      }
      if (type === 'options' || type === 'card') updateSelectedElement('options')
      if (type === 'linkk' || type === 'card') updateSelectedElement('links')
      if (type === 'form') updateSelectedElement('fields')
    }

    setSelectedElement(selectedElement)
    resetInteractive(false)
    //console.log('selectedElement:', selectedElement)
    //console.log('botCode?.elements', botCode?.elements)
    const foundIndex = botCode?.elements?.findIndex((el) => selectedElement?.id === el.id)
    //console.log('foundIndex:', foundIndex)
    if (foundIndex !== -1) botCode.elements[foundIndex] = selectedElement
    const elements = getElements((es) => es.concat(), botCode.elements)
    setBotCode({...botCode, elements})
    // updateBotElement(botCode.elements[foundIndex])
    setFlag(true)    
  }

  const onFileUpload = async (e, el) => {
    if (!e.target.files.length) return
    setIsLoading(true)
    const data = await updateImage(template._id, getFileData(e.target.files))
    if (data) el.data.imageSource = data
    setIsLoading(false)
  }

  const onFileRemove = (el) => (el.data.imageSource = '')

  const onOptionUpdate = (element, index, data) => {
    const id = selectedElement?.type === 'carousel' ? selectedElement.id : element.id
    updateElement(updateOption(id, index, data, selectedSlide, botCode.elements))
    setEmojiPicker({open: false, index: 0, type: ''})
  }

  const onOptionAdd = (element) => {
    const id = selectedElement?.type === 'carousel' ? selectedElement.id : element.id
    updateElement(addNewOption(id, selectedSlide, botCode.elements))
  }

  const onOptionRemove = (element, index) => {
    const id = selectedElement?.type === 'carousel' ? selectedElement.id : element.id
    updateElement(removeOption(id, index, selectedSlide, botCode.elements))
  }

  const updateElement = (data) => {
    if (!data) return
    const {foundIndex, elements} = data
    setBotCode({...botCode, elements})
    if (foundIndex) setSelectedElement(elements[foundIndex])
  }

  const renderInput = (Label, name, fieldIndex, value, type, checked, required) => (
    <div className='m-2 p-0'>
        {
          type!=='checkbox' ?
        <>
        <div className='d-flex justify-content-between'>
          <label>{Label}</label>
          {Label === t('FIELD_LABEL') && 
          <label htmlFor={`showlabel${fieldIndex}`} className='d-flex align-items-center'>
          <input type="checkbox" className="form-check m-0 mx-2" name='showlabel' id={`showlabel${fieldIndex}`} onChange={(e) => onChangeInput(e, fieldIndex)} checked={checked}/>
          {t('SHOW_LABEL')}
          </label>}
        </div> 
        <input
          type={type || "text"}
          className="form-control"
          name={name}
          value={value}
          required={required}  
          onChange={(e) => onChangeInput(e, fieldIndex)}
        />
        </>
        : 
        <div className='d-flex justify-content-between'>
        <label className='d-flex align-items-center' htmlFor={name + fieldIndex}>
        <input type="checkbox" className="form-check m-0" name={name} id={name + fieldIndex} onChange={(e) => onChangeInput(e, fieldIndex)} checked={checked}></input>
        <span className='mx-2'>{Label}</span></label>
        </div>
        }
    </div>
    );

  const renderTextArea = (Label, name, fieldIndex, value) => (
    <div className="m-2 p-0">
    <div className='d-flex justify-content-between'><label>{Label}</label></div>
    <textarea className="form-control" style={{height: "100px"}} name={name} value={value} onChange={(e) => onChangeInput(e, fieldIndex)}></textarea>
    </div>
    )

  const onChangeInput = (e, fieldIndex) => {
    const {target} = e
    const currentFieldObj = formFields[fieldIndex]
    const fieldValue = !['require','showlabel'].includes(target.name) ? target.value : target.checked   
    formFields.splice(fieldIndex, 1, {...currentFieldObj, [target.name]: fieldValue})
    selectedElement.data['fields'] = formFields
    setSelectedElement({...selectedElement})
  };

  const onChnageType = (selectedType, fieldIndex) =>{
    if(!selectedType) return;
    formFields.splice(fieldIndex, 1, {type: selectedType})
    selectedElement.data['fields'] = formFields
    setSelectedElement({...selectedElement})
  }

  const header = () => {
    const bgType = selectedElement?.data?.start ? 'start' : selectedElement?.type
    return (
      <div
        className={`card-header align-items-center position-fixed select-node-${bgType}`}
        style={{zIndex: 1000, minHeight: 63, width: '30%'}}
      >
        <div className='card-title'>
          <h3 className='text-white'>
            {selectedElement?.data?.name || TYPE_NAMES[selectedElement?.type]}
          </h3>
        </div>
        <div className='card-toolbar'>
          <button
            type='button'
            disabled={isLoading}
            className='btn btn-sm btn-light'
            onClick={onClose}
          >
            X
          </button>
        </div>
      </div>
    )
  }

  const renderCardHeader = (header, subTitle) => (
    <div className='card-header'>
      <div className='card-title align-items-start flex-column'>
        <span className='card-label fw-bolder fs-3'>{header}</span>
        {subTitle && <span className='text-muted fw-bold fs-7 mt-1'>{subTitle}</span>}
      </div>
      <div className='d-flex align-items-center'>
        {header === t('FIELD', {count: 1}) && 
        <div className='card-toolbar m-0'>
          <button
            className='btn btn-primary btn-sm'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            {t('ADD_NEW_FIELD')}
            <KTSVG
              path='/media/icons/duotune/arrows/arr072.svg'
              className='svg-icon svg-icon-5 m-0'
            />
          </button>

          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4 d-flex'>
                {t('FIELD', {count: 1})}
              </div>
            </div>
            <div className='separator mb-2 opacity-75'></div>
            { defaultField.map((field, index) => (
              <div key={`dropdwon${index}`}>
              {!field.label && <div className='separator mb-2 opacity-75'></div>}
              <div className='menu-item px-3'>
              <a
                onClick={() => {
                  formFields.push(field)
                  setFormFields([...formFields])
                  selectedElement.data['fields'] = formFields
                  setSelectedElement({...selectedElement})
                  }}
                className='menu-link px-3'
              >
                {field.label || t('ADD_NEW_FIELD')}
              </a>
              </div>
              </div>
            ))}
          </div>
        </div>}
      </div>
    </div>
  )

  const renderLabel = (label) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{label}</label>
  )

  const base = (h, element, slidesHeader) => (
    <div className='card w-100 h-100'>
      {h !== undefined && h}
      <div className='card-body bg-gray-100' style={{marginTop: 63}}>
        <div className='card card-flush my-4'>
          {renderCardHeader(t('STEP_HEADER'), t('STEP_SUB_TITLE'))}
          <div className='card-body text-center pt-0'>
            <div className='fv-row'>
              <input
                className='form-control form-control-lg form-control-solid'
                value={selectedElement.data.name || ''}
                placeholder={t('STEP_HEADER')}
                onChange={(ev) => {
                  const el = {
                    ...selectedElement,
                    data: {...selectedElement.data, name: ev.target.value},
                  }
                  setSelectedElement(el)
                }}
              />
            </div>
            {selectedElement?.type !== 'end' && <div className='fv-row'>
              <label className='form-check form-check-sm form-check-custom form-check-solid m-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={selectedElement.data.markAsComplete || false}
                  onChange={(ev) => {
                    const el = {
                      ...selectedElement,
                      data: {...selectedElement.data, markAsComplete: ev.target.checked},
                    }
                    setSelectedElement(el)
                  }}
                />
                <span className='form-check-label'>{t('SUCCESSFUL_COVERSATION')}</span>
              </label>
            </div>}
            {selectedElement?.type === 'carousel' ? <div className='fv-row mb-2'>
                {renderLabel(t('SLIDES_TO_SHOW'))}
                <input
                  type={'number'}
                  className='form-control form-control-lg form-control-solid'
                  value={selectedElement.data.slidesToShow || ''}
                  onChange={(ev) => {
                    selectedElement.data.slidesToShow = ev.target.value
                    setSelectedElement({...selectedElement})
                  }}
                />
              </div> : ''}
            {selectedElement?.type === 'carousel' && <div className='fv-row'>
              <label className='form-check form-check-sm form-check-custom form-check-solid m-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  defaultChecked={true}
                  checked={selectedElement.data.infinite}
                  onChange={(ev) => {
                    const el = {
                      ...selectedElement,
                      data: {...selectedElement.data, infinite: ev.target.checked},
                    }
                    setSelectedElement(el)
                  }}
                />
                <span className='form-check-label'>{t('INFINITE')}</span>
              </label>
            </div>}       
          </div>
        </div>

        {slidesHeader && (
          <div className='card card-flush my-4'>
            {renderCardHeader(t('SLIDE_HEADER'), t('SLIDE_SUB_TITLE'))}
            <div className='card-body text-center pt-0'>
              {slidesHeader}
            </div>
          </div>
        )}
        {element && (
          <>
            {(element.type === 'card' || element.type === 'carousel') && (
              <div className='card card-flush my-4'>
                {renderCardHeader(t('THUMBNAIL_HEADER'), t('THUMBNAIL_SUB_TITLE'))}
                <div className='card-body text-center pt-0'>
                  <ImageInputFeild
                    type='square'
                    url={element.data.imageSource}
                    element={element}
                    onUpload={onFileUpload}
                    onRemove={onFileRemove}
                  />
                </div>
              </div>
            )}

            <div className='card card-flush my-4'>
              {element.type === 'form' ? renderCardHeader(t('FIELD', {count: 1}), t('FIELD_SUBTITLE')) : renderCardHeader(t('DETAILS_HEADER'), t('DETAILS_SUB_TITLE'))}
              <div className='card-body text-center pt-0'>
                {['card', 'linkk', 'carousel'].includes(element.type) && (
                  <div className='fv-row mb-2'>
                    {renderLabel(t('CARD_TITLE'))}
                    <input
                      className='form-control form-control-lg form-control-solid'
                      value={element.data.cardTitle || ''}
                      onChange={(ev) => {
                        element.data.cardTitle = ev.target.value
                        setSelectedElement(!slidesHeader ? {...element} : {...selectedElement})
                      }}
                    />
                  </div>
                )}
                {['card'].includes(element.type) && selectedElement?.type !== 'carousel' ? (
                  <div className='fv-row mb-2'>
                    {renderLabel(t('CARD_WIDTH'))}
                    <input
                      className='form-control form-control-lg form-control-solid'
                      value={element.data.cardWidth || ''}
                      onChange={(ev) => {
                        element.data.cardWidth = ev.target.value
                        setSelectedElement(!slidesHeader ? {...element} : {...selectedElement})
                      }}
                    />
                  </div>                  
                ) : ''}
                {element.type === 'form' && (
                  <div className='fv-row mb-2'>
                        <div className='accordion' id='kt_accordionForm'>
                            <div className='draggable-zone-fields'>
                            {formFields.map((field, fieldIndex) => (
                            <div className='draggable mb-4 d-flex align-items-center' data-index={fieldIndex} data key={'Accordion-' + fieldIndex}>
                            <div className='accordion-item rounded-1 m-0 w-100' key={fieldIndex}>
                              <div className='accordion-button fs-6 fw-bolder p-0 px-4 border rounded-1 clickable collapsed'  data-bs-toggle='collapse' data-bs-target={`#kt_accordion_${fieldIndex}_body_${fieldIndex}`} aria-expanded='false' aria-controls={`kt_accordion_${fieldIndex}_body_${fieldIndex}`}>
                                  <h2 className="accordion-header m-0 form-control-lg d-flex align-items-center" id={`kt_accordion_${fieldIndex}_header_${fieldIndex}`}>
                                    <label className='field-label'>{field.label || t('FIELD', {count: 0})}</label>
                                  </h2>
                              </div>
                              <div
                                  id={`kt_accordion_${fieldIndex}_body_${fieldIndex}`}
                                  className='accordion-collapse collapse'
                                  aria-labelledby={`kt_accordion_${fieldIndex}_header_${fieldIndex}`}
                                  data-bs-parent='#kt_accordionForm'
                                  >
                                  <div className='accordion-body'>
                                        <div className="text-left m-2 p-0">
                                          <label>
                                          {t('FIELD_TYPE')}
                                          </label>
                                          <SelectInputFeild
                                            name='language'
                                            value={field?.type}
                                            options={fieldTypes}
                                            onChange={(name, value) => onChnageType(value, fieldIndex)}
                                          />
                                        </div>
                                        {renderInput(t("FIELD_NAME"), "name", fieldIndex, field?.name, "", "", true)}
                                        {renderInput(t("FIELD_LABEL"), "label", fieldIndex, field?.label,"", field?.showlabel, true)}
                                        {!['checkbox', 'dropdwon', 'hidden', 'file', 'radio', 'date'].includes(field?.type) && renderInput(t("PLACEHOLDER_TEXT"), "placeholder", fieldIndex, field?.placeholder)}
                                        {['checkbox', 'dropdwon', 'radio'].includes(field?.type) && renderTextArea(t("OPTIONS_ONE_PER_LINE"), "options", fieldIndex, field?.options)}
                                        {/* {['hidden'].includes(field?.type) && renderInput("Hidden value", "hiddenvalue", fieldIndex, obj.hiddenvalue)} */}
                                        {!['hidden'].includes(field?.type) && renderInput(t("REQUIRED"), "require", fieldIndex, "", "checkbox", field?.require)}
                                </div>
                              </div>
                            </div><button type='button' 
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2' 
                              name='delete' 
                              onClick={() => {
                                formFields.splice(fieldIndex, 1)  
                                selectedElement.data['fields'] = formFields
                                setSelectedElement({...selectedElement})
                              }}>
                                <KTSVG 
                                  path='/media/icons/duotune/general/gen027.svg' 
                                  className='svg-icon-3' 
                                />
                              </button>
                              <button className='btn btn-sm btn-icon btn-active-light-primary draggable-handle'>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr032.svg'
                                  className='svg-icon-2x svg-icon-success'
                                />
                              </button>
                              </div>
                            )
                            )}
                            </div>
                        </div>
                  </div>
                )}
                {element.type !== 'link' &&  element.type !== 'form' && (
                  <div className='fv-row mb-2'>
                    {renderLabel(t('BOT_TEXT'))}
                    {toggleEditor &&
                    <Editor
                      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                      initialValue={element.data.text}
                      icons= 'small'
                      init={{
                        // height: 300,
                        min_height: 200,
                        menubar: false,
                        // inline: true,
                        // statusbar: false, // hide status bar
                        branding: false, // hide only brand logo
                        setup: function(editor) {
                          editor.on('blur', function(e) {
                            element.data.text = editor.getContent()
                            setSelectedElement(!slidesHeader ? {...element} : {...selectedElement})
                          });
                        },
                        //display all buttons
                        toolbar: ["blocks | undo redo | bold italic | lists charmap |link image | bullist numlist | outdent indent | blockquote subscript superscript | alignleft aligncenter alignright alignjustify | preview  code"],
                        //wrap buttons
                        // toolbar:"blocks | undo redo | bold italic | lists charmap | link image | bullist numlist | outdent indent | alignleft aligncenter alignright alignjustify | blockquote subscript superscript | preview  code ", 
                        plugins : "link image lists charmap preview code autoresize",
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />}
                  </div>
                )}
              </div>
            </div>
            {element.type === 'form' && (
              <div className='card card-flush my-4'>
                {renderCardHeader(t('SUBMIT_BUTTON', {count: 0}), t('SUBMIT_BUTTON_SUBTITLE'))}
                <div className='card-body text-center pt-0'>
                <div className='fv-row'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    placeholder={t('SUBMIT_BUTTON_PLACEHOLDER')}
                    value={selectedElement.data.submitButtonText}
                    onChange={(ev) => {
                        element.data.submitButtonText = ev.target.value
                        setSelectedElement({...selectedElement})
                      }
                    }
                  />
                </div>
                </div>
              </div>
            )}
           { /* BEGINING GTM SWITCH */ }
            { /* ['inputt', 'options'].includes(element.type) && (
                <div className='card card-flush my-4'>
                  {renderCardHeader(t('GTM_EVENT_CONFIGURATION'))}
                  <div className='card-body pt-0'>
                    <div className='fv-row mb-2'>
                      <label className='form-check form-check-custom form-check-solid form-switch m-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='layout-builder'
                          checked={!!element.data.gtmEventName}
                          style={{height: '1.75rem'}}
                          onChange={(ev) => {
                            if (ev.target.checked) {
                              element.data.gtmEventName = `GTM-${uuid.v4()}`;
                            } else {
                              element.data.gtmEventName = '';
                            }
                            setSelectedElement(!slidesHeader ? {...element} : {...selectedElement})
                          }}
                        />
                        <span className='form-check-label text-muted mx-3'>{t('ENABLE_GTM_EVENT')}</span>
                      </label>
                      {!!element.data.gtmEventName ? renderLabel(t('GTM_EVENT_NAME')) : ''}
                      {!!element.data.gtmEventName ? <div className='d-flex flex-row'>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          value={element.data.gtmEventName || ''}
                          onChange={(ev) => {
                            element.data.gtmEventName = ev.target.value
                            setSelectedElement(!slidesHeader ? {...element} : {...selectedElement})
                          }}
                        />
                        <div className='btn btn-success' onClick={() => navigator.clipboard.writeText(element.data.gtmEventName) }>{t('COPY')}</div>
                      </div> : ''}
                    </div>
                  </div>
                </div>
                        ) */}
                { /* END GTM SWITCH */ }
            {['card', 'linkk'].includes(element.type) && (
              <div className='card card-flush my-4'>
                {renderCardHeader(t('LINKS_HEADER'), t('LINKS_SUB_TITLE'))}
                <div className='card-body pt-0'>
                  {emojiPicker.open && emojiPicker.type === 'links' && (
                    <Picker
                      onEmojiClick={(_e, {emoji}) =>
                        onOptionUpdate(element, emojiPicker.index, {key: 'emoji', value: emoji})
                      }
                      skinTone={SKIN_TONE_NEUTRAL}
                    />
                  )}
                  <div className='draggable-zone-links'>
                    {element.data.links &&
                      element.data.links.map((link, index) => (
                        <div className='draggable' data-index={index} key={'link-' + index}>
                          <div className='d-flex flex-stack mb-3'>
                            {element.type === 'card' && (
                              <button
                                type='button'
                                className='btn btn-sm btn-secondary me-2'
                                onClick={() => setEmojiPicker({open: true, index, type: 'links'})}
                              >
                                {link.emoji ? link.emoji : '+'}
                              </button>
                            )}
                            <input
                              value={link.text}
                              onChange={(ev) => {
                                link.text = ev.target.value
                                setSelectedElement(
                                  !slidesHeader ? {...element} : {...selectedElement}
                                )
                              }}
                              className='form-control'
                              placeholder={t('LINK_TEXT')}
                            />
                            <input
                              value={link.destination}
                              onChange={(ev) => {
                                link.destination = ev.target.value
                                setSelectedElement(
                                  !slidesHeader ? {...element} : {...selectedElement}
                                )
                              }}
                              className='form-control mx-2'
                              placeholder={t('LINK_DESTINATION')}
                            />
                            <button
                              type='button'
                              className='btn btn-sm btn-primary'
                              onClick={() => {
                                const elements = selectedElement?.type === "carousel"
                                  ? removeLink(selectedElement.id, index, botCode.elements, selectedSlide)
                                  : removeLink(element.id, index, botCode.elements)
                                setBotCode({...botCode, elements})
                              }}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                      addLink(element)
                      setSelectedElement(!slidesHeader ? {...element} : {...selectedElement})
                    }}
                  >
                    {t('ADD_LINK')}
                  </button>
                </div>
              </div>
            )}

            {['card', 'linkk', 'options', 'carousel'].includes(element.type) && (
              <div className='card card-flush my-4'>
                {['options'].includes(element.type)
                  ? renderCardHeader(t('CHOICES_HEADER'), t('CHOICES_SUB_TITLE'))
                  : renderCardHeader('Next Step', 'Defines next action')}
                <div className='card-body pt-0'>
                  {emojiPicker.open && emojiPicker.type === 'options' && (
                    <Picker
                      onEmojiClick={(_e, {emoji}) =>
                        onOptionUpdate(element, emojiPicker.index, {key: 'emoji', value: emoji})
                      }
                      skinTone={SKIN_TONE_NEUTRAL}
                    />
                  )}
                  <div className='draggable-zone-options'>
                    {element?.data?.options?.map((option, index) => (
                      <div className='draggable mb-4' data-index={index} key={'option-' + index}>
                        <div className='d-flex flex-stack'>
                          <button
                            type='button'
                            className='btn btn-sm btn-secondary'
                            onClick={() => setEmojiPicker({open: true, index, type: 'options'})}
                          >
                            {option.emoji ? option.emoji : '+'}
                          </button>
                          <input
                            className='form-control mx-2'
                            value={option.text}
                            onChange={(e) =>
                              onOptionUpdate(element, index, {key: 'text', value: e.target.value})
                            }
                          />
                          <div className='justify-content-end d-flex '>
                            <button
                              type='button'
                              className='btn btn-sm btn-primary'
                              onClick={() => onOptionRemove(element, index)}
                            >
                              X
                            </button>
                            {['options'].includes(element.type) && (
                              <button className='btn btn-sm btn-icon btn-active-light-primary draggable-handle'>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr032.svg'
                                  className='svg-icon-2x svg-icon-success'
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    {(['options'].includes(element.type) ||
                      (element.type !== 'options' && !element.data?.options?.length)) && (
                      <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        onClick={() => onOptionAdd(element)}
                      >
                        {t('ADD_CHOICE')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )

  let main = base(header(), selectedElement)

  const carouselHeader = (
    <div className='d-flex align-items-center justify-content-between'>
      <button
        type='button'
        className='btn btn-sm btn-primary'
        onClick={() => {
          const {foundIndex, elements} = addSlide(selectedElement.id, botCode.elements)
          updateElement({foundIndex, elements})
          const foundItem = elements?.find((el) => selectedElement?.id === el.id)
          if (foundItem){
            setToggleEditor(false)
            setSelectedSlide(foundItem.data.slides.length - 1)
          } 
        }}
      >
        {t('ADD_SLIDE')}
      </button>
      {selectedElement?.data?.slides?.length ? (
        <div className='dropdown d-flex align-items-center'>
          <div className='d-flex'>
            <div>
              <button
                onClick={() => setSlideDropdownOpen(true)}
                className='btn btn-secondary dropdown-toggle text-capitalize m-1'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                {t('SLIDE_NO')} {selectedSlide + 1}
              </button>
              <div
                style={{display: slideDropdownOpen ? 'block' : 'none'}}
                className='dropdown-menu'
                aria-labelledby='dropdownMenuButton'
              >
                {selectedElement.data.slides.map((_sld, index) => (
                  <a
                    className='dropdown-item'
                    key={`a-${index}`}
                    onClick={() => {
                      selectedSlide !== index && setToggleEditor(false)
                      setSelectedSlide(index)
                      setSlideDropdownOpen(false)
                    }}
                  >
                    {index + 1}
                  </a>
                ))}
              </div>
            </div>
            {selectedElement?.data?.slides?.length && (
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={() => {
                  updateElement(removeSlide(selectedElement.id, selectedSlide, botCode.elements))
                  setSelectedSlide(0)
                }}
              >
                X
              </button>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )

  if (selectedElement?.type === 'carousel') {
    main = (
      <div className='card w-100'>
        {header()}
        {base(null, selectedElement.data.slides[selectedSlide], carouselHeader)}
      </div>
    )
  }

  return (
    <>
      {selectedElement && (
        <div
          onClick={hideTypeDropDown}
          className={`pe-auto drawer drawer-end ${flyoutShown ? 'drawer-on' : ''}`}
          style={{width: '30%'}}
        >
          {main}
          {isLoading && <FallbackView />}
        </div>
      )}
    </>
  )
}
