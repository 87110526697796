/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'

type Props = {
  setShowOptionModal?: any
  setSelectedOption?: any
  option?: any
  addOptionEdge?: any
  selectedOption?: any
}

const OptionModal: FC<Props> = ({
  setShowOptionModal,
  option,
  setSelectedOption,
  addOptionEdge,
  selectedOption
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  return (
    <>
      <div
        className='modal fade show d-block'
        tabIndex={-1}
        id='kt_flow_option_modal'
        style={{background: 'rgba(0,0,0,.4)'}}
      >
        <div className='modal-dialog modal-dialog-centered mw-350px'>
          <div className='modal-content'>
            <div className='modal-header py-4 px-7'>
              <h4 className='modal-title'>{t('OPTION', {count: 1})}</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={() => setShowOptionModal(false)}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              {option?.map((opt: any, idx: any) => (
                <label
                  className={`fs-4 fw-semibold mb-3 d-flex align-items-center ${
                    opt?.checked ? 'text-muted' : ''
                  }`}
                >
                  <input
                    type={'checkbox'}
                    className='form-check-input mx-3'
                    name={'option'}
                    checked={opt?.checked}
                    disabled={opt?.checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        selectedOption.push(opt)
                        setSelectedOption(selectedOption)
                      } else {
                        const findIndex = selectedOption?.findIndex(
                          (sltOpt: any) => JSON.stringify(sltOpt) === JSON.stringify(opt)
                        )
                        if (findIndex === -1) return
                        selectedOption.splice(findIndex, 1)
                        setSelectedOption(selectedOption)
                      }
                    }}
                  />
                  {opt?.title}
                </label>
              ))}

              <div className='fv-row mt-10 mb-2'>
                <div className='text-end '>
                  <button
                    type='submit'
                    className='c-button c-button--type-solid'
                    onClick={() => addOptionEdge()}
                  >
                    <span className='indicator-label'>
                      {t('SUBMIT')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OptionModal
