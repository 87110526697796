/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, FC, useCallback, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDropzone} from 'react-dropzone'
import '@yaireo/tagify/dist/tagify.css'
import {downloadFile, getFileData} from '../../../../../utils/utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import ConfirmModal from '../../modal/ConfirmModal'
import {updateImage} from '../../../../../service/module.service'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import EmailTemplateCard from '../../shared/card/EmailTemplateCard'
import { getCustomMailTemplateFields } from '../../../../../utils/module.utils'
import { campaignEmailTemplate } from '../../../../../utils/campaign.utils'

type Step6Props = {
  template?: any
  setTemplate?: any
}

const Step6: FC<Step6Props> = ({template, setTemplate}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [files, setFiles] = useState([])
  const [fileList, setFileList] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  useEffect(() => {
    if (files.length) {
      onFileUpload(files)
      setFiles([])
    }
    // eslint-disable-next-line
  }, [files])

  const reinitializMenu = () => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }

  useEffect(() => {
    setFileList(template?.documents)
    reinitializMenu()
  }, [template?.documents])

  const onFileUpload = async (files: any) => {
    const data: string = await updateImage(template?._id, getFileData(files))
    if (data) {
      const documents: any = template.documents || []
      const uploadData = {
        url: data,
        sendAsAttach: true,
      }
      documents.push(uploadData)
      saveDocument(documents)
    }
  }

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles(acceptedFiles)
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  const fileExtention = (url: string) => {
    const ext: any = url?.split('.').pop()
    return ['ai', 'css', 'pdf', 'sql', 'tif', 'xml'].includes(ext) ? ext : 'folder-document'
  }

  const onDelete = () => {
    if (!deleteId) return
    const documents: any = template.documents || []
    const index = documents.indexOf(deleteId)
    if (index > -1) documents.splice(index, 1) // 2nd parameter means remove one item only
    saveDocument(documents)
  }

  const sendAsAttachment = (check: boolean, file: any) => {
    const documents: any = template.documents || []
    const index = documents.indexOf(file)
    if (index > -1) documents[index].sendAsAttach = check
    saveDocument(documents)
  }

  const saveDocument = (documents: any) => {
    setTemplate({...template, documents})
    reinitializMenu()
  }

  const showDeleteModel = async (url: string) => {
    setDeleteId(url)
    setShowConfirmModal(true)
  }

  const customFields = getCustomMailTemplateFields(template?.code)

  return (
    <div className='form d-flex flex-column flex-lg-row gap-7 gap-lg-10'>
      <div className='d-flex flex-column gap-7 gap-lg-5 w-100 w-lg-300px'>
        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>{t('DOCUMENTS')}</h3>
            </div>
            <div className='card-body px-0'>
              <div className='h-100 text-center bg-light-primary border-primary border border-dashed p-8'>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label className='simple-file-label'>
                    <img src='/media/svg/files/upload.svg' className='mb-5' alt='' />
                    <a className='text-hover-primary fs-5 fw-bolder mb-2 cursor-pointer'>
                      {t('FILE_UPLOAD')}
                    </a>
                    <div className='fs-7 fw-bold text-gray-400'>{t('DRAG_DROP')}</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='d-flex flex-column g-6 g-xl-9 mb-6 mb-xl-9 overflow-auto'
          style={{height: '57vh'}}
        >
          {fileList?.map((l: any, index: number) => (
            <div className='card card-flush my-4' key={`file_list_${index}`}>
              <div className='card-toolbar d-flex justify-content-between'>
                <div>
                  <label className='form-check form-check-sm form-check-custom form-check-solid m-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={l?.sendAsAttach}
                      onChange={(e) => sendAsAttachment(e.target.checked, l)}
                    />
                    <span className='form-check-label'>{t('SEND_AS_ATTACHMENT')}</span>
                  </label>
                </div>
                <div className='card-toolbar m-0'>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen024.svg'
                      className='svg-icon-3 svg-icon-primary'
                    />
                  </button>

                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                        {t('SHORTCUT_ACTION')}
                      </div>
                    </div>
                    <div className='separator mb-3 opacity-75'></div>

                    <div className='menu-item px-3'>
                      <a
                        onClick={() => navigator?.clipboard?.writeText(l?.url)}
                        className='menu-link px-3'
                      >
                        {t('COPY')}
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title={`${t('COPY_FILE')}`}
                        ></i>
                      </a>
                    </div>
                    <div className='menu-item px-3'>
                      <a onClick={() => downloadFile(l?.url)} className='menu-link px-3'>
                        {t('FILE_DOWNLOAD')}
                      </a>
                    </div>
                    <div className='menu-item px-3'>
                      <a onClick={() => showDeleteModel(l)} className='menu-link px-3'>
                        {t('DELETE')}
                      </a>
                    </div>
                    <div className='separator mt-3 opacity-75'></div>
                  </div>
                </div>
              </div>
              <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                <a
                  href={l?.url}
                  className='text-gray-800 text-hover-primary d-flex flex-column'
                  download
                >
                  <div className='symbol symbol-60px mb-5'>
                    <img src={`/media/svg/files/${fileExtention(l?.url)}.svg`} alt='' />
                  </div>
                  <div className='fs-5 fw-bolder mb-2'>{l?.url.split('/').pop()}</div>
                </a>
              </div>
            </div>
          ))}
        </div>
        {showConfirmModal && (
          <ConfirmModal
            icon={''}
            show={showConfirmModal}
            close={setShowConfirmModal}
            onConfirm={onDelete}
          />
        )}
      </div>
      <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
        <EmailTemplateCard
          template={template}
          emailTemplate={template.emailTemplate || campaignEmailTemplate}
          onEmailTemplateSave={(values: any) => {
            setTemplate({...template, emailTemplate: { ...values }})
          }}
          customFields={customFields}
        />
      </div>
    </div>
  )
}

export default Step6
