import React from 'react'
import clsx from 'clsx'

const TextareaInputFeild = (props: any) => {
  const valid = (key: string) => {
    let name = props?.formik[key]
    props.name.split('.').forEach((item: any) => (name = name?.[item]))
    return name
  }

  const onChange = (e: any) => {
    const {name, value} = e.target
    props.onChange(name, value)
  }

  return (
    <>
      {props?.formik ? (
        <>
          <textarea
            placeholder={props.placeholder}
            {...props?.formik?.getFieldProps(props.name)}
            className={clsx(
              `form-control form-control-lg form-control-solid`,
              {'is-invalid': valid('touched') && valid('errors')},
              {
                'is-valid': valid('touched') && !valid('errors'),
              }
            )}
            name={props.name}
            required={props?.required}
            autoComplete='off'
            disabled={props?.disabled}
            onBlur={props?.onBlur}
            style={props?.style}
          />
          {valid('touched') && valid('errors') && (
            <div className='text-danger'>{valid('errors')}</div>
          )}
        </>
      ) : (
        <textarea
          className={`form-control form-control-lg form-control-solid`}
          style={props?.style}
          placeholder={props.placeholder}
          name={props.name}
          required={props.required}
          autoComplete='off'
          disabled={props.disabled}
          value={props.value}
          onBlur={props.onBlur}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default TextareaInputFeild
