import LibrariesBreadCrumb from './LibrariesBreadCrumb'
import LibraryIndex from '../../modules/libraries';

const Libraries = () => {
  return (
    <>
      <LibrariesBreadCrumb />
      <LibraryIndex />
    </>
  )
}

export default Libraries
