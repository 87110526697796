/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import ChangeLogList from './ChangeLogList'

const ChangeLogIndex: FC = () => {
  return (
    <Switch>
      <Route path='/changelog' component={ChangeLogList} exact />
    </Switch>
  )
}

export default ChangeLogIndex
