/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Login from './components/Login'
import NewPasswordSet from './components/NewPasswordSet'
import ForgetPassword from './components/ForgetPassword'
import {useIntl} from 'react-intl'


export function AuthPage() {
	const intl = useIntl()
	const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (

    <div className="d-flex flex-column flex-lg-row flex-column-fluid" style={{ backgroundColor: '#E83842' }} >
				{/* begin::Aside */}
				<div className="d-flex flex-column flex-lg-row-auto positon-xl-relative" style={{ flex: '1' ,alignItems: 'center'}} >
					{/* begin::Wrapper */}
					<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 "  >
						{/* begin::Content */}
						<div className="d-flex flex-row-fluid flex-column text-center pt-lg-10 pt-lg-20" style={{ marginTop: '30px' }}>
              	{/* begin::Title */}
							<h1 className="fw-bolder fs-2qx pb-lg-5 pb-lg-10" style={{ color: 'white'}}></h1>
							{/* end::Title */}
              {/* begin::Logo */}
							<a href="#" className="py-12 my-5">
								<img alt="Logo" src={toAbsoluteUrl('/media/logos/groweo_logo.svg')}  className="h-60px" />
							</a>
							{/* end::Logo */}
						
							{/* begin::Description */}
							<p className="fw-bold fs-2" style={{ color: 'white'}}>
			        </p>
							{/* end::Description */}
						</div>
						{/* end::Content */}
						{/* begin::Illustration */}
						<div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-400px"
						/*  style={{'backgroundImage':'url(/media/illustrations/sketchy-1/1.png)'}} */
						 ></div>
						{/* end::Illustration */}
					</div>
					{/* end::Wrapper */}
				</div>
				{/* end::Aside */}
				{/* begin::Body */}
				<div className="d-flex flex-column flex-lg-row-fluid pt-lg-10 pt-lg-20">
				<h1 className="fw-bolder fs-2qx pb-lg-5 pb-lg-10" style={{ color: 'white'}}></h1>
					{/* begin::Content */}
					<div className="d-flex flex-column ">
						{/* begin::Wrapper */}
						<div className="w-lg-500px p-10 p-lg-15">
								<Switch>
								<Route exact path='/auth/login' render={() => <Login />} />
								<Route exact path='/auth/forgetpassword' render={() => <ForgetPassword />} />
								<Route exact path='/auth/password/:token' render={() => <NewPasswordSet />} />
								<Redirect to='/auth/login' />
							</Switch>
						</div>
						{/* end::Wrapper */}
					</div>
					<div className="d-flex flex-column flex-column-fluid">
						<div className=''>
							<h2 className="fw-bolder fs-2qx px-10 " style={{ color: 'white'}}>Oletko uusi käyttäjä?</h2>
							<p className="fw-bolder fs-4 px-10 mw-450px" style={{ color: 'white'}}>Kerromme 30 minuutissa kuinka yrityksesi kasvaa huomenna älykkäämmin, edullisemmin ja nopeammin.</p>
							<a href="http://www.groweo.com/varaa-esittely/" className="fw-bolder btn btn-outline btn-outline-solid mx-10 text-uppercase btn-outline-default me-2 mb-2">{t('DEMO_BTN')}</a>
							<h4 className="fw-bolder mt-5 px-10 " style={{ color: 'white'}}>Lisää tietoa Growth Enginestä löydät <a href="http://www.groweo.com" className='text-white text-decoration-underline'>verkkosivuiltamme</a></h4>
						</div>
					</div>
					{/* end::Content */}
					{/* begin::Footer */}
					<div className="d-flex flex-wrap fs-6 p-5 pb-0">
						{/* begin::Links */}
						<div className="d-flex flex-start  fw-bold fs-6" style={{flex:'1'}}>
							<a href="#" className="text-white  px-2 " target="_blank">Rekisteri- ja tietosuojaseloste</a>
							<a href="#" className="text-white  px-2 " target="_blank">Evästeasetukset</a>
							<a href="#"  className="text-white  px-2" target="_blank"> Version 1.0.0</a> 
						</div>
						<div className="d-flex flex-end  fw-bold fs-6">
							<a href="#" className="text-white  px-2 " target="_blank">© groweo 2022</a>
						</div>	
						{/* end::Links */}
					</div>
					{/* end::Footer */}
				</div>
        {/* end::Body */}
            {/* <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div style={{ backgroundColor: 'currentColor' }} className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 min-h-800px'>
        <div
          style={{
            padding: '5px 15px',
            marginBottom: '5px',
            borderRadius: '25px',
          }}>
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/groweo_logo.svg')} className='h-55px' />
          </a>
        </div>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>

        </div>
      </div>
    </div> */}
			</div>


  )
}
