/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'

type Props = {
 className: string
 chartData: any
}

const Chart: React.FC<Props> = ({ className, chartData }) => {
 const chartRef = useRef<HTMLDivElement | null>(null)

 useEffect(() => {
  if (!chartRef.current) {
   return
  }

  const height = parseInt(getCSS(chartRef.current, 'height'))

  const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartData))
  if (chart) {
   chart.render()
  }

  return () => {
   if (chart) {
    chart.destroy()
   }
  }
 }, [chartRef, chartData])

 return (
  <div className={`card ${className}`}>
   <div className='card-body'>
    <div ref={chartRef} id='kt_charts_widget_6_chart' style={{ height: '350px' }}></div>
   </div>
  </div>
 )
}

export default Chart

function getChartOptions(height: number, chartData: any): ApexOptions {
 const labelColor = getCSSVariableValue('--bs-gray-500')
 const borderColor = getCSSVariableValue('--bs-gray-200')

 const { netProfit, revenue, expenses } = chartData

 const baseColor = '#bd2d70'
 const baseLightColor = '#d463a3'
 const secondaryColor = '#7e469c'

 return {
  series: [
   {
    name: 'Net Profit',
    type: 'bar',
    data: netProfit,
   },
   {
    name: 'Revenue',
    type: 'bar',
    data: revenue,
   },
   {
    name: 'Expenses',
    type: 'bar',
    data: expenses,
   },
  ],
  chart: {
   fontFamily: 'inherit',
   stacked: true,
   height: 350,
   toolbar: {
    show: false,
   },
  },
  plotOptions: {
   bar: {
    horizontal: false,
    borderRadius: 5,
    columnWidth: '12%',
   },
  },
  legend: {
   show: false,
  },
  dataLabels: {
   enabled: false,
  },
  stroke: {
   curve: 'smooth',
   show: true,
   width: 2,
   colors: ['transparent'],
  },
  xaxis: {
   categories: ['1', '2', '3', '4', '5', '6', '7'],
   axisBorder: {
    show: false,
   },
   axisTicks: {
    show: false,
   },
   labels: {
    style: {
     colors: labelColor,
     fontSize: '12px',
    },
   },
  },
  yaxis: {
   max: 120,
   labels: {
    style: {
     colors: labelColor,
     fontSize: '12px',
    },
   },
  },
  fill: {
   opacity: 1,
  },
  states: {
   normal: {
    filter: {
     type: 'none',
     value: 0,
    },
   },
   hover: {
    filter: {
     type: 'none',
     value: 0,
    },
   },
   active: {
    allowMultipleDataPointsSelection: false,
    filter: {
     type: 'none',
     value: 0,
    },
   },
  },
  tooltip: {
   style: {
    fontSize: '12px',
   },
   y: {
    formatter: function (val) {
     return '$' + val + ' thousands'
    },
   },
  },
  colors: [baseColor, secondaryColor, baseLightColor],
  grid: {
   borderColor: borderColor,
   strokeDashArray: 4,
   yaxis: {
    lines: {
     show: true,
    },
   },
   padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
   },
  },
 }
}
