/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

const ForgetPassword: React.FC<any> = () => {
  const history = useHistory();
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const SendLink = async () => {
    try {
      setLoading(true)
      await axios.post(`/api/resendToken`, { email })
      setEmail('')
      setLoading(false)
      Swal.fire({
        text: "Please check your email for reset the password!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      }).then(function () {
        history.push('/auth/login')
      });
    } catch (err) {
      setLoading(false)
      Swal.fire({
        text: "There are no account this email. Please enter a valid email addess.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      });
    }
  }

  const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  const validEmail = email.match(validRegex) === null ? true : false

  return (
    <>
      <div>
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control form-control-solid"
          />
          {email && validEmail && <p style={{ color: 'red' }}>Please enter a valid email id</p>}
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="button"
            onClick={SendLink}
            id="kt_password_reset_submit"
            disabled={validEmail}
            className="btn btn-lg btn-primary fw-bolder me-4"
          >
            {!loading && (<span className="indicator-label">Submit</span>)}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <a
            href='/auth/login'
            className="btn btn-lg btn-light-primary fw-bolder"
          >
            Cancel
          </a>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword
