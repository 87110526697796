import SlideBuilder from 'groweo-slide-builder'
import shadow from "react-shadow";

export const SlideBuilderElement = ({steps, appearance, isGrid, setSlideContent, isPreview, isEditor, isMobile, inPage, preBtnEditor, showPopup, nxtBtnEditor, setShowPopup}) => {
  return (
    <>
    {appearance?.style?.googleFontUrl && <link rel="stylesheet" href={appearance?.style?.googleFontUrl}/>}
    <shadow.div className='shadow-div'>
      <SlideBuilder
        steps={steps}
        appearance={appearance}
        isGrid={isGrid}
        setSlideContent={setSlideContent}
        isPreview={isPreview}
        preBtnEditor={preBtnEditor}
        nxtBtnEditor={nxtBtnEditor}
        isEditor={isEditor}
        isMobile={isMobile}
        inPage={inPage}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
    </shadow.div>
    </>
  )
}
