import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../assets/ts/components'
import { getGlobalItem } from '../../../utils/utils'

const Content: React.FC = ({ children }) => {
  const location = useLocation()
  const [isAdmin, setIsAdmin] = useState(false)
  
  useEffect(() => {
    DrawerComponent.hideAll()
    const userData: any = getGlobalItem('user')
    setIsAdmin(['groweo-admin', 'groweo-subadmin', 'partner-admin'].includes(userData.role))
  }, [location])

  return (
    <div id='kt_content_container' className={isAdmin ? 'container-fluid' : 'container gw-mw-1500'}>
      {children}
    </div>
  )
}

export { Content }
