import AppIndex from '../../modules/apps/AppIndex'
import GrowthEngineBreadCrumb from './GrowthEngineBreadCrumb'

const Apps = () => {
  return (
    <>
      <GrowthEngineBreadCrumb />
      <AppIndex />
    </>
  )
}

export default Apps
