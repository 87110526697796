import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import {Step4} from './steps/Step4'
import Step5 from './steps/Step5'
import {observer} from 'mobx-react'
import $ from 'jquery'
// import {ChatBotElement} from '../../bots/botCreate/botTree/bot/ChatBot'
import {KTSVG} from '../../../../_metronic/helpers'
import {useStore} from '../../../../store'
import {BOT_APPEARANCE, BOT_APPEARANCE3, getModulePath, getTheme3} from '../../../../utils/module.utils'
import ConfirmModal from '../modal/ConfirmModal'
import {Header} from '../../../../_metronic/layout/components/header/Header'
import StepperBreadCrumb from './StepperBreadCrumb'
import TextModal from '../modal/TextModal'
import {getLimitConfig, isWorkspaceReadonly} from '../../../../utils/utils'
import {createTemplate, updateTemplate} from '../../../../service/template.service'
import {updateModule,autoSaveModule, getModuleCount} from '../../../../service/module.service'
import AppPublishModal from '../modal/AppPublishModal'
import {
  botBuildRedo,
  botBuildUndo,
  clearElements,
  generateSimpleChatbotData,
  generateSimpleSlideData,
} from '../../../../utils/template.utils'
import Step6 from './steps/Step6'
//import { storeModuleToS3 } from '../../../../service/s3.service'
import _ from 'lodash'
import { inPopupCodeForBot } from '../../../../utils/code.utils'

let templateID = ''

const Stepper: FC<any> = ({from, moduleId, templateId, step, templateData, setTemplateData, statsData}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {authStore} = useStore()
  const {
    setModuleAccessLimit,
    moduleAccessLimit: {publishCount},
  } = authStore
  const history = useHistory()
  // const [botSteps, setBotSteps]: any = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [chatBotShow, setChatBotShow] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const [template, setTemplate]: any = useState({})
  const [isPreview, setIsPreview]: any = useState(false)
  const [isClear, setIsClear]: any = useState(false)
  const [autoSaved,setAutoSaved]: any = useState(true)
  const [flag, setFlag]: any = useState({flag: false, dontSave: false})
  const [botHistory, setBotHistory] = useState({buildHistory: [], undoRedoCursor: 0})
  const [type, setType]: any = useState('')
  const [showSuccessModal, setShowSuccessModal]: any = useState(false)
  const [isSmartEdge, setIssmartEdge] = useState(true)
  const [isShowNodeCounts, setIsShowNodeCounts] = useState(false)
  const [isNotificationInvalid, setIsNotificationInvalid] = useState(false)
  // const [showBotView, setShowBotView]: any = useState(false)

  /*useEffect(() => {
    autoSaveModule(moduleId, template)
  }, [moduleId, template])
   */

  useEffect(() => {
    if (!templateData?.botCode?.appearance3 &&  templateData?.botCode?.appearance) {
      const {mode, primaryColor}: any = templateData.botCode.appearance
      const themeData = getTheme3(primaryColor)
      const appearanceData = mode === 'light' ? themeData.light : themeData.dark
      templateData.botCode.appearance3 = {...appearanceData, ...templateData.botCode.appearance}
    }
    setTemplate(templateData)
    // eslint-disable-next-line
  }, [templateData])

  useEffect(() => {
    if (step === 'appearance'  && template._id) getContent('bot-appearance-preview')
    // eslint-disable-next-line
  }, [template._id])

  useEffect(() => {
    if (!flag.flag) return
    /*if (!flag.dontSave) {
      onSave()
    }*/
    //Call auto save on module update
    const autoSaved = autoSaveModule(moduleId, template);
    setAutoSaved(autoSaved)
    setFlag({flag: false, dontSave: false,changed:true })
    setTemplateData(template)
    // eslint-disable-next-line
  }, [flag])

  useEffect(() => {
    setChatBotShow(step === 'design')
    // eslint-disable-next-line
  }, [chatBotShow, step])

  const validateNotification = () => {
    const notifications = template.botCode?.appearance3?.preNotification
    if(!notifications) return false
    const { count, notification } = notifications
    if (!count) return false
    const anEmptyValue: any = [];
    for (let i = 0; i < Number(count); i++) {
      anEmptyValue.push(!notification[i]?.text)
  }
    return anEmptyValue.find((n: any) => n)
  }

  const onSave = (publish?: string) => {
    // lets add commments here 
    if (from === 'bot') {
      const checkNotificationValidation = validateNotification()
      if (checkNotificationValidation) {
        setIsNotificationInvalid(true)
        return
      }
      setIsNotificationInvalid(false)
    }
    if (!templateId) {
      if (['form', 'file', 'smartblock', 'smartpopup'].includes(from)) onFormSave(publish)
      if (from === 'bot') onBotSave(publish)
      if (from === 'smartflow') onFlowSave(publish)
    } else saveTemplate()
  }

  const prepareData = () => {
    //TODO: change template to module everywhere
    const {name, content, code} = template
    return {name, content, code, type: from}
  }

  const onFormSave = (publish: string | void) => {
    //TODO: Add comments for every line
    const data: any = prepareData()
    if (template?.appearence) {
      data.appearence = template.appearence
      data.appearence.scriptVersion = 1
    }
    if (template?.settings) data.settings = template.settings
    if (template?.documents) data.documents = template.documents
    if (template?.emailTemplate) data.emailTemplate = template.emailTemplate
    if (template?.publishEvent?.length) data.publishEvent = template.publishEvent
    setType(publish)
    data.content = publish === 'publish' ? template?.appearence?.html : publish === 'unpublish' ? null : template?.content
    data.status = publish === 'publish' ? 'active' : 'draft'
    if(isWorkspaceReadonly()) return
    updateModule(moduleId, data).then(() => {
      if (publish) setShowPublishModal(true)
      else setShowSuccessModal(true)
    })
  }

  const onBotSave = async (publish: string | void) => {

    // In readonly mode saving not allowed
    if(isWorkspaceReadonly()) return

   // const {appearance, elements} = template.botCode || template.code || {}
  
   let appearance = {}
   let elements = []
   
   if (template.botCode?.appearance3)  { //  Lets check for  appearence exist in bot code 
     appearance = template.botCode?.appearance3
   } else if(template.code?.appearance) {//  else Lets check for appearence exist in code 
     appearance = template.code?.appearance
   } else { //  else Lets take the default , TODO: add default appearence here
     appearance = {}
   }

   if (template.botCode?.elements?.length)  { //  Lets check for elements exist in bot code 
    elements = template.botCode?.elements
  } else if(template.code?.elements?.length) {//  else Lets check for elemenst exist in code 
    elements = template.code?.elements
  }

    const nodes = generateSimpleChatbotData(elements)
    const code = {nodes, elements, scriptVersion: 3}
    const data: any = {
      name: template.name,
      type: from,
      botCode: {...code, appearance: template.botCode.appearance, appearance3: appearance},
      status: 'draft',
    }
    if (template?.settings) data.settings = template.settings
    if (template?.publishEvent?.length) data.publishEvent = template.publishEvent
    if (publish) {
      setType(publish)
      data.content = publish === 'publish' ? JSON.stringify( {...code, appearance}) : null
      data.status = publish === 'publish' ? 'active' : 'draft'
    }
   
   await updateModule(moduleId, data);
   if (step === 'appearance') getContent('bot-appearance-preview')
 
   if (publish) setShowPublishModal(true)
   else setShowSuccessModal(true)
  }

  const onFlowSave = async (publish: string | void) => {

    // In readonly mode saving not allowed
    if(isWorkspaceReadonly()) return

   // const {appearance, elements} = template.botCode || template.code || {}
   let general = {}
   let elements = []

    if (template.botCode?.general)  { //  Lets check for  appearence exist in bot code 
      general = template.botCode?.general
    }  
    if (template.botCode?.elements?.length)  { //  Lets check for elements exist in bot code 
      elements = template.botCode?.elements
    } else if(template.code?.elements?.length) {//  else Lets check for elemenst exist in code 
      elements = template.code?.elements
    }
    const nodes = generateSimpleSlideData(elements)
    const code = {nodes, elements, general, scriptVersion: 1}
    const data: any = {
      name: template.name,
      type: from,
      botCode: {...code},
      status: 'draft',
    }
    if (template?.publishEvent?.length) data.publishEvent = template.publishEvent
    if (publish) {
      setType(publish)
      data.content = publish === 'publish' ? JSON.stringify( {...code}) : null
      data.status = publish === 'publish' ? 'active' : 'draft'
    }
   
   await updateModule(moduleId, data);
   
   if (publish) setShowPublishModal(true)
   else setShowSuccessModal(true)
  }

  const saveTemplate = () => {
    const data: any = prepareData()
    if (from === 'bot') {
      let code
      if (!template.code) {
        const elements = clearElements()
        const appearance = BOT_APPEARANCE3
        const nodes = generateSimpleChatbotData(elements)
        code = {appearance, nodes, elements}
      } else {
        const {appearance, elements} = JSON.parse(template.code)
        const nodes = generateSimpleChatbotData(elements)
        code = {appearance, nodes, elements}
      }
      data.code = JSON.stringify(code)
    } else if (['form', 'file', 'smartblock', 'smartpopup', 'smartflow'].includes(from)) {
      if (template?.appearence) data.appearence = template.appearence
    }
    const id = templateId !== 'create' ? templateId : templateID
    if (!id) {
      createTemplate(data).then((id: any) => {
        if (id) templateID = id
        setTemplate(data)
      })
    } else updateTemplate(id, data)
  }

  const closeModal = async () => {
    if(!autoSaved) {
      console.log("Pending changes to be saved")
      //TODO: Show pending changes modal
    }
    setTemplateData({})
    if (templateId) history.push(`/templates/${from}s`)
    else history.push(`${getModulePath(from)}`)
  }

  // const convert = () => {
  //   const validators: any = {
  //     email: (value: any) => {
  //       if (value && value.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/)) {
  //         return true
  //       }
  //       return t('VALID.EMAIL')
  //     },
  //   }

  //   const templateCode = template.botCode
  //   const steps = generateSimpleChatbotData(templateCode.elements)
  //   for (const step of steps) {
  //     if (!step.trigger && !step.options) step.end = true
  //     if (step.validator) step.validator = validators[step.validator]
  //   }
  //   setBotSteps(steps)
  // }

  // const openChatBotWindow = (open: boolean) => {
  //   convert()
  //   setChatBotShow(open)
  //   setShowBotView(open)
  // }

  const onPreviewClick = () => {
    // if (from === 'bot') openChatBotWindow(true)
    if (from === 'bot') getContent('bot-design-preview')
    if (['form', 'file', 'smartblock', 'smartpopup', 'smartflow'].includes(from)) setIsPreview(true)
  }

  const renderPreview = () => (
    <div onClick={onPreviewClick} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/stepper/play-outline.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('PREVIEW')}</p>
    </div>
  )

  const onUndoRedo = (type: string) => {
    const templateCode = template.botCode
    const data =
      type === 'redo'
        ? botBuildRedo(botHistory, templateCode?.elements, moduleId)
        : botBuildUndo(botHistory, templateCode?.elements, moduleId)
    if (data?.history) setBotHistory(data.history)
    if (data?.elements)
      setTemplate({...template, botCode: {...templateCode, elements: data.elements}})
    console.log('onUndoRedo setFlag true')
    setFlag({ flag: true, dontSave: true })
  }

  const getContent = (id: string) => {
    let content: any = inPopupCodeForBot(template._id, template.workspaceId, true, false)
    if (typeof content === 'string') {
      content = content.split('\n')
    }
    $(`#${id}`).empty();
    $(`#${id}`).append(content)
  }

  const renderUndo = () => (
    <div onClick={() => onUndoRedo('undo')} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('UNDO')}</p>
    </div>
  )

  const renderRedo = () => (
    <div onClick={() => onUndoRedo('redo')} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('REDO')}</p>
    </div>
  )

  const renderSave = () => (
    <div onClick={() => onSave()} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/icons/custom/save_icon.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('SAVE')}</p>
    </div>
  )

  const renderClear = () => (
    <div onClick={() => setShowConfirmModal(true)} className='cursor-pointer m-3 text-center'>
      <KTSVG path='/media/stepper/prohibition.svg' className='svg-icon-2x' />
      <p className='text-muted m-0 fw-bold fs-7'>{t('CLEAR')}</p>
    </div>
  )

  const onConfirm = () => {
    if (from === 'bot') {
      const elements = clearElements()
      const templateCode = JSON.parse(template.code)
      setTemplate({...template, botCode: templateCode })
      console.log('onConfirm setFlag true')
      setFlag({ flag: true, dontSave: false })
    }
    if (['form', 'file', 'smartblock', 'smartpopup', 'smartflow'].includes(from)) setIsClear(true)
  }

  const onPublish = (publishData: object) => {
    // Do limit validation in separate and add comments
    if (from === 'bot') {
      const checkValidation = validateNotification()
      if (checkValidation) {
        setIsNotificationInvalid(true)
        return
      }
      setIsNotificationInvalid(false)
    }
    if (!getLimitConfig('publish_app', publishCount)) { // Show error up if reached limit
      const publishEvent = template?.publishEvent || []
      publishEvent.push(publishData)
      setTemplate({...template, publishEvent})
      onSave('publish') 
    }
    else if (['form', 'file', 'smartblock', 'smartpopup', 'smartflow'].includes(from)) onSave()
  }

  const onUnpublish = () => onSave('unpublish')

  const onTemplateSave = (data: any, dontSave?: boolean) => {
    setTemplate({...data})
    console.log('onTemplateSave setFlag true')
    setFlag({ flag: true, dontSave: !!dontSave })
  }

  return (
    <>
      {isNotificationInvalid && from === 'bot' && <TextModal
        message={t('CHAT_NOTIFICATION_INVALID_TEXT')}
        icon='error'
        close={() => setIsNotificationInvalid(false)}
      ></TextModal>
      }
      {showPublishModal ? (
        <TextModal
          message={t(`${type?.toUpperCase()}_COMPLETE_MODAL_TEXT`)}
          close={() => {
            setType('')
            setShowPublishModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
       {showSuccessModal ? (
        <TextModal
          message={t('COMPLETE_MODAL_TEXT')}
          close={() => {
            setShowSuccessModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <StepperBreadCrumb data={{name: template?.name, from, moduleId, templateId}} />
      <div className='modal d-block' tabIndex={-1} id='kt_modal_22'>
        <div className='modal-dialog modal-fullscreen'>
          <div className='modal-content shadow-none'>
            <div
              id='kt_create_account_stepper'
              className='stepper stepper-links d-flex flex-column h-100'
            >
              <div className='d-flex align-items-center justify-content-between px-4'>
                <Header editable setName={(name: string) => onTemplateSave({...template, name})} />
                <div className='d-flex'>
                  {templateId && (
                    <div className='mt-4 ms-7'>
                      {step === 'design' && (
                        <input
                          value={template?.name}
                          placeholder={t('NAME')}
                          onChange={(e) => setTemplate({...template, name: e.target.value})}
                          onBlur={() => onTemplateSave(template)}
                        />
                      )}
                    </div>
                  )}
                  {!isWorkspaceReadonly() && (
                    <div className='d-flex align-items-center pe-8'>
                      {from === 'bot' && (
                        <>
                          <label className='form-check form-check-custom form-check-solid form-switch m-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={isSmartEdge}
                              onChange={() => setIssmartEdge(!isSmartEdge)}
                            />
                            <span className='form-check-label text-muted mx-3'>{t('SMART_EDGE')}</span>
                          </label>
                         {/*  {renderUndo()}
                          {renderRedo()} */}
                        </>
                      )}
                      {from === 'bot' && (
                        <>
                          <label className='form-check form-check-custom form-check-solid form-switch m-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={isShowNodeCounts}
                              onChange={() => { 
                                setIsShowNodeCounts(!isShowNodeCounts) 
                              }
                              }
                            />
                            <span className='form-check-label text-muted mx-3'>{t('VISITOR', {count: 1})}</span>
                          </label>
                        </>
                      )}
                      {renderSave()}
                      {step === 'design' ? renderPreview() : ''}
                      <div
                        onClick={async() => {
                          const moduleCount: any = await getModuleCount();
                          setModuleAccessLimit(moduleCount?.totalCount, moduleCount?.publishCount) 
                        }}
                        className={`cursor-pointer m-3 text-center ${
                          getLimitConfig('publish_app', publishCount) ? 'disabled' : ''
                        }`}
                        data-bs-toggle='modal'
                        data-bs-target='#kt_publish_event_modal'
                        title={`${
                          getLimitConfig('publish_app', publishCount) ? t('PUBLISH_TITLE') : ''
                        }`}
                      >
                        <KTSVG path='/media/stepper/cloud-upload.svg' className='svg-icon-2x' />
                        <p className='text-muted m-0 fw-bold fs-7'>{t('PUBLISH')}</p>
                      </div>
                      <div onClick={onUnpublish} className='cursor-pointer m-3 text-center'>
                        <KTSVG
                          path='/media/icons/custom/cloud_upload_icon.svg'
                          className='svg-icon-2x'
                        />
                        <p className='text-muted m-0 fw-bold fs-7'>{t('UNPUBLISH')}</p>
                      </div>
                    </div>
                  )}
                  <div className='d-flex align-items-center'>
                    {!isWorkspaceReadonly() && renderClear()}
                    <div onClick={closeModal} className='cursor-pointer m-3 text-center'>
                      <KTSVG
                        className='svg-icon-2x'
                        path='/media/icons/custom/close_icon.svg'
                      />
                      <p className='text-muted m-0 fw-bold fs-7'>{t('CLOSE')}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal-body ${
                  !((step === 'design' && from === 'bot' && isWorkspaceReadonly()) || !isWorkspaceReadonly()) && 'pe-none'
                } `}
                style={{background: '#eff2f5'}}
              >
                 {/* {from === 'bot' && chatBotShow && showBotView && botSteps && botSteps.length && (
                  <ChatBotElement
                    steps={botSteps}
                    opened={showBotView}
                    toggleFloating={(e: any) => {
                      openChatBotWindow(!showBotView)
                    }}
                  ></ChatBotElement>
                )} */}
                {step === 'design' && (
                  <>
                    <Step2
                      templateId={templateId}
                      from={from}
                      moduleId={moduleId}
                      template={template}
                      setTemplate={onTemplateSave}
                      setIsPreview={setIsPreview}
                      setIsClear={setIsClear}
                      isClear={isClear}
                      isPreview={isPreview}
                      botHistory={botHistory}
                      setBotHistory={setBotHistory}
                      isSmartEdge={isSmartEdge}
                      setIssmartEdge={setIssmartEdge}
                      isShowNodeCounts={isShowNodeCounts}
                      statsData={statsData}
                    />
                  </>
                )}
                {step === 'appearance' && !['smartblock', 'smartpopup', 'smartflow'].includes(from) && (
                  <>
                    <Step3 from={from} template={template} setTemplate={onTemplateSave} />
                  </>
                )}
                {step === 'code' && (
                  <Step4 from={from} template={template} setTemplate={onTemplateSave} />
                )}
                {step === 'settings' && <Step5 template={template} setTemplate={onTemplateSave} />}
                {step === 'emailTemplate' &&  ['file'].includes(from) && (
                  <Step6 template={template} setTemplate={onTemplateSave} />
                )}
              </div>
              {from === 'bot' && <div id='bot-design-preview'></div>}
              {from === 'bot' && <div id='bot-appearance-preview'></div>}
              {showConfirmModal && (
                <ConfirmModal
                  message={t('SETTINGS_CONFIRM')}
                  show={showConfirmModal}
                  close={setShowConfirmModal}
                  onConfirm={onConfirm}
                />
              )}
              <AppPublishModal onPublish={onPublish}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Stepper)
