/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'

type PaginationProps = {
  activePage: any
  setActivePage: any
  pageCount: any
}

const UsersListPagination: FC<PaginationProps> = ({activePage, setActivePage, pageCount}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  return (
    <div className='row mt-10 mb-5'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li className='page-item'>
              <a
                className='page-link bg-light'
                onClick={() => {
                  activePage !== 1 && setActivePage(activePage - 1)
                }}
                style={{cursor: 'pointer'}}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr074.svg'
                  className='svg-icon-5 svg-icon-gray-500 me-1'
                />
              </a>
            </li>
            {[...Array(Number(pageCount))].map((u: any, index: any) => (
              <li
                key={`page${index + 1}`}
                className={clsx('page-item', {
                  active: index + 1 === activePage,
                })}
              >
                <a
                  className={clsx('page-link', {
                    'bg-light': index + 1 !== activePage,
                  })}
                  onClick={() => setActivePage(index + 1)}
                  style={{cursor: 'pointer'}}
                >
                  {index + 1}
                </a>
              </li>
            ))}
            <li className='page-item'>
              <a
                className='page-link bg-light'
                onClick={() => {
                  activePage !== pageCount && setActivePage(activePage + 1)
                }}
                style={{cursor: 'pointer'}}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr071.svg'
                  className='svg-icon-5 svg-icon-gray-500 me-1'
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
