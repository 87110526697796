import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'

const ProfileBreadCrumb: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const ProfileBreadCrumb: Array<PageLink> = [
    {
      title: t('PROFILE'),
      path: '/profile',
      isSeparator: false,
      isActive: true
    }
  ]
  const filteredData = ProfileBreadCrumb.filter((b) => b.isShow)

  return (
    <PageTitle breadcrumbs={filteredData}>{t('PROFILE')}</PageTitle>
  )
}

export default ProfileBreadCrumb
