/*
  *** Warning .replaceAll was introduced into browsers in 2020
*/

import changeLogDataMD from '../CHANGE_LOG.md'

export const changeLogData = async () => {

  let data = await fetch(changeLogDataMD).then((res)=> res.text())

  // depending on which is OS used, windows uses \r\n and MacOS uses \r and Linux \n
  const endOfLineOptions = ["\n", "\r\n", "\r"]; 

  const endOfLine = Object.keys(endOfLineOptions
    .map((eol) => {
      return {[eol]: data.split(eol).length}
    })
    .sort((a, b) => (Object.values(b)[0] - Object.values(a)[0]))[0]
  )[0] // Pick the one with highest number of lines

  let dataRows = data.split(endOfLine);

  let output:any[] = []
  let objectIteration = -1 // iteration for data
  let featureName = '' // iteration and assigning of features

  dataRows.map((row:string) => {
    if (row.includes('### Version')) {
      output.push({
        version: row.split('### Version ')[1].split(' ')[0],
        date: row.split('### Version ')[1].split(' ')[1].replace('(', '').replace(')', ''),
        changes: {},
      })
      objectIteration += 1
      featureName = '' // reset if new object
    }

    if (row.includes('#### ')) {
      featureName = row.split('#### ')[1]
      output[objectIteration].changes[featureName] = []
    }

    if (featureName !== "" && !row.includes("#### ")) {
      if (row.trim() !== "") {
        if (row.split("[Link]")[1]) {
          output[objectIteration].changes[featureName].push({
            title: row.split("[Link]")[0]?.slice(1, -1).trim().split(":")[0],
            description: row
              .split("[Link]")[0]
              .slice(1, -1)
              .trim()
              .split(":")[1]
              .trim(),
            link: row
              .split("[Link]")[1]
              .replaceAll("(", "")
              .replaceAll(")", ""),
          });
        } else {
          output[objectIteration].changes[featureName].push({
            title: row.split(":")[0].slice(1).trim(),
            description: row.split(":")[1].trim(),
            link: "",
          });
        }
      }
    }
  })

  return output

}