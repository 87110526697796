/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import { updateUser } from '../../../../service/user.service'
import {useStore} from '../../../../store'
import { getGlobalItem } from '../../../../utils/utils'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'fi',
    name: 'Suomi',
    flag: toAbsoluteUrl('/media/flags/finland.svg'),
  },
  //  {
  //   lang: 'es',
  //   name: 'Spanish',
  //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: toAbsoluteUrl('/media/flags/japan.svg'),
  // },
  // {
  //   lang: 'de',
  //   name: 'German',
  //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
  // },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: toAbsoluteUrl('/media/flags/france.svg'),
  // },
]

const Languages: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const lang = useLang()
  const [currentLanguage, setCurrentLanguage] = useState(languages.find((x) => x.lang === lang))
  const {
    authStore: {getUser, user, setUser},
  } = useStore()

  useEffect(() => {
    getUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.language) {
      setCurrentLanguage(languages.find((x) => x.lang === user.language))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const updateLanguage = async(lang: string) => {
    const res = await updateUser(user?._id, {language: lang})
    if (res) {
      const userData = getGlobalItem('user')
      setLanguage(lang)
      setUser({...userData, language: lang})
    }
  }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {t('LANGUAGE')}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='groweo'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => updateLanguage(l.lang)}
          >
            <a
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='groweo' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
