import axios from 'axios'
import { handleError, qs } from '../utils/utils'


export const getUser = async (workspaceId: string) => {
  try {
    const response = await axios.get(`/api/user?${qs({ workspaceId })}`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const createUser = async (data: any) => {
  try {
    const response = await axios.post(`/api/user`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const updateUser = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/user/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const deleteUser = async (id: string) => {
  try {
    const response = await axios.delete(`/api/user/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const setUserPassword = async (id: string, data: any) => {
  try {
    const response = await axios.post(`/api/user/password/${id}`, data)
    return response?.data?.data?.id
  } catch ({ response }) {
    handleError(response)
  }
}

export const existsUser = async (data: any) => {
  try {
    const response = await axios.post(`/api/user/exists`, {...data})
    return response?.data
  } catch ({ response }) {
    handleError(response)
  }
}

