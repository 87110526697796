import {useState, useRef, useEffect, useMemo} from 'react'
import * as uuid from 'uuid'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  ConnectionLineType,
  Background,
} from 'react-flow-renderer'
import {TextNode} from './nodes/TextNode'
import {SettingsFlyout} from './settings/SettingsFlyout'
import './dnd.css'
import {resetInteractive, addBotElement, TYPE_NAMES, removeBotElement, updateBotElement} from '../../../../../utils/module.utils'

import botStore from './store/botStore'
import SmartEdge from '@tisoap/react-flow-smart-edge'
import BotCopyModal from '../../../common/modal/BotCopyModal'
import {NodeDropdown} from './dropdown/NodeDropdown'
import {addBotBuildHistory, getElements, removeElement} from '../../../../../utils/template.utils'
import { observer } from 'mobx-react'
import { isWorkspaceReadonly } from '../../../../../utils/utils'
import { FallbackView } from '../../../../../_metronic/partials'
import { useIntl } from 'react-intl'

const getId = () => uuid.v4()

const DnDFlow = ({ moduleId, store, spacing, template, setTemplate, botHistory, setBotHistory, isSmartEdge, setIssmartEdge, statsData, isShowNodeCounts }) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false)
  const [nodePosition, setNodePosition] = useState({x: 0, y: 0})
  const [botCode, setBotCode] = useState({})
  const [flyoutShown, setFlyoutShown] = useState(false)
  const [selectedElement, setSelectedElement] = useState({})
  const [flag, setFlag] = useState(false)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [operationType, setOperationType] = useState({operation: "", type: ""})
  const [isLoading, setIsLoading] = useState(false)

  const ref = useRef()
  const createNode = () => {
    const id = getId()
    const node = {
      id,
      type: 'text',
      position: {},
      parent: null,
      children: {},
      data: {
        label: `${TYPE_NAMES['text']} node`,
        text: 'Double-click to edit',
        options: [],
        links: [],
        start: false,
        end: false,
      },
    }
    return node
  }
  const [selectedNode, setSelectedNode] = useState(createNode())

  useEffect(() => {
    if (template?.botCode?.elements) {
    /*Get the initial or first step details */
    let getFirstStepsDetails = getNodesDetails("1")
    if (!getFirstStepsDetails) getFirstStepsDetails = template?.botCode?.nodes[0]
    /*Get the choice steps details */
    const optionsStepsDetails = getNodesDetails(getFirstStepsDetails?.trigger)
    /*Get all choices list */
    const statInfo = getstatById(optionsStepsDetails?.trigger || optionsStepsDetails?.id)
      template.botCode.elements.map((item, i) => {
        const foundItem = statsData.find(stat => stat.id === item.id)
        if (foundItem && isShowNodeCounts && statInfo?._col1 && foundItem?._col1) {
          const nodePercentage = convertedIntoPercentage(foundItem._col1, statInfo._col1)
          item.data.percentageCount = `${parseInt(nodePercentage)} %`;
          item.data.nodeCount = foundItem._col1 || 0
        } else if (item.data) {
          item.data.percentageCount = isShowNodeCounts ? `0%` : 0
          item.data.nodeCount = 0
        }
        return item
      });
    }
    if (template.botCode) setBotCode(template.botCode)
  }, [template.botCode, isShowNodeCounts])

  const getNodesDetails = (triggerId) => {
    return template?.botCode?.nodes.find((node) => node.id === triggerId)
  }

  const getstatById = (id) => {
    return statsData.find((stat) => stat.id === id)
  }

  const convertedIntoPercentage = (statCount, totalStatsCount) => {
    const counts = parseInt(statCount) * 100 / parseInt(totalStatsCount)
    return counts
  }

  useEffect(() => {  // Temporarily move the old bot code to new version. will be removed in future
    if (template.code && !template.botCode) {
      const botCode = JSON.parse(template.code)
      setTemplate({...template, botCode }, true)
    } else if (template.botCode && !template.botCode.appearance ) { 
      const botCode = JSON.parse(template.code) //old bot tree, 
      template.botCode.appearance = botCode.appearance
      setTemplate(template, true)
    }
  }, [template.code])  


  useEffect(() => {
    if (!flag) return
    setTemplate({...template, botCode }, true)
    setFlag(false)
    // eslint-disable-next-line
  }, [flag])

  useEffect(() => {
    if (!reactFlowInstance) return
    if (botCode?.appearance3?.flowSettings) {
      const {x, y, zoom} = botCode.appearance3.flowSettings
      reactFlowInstance.setTransform({x, y, zoom})
    }
    // eslint-disable-next-line
  }, [botCode?.appearance3?.flowSettings])

  useEffect(() => {
    if (isSmartEdge && botCode?.elements?.length > 200) setIssmartEdge(false)
    // eslint-disable-next-line
  }, [botCode?.elements])

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 0);
  //   // eslint-disable-next-line
  }, [isSmartEdge, isShowNodeCounts])

  const onConnect = (params) => {
    params.id = `${params.source}_${params.target}_${uuid.v4()}`
    console.log('onConnect params:', params)
    /*for (const element of botCode.elements) {
      if (element.source === params.source && element.sourceHandle === params.sourceHandle) {
        return
      }
    }*/
    params.type = 'smart'
    const elements = getElements((els) => addEdge(params, els), botCode.elements)
    params.id = elements[elements.length-1].id
    const data = addBotBuildHistory(botHistory, {type: 'connect', params})
    setBotHistory(data)
    // const newElement = elements[elements.length-1]
    const foundIndex = elements.findIndex((item) => item.id === params.source)
    if (foundIndex !== -1 && elements[foundIndex].data.options.length) {
      elements[foundIndex].data.options[params.sourceHandle].edgeId =
        elements[elements.length - 1].id
    }
    setBotCode({...botCode, elements})
    // addBotElement(moduleId, newElement)
    setFlag(true)
  }

  const onElementsRemove = (elementsToRemove) => {
    console.log('onElementsRemove')
    elementsToRemove = elementsToRemove.filter((el => el.id !== "1"))
    // for (const el of elementsToRemove) {
    //   removeBotElement(moduleId, el.id)
    // }
    const data = addBotBuildHistory(botHistory, {type: 'remove', params: {elementsToRemove}})
    setBotHistory(data)
    const elements = getElements((els) => removeElements(elementsToRemove, els), botCode.elements)
    setBotCode({...botCode, elements})
    setFlag(true)
    return elements
  }

  const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance)

  const onDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const getPosition = (event) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    return reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
  }

  const onDrop = (event) => {
    event.preventDefault()

    let type = event.dataTransfer.getData('application/reactflow')
    if (!type) return
    let start = false
    let end = false
    if (type === 'start') {
      start = true
      type = 'text'
    } else if (type === 'end') {
      end = true
    }
    const newNode = {...createNode(), type, position: getPosition(event)}
    newNode.data = {...newNode.data, label: `${type} node`, start, end}
    const elements = getElements((es) => {
      return es.concat(newNode)
    }, botCode.elements)
    setBotCode({...botCode, elements})
    setFlag(true)
    // const newElement = elements[elements.length-1]
    // addBotElement(moduleId, newElement)
  }

  const nodeTypes = {
    text: TextNode,
    input: TextNode,
    inputt: TextNode,
    linkk: TextNode,
    end: TextNode,
    options: TextNode,
    email: TextNode,
    card: TextNode,
    carousel: TextNode,
    form: TextNode,
  }

  const changeType = (event, currentType, dt) => {
    event.preventDefault()
    if (currentType === 'carousel') {
      selectedNode.data.text = 'Carousel'
      selectedNode.data.slides = []
      selectedNode.data.infinite = true;
    }
    if (currentType === 'form') {
      selectedNode.data.text = 'Form'
      selectedNode.data.markAsComplete = true
      selectedNode.data.submitButtonText = t('SUBMIT')
    }
    if (dt) {
      selectedNode.data = dt
    }
    selectedNode.position = getPosition(event)
    selectedNode.type = currentType
    // selectedNode.store = store
    setSelectedNode(selectedNode)
    const data = addBotBuildHistory(botHistory, {type: 'newNode', params: {newNode: selectedNode}})
    setBotHistory(data)
    const elements = getElements((es) => {
      return es.concat(selectedNode)
    }, botCode.elements)
    setBotCode({...botCode, elements})
    setFlag(true)
    setTypeDropdownOpen(false)
    setSelectedNode(createNode())
    // const newElement = elements[elements.length-1]
    // addBotElement(moduleId, newElement)
  }

  const onNodeDragStop = (event, node) => {
    const foundIndex = botCode.elements.findIndex((item) => item.id === node.id)
    botCode.elements[foundIndex].position = getPosition(event)
    setBotCode({...botCode, elements: botCode.elements})
    // updateBotElement(moduleId, botCode.elements[foundIndex])
    setFlag(true)
  }

  const OnDoubleClick = (event) => {
    if (event?.target?.classList?.contains('select-node-text') || flyoutShown || isWorkspaceReadonly()) return
    event.preventDefault()
    setNodePosition({x: event.clientX, y: event.clientY - 92})
    setTypeDropdownOpen(!typeDropdownOpen)
  }

  const onMoveEnd = ({x, y, zoom}) => {
    setBotCode({...botCode, appearance3: {...botCode.appearance3, flowSettings: {x, y, zoom}}})
    setFlag(true)
  }

  const onNodeDoubleClick = (event, node) => {
    if (flyoutShown || isWorkspaceReadonly()) return
    event.stopPropagation()
    setFlyoutShown(true)
    const foundElement = botCode.elements.find((item) => item.id === node.id)
    if (foundElement) setSelectedElement(foundElement)
    resetInteractive(true)
  }

  const findChildNode = (nodeId) => {
    return !botCode.elements.some((el) => el?.source === nodeId)
  }

  const onElementClick = (event, node) => {
    if (event.target.classList?.contains('node-close') && !flyoutShown) {
      setOperationType({operation: 'remove', type: "singular", nodeId: node.id, disable: findChildNode(node.id) })
    }
  }

  const contextMenuStyle = {
    borderRadius: "15px",
    backgroundColor: "white",
    // padding: "5px",
    position: "absolute",
    width: "100px",
    zIndex: 10
  }

  const copyTreeElements = () => {
    window.navigator.clipboard.readText().then((text) => {
      try {
        const nodeObj = JSON.parse(text)
        console.log('nodeObj:', nodeObj)
        const existingElements = JSON.parse(JSON.stringify(botCode.elements))
        const copyElements = []
        const copyElementsIds = []

        const getCopyElements = (nodeId) => {
          if (copyElementsIds.includes(nodeId)) return
          copyElementsIds.push(nodeId)
          const getNode = existingElements.find((node) => node.id === nodeId)
          copyElements.push(getNode)
          // eslint-disable-next-line array-callback-return
          if (operationType.type !== 'singular') {
            existingElements.filter((edge) => {
              if (edge.source !== nodeId) return false
              copyElements.push(edge)
              edge.target && getCopyElements(edge.target)
            })
          }else {
            if (nodeObj) setOperationType({operation: 'copy', type: 'singular', disable: findChildNode(nodeObj.id)})
             botStore.setCopyMessage({show: false})
          }
        }
        getCopyElements(nodeObj.id)
        let stringifyCopyElements = JSON.stringify(copyElements)
        copyElementsIds?.map(
          (id) => (stringifyCopyElements = stringifyCopyElements.replaceAll(id, getId()))
        )
        window.navigator.clipboard.writeText(stringifyCopyElements).then(() => {})
      } catch (err) {}
    })
  }

  const onPasteButtonClick = () => {
    const ev = store.contextMenu.ev
    store.setContextMenu({
      open: false,
    })
    window.navigator.clipboard.readText().then((text) => {
      try {
        const position = getPosition(ev)
        const copyElements = JSON.parse(text)
        console.log('nodeObj:', copyElements)
        
        if (!copyElements.length) return
        let firstNodePosition
        for (let index = 0; index < copyElements.length; index++) {
          if (copyElements[index].type === 'smart') continue;
          if (!index) {
            firstNodePosition = copyElements[index].position
            copyElements[index].position = position
          } else
            copyElements[index].position = {
              x: copyElements[index].position.x - firstNodePosition.x + position.x,
              y: copyElements[index].position.y - firstNodePosition.y + position.y,
            }
        }
        const newNodeType = operationType.type !== 'singular' ? 'newNodes' : 'newNode'
        const data = addBotBuildHistory(botHistory, {type: newNodeType, params:  operationType.type === 'singular' ? {newNode: copyElements} : {newNodes: copyElements}})
        setBotHistory(data)
        botCode.elements = [...botCode.elements, ...copyElements]
        setFlag(true)
        setTypeDropdownOpen(false)
        setOperationType({operation: "", type: ""})
        window.navigator.clipboard.writeText('').then(() => {})
      } catch (err) {}
    })
  }

  const contextMenu = () => {
    const { left, top } = store.contextMenu.position
    return (<div style={{...contextMenuStyle, left, top }}>
      <div onClick={onPasteButtonClick} style={{padding: '10px', cursor: 'pointer'}}>Paste</div>
    </div>)
  }

  const onContextMenu = (ev) => {
    const componentPosition = ref.current.getBoundingClientRect()
    const left = ev.pageX-componentPosition.x
    const top = ev.pageY-componentPosition.y
    store.setContextMenu({ 
      open: true,
      ev,
      position: {
        left,
        top
      }
    })
    setTimeout(() => {
      store.setContextMenu({
        open: false
      })
    }, 5000)
    ev.preventDefault()
  }

  const copyContainerStyle = {
    borderRadius: "15px",
    backgroundColor: "white",
    // padding: "5px",
    position: "absolute",
    width: "100px",
    zIndex: 10
  }

  // const onCopyButtonClick = () => {
  //   const element = botCode.elements.find((elm) => elm.id === store.copyMenu.element);
  //   console.log('copied element:', element)
  //   window.navigator.clipboard.writeText(JSON.stringify(element)).then(() => {
  //     console.log('copied to clipboard')
  //   })
  //   store.setCopyMenu({
  //     open: false
  //   })
  //   store.setContextMenu({
  //     open: false
  //   })
  // }

  const copyMessage = () => {
    window.navigator.clipboard.readText().then((text) => {
      try {
        const nodeObj = JSON.parse(text)
        if (nodeObj) setOperationType({operation: 'copy', type: 'singular', disable: findChildNode(nodeObj.id)})
        botStore.setCopyMessage({show: false})
      } catch(err) {}
    }
    )
  }

  const removeTreeElements = () => {
    if (!operationType.nodeId) return
    console.log('onNodeRemove')
    const copyElementsIds = []
    if (operationType.type !== 'singular') {
      const existingElements = JSON.parse(JSON.stringify(botCode.elements))

      const getCopyElements = (nodeId) => {
        if (copyElementsIds.includes(nodeId)) return
        copyElementsIds.push(nodeId)
        // eslint-disable-next-line array-callback-return
        existingElements.filter((edge) => {
          if (edge.source === nodeId) getCopyElements(edge.target)
        })
      }
      getCopyElements(operationType.nodeId)
    }
    const {elementsToRemove, elements} =
      operationType.type === 'singular'
        ? removeElement([operationType.nodeId], botCode.elements)
        : removeElement([...copyElementsIds], botCode.elements)
    setBotCode({...botCode, elements})
    const data = addBotBuildHistory(botHistory, {type: 'remove', params: {elementsToRemove}})
    setOperationType({operation: "", type: ""})
    setBotHistory(data)
    setFlag(true)
  }

  return (
    <div ref={ref} style={{height: '400px'}} onContextMenu={onContextMenu}>
      {!botCode?.elements?.length && <FallbackView />}
      {store.copyMessage.show ? copyMessage() : ''}
      {store.contextMenu.open ? contextMenu() : ''}
      <div className='dndflow' style={{height: `calc(100vh - ${spacing}px)`}} onMouseDown={(ev) => ev.button === 0 && setShowContextMenu(false)}>
      {isLoading ? <FallbackView/> : <ReactFlowProvider>
          <div className='reactflow-wrapper' ref={reactFlowWrapper}>
            <ReactFlow
              nodeTypes={nodeTypes}
              elements={botCode.elements}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              onLoad={onLoad}
              edgeTypes={isSmartEdge ? { smart: SmartEdge } : {}}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onNodeDragStop={onNodeDragStop}
              connectionLineType={ConnectionLineType.SmoothStepEdge}
              zoomOnDoubleClick={false}
              onDoubleClick={OnDoubleClick}
              onMoveEnd={onMoveEnd}
              onNodeDoubleClick={onNodeDoubleClick}
              onElementClick={onElementClick}
              defaultPosition={[botCode?.appearance3?.flowSettings?.x || 0, botCode?.appearance3?.flowSettings?.y || 0]}
              defaultZoom={botCode?.appearance3?.flowSettings?.zoom || 0}
            >
              <Background variant='lines' gap={15} size={2} />
              <Controls />
            </ReactFlow>
          </div>
        </ReactFlowProvider>}
        {typeDropdownOpen && (
          <div
            className='dropdown position-absolute d-flex align-items-center'
            style={{top: nodePosition.y, left: nodePosition.x, zIndex: 10}}
          >
            <NodeDropdown changeType={changeType} />
          </div>
        )}
      </div>
      {flyoutShown ? (
        <SettingsFlyout
          template={template}
          setTemplate={setTemplate}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          flyoutShown={flyoutShown}
          setFlyoutShown={setFlyoutShown}
          // updateBotElement={(element) => updateBotElement(moduleId, element)}
        ></SettingsFlyout>
      ) : (
        ''
      )}
      <BotCopyModal
        setOperationType={setOperationType}
        operationType={operationType}
        copyTreeElements={copyTreeElements}
        removeTreeElements={removeTreeElements}
      />
    </div>
  )
}

export default observer(DnDFlow)
