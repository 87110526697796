/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {getGlobalItem, isGroweoPartner, setGlobalItem, tableDisplayDate} from '../../../../../utils/utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import {getWorkspace, loginWorkspace} from '../../../../../service/workspace.service'

type Props = {
  subscriptionId :string
}
const WorkspaceLists: FC<Props> = ({subscriptionId}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const history = useHistory()
  const [workspaceLists, setWorkspaceLists] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getWorkspace({ subscriptionPlanId:subscriptionId})
      if (data) setWorkspaceLists(data)
    }
    fetchData()
  }, [])

  const onLogin = async (workspaceId: string) => {
    const userData = await loginWorkspace(workspaceId)
    const userSessionData = getGlobalItem('user')
    userData.contentAdminRole = userSessionData['role']
    setGlobalItem('user', userData)
    document.location.reload()
  }

  const renderValue = (value: any, link?: any) => (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <a
          onClick={!isGroweoPartner()?() => history.push(link):()=>{}}
          className={`text-dark fw-bolder text-muted text-hover-primary fs-4 ${
            (link&&!isGroweoPartner()) ? 'cursor-pointer' : ''
          }`}
        >
          {value}
        </a>
      </div>
    </div>
  )
  const loadingSpinner = (
    <div className='d-flex flex-row justify-content-center'>
      <div className='lds-ring'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
  return (
    <>
      <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
      {workspaceLists.length === 0 ? loadingSpinner :
        <div className='card-body py-3'>
          
            <div className='table-responsive'>
            
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder'>
                    <th className='min-w-125px fs-4'>{t('CUSTOMER', { count: 0 })}</th>
                    <th className='min-w-125px fs-4'>{t('DOMAIN')}</th>
                    <th className='min-w-125px fs-4'>{t('CREATED')}</th>
                    <th className='min-w-100px text-end fs-4'>{t('ACTION', { count: 1 })}</th>
                  </tr>
                </thead>
                <tbody>
                  {workspaceLists?.map((l: any) => (
                    <tr key={`workspaceList_${l._id}`}>
                      <td>{renderValue(l.name, `/workspaces/${l._id}`)}</td>
                      <td>{renderValue(l.domain)}</td>
                      <td>{renderValue(tableDisplayDate(l.created))}</td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            onClick={() => onLogin(l._id)}
                            className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
                            title={t('LOGIN_BTN')}
                          >
                            <KTSVG path='/media/icons/duotune/arrows/arr076.svg' className='svg-icon-3' />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            
            </div>
         
          </div>
         }
      </div>
   
    </>
  )
}

export default observer(WorkspaceLists)
