/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

interface AccessConfig {
  limits?: {publish_app: number; total_app: number}
  modulesPermission?: string[] | undefined
}

type Props = {
  modulePermissions: string[]
  updateSubscription: Function
  navigateToEdit: Function
  subscription: any
  setSubscription: any
}
const ModuleLists: FC<Props> = ({
  modulePermissions,
  updateSubscription,
  navigateToEdit,
  subscription,
  setSubscription,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const accessConfig: AccessConfig = subscription.accessConfig
  const [publishAppInput, setPublishAppInput] = useState(false)
  const [totalAppInput, setTotalAppInput] = useState(false)

  const renderAppCount = (
    module: string,
    name: string,
    appCount: any,
    toggleInput: any,
    setToggleInput: any
  ) => {
    return module === 'GROWTH_APPS' ? (
      <>
        {!toggleInput && (
          <p className='m-0' onDoubleClick={() => setToggleInput(!toggleInput)}>
            {appCount}

            <a onClick={() => setToggleInput(!toggleInput)} className='btn btn-icon btn-sm mx-1'>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </a>
          </p>
        )}

        {toggleInput && (
          <input
            value={appCount}
            name={name}
            className={'w-75'}
            onChange={(e: any) => {
              setSubscription({
                ...subscription,
                accessConfig: {
                  ...subscription.accessConfig,
                  limits: {...subscription.accessConfig.limits, [e.target.name]: e.target.value},
                },
              })
            }}
            onBlur={() => setToggleInput(!toggleInput)}
            onKeyUp={(e: any) => {
              if (e.key === 'Enter' && e.target.value) {
                setToggleInput(!toggleInput)
              }
            }}
          />
        )}
      </>
    ) : (
      ''
    )
  }
  return (
    <div className='card card-flush pt-3 mb-5 mb-lg-10'>
      <div className='card-header'>
        <div className='card-title'>
          <a
            href='#'
            className='text-gray-800 text-hover-primary fs-2 fw-bolder me-3 text-capitalize'
          >
            {t('MODULE', {count: 1})}
          </a>
        </div>

        <div className='card-toolbar'>
          {/* <button type="button" className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_product">Save</button> */}
          <button
            type='button'
            className='btn btn-light-primary'
            onClick={() => updateSubscription()}
          >
            {t('SAVE')}
          </button>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <div
            id='kt_subscription_products_table_wrapper'
            className='dataTables_wrapper dt-bootstrap4 no-footer'
          >
            <div className='table-responsive'>
              <table
                className='table align-middle table-row-dashed fs-6 fw-bold gy-4 dataTable no-footer'
                id='kt_subscription_products_table'
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th
                      className='min-w-300px sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '340.278px'}}
                    >
                      {t('MODULE', {count: 0})}
                    </th>
                    <th
                      className='min-w-100px sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '115.521px'}}
                    >
                      {t('ENABLED')}
                    </th>
                    <th
                      className='min-w-70px sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '80.3993px'}}
                    >
                      {t('PUBLISH_QTY')}
                    </th>
                    <th
                      className='min-w-70px sorting_disabled'
                      rowSpan={1}
                      colSpan={1}
                      style={{width: '80.3993px'}}
                    >
                      {t('TOTAL_QTY')}
                    </th>
                  </tr>
                </thead>
                <tbody className='text-gray-600'>
                  {modulePermissions.map((module: string, index: any) => (
                    <tr className='odd' key={`modulePermissions_${index}`}>
                      <td>{t(module, {count: 1})}</td>
                      <td>
                        <input
                          className='form-check-input cursor-pointer'
                          type='checkbox'
                          onChange={(event) => navigateToEdit(event, index, module)}
                          checked={
                            accessConfig?.modulesPermission?.indexOf(module) !== -1 && accessConfig
                              ? true
                              : false
                          }
                        />
                      </td>
                      <td>
                        {renderAppCount(
                          module,
                          'publish_app',
                          accessConfig?.limits?.publish_app,
                          publishAppInput,
                          setPublishAppInput
                        )}
                      </td>
                      <td>
                        {renderAppCount(
                          module,
                          'total_app',
                          accessConfig?.limits?.total_app,
                          totalAppInput,
                          setTotalAppInput
                        )}
                      </td>
                      {/* <td>{module === 'GROWTH_APPS' ? accessConfig?.limits?.total_app : ''}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModuleLists
