/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import moment from 'moment'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {useHistory, useLocation} from 'react-router-dom'
import {getModulePath} from '../../../../utils/module.utils'
import SelectInputFeild from '../../../../app/shared/SelectInputFeild'

type AutomationPageToolbarProps = {
  from?: any
  title?: any
  setDateRange: any
  setPreviousRange?: any
  downloadPdf?: any
  lastOneYearData?: any
  isHideBack: boolean
}

const CustomerReportToolbar: FC<AutomationPageToolbarProps> = ({
  from,
  title,
  setDateRange,
  setPreviousRange,
  downloadPdf,
  lastOneYearData,
  isHideBack,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  const history = useHistory()
  const {state}: any = useLocation()
  const [selectedValue, setSelectedValue]: any = useState('')

  const onSelectDateRange = (_name: any, month: any) => {
    if (month.split(' ')[0] !== '01') {
      var startDate = moment().subtract(30, 'days').format('ll')
      var endDate = moment().format('ll')
    } else {
      startDate = moment(month).startOf('month').format('ll')
      endDate = moment(month).endOf('month').format('ll')
    }
    const previousStartDate = moment(month).subtract(1,'months').startOf('month').format('ll')
    const previousEndDate = moment(month).subtract(1,'months').endOf('month').format('ll')
    setSelectedValue(month)
    setDateRange({startDate, endDate})
    setPreviousRange({startDate: previousStartDate, endDate: previousEndDate})
  }

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack p-0')}
      >
        <div>
          <SectionHeading title={`${t(title)}`} subTitle={t(`${title}_SUBTITLE`)} />
        </div>
        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center position-relative mx-2'>
            <SelectInputFeild
              hideDefault={true}
              name='name'
              classes='bg-white'
              value={selectedValue}
              options={lastOneYearData}
              onChange={onSelectDateRange}
            />
          </div>
          <div className='d-flex align-items-center mx-2'>
            <a
                className='btn btn-success text-uppercase'
                onClick={downloadPdf}
                >
                <KTSVG
                    path='/media/icons/duotune/arrows/arr091.svg'
                    className='svg-icon-5 svg-icon-gray-500 me-1'
                />
                {t('DOWNLOAD_PDF', {count: 0})}
            </a>
          </div>
          {!isHideBack && (
            <a
              className='btn btn-primary text-uppercase mx-2'
              onClick={() => history.push({
                      pathname: `${getModulePath(from)}`,
                      state,
                    })
                  } 
            >
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon svg-icon-2' />
              {t('BACK')}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export {CustomerReportToolbar}
