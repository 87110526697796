import EmailEditor from 'react-email-editor'
import {useIntl} from 'react-intl'

const EmailBuilder = (props) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  let editor = undefined
  const { emailTemplate, onChange, customFields, styles } = props

  const init = () => {
    if (editor) {
      editor.setMergeTags(customFields)
      editor.addEventListener('design:updated', function (updates) {
        if (editor) {
          editor.exportHtml(function (data) {
            const { html, design } = data
            onChange({ html, design: JSON.stringify(design) })
          })
        }
      })
      const design = emailTemplate?.design
      editor.loadDesign(JSON.parse(design))
    }
  }

  const setEditorRef = (e) => {
    if (e) editor = e
  }

  return (
  <EmailEditor
    projectId={82129}
    ref={(e) => setEditorRef(e)}
    onLoad={init}
    onReady={init}
    style={styles || {height: '90vh'}}
    locale={t('UNLAYER_LANGUAGE')}
  />
  )
}

export default EmailBuilder
