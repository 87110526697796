import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import {observer} from 'mobx-react'
import AppCard from '../common/shared/card/AppCard'
import AppsToolbar from '../../../_metronic/layout/components/toolbar/AppsToolbar'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import AppName from '../common/shared/modal/AppName'
import ConfirmModal from '../common/modal/ConfirmModal'
import AppPublishModal from '../common/modal/AppPublishModal'
import {filterType, getFilterAppType, getModulePath} from '../../../utils/module.utils'
import GrowthEngineBreadCrumb from '../../pages/growth-engine/GrowthEngineBreadCrumb'
import AppTable from '../common/shared/table/AppTable'
import TextModal from '../common/modal/TextModal'
import {getModuleById, updateModule, deleteModule, getModule, getModuleCount} from '../../../service/module.service'
import {useStore} from '../../../store'
import { isWorkspaceReadonly } from '../../../utils/utils'
import { createModuleLibrary } from '../../../service/moduleLibrary.service'
import LibraryModal from '../libraries/LibraryModal'

type ListProps = {
  from?: string
  title?: string
}

const List: FC<ListProps> = ({from, title = 'App'}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const {authStore} = useStore()
  const {setModuleAccessLimit} = authStore
  const history = useHistory()
  const {location} = history
  const modalId = `gw_modal_${from || 'app'}_name`
  const duplicateModalId = `gw_modal_duplicate_name`
  const [showPublishSuccessModal, setShowPublishSuccessModal] = useState(false)
  const [moduleList, setModuleList] = useState([])
  const [template, setTemplate]: any = useState({})
  const [appView, setAppView] = useState('cardView')
  const [type, setType]: any = useState(getFilterAppType)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [moduleList, appView])

  const onEdit = (data: any) => history.push(`${getModulePath(data.type)}/${data._id}/${data.type !== 'smartflow' ? 'design' : 'slides'}`)

  const duplicateHandler = async (data: any) => {
    const moduleData = await getModuleById(data._id)
    setTemplate(moduleData)
  }

  const onPublishOrUnpublish = async (isPublish: boolean, publishData?: Object) => {
    const data = await getModuleById(template._id)
    if (!data) return

    let appearance: any = {}
    if (data.botCode?.appearance3)  { //  Lets check for  appearence exist in bot code 
      appearance = data.botCode?.appearance3
    } else if(data.code?.appearance) {//  else Lets check for appearence exist in code 
      appearance = data.code?.appearance
    } else if(data.botCode?.appearance){
      appearance = data.botCode?.appearance
    }
    const {appearence, type, code, botCode, moduleCode, _id} = data
    const moduleData = {status: isPublish ? 'active' : 'draft'}
    if(isPublish) {
      const publishEvent = data.publishEvent || []
      publishEvent.push(publishData)
      Object.assign(moduleData, {publishEvent})
    }
    let content
    let useCode = botCode ? JSON.stringify({...botCode, appearance }) : code;
    if (['form', 'file', 'smartblock', 'smartpopup'].includes(type)) content = isPublish ? appearence?.html : null
    if (type === 'bot') content = isPublish ? useCode : null
    if (['smartbar', 'smartflow'].includes(type)) content = isPublish ? moduleCode ? JSON.stringify(moduleCode) : code : null
    Object.assign(moduleData, {content})        
    const updatedData = await updateModule(_id, moduleData)
    if (updatedData) setShowPublishSuccessModal(true)
    resetList()
  }

  const onArchieved = async () => {
    const data = await deleteModule(template._id)
    if (data) {
      const foundIndex = moduleList.findIndex((item: any) => item._id === template._id)
      moduleList.splice(foundIndex, 1)
      setModuleList([...moduleList])
    }
    resetList()
  }

  const onSaveAsLibrary = async ({ name, description }: any) => {
    const data = await getModuleById(template?._id)
    if (!data?._id) return
    const { _id, code, botCode, moduleCode, appearence, emailTemplate, documents, settings, type } = data
    const library = {
      name,
      description,
      moduleId: _id,
      type,
      json: JSON.stringify({ code, botCode, moduleCode, appearence, emailTemplate, documents, settings })
    }
    await createModuleLibrary({ ...library })
  }

  const resetList = async () => {
    const list = await getModule({type: from ? from : filterType(type)})
    if (!list) return
    const {records, totalCount, publishCount} = list
    setModuleList(sortBySession(records))
    setModuleAccessLimit(totalCount, publishCount)
  }

  const sortBySession = (records: any) => {
    return records.sort((r1: any, r2: any) => r1['sessionCount'] > r2['sessionCount'] ? -1 : 1 )
  }

  const onModalOpen = (data: string, type: string) => {
    setShowConfirmModal(true)
    setModalType(type)
    setTemplate(data)
  }

  const  publishData = async (data: string, type: string) => {  
    const moduleCount: any = await getModuleCount();
    setModuleAccessLimit(moduleCount?.totalCount, moduleCount?.publishCount)     
    setModalType(type)
    setTemplate(data)
  }

  const onConfirm = async () => {
    if (modalType === 'remove') onArchieved()
    else if (modalType === 'publish') onPublishOrUnpublish(true)
    else if (modalType === 'unpublish') onPublishOrUnpublish(false)
  }

  const onPublish = (data:any) =>{
   onPublishOrUnpublish(true, data)
  }

  return (
    <>
      {showPublishSuccessModal ? (
        <TextModal
          message={t(`${modalType?.toUpperCase()}_COMPLETE_MODAL_TEXT`)}
          close={() => setShowPublishSuccessModal(false)}
        ></TextModal>
      ) : (
        ''
      )}
      {location.pathname === '/gapps' ? <GrowthEngineBreadCrumb /> : ''}
      <AppsToolbar
        from={from}
        title={title}
        modalId={modalId}
        appView={appView}
        setAppView={setAppView}
        setModuleList={setModuleList}
        sortBySession={sortBySession}
        isPreview={isWorkspaceReadonly()}
        type={type}
        setType={setType}
      />
      {appView === 'cardView' ? (
        <div className='row g-6 g-xl-9 pt-2'>
          {moduleList?.map((d: any, idx: number) => (
            <div key={d.name.toString()} className='col-md-6 col-xxl-4'>
              <AppCard
                type={from ? from : d.type}
                name={d.name}
                status={d.status}
                leadCount={d.leadCount}
                sessionCount={d.sessionCount}
                childModule={d?.childModule || []}
                edit={() => onEdit(d)}
                archive={() => onModalOpen(d, 'remove')}
                duplicate={() => duplicateHandler(d)}
                published={() => publishData(d, 'publish')}
                unpublished={() => onModalOpen(d, 'unpublish')}
                saveAsLibrary={() => setTemplate(d)}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className='card mb-5 mb-xl-8 mt-2'>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <tbody>
                  {moduleList?.map((d: any, idx: number) => (
                    <AppTable
                      type={d.type}
                      name={d.name}
                      status={d.status}
                      leads={d.leadCount}
                      sessions={d.sessionCount}
                      childModule={d?.childModule || []}
                      edit={() => onEdit(d)}
                      archive={() => onModalOpen(d, 'remove')}
                      duplicate={() => duplicateHandler(d)}
                      published={() => publishData(d, 'publish')}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showConfirmModal && (
        <ConfirmModal
          icon={['publish', 'unpublish'].includes(modalType) ? 'question' : ''}
          label={modalType}
          show={showConfirmModal}
          close={setShowConfirmModal}
          onConfirm={onConfirm}
        />
      )}

      <AppPublishModal onPublish={onPublish} />
      <AppName from={from} title={title} modalId={modalId} />
      <AppName from={from} title={title} modalId={duplicateModalId} templateData={template} />
      <LibraryModal
        modalId={'gw_library_modal'}
        onSave={onSaveAsLibrary}
      />
    </>
  )
}

export default observer(List)
