/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import Card from './Card'
import Chart from './Charts'
import DashboardHeader from './DashboardHeader'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useStore } from '../../../store'

const DashboardWrapper: FC = () => {
  const { dashboardStore } = useStore()
  const [activeTab, setActiveTab] = useState('all')
  const { getDashboardCard, DashboardCardData, DashboardChartData } = dashboardStore

  const cardData = toJS(DashboardCardData)
  const chartData = toJS(DashboardChartData)

  useEffect(() => {
    getDashboardCard('all')
  }, [getDashboardCard])

  const onChangeTab = (e: string) => {
    setActiveTab(e)
    getDashboardCard(e)
  }

  return (
    <>
                <DashboardHeader activeTab={activeTab} setActiveTab={onChangeTab} />
      {!cardData.length ?
        (
          <div id="splash-screen" className="splash-screen">
            <img
              width='200px'
              height='100px'
              alt="Groweo logo"
              className="h-30px"
              src="/media/logos/groweo_logo.svg"
            />
            <span>Loading ...</span>
          </div>
        ) :
        (
          <>
            <div className='p-5' style={{ marginTop: 75 }}>
              <div className='row g-6 g-xl-9'>
                {cardData.map((d, i) => (
                  <div className='col-sm-3 col-xl-2 mt-1' key={i}>
                    <Card
                      image={d.image}
                      title={d.title}
                      status={d.status}
                      progress={d.progress}
                      statusDesc={d.statusDesc}
                      statusValue={d.statusValue}
                      description={d.description}
                      progressType={d.progressType}
                    />
                  </div>
                ))}
              </div>
              {Object.keys(chartData).length ? (
                <div className='mt-10'>
                  <Chart className='card-xl-stretch mb-5 mb-xl-8' chartData={chartData} />
                </div>
              ) : (
                <div id="splash-screen" className="splash-screen">
                  <img
                    width='200px'
                    height='100px'
                    alt="Groweo logo"
                    className="h-30px"
                    src="/media/logos/groweo_logo.svg"
                  />
                  <span>Loading ...</span>
                </div>
              )}
            </div>
          </>
        )}
    </>
  )
}

export default observer(DashboardWrapper)
