import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const TextModal: FC<any> = ({close, icon, message}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const NSwal = withReactContent(Swal)

  useEffect(() => {
    NSwal.fire({
      html: message,
      icon: icon || 'success',
      buttonsStyling: false,
      showCancelButton: false,
      confirmButtonText: t('OK'),
      customClass: {
        confirmButton: 'btn btn-primary mx-2',
        cancelButton: 'btn btn-danger mx-2',
      },
    }).then(({isConfirmed}) => {
      close()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default TextModal
