import {FC} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

type Props = {
  data?: any
}

const StepperBreadCrumb: FC<Props> = ({data}) => {
  const {name} = data
  const stepperBreadCrumb: Array<PageLink> = []
  return <PageTitle breadcrumbs={stepperBreadCrumb}>{name}</PageTitle>
}

export default StepperBreadCrumb
