/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {getAppSpecificReport} from '../../../../../service/performanceReport.service'
import {getDayDifference, getDecimal, sortNumber, sortString} from '../../../../../utils/utils'
import ReportTable from '../../../widgets/tables/ReportTable'
import GrowthAppFilterMenu from '../../../common/shared/filter/GrowthAppFilterMenu'
import { filterStatus, getFilterAppType } from '../../../../../utils/module.utils'
import AppTypePerformance from './AppTypePerformance'

interface RouteParams {
  workspaceId: string
}

type Props = {
  dateRange: any
}
const AppsPerformance: FC<Props> = ({dateRange}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {workspaceId} = useParams<RouteParams>()
  const [toggleSorting, setToggleSorting]: any = useState({
    sortBy: 'moduleName',
    sortDirection: true,
  })
  const [filter, setFilter]: any = useState({})
  const [appReportData, setAppReportData]: any = useState([])
  const [filterData, setFilterData]: any = useState([])
  const [pageData, setPageData]: any = useState([])
  const [activePage, setActivePage]: any = useState(1)
  const [type, setType]: any = useState(getFilterAppType)

  const onApply = () => {
    setFilter({...filter, type: filterStatus(type)})
  }

  const onReset = () => {
    setType(getFilterAppType)
    setFilter({...filter, type: filterStatus(getFilterAppType)})
  }

  useEffect(() => {
    if(Object.keys(filter).length) filterReportData(filter)
    // eslint-disable-next-line
  }, [filter])

  const filterReportData = (filter: any) => {
    const {sort, type} = filter
    const typeFilter = appReportData.filter(({ moduleType }: any) => type.includes(moduleType))
    let sortData =
      ['moduleName', 'moduleType'].includes(sort?.sortBy)
        ? sortString(typeFilter, sort?.sortDirection, sort?.sortBy)
        : sortNumber(typeFilter, sort?.sortDirection, sort?.sortBy)
    setFilterData([...sortData])
  }

  const onSort = (filterData: any, records: any) => {
    setToggleSorting({...filterData})
    setFilter({...filter, sort : filterData})
  }

  useEffect(() => {
    const getAppsData = async () => {
      let records = await getAppSpecificReport(workspaceId, dateRange)
      if(!records) return;
      const appsReport = records?.map((data: any) => {
        const visitorVSstarted = data['UNIQUE-VISITOR']
          ? getDecimal((data['SESSION'] / data['UNIQUE-VISITOR']) * 100 || 0)
          : 0
        const startedVSsuccessful = data['SESSION']
          ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['SESSION']) * 100 || 0)
          : 0
        const startedVScontact = data['SESSION']
          ? getDecimal((data['CONTACT'] / data['SESSION']) * 100 || 0)
          : 0
        const visitorsVScontact = data['UNIQUE-VISITOR']
          ? getDecimal((data['CONTACT'] / data['UNIQUE-VISITOR']) * 100 || 0)
          : 0
        const daysSinceInstalled = data['installationDate']
          ? `${getDayDifference(data['installationDate'])}`
          : 0
        const visitorsVSsuccessful = data['UNIQUE-VISITOR']
          ? getDecimal((data['SUCCESSFULL-CONVERSATION'] / data['UNIQUE-VISITOR']) * 100 || 0)
          : 0

        return {
          ...data,
          visitorVSstarted,
          startedVSsuccessful,
          startedVScontact,
          visitorsVScontact,
          daysSinceInstalled,
          visitorsVSsuccessful
        }
      })
      setAppReportData([...appsReport])
      setFilter({ sort: {
        sortBy: 'moduleName',
        sortDirection: true
      },
      type: filterStatus(getFilterAppType)})
    }
    getAppsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  useEffect(() => {
    const startIndex = activePage * 100 - 100
    const endIndex = activePage * 100
    setPageData([...filterData.slice(startIndex, endIndex)])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, activePage])

  return (
    <div className='tab-content' style={{marginTop: 0}}>
      <div className='card' style={{backgroundColor: '#f5f8fa'}}>
        <div className='card-body' style={{padding: '1rem'}}>
          <div className='d-flex justify-content-between align-items-start flex-wrap'>
            <h4 className='card-title align-items-start flex-column'>
              <span className='text-dark fs-2 fw-bolder me-3 text-capitalize'>
                {t('APPLICATION_REPORT', {count: 1})}
              </span>
              <br />
              <span className='text-muted fw-bold fs-7'>{t('APPLICATION_REPORT_SUBTITLE', {count: 1})}</span>
            </h4>
            <div className='d-flex align-items-center py-1'>
              <GrowthAppFilterMenu
                type={type}
                setType={setType}
                onApply={onApply}
                onReset={onReset}
                appType={getFilterAppType}
              />
            </div>
          </div>
        </div>
        <ReportTable
          onSort={onSort}
          pageData={pageData}
          filterData={filterData}
          toggleSorting={toggleSorting}
          type={'app_report'}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </div>
      <AppTypePerformance
        dateRange={dateRange}
       />
    </div>
  )
}
export default AppsPerformance