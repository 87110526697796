/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import WorkspaceLists from './WorkspaceLists'
import WorkspaceView from './view/WorkspaceView'

const WorkspaceContainer: FC = () => {
  return (
    <Switch>
      <Route path='/workspaces' component={WorkspaceLists} exact />
      <Route path='/workspaces/:workspaceId' component={WorkspaceView} exact />
    </Switch>
  )
}

export default WorkspaceContainer
