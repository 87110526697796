/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useSmartBarData} from '../StepperContext'
import {codeForSmartBar} from '../../../../../utils/code.utils'
import TextModal from '../../../common/modal/TextModal'
import SelectInputFeild from '../../../../shared/SelectInputFeild'
import {LANGUAGES} from '../../../../../utils/utils'

const SmartBarCode = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {smartBar, setSmartBar}: any = useSmartBarData()
  const [showSwalModal, setShowSwalModal] = useState(false)
  const [language, setLanguage]: any = useState('fi')
  const [selected, setSelected]: any = useState({})
  const [includeInpage, setIncludeInPage]: any = useState()
  const [excludeInpage, setExcludeInPage]: any = useState()
  const tagifyIncludeInpageRef: any = useRef()
  const tagifyExcludeInpageRef: any = useRef()
  useEffect(() => {
    onAppearanceLoad(smartBar?.moduleCode?.appearance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    smartBar?.moduleCode?.appearance?.includeInpage,
    smartBar?.moduleCode?.appearance?.excludeInpage,
  ])
  useEffect(() => {
    const appearance = smartBar?.moduleCode?.appearance
    if (appearance && !appearance.includeInpage) onAppearanceLoad(smartBar?.moduleCode?.appearance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartBar?.moduleCode?.appearance])
  useEffect(() => {
    if (includeInpage) onInputChange('includeInpage', includeInpage)
    // eslint-disable-next-line
  }, [includeInpage])
  useEffect(() => {
    if (excludeInpage) onInputChange('excludeInpage', excludeInpage)
    // eslint-disable-next-line
  }, [excludeInpage])
  const onAppearanceLoad = (appearance: any) => {
    if (!appearance) return
    const {includeInpage, excludeInpage, direction, autoBarHeight} = appearance
    if (!appearance.hasOwnProperty('includeInpage')) setIncludeInPage(['/*'])
    setSelected({
      includeInpage,
      excludeInpage,
      direction,
      autoBarHeight,
    })
  }
  const onCopy = async (id: string) => {
    if (navigator.clipboard.writeText) {
      const el: any = document.getElementById(id)
      await navigator.clipboard.writeText(el.value)
      setShowSwalModal(true)
    }
  }
  const onInputChange = (name: string, value: any) => {
    const appearance = {...selected, [name]: value}
    setSmartBar({
      ...smartBar,
      moduleCode: {
        ...smartBar.moduleCode,
        appearance: {...smartBar?.moduleCode?.appearance, ...appearance},
      },
    })
    setSelected(appearance)
  }
  const onTagifyInputChange = (name: string, e: any) => {
    let data: any = []
    const arr = e.detail.value ? JSON.parse(e.detail.value) : []
    arr.forEach((item: any) => data.push(item.value))
    name === 'includeInpage' ? setIncludeInPage(data) : setExcludeInPage(data)
  }
  const renderCodeInput = (id: string) => (
    <>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <label className='fs-5 fw-semibold mt-5 mb-2'>{t('INSTALLATION_CODE')}</label>
        <button onClick={() => onCopy(id)} className='btn btn-sm btn-primary mt-4'>
          {t('COPY_CODE')}
        </button>
      </div>
      <div>
        <textarea
          spellCheck='false'
          value={getScriptCode()}
          id={id}
          className='form-control form-control-lg form-control-solid'
          data-kt-autosize='true'
          readOnly
        ></textarea>
      </div>
    </>
  )
  const getScriptCode = () => {
    return codeForSmartBar(smartBar?._id, smartBar?.workspaceId, false, language)
  }
  const renderTagifyInput = (name: string) => {
    if (name === 'includeInpage' && tagifyIncludeInpageRef && tagifyIncludeInpageRef.current) {
      tagifyIncludeInpageRef.current.settings.blacklist = selected.excludeInpage || []
    } else if (
      name === 'excludeInpage' &&
      tagifyExcludeInpageRef &&
      tagifyExcludeInpageRef.current
    ) {
      tagifyExcludeInpageRef.current.settings.blacklist = selected.includeInpage || []
    }
    return (
      <Tags
        className='form-control form-control-solid'
        tagifyRef={name === 'includeInpage' ? tagifyIncludeInpageRef : tagifyExcludeInpageRef}
        settings={{
          trim: true,
          pasteAsTags: true,
          pattern: /[/]/,
        }}
        value={selected[name]}
        onChange={(e) => onTagifyInputChange(name, e)}
      />
    )
  }
  return (
    <>
      {renderCodeInput('smartBarInstallationCode')}
      <label className='fs-5 mt-5 mb-2 fw-bolder'>{t('INSTALLATION_SETTINGS')}</label>
      <div>
        <label className='fs-5 fw-semibold mb-2'>{t('DIRECTION')}</label>
        <label className='form-check form-check-custom form-check-solid form-switch m-1' style={{width: 'fit-content'}}>
          <span className='form-check-label text-muted mx-3'>{t('LEFT')}</span>
          <input
            className='form-check-input'
            type='checkbox'
            checked={selected?.direction === 'right'}
            onChange={(ev) => onInputChange('direction', ev.target.checked ? 'right' : 'left')}
          />
          <span className='form-check-label text-muted mx-3'>{t('RIGHT')}</span>
        </label>
        <label className='fs-5 fw-semibold mt-5 mb-2'>{t('AUTO_BAR_HEIGHT')}</label>
        <label className='form-check form-check-custom form-check-solid form-switch m-1' style={{width: 'fit-content'}}>
          <span className='form-check-label text-muted mx-3'>{t('OFF')}</span>
          <input
            className='form-check-input'
            type='checkbox'
            checked={selected?.autoBarHeight}
            onChange={(ev) => onInputChange('autoBarHeight', ev.target.checked)}
            // onBlur={onSave}
          />
          <span className='form-check-label text-muted mx-3'>{t('ON')}</span>
        </label>
      </div>
      <label className='fs-5 fw-semibold mt-5 mb-2'>{t('LANGUAGE')}</label>
      <SelectInputFeild
        name='language'
        value={language}
        options={LANGUAGES}
        onChange={(_name: string, value: any) => setLanguage(value)}
      />
      <label className='fs-5 fw-semibold mt-5 mb-2'>{t('INCLUDE_INPAGE')}</label>
      {renderTagifyInput('includeInpage')}
      <label className='fs-5 fw-semibold mt-5 mb-2'>{t('EXCLUDE_INPAGE')}</label>
      {renderTagifyInput('excludeInpage')}
      {showSwalModal ? (
        <TextModal
          message={t('COPIED_MSG')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
    </>
  )
}
export default SmartBarCode
