/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {useIntl} from 'react-intl'

import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  datas: {value: number; category: string}[]
  title: string
  subTitle: string
}

const ApplicationStatisticsChart: React.FC<Props> = ({className, datas, title, subTitle}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const chartRef = useRef<HTMLDivElement | null>(null)
  const initChart = (element: HTMLElement) => {
    const baseColor = getCSSVariableValue('--bs-gray-500')
    const color1 = getCSSVariableValue('--bs-warning')
    const color4 = getCSSVariableValue('--bs-danger')
    const color5 = getCSSVariableValue('--bs-info')
    const color6 = getCSSVariableValue('--bs-secondary')
    const colors = [color1, color4, color6, color4, color5, color6]
    const root = am5.Root.new(element)
    root.setThemes([am5themes_Animated.new(root)])
    let chartData = datas.map((data, i) => {
      return {
        value: data.value,
        category: data.category,
        fill: am5.color(colors[i]),
      }
    })

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 180,
        endAngle: 360,
        layout: root.verticalLayout,
        innerRadius: am5.percent(55),
        radius: am5.percent(62),
      })
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    // start and end angle must be set both for chart and series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        startAngle: 180,
        endAngle: 360,
        valueField: 'value',
        categoryField: 'Application Type',
        alignLabels: false,
      })
    )
    // if (chart.root._logo) chart.root._logo.dispose()
    series.labels.template.setAll({
      fontWeight: '400',
      fontSize: 13,
      fill: am5.color(baseColor),
    })
    series.states.create('hidden', {
      startAngle: 180,
      endAngle: 180,
    })
    series.slices.template.setAll({
      cornerRadius: 5,
    })
    series.ticks.template.setAll({
      forceHidden: true,
    })
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(chartData)
    series.appear(1000, 100)
  }

  useEffect(() => {
    if (!chartRef.current) return
    if (chartRef.current) initChart(chartRef.current)
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
          <span className='text-muted fw-bold fs-7'> {subTitle}</span>
        </h3>
      </div>

      <div className='card-body '>
        {datas[0].value !== 0 ? (
          <div
            ref={chartRef}
            id='kt_charts_widget_17_chart'
            style={{height: '400px'}}
            className='card-rounded-bottom pt-10'
          ></div>
        ) : (
          <div className='fs-4 fw-bolder d-flex justify-content-center align-items-center h-100'>
            {t('EMPTY.TEXT')}
          </div>
        )}
      </div>
    </div>
  )
}

export default ApplicationStatisticsChart
