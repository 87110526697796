/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {sendMail} from '../../../../../service/common.service'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FallbackView} from '../../../../../_metronic/partials'
import TextInputFeild from '../../../../shared/TextInputFeild'
import EmailBuilder from '../../../emailTemplate/editor/EmailBuilder'
import EmailBuilderJs from '../../../emailTemplate/editor/EmailBuilderJs'
import TextModal from '../../modal/TextModal'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'
type EmailTemplateCardProps = {
  module?:string
  template?: any
  emailTemplate: any
  onEmailTemplateSave?: any
  customFields?: any
}

const EmailTemplateCard: FC<EmailTemplateCardProps> = ({
  module,
  template,
  emailTemplate,
  onEmailTemplateSave,
  customFields,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [data, setData]: any = useState({})
  const [flag, setFlag]: any = useState(false)
  const [toEmail, setToEmail]: any = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isLoading, setIsLoading]: any = useState(true)
  const [emailAddress, setEmailAddress] = useState(null)
  
  const tagifyRef = useRef()
  useEffect(() => {
    setData(emailTemplate)
    setIsLoading(false)
  }, [emailTemplate])
  
  useEffect(() => {
    if (isLoading) return
    const html = emailTemplate?.html
    if (!html) return
    const el: any = document.getElementById('emailTemplatePreview')
    const iframeEl = el.contentWindow.document
    let doc = iframeEl.open("text/html"); 
    doc.write(html);
    setIsLoading(false)
  }, [isLoading, emailTemplate])

  useEffect(() => {
    if (flag) {
      onBlur()
      setFlag(false)
    }
    // eslint-disable-next-line
  }, [flag])

  useEffect(() => {
    if (emailAddress || emailAddress === '') {
      setData({ ...data, toEmail: emailAddress })
      setFlag(true)
    } 
    // eslint-disable-next-line
  }, [emailAddress])

  const onChange = async (values: any) => {
    await setData({...data, ...values})
    setFlag(true)
  }

  const onBlur = () => {
    onEmailTemplateSave(data)
  }

  const renderInput = (name: string, label: string, type: string) => (
    <>
      <label className='form-label'>{label}</label>
      <input
        type={type}
        value={data?.[name]}
        className='form-control form-control-lg form-control-solid'
        onChange={(e) => setData({...data, [name]: e.target.value})}
        onBlur={onBlur}
      />
    </>
  )

  const onSubmit = async () => {
    const {subject, fromEmail} = emailTemplate || {}
    const html = emailTemplate?.html
    const data = await sendMail({html, subject, fromEmail, toEmail})
    if (!data) return
    setShowSuccessModal(true)
    setToEmail('')
  }

  const renderEmailTemplateModal = () => (
    <div className='modal fade' tabIndex={-1} id='gw_modal_emailTemplate'>
      <div className='modal-dialog h-100 m-0 min-w-100'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{t('CAMPAIGN_EMAIL_TEMPLATE')}</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body p-0'>
            {template?._id && emailTemplate?.html ? template?.type === 'smartblock' ? (
              <EmailBuilderJs
                emailTemplate={emailTemplate}
                onChange={onChange}
                customFields={customFields}
              />
            ) : (
              <EmailBuilder
                emailTemplate={emailTemplate}
                onChange={onChange}
                customFields={customFields}
              />
            ) : ('')}
          </div>
        </div>
      </div>
    </div>
  )

  const renderEmailSendModal = () => (
    <div className='modal fade' tabIndex={-1} id='gw_modal_sendEmail'>
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {t('SEND')} {t('EMAIL', {count: 0})}
            </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body mx-5 my-7'>
            <div className='fv-row mb-5'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>{t('TO_EMAIL')}</span>
              </label>
              <TextInputFeild
                type='email'
                value={toEmail}
                onChange={(_name: string, value: string) => setToEmail(value)}
              />
            </div>
            <div className='fv-row'>
              <div className='text-center '>
                <button
                  data-bs-dismiss='modal'
                  className='c-button c-button--type-solid'
                  disabled={!toEmail}
                  onClick={onSubmit}
                >
                  <span className='indicator-label'>
                    {t('SUBMIT')}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const toEmailHandler = (e: any) => {
    let str: any = ''
    const arr = e.detail.value ? JSON.parse(e.detail.value) : []
    arr.forEach((item: any) => (str += `${item.value},`))
    setEmailAddress(str)
  }

  let htmlTemplate = module ? 'none' : ''
  
  return (
    <div className='card card-flush py-4'>
      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          {module === 'notification' ? <span className='card-label fw-bolder fs-2 mb-1'>{t('NOTIFICATION_EMAIL_TITLE', {count: 0})}</span>: <span className='card-label fw-bolder fs-2 mb-1'>{t('CAMPAIGN_EMAIL_TEMPLATE')}</span>}
          {/*module === 'notification' ? <span className='text-muted fw-bold fs-7'>{t('NOTIFICATION_EMAIL_SUB_TITLE')}</span> : ''*/}
        </h3>
      </div>
      <div>
        <div className='card-body pt-0'>
          <div className='row'>
            { module ? '': <div className='col-md-8'>{renderInput('subject', t('EMAIL_SUBJECT'), 'text')}</div>}
            {module ? '' :
              <div className='col-md-4'>
                {renderInput('fromEmail', `${t('FROM_EMAIL')}`, 'email')}
              </div>}
          </div>
          {module &&
            <div className='row g-5'>
              <div className='col-md-12'>
              
              <label className='form-label'>{t('EMAIL_INPUT')}</label>
              <Tags
                className='form-control form-control-lg form-control-solid'
                tagifyRef={tagifyRef} // optional Ref object for the Tagify instance itself, to get access to  inner-methods
                settings={{
                  trim: true,
                  pasteAsTags: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  }}
                value={data?.toEmail}
                onChange={toEmailHandler}
              />
              </div>
              <div className='col-md-12'>{renderInput('subject', t('EMAIL_SUBJECT'), 'text')}</div>
            </div>
          }
        </div>
        {module !== 'notification' &&
          <div className='d-flex justify-content-between me-10 my-2'>
            <label className='fs-5 fw-bold mb-2 mx-10'>{t('PREVIEW')}</label>
            <div>
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
                data-bs-toggle='modal'
                data-bs-target='#gw_modal_sendEmail'
              >
                <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-3' />
              </a>
              <a
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
                data-bs-toggle='modal'
                data-bs-target='#gw_modal_emailTemplate'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </a>
            </div>
          </div>
        }
        {
          <div className='px-9 overflow-auto' style={{ height: '57vh', display: htmlTemplate }}>
            {isLoading ? (
              <FallbackView />
            ) : (
              <iframe id='emailTemplatePreview' height='98%' width='100%' title='emailTemplateView'></iframe>
            )}
          </div>
        }
        {renderEmailTemplateModal()}
        {renderEmailSendModal()}
        {showSuccessModal && (
          <TextModal
            message={t('EMAIL_SEND_MODAL_TEXT')}
            close={() => setShowSuccessModal(false)}
          ></TextModal>
        )}
      </div>
    </div>
  )
}

export default EmailTemplateCard
