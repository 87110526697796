import axios from 'axios'
import {getGlobalItem, handleError, qs} from '../utils/utils'

export const createSegment = async (data: any) => {
  try {
    const response = await axios.post(`/api/segment`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const updateSegment = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/segment/${id}`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const deleteSegment = async (id: string) => {
  try {
    const response = await axios.delete(`/api/segment/${id}`)
    return response.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const getSegment = async () => {
  try {
    const response = await axios.get(`/api/segments`)
    return response.data?.data?.records
  } catch ({response}) {
    handleError(response)
  }
}

export const getSegmentsWithExecute = async (params: any) => {
  try {
    const response = await axios.get(`/api/segments?${qs(params)}`)
    const segments = response.data?.data?.records
    const executeResults = await Promise.all(segments.map((s: any) => executeSegment1(s._id)))
    return segments.map((s: any, index: number) => ({ ...s, executeResults: executeResults[index] }))
  } catch ({response}) {
    handleError(response)
  }  
}

export const getSegmentById = async (id: string) => {
  try {
    const response = await axios.get(`/api/segment/${id}`)
    return response.data
  } catch ({response}) {
    handleError(response)
  }
}

export const executeSegment1 = async (id: string) => {
  try {
    const response = await axios.post('/api/segment1/execute/' + id)
    return response.data
  } catch (err) {
    handleError(err)
  }
}

export const executeSegment = async (query: any) => {
  try {
    const response = await axios.post('/api/segment/execute', { query })
    return response.data
  } catch (err) {
    handleError(err)
  }
}
