/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials/content/dropdown/Dropdown1'

import {useIntl} from 'react-intl'
type Props = {
  className: string
  title:string
  datas : {title:string, subTitle:string, average:number}[]
}
const RecentAudienceList: React.FC<Props> = ({className,title, datas}) => {
  const intl = useIntl()
const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  return (
    <div className={`card ${className}`}>
    {/* begin::Header */}
    <div className='card-header border-0'>
      <h3 className='card-title fw-bolder text-dark'>{title}</h3>
      <div className='card-toolbar'>
        {/* begin::Menu */}
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
        </button>
        <Dropdown1 />
        {/* end::Menu */}
      </div>
    </div>
    {/* end::Header */}
    {/* begin::Body */}
    <div className='card-body pt-2'>
      <div className='d-flex align-items-center mb-8'>
      <span className={` h-40px `}></span>
      <div className='form-check form-check-custom form-check-solid mx-5'></div>
      <div className='flex-grow-1'>

                <span className='text-muted fw-bold d-block' style={{paddingLeft:'30px'}}>{t('APP_NAME' , {count:0})}</span>
              
        </div>
        <div className='d-flex text-muted   w-100px me-2'>{t('CONVERSION_PERCENTAGE' , {count:0})}</div>
      </div> 
      {datas.map((data,i)=>{
        let style = "";
        if(i%4===0)
            style="success"
        else if(i%4===1)
            style="primary"
        else if(i%4===2)
            style="warning"
        else
            style="danger"

        return(
            /* begin::Item */
            <div className='d-flex align-items-center mb-8'>
            {/* begin::Bullet */}
            <span className={`bullet bullet-vertical h-40px bg-${style}`}></span>
            {/* end::Bullet */}
            {/* begin::Checkbox */}
            <div className='form-check form-check-custom form-check-solid mx-5'>
              <input className='form-check-input' type='checkbox' value='' />
            </div>
            {/* end::Checkbox */}
            {/* begin::Description */}
            <div className='flex-grow-1'>
              <a href='#' className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                {data.title}
              </a>
              <span className='text-muted fw-bold d-block'>{data.subTitle}</span>
            </div>
            {/* end::Description */}
            <div className="d-flex flex-column w-100px me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-bold">{data.average}%</span>
                    </div>
                    <div className="progress h-6px w-100">
                      <div className={`progress-bar bg-${style}`} role="progressbar" style={{width: data.average + "%"}}></div>
                    </div>
            </div>
            {/* <span className={`badge badge-light-${style} fs-8 fw-bolder`}>{data.status}</span> */}
            </div>
            /* end:Item */
        )
      })}
    </div>
    {/* end::Body */}
  </div>
  )
}
export default RecentAudienceList