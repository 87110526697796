import React from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'

const SelectInputFeild = (props: any) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const valid = (key: string) => {
    let name = props.formik[key]
    props.name.split('.').forEach((item: any) => (name = name?.[item]))
    return name
  }

  const onChange = (e: any) => {
    const {name, value} = e.target
    props.onChange(name, value)
  }

  return (
    <>
      {props.formik ? (
        <>
          <select
            className={clsx(
              'form-select form-select-solid form-select-lg',
              {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
              {
                'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name],
              }
            )}
            {...props.formik.getFieldProps(props.name)}
            onBlur={props?.onBlur}
            disabled={props.disabled}
          >
            <option value={''}>{t('SELECT')}</option>
            {props.options.map((option: any, index: number) => (
              <option
                value={option[props?.format?.value || 'value']}
                key={`${props.name}_${index}`}
              >
                {option[props?.format?.text || 'text']}
              </option>
            ))}
          </select>
          {valid('touched') && valid('errors') && (
            <div className='text-danger'>{valid('errors')}</div>
          )}
        </>
      ) : (
        <select
          className={clsx('form-select form-select-solid form-select-lg', props.classes)}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          value={props.value}
          onBlur={props.onBlur}
          onChange={onChange}
        >
          {!props?.hideDefault && (props?.appendOption ? (
            <option value={props?.appendOption?.value}>{t(props?.appendOption?.name)}</option>
          ) : (
            <option value={''}>{t('SELECT')}</option>
          ) )}
          
          {props.options.map((option: any, index: number) => (
            <option value={option[props?.format?.value || 'value'] ? option[props?.format?.value || 'value'] : option} key={`${props.name}_${index}`}> 
              {option[props?.format?.text || 'text'] ? option[props?.format?.text || 'text'] : option}
            </option>
          ))}
        </select>
      )}
    </>
  )
}

export default SelectInputFeild
