import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getModuleById, getModuleStats} from '../../../service/module.service'
import {getTemplateById} from '../../../service/template.service'
import { isBotAccessible } from '../../../utils/utils'
import Stepper from '../common/stepper'
interface RouteParams {
  moduleId: string
  step: string
  templateId: string
}

let templateData: any = {}
let statsData: any = []

const BotsDetailContainer: FC = () => {
  const [template, setTemplate] = useState(templateData)
  const {moduleId, step, templateId} = useParams<RouteParams>()
  const [stats, setStats] = useState(statsData)
  const disableBot = isBotAccessible()

  useEffect(() => {
    if (disableBot) window.history.back();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTemplateData = async (type: string) => {
    let data
    if (type === 'template') data = await getTemplateById(templateId)
    if (type === 'module') {
      data = await getModuleById(moduleId)
      const sData = await getModuleStats(moduleId)
      statsData = sData || []
      setStats(statsData)
    }
    if (data) setTemplate(data)
    if (templateData?._id !== data?._id) initTemplate(data)
  }

  useEffect(() => {
    if (templateData?._id !== moduleId) getTemplateData('module')
    // eslint-disable-next-line
  }, [moduleId])

  useEffect(() => {
    if (templateId && templateId !== 'create') getTemplateData('template')
    // eslint-disable-next-line
  }, [templateId])

  const setTemplateData = (value: any) => initTemplate(value)

  const initTemplate = (data: any) => {
    setTemplate(data)
    templateData = data
  }

  return (
    <>
      <Stepper
        from={'bot'}
        moduleId={moduleId}
        templateId={templateId}
        step={step}
        templateData={template}
        setTemplateData={setTemplateData}
        statsData={stats}
      />
    </>
  )
}

export default BotsDetailContainer
