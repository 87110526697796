import {FC} from 'react'
import {useIntl} from 'react-intl'
import FormBuilder from './FormBuilder'
import CustomizeForm from '../../common/shared/CustomizeForm'

type FormPageProps = {
  templateId?: any
  template?: any
  setIsPreview?: any
  setIsClear?: any
  isClear?: boolean
  isPreview?: boolean
  setTemplate?: any
  from?: string
}

const FormPage: FC<FormPageProps> = ({
  templateId,
  template,
  setIsPreview,
  setIsClear,
  isClear,
  isPreview,
  setTemplate,
  from,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const onGetData = (code: any, content: any) => {
    const formEl = document.getElementById('form-appearance')
    if (!template?.appearence)
      template.appearence = {theme: '', popup: 'click', delay: '', html: ''}
    if (formEl) {
      const data = content.replaceAll('form-control', '').replaceAll('rendered-form', 'customForm')
      formEl.innerHTML = data
      template.appearence.html = JSON.stringify(data)
      const el = document.getElementById('customize-form')
      if (el) template.appearence.html = JSON.stringify(el.innerHTML)
    }
    template.code = JSON.stringify(code)
    setTemplate(template)
  }

  const themeData =
    template?.appearence?.theme ||
    '{"background":"","width":"auto","height":"auto","theme":"white","styles":"none-style"}'

  return (
    <>
      <form className='form w-100' noValidate>
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>{t('BUILDER')}</label>
          <FormBuilder
            loadForm={template?._id || templateId === 'create'}
            formData={template.code}
            setFormData={onGetData}
            isClear={isClear}
            isPreview={isPreview}
            setIsClear={setIsClear}
            setIsPreview={setIsPreview}
            from={from}
          />
        </div>
      </form>
      <div className='d-none' id='customize-form'>
        <CustomizeForm data={{...JSON.parse(themeData)}} />
      </div>
    </>
  )
}

export default FormPage
