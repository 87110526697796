import $ from 'jquery'
import {createRef, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap-v5'
import {inputTypes} from './Modal'
import {KTSVG} from '../../../../_metronic/helpers'
import './editor.css'
import { getFormLanObj, getFormLanValue } from '../locale/locale.util'
window.jQuery = $
window.$ = $

require('jquery-ui-sortable')
require('formBuilder')
require('formBuilder/dist/form-render.min.js')

let previousFormData

const FormBuilder = (props) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const fb = createRef()
  const [fbBuilder, setFbBuilder] = useState(null)
  const [flag, setFlag] = useState(false)
  const [show, setShow] = useState(false)
  const objInputTypes = {}

  const fields = [
    {
      label: getFormLanValue('Email'),
      type: 'text',
      subtype: 'email',
      icon: '&#9993;',
      name: 'email',
    },
    {
      label: getFormLanValue('Phone'),
      type: 'text',
      subtype: 'tel',
      icon: '&#128222;',
      name: 'phone',
    },
    {
      label: getFormLanValue('Submit'),
      type: 'button',
      subtype: 'submit',
      icon: '▤',
      name: 'submit',
      className: 'btn-success btn form-submit',
    },
  ]

  useEffect(() => {
    if (flag) {
      if (fbBuilder.actions.getData) {
        const formData = fbBuilder.actions.getData()
        if (previousFormData !== JSON.stringify(formData)) {
          const $markup = $('<div/>')
          $markup.formRender({formData})
          props.setFormData(formData, $markup.formRender('html'))
        }
      }
      setFlag(false)
    }
    // eslint-disable-next-line
  }, [flag])

  const events = {
    onadd: () => updateFormData(),
    onclone: () => updateFormData(),
    onremove: () => {
      setTimeout(() => {
        updateFormData()
      }, [500])
    },
  }
  inputTypes.forEach((key) => (objInputTypes[key] = events))

  const updateFormData = () => setFlag(flag ? false : true)

  const options = {
    dataType: 'xml',
    disabledActionButtons: ['save', 'data', 'clear'],
    typeUserEvents: objInputTypes,
    formData: '<form-template></form-template>',
    disableFields: ['autocomplete'],
    onCloseFieldEdit: () => updateFormData(),
    fields,
    i18n: {
      override: {
         'en-US': getFormLanObj()
      }
    }
  }

  if (props?.from === 'file') {
    Object.assign(options, {
      defaultFields: [
        {
          label: 'Email',
          type: 'text',
          subtype: 'email',
          name: 'email',
          className: 'form-control',
          required: true,
        },
      ],
      persistDefaultFields: true,
    })
  }

  useEffect(() => {
    if (!props.loadForm) return
    const {formData} = props
    options.formData =
      typeof formData === 'string' && formData ? JSON.parse(props.formData) : formData || []
    setFbBuilder($(fb.current).formBuilder(options))
    previousFormData = formData
    // eslint-disable-next-line
  }, [props.loadForm])

  useEffect(() => {
    if (props.isClear) {
      fbBuilder.actions.clearFields()
      props.setIsClear(false)
      setFlag(true)
    }
    // eslint-disable-next-line
  }, [props.isClear])

  useEffect(() => {
    if (props.isPreview) {
      showPreview(fbBuilder.actions.getData('xml'))
      props.setIsPreview(false)
      setFlag(true)
    }
    // eslint-disable-next-line
  }, [props.isPreview])

  const showPreview = (formData) => {
    let formRenderOpts = {dataType: 'xml', formData}
    let renderContainer = $('<form />')
    renderContainer.formRender(formRenderOpts)
    setShow(true)
    setTimeout(() => {
      $('.form-builder-body').append(renderContainer[0])
    }, 10)
  }

  const updateShow = () => setShow(false)

  return (
    <>
      <div id='fb-editor' ref={fb} />
      <Modal
        dialogClassName='modal-dialog-centered mw-900px h-auto'
        show={show}
        onHide={updateShow}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='modal-title'>{t('PREVIEW')}</h2>
            <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={updateShow}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div className='modal-body form-builder-body' />
        </div>
      </Modal>
    </>
  )
}

export default FormBuilder
