import {useEffect, useState} from 'react'

import {getFilterLibraryType} from '../../../utils/module.utils'
import ConfirmModal from '../common/modal/ConfirmModal'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import { deleteModuleLibrary, updateModuleLibrary } from '../../../service/moduleLibrary.service'
import LibraryCard from '../widgets/statistics/LibraryCard'
import LibraryToolbar from '../../../_metronic/layout/components/toolbar/LibraryToolbar'
import LibraryModal from './LibraryModal'

const LibraryList = () => {
  const [libraryList, setLibraryList]: any = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [template, setTemplate]: any = useState({})
  const [type, setType]: any = useState(getFilterLibraryType)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [libraryList])

  const onArchieved = async () => {
    const id = await deleteModuleLibrary(template._id)
    if (id) {
      const foundIndex = libraryList.findIndex((item: any) => item._id === template._id)
      libraryList.splice(foundIndex, 1)
      setLibraryList([...libraryList])
    }
  }

  const onModalOpen = (data: string, type: string) => {
    setShowConfirmModal(true)
    setModalType(type)
    setTemplate(data)
  }

  const onConfirm = () => {
    if (modalType === 'remove') onArchieved()
  }

  const  onEdit = async (values: object) => {
    const data = await updateModuleLibrary(template._id, { ...values });
    if (!data) return;
    const foundIndex = libraryList.findIndex((item: any) => item._id === template._id)
    const library = libraryList.with(foundIndex, {...data})
    setLibraryList([...library])
    setTemplate({})
  }

  return (
    <>
      <LibraryToolbar
        setLibraryList={setLibraryList}
        type={type}
        setType={setType}
      />
      <div className='row gy-5 g-xl-12'>
        {libraryList.map((data: any, i: number) => {
          return (
            <div className='col-md-6 col-xxl-4' key={`notification_list_${i}`}>
              <LibraryCard
                data={data}
                onEdit={() => setTemplate(data)}
                archive={() => onModalOpen(data, 'remove')}
              />
            </div>
          )
        })}
      </div>
      {showConfirmModal && (
        <ConfirmModal
          label={modalType}
          show={showConfirmModal}
          close={setShowConfirmModal}
          onConfirm={onConfirm}
        />
      )}
      <LibraryModal
        modalId={'gw_library_modal'}
        template={template}
        onSave={onEdit}
      />
    </>
  )
}
export default LibraryList
