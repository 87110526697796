import {useEffect, useState} from "react"
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useLocation} from 'react-router'
import {checkIsActive} from '../../../helpers'
import {isModulePermitted, isWorkspaceGroweoAdmin, isGroweoContentadmin} from '../../../../utils/utils'
import {changeLogData} from "../../../../service/changelogMD.service"
import {getGlobalItem} from '../../../../utils/utils'

export function AsideMenuMain() {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {pathname} = useLocation()
  const workspaceId = getGlobalItem('user')?.workspaceId
  const growthEngineActive =
    checkIsActive(pathname, '/dashboard') ||
    checkIsActive(pathname, '/performance-reports') ||
    checkIsActive(pathname, '/gapps')
  const growthAppsActive = checkIsActive(pathname, 'apps')
  const cdpActive = checkIsActive(pathname, 'cdp')
  const automationsActive = checkIsActive(pathname, 'automations')
  const growthEnginePermitted =
    isModulePermitted('DASHBOARD') ||
    isModulePermitted('PERFORMANCE_REPORT') ||
    isModulePermitted('GROWTH_APPS')
  const growthAppsPermitted =
    isModulePermitted('SMART_BOT') ||
    isModulePermitted('SMART_FORM') ||
    isModulePermitted('SMART_BLOCK') ||
    isModulePermitted('DOWNLOAD_TOOL') ||
    isModulePermitted('PREVIEW')
  const cdpPermitted =
    isModulePermitted('SESSION') ||
    isModulePermitted('CONTACT') ||
    isModulePermitted('LIST') ||
    isModulePermitted('SEGMENT')
  const automationsPermitted =
    isModulePermitted('CAMPAIGN') ||
    isModulePermitted('TRIGGER') ||
    isModulePermitted('NOTIFICATION')
  const librariesActive = checkIsActive(pathname, '/libraries')
  const librariesPermitted = isWorkspaceGroweoAdmin()
  const contentAdmin = isGroweoContentadmin()
  const [latestVersion, setLatestVersion] = useState('')

  useEffect(() => {
    async function makeAsync() {
      let data = await changeLogData()
      let sortedLogData = data.sort(function (a:any, b:any) {
        var val1 = parseFloat(a.version),
          val2 = parseFloat(b.version);
        if (val1 > val2) return -1;
        if (val1 < val2) return 1;
        return 0;
      });
      setLatestVersion(sortedLogData[0]?.version);
    }
    makeAsync()
  }, [changeLogData])

  return (
    <>
      {growthEnginePermitted && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span
              className={`menu-section ${
                growthEngineActive ? 'text-white' : 'text-muted'
              } text-uppercase fs-8 ls-1 `}
            >
              {t('GROWTH')} {t('ENGINE')}
            </span>
          </div>
        </div>
      )}

      <AsideMenuItem
        to='/dashboard'
        title={t('DASHBOARD')}
        icon='/media/icons/custom/dashboard_icon.svg'
        isShow={isModulePermitted('DASHBOARD')}
      />

      <AsideMenuItem
        to={`/performance-reports/${workspaceId}`}
        title={t('PERFORMANCE_REPORT')}
        icon='/media/icons/custom/performance_report_icon.svg'
        isShow={isModulePermitted('PERFORMANCE_REPORT')}
      />

      <AsideMenuItem
        to='/gapps'
        title={`${t('GROWTH_APPS_MENU', {count: 1})}`}
        icon='/media/icons/custom/growth_apps_icon.svg'
        isShow={isModulePermitted('GROWTH_APPS')}
      />

      {growthAppsPermitted && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span
              className={`menu-section ${
                growthAppsActive ? 'text-white' : 'text-muted'
              } text-uppercase fs-8 ls-1 `}
            >
              {t('GROWTH_APPS_MENU_TITLE', {count: 1})}
            </span>
          </div>
        </div>
      )}

      <AsideMenuItem
        to='/apps/bots'
        title={t('SMART_BOT', {count: 0})}
        icon='/media/icons/custom/smart_chat_menu_icon.svg'
        isShow={isModulePermitted('SMART_BOT')}
      />

      <AsideMenuItem
        to='/apps/forms'
        title={t('SMART_FORM', {count: 0})}
        icon='/media/icons/custom/smart_form_menu_icon.svg'
        isShow={isModulePermitted('SMART_FORM')}
      />

      <AsideMenuItem
        to='/apps/smartblock'
        title={t('SMART_BLOCK', {count: 0})}
        icon='/media/icons/custom/smart_block_menu_icon.svg'
        isShow={isModulePermitted('SMART_BLOCK')}
      />
      <AsideMenuItem
        to='/apps/smartpopup'
        title={t('SMART_POPUP', {count: 0})}
        icon='/media/icons/custom/smart_popup_menu_icon.svg'
        isShow={isModulePermitted('SMART_POPUP')}
      />
      <AsideMenuItem
        to='/apps/smartflows'
        title={t('SMART_FLOW', {count: 0})}
        icon='/media/icons/custom/smart_flow_menu_icon.svg'
        isShow={isModulePermitted('SMART_FLOW')}
      />
      <AsideMenuItem
        to='/apps/smartbars'
        title={t('SMART_BAR', {count: 0})}
        icon='/media/icons/custom/smart_bar_menu_icon.svg'
        isShow={isModulePermitted('SMART_BAR')}
      />
      <AsideMenuItem
        to='/apps/files'
        title={t('DOWNLOAD_TOOL', {count: 0})}
        icon='/media/icons/custom/download_tools_menu_icon.svg'
        isShow={isModulePermitted('DOWNLOAD_TOOL')}
      />

      <AsideMenuItem
        to='/apps/preview'
        title={t('PREVIEW', {count: 1})}
        icon='/media/stepper/play-outline.svg'
        isShow={isModulePermitted('PREVIEW')}
      />

      {cdpPermitted && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span
              className={`menu-section ${
                cdpActive ? 'text-white' : 'text-muted'
              } text-uppercase fs-8 ls-1 `}
            >
              {t('PLATFORM')}
            </span>
          </div>
        </div>
      )}

      <AsideMenuItem
        to='/cdp/sessions'
        title={t('SESSION', {count: 1})}
        icon='/media/icons/custom/sessions_icon.svg'
        isShow={isModulePermitted('SESSION') && !contentAdmin}
      />

      <AsideMenuItem
        to='/cdp/leads'
        title={t('CONTACT', {count: 1})}
        icon='/media/icons/custom/lead_icon.svg'
        isShow={isModulePermitted('CONTACT')}
      />

      <AsideMenuItem
        to='/cdp/segments'
        title={t('SEGMENT', {count: 1})}
        icon='/media/icons/custom/segments_icon.svg'
        isShow={isModulePermitted('SEGMENT')}
      />
      
      <AsideMenuItem
        to='/cdp/lists'
        title={t('LIST', {count: 1})}
        icon='/media/icons/custom/lists_icon.svg'
        isShow={isModulePermitted('LIST')}
      />

      {automationsPermitted && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span
              className={`menu-section ${
                automationsActive ? 'text-white' : 'text-muted'
              } text-uppercase fs-8 ls-1 `}
            >
              {t('AUTOMATIONS', {count: 1})}
            </span>
          </div>
        </div>
      )}

      <AsideMenuItem
        to='/automations/campaigns'
        title={t('CAMPAIGN', {count: 1})}
        icon='/media/icons/custom/campaigns_icon.svg'
        isShow={isModulePermitted('CAMPAIGN')}
      />

      <AsideMenuItem
        to='/automations/triggers'
        title={t('TRIGGER_TITLE', {count: 1})}
        icon='/media/icons/custom/triggers_icon.svg'
        isShow={isModulePermitted('TRIGGER')}
      />

      <AsideMenuItem
        to='/automations/notifications'
        title={t('NOTIFICATION', {count: 1})}
        icon='/media/icons/custom/notifications_menu_icon.svg'
        isShow={isModulePermitted('NOTIFICATION')}
      />

      {librariesPermitted && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span
              className={`menu-section ${
                librariesActive ? 'text-white' : 'text-muted'
              } text-uppercase fs-8 ls-1 `}
            >
              {t('LIBRARY', {count: 1})}
            </span>
          </div>
        </div>
      )}

      <AsideMenuItem
        to='/libraries'
        title={t('LIBRARY', {count: 1})}
        icon='/media/icons/custom/library_menu_icon.svg'
        isShow={isWorkspaceGroweoAdmin()}
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <AsideMenuItem
        to='/changelog'
        title={`v${latestVersion}`}
        icon='/media/icons/duotune/coding/cod003.svg'
        isDisable={!isWorkspaceGroweoAdmin()}
      />
    </>
  )
}
