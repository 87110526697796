import React from 'react'
import {useIntl} from 'react-intl'
import {useHistory,useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {getFormattedTime} from '../../../../utils/utils'
import {UsersListPagination} from '../../common/Pagination'

type Props = {
  activePage?: any
  setActivePage?: any
  onSort: any
  dateRange?: any
  pageData: any
  filterData: any
  toggleSorting: any
  type: string
  workspaceFilter?: Object 
  appFilter?: Object
  settingObject?:any
  reportType?: string
}

const ReportTable: React.FC<Props> = ({
  type,
  activePage,
  setActivePage,
  onSort,
  pageData,
  filterData,
  toggleSorting,
  dateRange,
  workspaceFilter,
  appFilter,
  settingObject,
  reportType
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const history = useHistory()
  const {state}: any = useLocation()
  const renderValue = (value: any,settingColor?:boolean) => (
    <div className='d-flex justify-content-center'>
      <div className='d-flex flex-column'>
        <a className={`text-dark fw-bolder ${settingColor?'text-white':'text-muted'} text-hover-primary fs-6`}>{value}</a>
      </div>
    </div>
  )
  const renderSortingIcon = (key: string) =>
    key === toggleSorting?.sortBy &&
    (toggleSorting?.sortDirection ? (
      <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-3 ms-1' />
    ) : (
      <KTSVG path='/media/icons/duotune/arrows/arr073.svg' className='svg-icon-3 ms-1' />
    ))
  const renderTableHeader = (title: string, column: any) => (
    <th
      className={`${
        ['visitorsVScontact'].includes(column)
          ? 'min-w-125px'
          : [
              'visitorVSstarted',
              'startedVSsuccessful',
              'startedVScontact',
            ].includes(column)
          ? 'min-w-150px'
          : ''
      } fs-6 cursor-pointer text-center`}
      onClick={() => {
        if (column)
          onSort({sortBy: column, sortDirection: !toggleSorting.sortDirection}, filterData)
      }}
    >
      <div className='d-flex align-items-center cursor-pointer'>
        <div className='text-wrap'>{title}</div>
        {renderSortingIcon(column)}
      </div>
    </th>
  )

  const settingColorHandler = (data:any,type:string)=>{
    return `${data[type]>=settingObject[type].greenCount&&state?.settingColorStatus?"#58d68d":(data[type]>=settingObject[type].orangeCount&&data[type]<settingObject[type].greenCount)&&state?.settingColorStatus?"#F5B041":data[type]<settingObject[type].redCount&&state?.settingColorStatus?"#EC7063":""}`
  }

  const getBgColor = (data:any,key:string)=>(
    settingObject ? settingColorHandler(data,key) : ""
  )

  const renderWorkspaceName = ({ workspaceId, workspaceName }: any) => (
    <div className='d-flex align-items-center flex-row'>
      <div className='d-flex align-items-center justify-content-center'>
        <a
          className='text-dark fw-bolder text-muted text-hover-primary fs-6'
          onClick={() =>
            history.push({
              pathname: `/performance-reports/${workspaceId}`,
              state: {...state, dateRange, toggleSorting, activePage, workspaceFilter, appFilter, reportType},
            })
          }
        >
          {workspaceName}
        </a>
      </div>
      <div className='d-flex align-items-center justify-content-center ps-2 text-hover-primary position-relative' onClick={() =>
            history.push(`/performance-reports/${workspaceId}/customer-report/${workspaceName}`)}>
        <KTSVG
          path='/media/icons/duotune/general/gen005.svg'
          className='svg-icon svg-icon-primary position-absolute ms-6'
        />
      </div>
    </div>
  );

  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder'>
                {renderTableHeader(t('ROW_NO'), '')}
                {type === 'customer_report'
                  ? renderTableHeader(t('CUSTOMER_COLUMN'), 'workspaceName')
                  : type === 'app_type_report' 
                    ? renderTableHeader(t('APP_TYPE', {count: 0}), 'type')
                    : renderTableHeader(t('APP_NAME', {count: 1}), 'moduleName')}
                {['app_report', 'all_app_report'].includes(type) && renderTableHeader(t('APP', {count: 0}), 'moduleType')}
                {['customer_report', 'app_type_report'].includes(type)  && renderTableHeader(t('APP', {count: 1}), 'TOTAL-APP')}
                {type === 'all_app_report' && renderTableHeader(t('CUSTOMER_COLUMN'), 'workspaceName')}
                {type !== 'app_type_report' && renderTableHeader(t('DAYS_SINCE_INSTALLED'), 'daysSinceInstalled')}
                {/* {renderTableHeader(t('CREATED'), 'created')} */}
                {type === 'customer_report' && renderTableHeader(t('VISITOR_COLUMN'), 'UNIQUE-VISITOR')}
                {renderTableHeader(t('VISITOR_VS_STARTED'), 'visitorVSstarted')}
                {renderTableHeader(t('STARTED_CONVERSATION'), 'SESSION')}
                {renderTableHeader(t('STARTED_VS_SUCCESSFUL'), 'startedVSsuccessful')}
                {renderTableHeader(t('STARTED_VS_CONTACT'), 'startedVScontact')}
                {renderTableHeader(t('SUCCESSFULL_CONVERSATION'), 'SUCCESSFULL-CONVERSATION')}
                {renderTableHeader(t('VISITORS_VS_SUCCESSFUL'), 'visitorsVSsuccessful')}
                {renderTableHeader(t('VISITORS_VS_CONTACTS'), 'visitorsVScontact')}
                {renderTableHeader(t('PERFORMANCE_CONTACT_COLUMN'), 'CONTACT')}
                {/* {renderTableHeader(t('DURATION', {count: 0}), 'DURATION')} */}
              </tr>
            </thead>
            <tbody>
              {pageData?.map((data: any, index: any) => (
                <tr key={`report_${index}`}>
                  <td className='text-dark fw-bolder text-muted text-hover-primary fs-6'>
                    {(activePage - 1) * 100 + index + 1 || 1}
                  </td>
                  {type === 'customer_report' ? (
                    <td className='cursor-pointer'>{renderWorkspaceName({workspaceId: data.workspaceId, workspaceName: data.workspaceName})}</td>
                  ) : (
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='text-dark fw-bolder text-muted text-hover-primary fs-6'>
                          {['app_report', 'all_app_report'].includes(type)
                            ? data.moduleName 
                            : data.type
                              ? t(data.type?.toUpperCase(), {count: 0})
                              : '' } 
                        </div>
                      </div>
                    </td>
                  )}
                  {['app_report', 'all_app_report'].includes(type) && (
                    <td>
                     <div className='badge badge-light-success'>
                       {data.moduleType
                         ? t(data.moduleType?.toUpperCase(), {count: 0})
                         : ''}
                     </div>
                    </td>
                  )}
                  {['customer_report', 'app_type_report'].includes(type) && <td>{renderValue(data['TOTAL-APP'])}</td>}
                  {type === 'all_app_report' && <td className='cursor-pointer'>{renderWorkspaceName({workspaceId: data?.workspaceId, workspaceName: data?.workspaceName})}</td>}
                  {type !== 'app_type_report' && <td>{renderValue(data['daysSinceInstalled'])}</td>}
                  {/* <td>{renderValue(moment(data.created).format('DD-MM-YYYY'))}</td> */}
                  {type === 'customer_report' && <td>{renderValue(data['UNIQUE-VISITOR'])}</td>}
                  <td  style={{backgroundColor:getBgColor(data,"visitorVSstarted")}}>{renderValue(`${data['visitorVSstarted']}%`,getBgColor(data,"visitorVSstarted") ? true : false)}</td>
                  <td >{renderValue(data['SESSION'])}</td>
                  <td style={{backgroundColor:getBgColor(data,'startedVSsuccessful')}}>{renderValue(`${data['startedVSsuccessful']}%`,getBgColor(data,"startedVSsuccessful")? true : false)}</td>
                  <td>{renderValue(`${data['startedVScontact']}%`)}</td>
                  <td>{renderValue(data['SUCCESSFULL-CONVERSATION'])}</td>
                  <td style={{backgroundColor:getBgColor(data,'visitorsVSsuccessful')}}>{renderValue(`${data['visitorsVSsuccessful']}%`,getBgColor(data,"visitorsVSsuccessful") ? true : false)}</td>
                  <td style={{backgroundColor:getBgColor(data,'visitorsVScontact')}}>{renderValue(`${data['visitorsVScontact']}%`,getBgColor(data,"visitorsVScontact")? true : false)}</td>
                  <td>{renderValue(data['CONTACT'])}</td>
                  {/* <td>{renderValue(getFormattedTime(data['DURATION']))}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {filterData?.length > 100 && (
          <UsersListPagination
            setActivePage={setActivePage}
            activePage={activePage}
            pageCount={Math.ceil(filterData.length / 100)}
          />
        )}
      </div>
    </div>
  )
}

export default ReportTable