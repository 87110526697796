import {FC} from 'react'
import {Code} from '../../../code'

type Step4Props = {
  from: String
  template?: any
  setTemplate?: any
}

const Step4: FC<Step4Props> = ({from, template, setTemplate}) => {
  return (
    <div className='container'>
      <Code from={from} template={template} setTemplate={setTemplate} />
    </div>
  )
}

export {Step4}
