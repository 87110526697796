import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {getSession} from '../../../../service/session.service'
import GrowthAppFilterMenu from '../../../../app/modules/common/shared/filter/GrowthAppFilterMenu'
import { filterType, getFilterAppType } from '../../../../utils/module.utils'

type SessionToolbarProps = {
  setSessions: any
}

const SessionToolbar: FC<SessionToolbarProps> = ({setSessions}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  const [type, setType]: any = useState(getFilterAppType)
  const [moduleId, setModuleId]: any = useState('')

  const fetchData = async (params: any) => {
    const data = await getSession(params)
    if (data) setSessions(data)
  }

  useEffect(() => {
    fetchData({type: filterType(type)})
    // eslint-disable-next-line
  }, [])

  const onApply = () => fetchData(moduleId ? { moduleId } : { type: filterType(type) })

  const onReset = () => {
    setType(getFilterAppType)
    setModuleId('')
    fetchData({type: filterType(getFilterAppType)})
  }

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack p-0')}
      >
        <div>
          <SectionHeading title={t('SESSION', {count: 1})} subTitle={t('SESSION.SHOW')} />
        </div>
        <div className='d-flex align-items-center py-1'>
          <GrowthAppFilterMenu
            type={type}
            setType={setType}
            onApply={onApply}
            onReset={onReset}
            moduleId={moduleId}
            setModuleId={setModuleId}
            appType={getFilterAppType}
          />
        </div>
      </div>
    </div>
  )
}

export {SessionToolbar}
