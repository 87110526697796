/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {filterType, getFilterLibraryType} from '../../../../utils/module.utils'
import {getModuleLibrary} from '../../../../service/moduleLibrary.service'
import LibraryFilterMenu from '../../../../app/modules/common/shared/filter/LibraryFilterMenu'

type LibraryProps = {
  setLibraryList?: any
  type?: any
  setType?: any
}

const LibraryToolbar: FC<LibraryProps> = ({setLibraryList, type, setType}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()

  const [filterData, setFilterData]: any = useState('')

  const fetchList = async (data: any) => {
    const list = await getModuleLibrary(data)
    setLibraryList(list || [])
  }

  useEffect(() => {
    setFilterData({...filterData, type: filterType(type)})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (filterData) fetchList({...filterData})
    // eslint-disable-next-line
  }, [filterData])

  const onSearch = (e: any) => setFilterData({...filterData, search: e.target.value})

  const onApply = () => {
    let filter = filterData
    if (!filter) filter = {}
    if (filterType(type)) filter.type = filterType(type)
    else filter.type = filterType(getFilterLibraryType)
    setFilterData({...filter})
  }

  const onReset = () => {
    setType(getFilterLibraryType)
    setFilterData({...filterData, type: filterType(getFilterLibraryType)})
  }

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        style={{padding: '0px'}}
      >
        <div>
          <SectionHeading title={t('LIBRARY', {count: 1})} subTitle={t(`LIBRARY_SUBTITLE`)} />
        </div>

        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center position-relative mx-2'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              className='form-control form-control-solid w-300px ps-14 bg-white'
              placeholder={t('SEARCH')}
              onChange={onSearch}
            />
          </div>
          <LibraryFilterMenu type={type} setType={setType} onApply={onApply} onReset={onReset} />
        </div>
      </div>
    </div>
  )
}

export default LibraryToolbar
