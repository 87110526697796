import AdministrationBreadCrumb from './AdministrationBreadCrumb'
import SubscriptionContainer from '../../modules/admin/subscription/SubscriptionContainer'

const Subscriptions = () => {

  
  return (
    <>
      <AdministrationBreadCrumb />
      <SubscriptionContainer />    
    </>
  )
}

export default Subscriptions
