import { url } from 'inspector';
import React,{useState} from 'react'

import {KTSVG} from '../../../_metronic/helpers';
import AppName from '../common/shared/modal/AppName';
type Props = {
  activeTab: string
  setActiveTab: (e: string) => void
}

const DashboardHeader: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  
  const saveChages = () =>{

  }

  return (
    <>
    <div id="kt_header" className="header align-items-stretch flex-lg-grow-0 p-5">
      <div className='d-flex justify-content-between flex-lg-grow-1'>
        <div>
        <nav className="grmo__nav">
        <ul>
                      <li>
              <button className="js-grmo-tab grmo__tab grmo__tab--0" data-tab="0" aria-expanded="false">
                All              </button>
            </li>
                      <li>
              <button className="js-grmo-tab grmo__tab grmo__tab--1" data-tab="1" aria-expanded="false">
                Bots             </button>
            </li>
                      <li>
              <button className="js-grmo-tab grmo__tab grmo__tab--2" data-tab="2" aria-expanded="false">
                Forms              </button>
            </li>
              <li>
                <button className="js-grmo-tab grmo__tab grmo__tab--3" data-tab="3" aria-expanded="false">
                PDF             
                </button>
              </li>
              <li>
                
              </li>
                
            </ul>
      </nav>
          {/* <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <div
                onClick={() => setActiveTab('all')}
                className={`nav-link pe-2 ` + (activeTab === 'all' && 'active')}
              >
                All
              </div>
            </li>
            <span className="h-20px border-gray-800 border-start ms-3 mx-2 mt-3" />
            <li className='nav-item' style={{ cursor: 'pointer' }}>
              <div
                style={{ color: '#bd2d70' }}
                onClick={() => setActiveTab('bot')}
                className={`nav-link pe-2 ` + (activeTab === 'bot' && 'active')}
              >
                Bot
              </div>
            </li>
            <span className="h-20px border-gray-800 border-start ms-3 mx-2 mt-3" />
            <li className='nav-item' style={{ cursor: 'pointer' }}>
              <div
                style={{ color: '#7e469c' }}
                onClick={() => setActiveTab('pdf')}
                className={`nav-link pe-2 ` + (activeTab === 'pdf' && 'active')}
              >
                PDF
              </div>
            </li>
            <span className="h-20px border-gray-800 border-start ms-3 mx-2 mt-3" />
            <li className='nav-item' style={{ cursor: 'pointer' }}>
              <div
                style={{ color: '#d463a3' }}
                onClick={() => setActiveTab('form')}
                className={`nav-link pe-2 ` + (activeTab === 'form' && 'active')}
              >
                Form
              </div>
            </li>
          </ul>
 */}        </div>
        {/* begin::Daterange */}
        <div>
          <select
            name='Daterange'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm'
            defaultValue='1'
          >
            <option value='1'>Today</option>
            <option value='2'>Last 7 days</option>
            <option value='3'>Last 28 days</option>
            <option value='4'>Last 180 days</option>
          </select>
        </div>
          {/* <div className="" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <button className="js-grmo-tab grmo__tab grmo__tab--3 c-button c-button--type-solid" data-tab="3" aria-expanded="false">
                  Create             
                </button>
					</div>
 */}          <div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px" data-kt-menu="true" style={{zIndex: '105',position: 'fixed', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(-138px, 55px)'}} data-popper-placement="bottom-end">
									
											<div className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10" style={{backgroundColor:"#3851B6"}}>
										
												<h3 className="text-white fw-bold mb-3">Quick Links</h3>
										
												{/* <span className="badge bg-primary py-2 px-3">25 pending tasks</span> */}
										
											</div>
								
											<div className="row g-0">
										
												<div className="col-6"
                          data-bs-toggle="modal"
                            data-bs-target="#gw_modal_form_name">
													<p className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
													
														<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect y="6" width="16" height="3" rx="1.5" fill="black"></rect><rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="black"></rect><rect opacity="0.3" width="12" height="3" rx="1.5" fill="black"></rect></svg>
														</span>
													
														<span className="fs-5 fw-bold text-gray-800 mb-0">Form</span>
													</p>
												</div>
										
												<div className="col-6" data-bs-toggle="modal"
                          data-bs-target="#gw_modal_bot_name">
													<p 
                            className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom">
												
														<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black"></path><rect x="6" y="12" width="7" height="2" rx="1" fill="black"></rect><rect x="6" y="7" width="12" height="2" rx="1" fill="black"></rect></svg>
														</span>
												
														<span className="fs-5 fw-bold text-gray-800 mb-0">Bot</span>
													</p>
												</div>
											
												{/* <div className="col-6">
													<a href="/metronic8/demo1/../demo1/apps/projects/list.html" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end">
														
														<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path d="M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z" fill="black"></path>
																<path opacity="0.3" d="M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z" fill="black"></path>
															</svg>
														</span>
													
														<span className="fs-5 fw-bold text-gray-800 mb-0">Projects</span>
														<span className="fs-7 text-gray-400">Pending Tasks</span>
													</a>
												</div>
											
												<div className="col-6">
													<a href="/metronic8/demo1/../demo1/apps/projects/users.html" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light">
									
														<span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black"></path>
																<path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black"></path>
															</svg>
														</span>
												
														<span className="fs-5 fw-bold text-gray-800 mb-0">Customers</span>
														<span className="fs-7 text-gray-400">Latest cases</span>
													</a>
												</div> */}
											
											</div>
									
											{/* <div className="py-2 text-center border-top">
												<a href="/metronic8/demo1/../demo1/pages/user-profile/activity.html" className="btn btn-color-gray-600 btn-active-color-primary">View All 
												
												<span className="svg-icon svg-icon-5">
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
														<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
														<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
													</svg>
												</span>
										    </a>
											</div> */}
										
				</div>
        {/* begin::Daterange */}
      </div>
   
    </div>
    
    
    <div>
      <AppName from='form' title='Form' modalId='gw_modal_form_name' />
      <AppName from='bot' title='Bot' modalId='gw_modal_bot_name' />
    </div>
       
      
     </>
  )
}

export default DashboardHeader
