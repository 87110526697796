import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import {BOT_APPEARANCE, getModulePath, getAppTypesOption, SMARTFLOW_APPEARANCE, BAR_APPEARANCE} from '../../../../../utils/module.utils'
import {clearElements, clearSlider, generateSimpleChatbotData, generateSimpleSlideData} from '../../../../../utils/template.utils'
import {createModule} from '../../../../../service/module.service'
import {createCampaign} from '../../../../../service/campaign.service'
import {createSegment} from '../../../../../service/segment.service '
import { createTrigger } from '../../../../../service/trigger.service'
import {createNotification} from "../../../../../service/notification.service"
import { isBotAccessible, isWorkspaceGroweoAdmin, isWorkspaceReadonly} from '../../../../../utils/utils'
import { getModuleLibrary } from '../../../../../service/moduleLibrary.service'
import ModuleLibraryCard from '../card/ModuleLibraryCard'

type AppNameProps = {
  from?: any
  title?: string
  modalId: string
  templateData?: any
}

const AppName: FC<AppNameProps> = ({from, title = 'App', modalId, templateData}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [name, setName] = useState('')
  const [app, setApp] = useState(from || 'form')
  const history = useHistory()
  const [templateList, setTemplateList] = useState([])
  const [template, setTemplate]: any = useState({})

  const disableBot = from === 'bot' && isBotAccessible()


  const fetchModuleLibrary = async (params: any) => {
    if (["campaign", "trigger"].includes(params.type)) params.type = "campaign,trigger";
    const data = await getModuleLibrary(params)
    if (data) setTemplateList(data)
  }

  const onSearch = (e: any) => fetchModuleLibrary({type:from, search: e.target.value})

  useEffect(() => {
    if (modalId === 'gw_modal_duplicate_name' && templateData?.type) {
      setName(`Copy_${templateData.name}`)
      setApp(templateData.type)
      setTemplate(templateData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData])

  useEffect(() => {
    if (modalId !== 'gw_modal_duplicate_name' && ['form', 'bot', 'file', 'smartblock', 'smartpopup', 'smartbar', 'smartflow', 'segment', 'campaign', 'trigger', 'notification'].includes(app) && isWorkspaceGroweoAdmin())
    fetchModuleLibrary({type: app})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app])

  const navigateToEditPage = (id: string) => {
    if(!id) return
    if (['campaign', 'trigger','notification'].includes(from))
      history.push(`${getModulePath(app)}/${id}`)
    else if(['segment'].includes(from)) history.push(`${getModulePath(app)}/edit/${id}`)
    else if (['smartflow'].includes(app)) history.push(`${getModulePath(app)}/${id}/slides`)
    else history.push(`${getModulePath(app)}/${id}/design`)
    setTemplate({})
  }

  const onCreateModule = async () => {
    console.log(app);
    const data: any = {name}
    if (['form', 'file', 'bot', 'smartblock', 'smartpopup', 'smartbar', 'smartflow'].includes(app)) {
      await saveModule(data)
    }
    if (app === 'segment') saveSegment(data)
    if (app === 'campaign') saveCampaign(data)
    if (app === 'trigger') saveTrigger(data)
    if (app === 'notification') saveNotification(data)
  }

  const saveModule = async (data: any) => {
    data.type = app
    if (app === 'bot') {
      const elements = clearElements()
      const appearance = BOT_APPEARANCE
      const nodes = generateSimpleChatbotData(elements)
      const codeData = {appearance, nodes, elements, scriptVersion: 3}
      if (template.botCode) data.botCode = template.botCode
      else if (template.code) data.botCode = JSON.parse(template.code)
      else data.botCode = codeData
    } else if (app === 'smartflow') {
      const elements = clearSlider()
      const appearance = SMARTFLOW_APPEARANCE
      const nodes = generateSimpleSlideData(elements)
      const codeData = { nodes, elements, appearance}
      if (template.moduleCode) data.moduleCode = template.moduleCode
      else data.moduleCode = codeData
    }
     else if (['form', 'file', 'smartblock', 'smartpopup'].includes(app)) {
      if (template.content) data.content = template.content
      if (template.code) data.code = template.code
      if (template?.appearence) data.appearence = template.appearence
      if (template?.settings) data.settings = template.settings
      if (template?.documents) data.documents = template.documents
      if (template?.emailTemplate) data.emailTemplate = template.emailTemplate
    } else if (['smartbar'].includes(app)) {
      if (template.moduleCode) data.moduleCode = template.moduleCode
      else data.moduleCode = {appearance: BAR_APPEARANCE, code: {fields: []}}
    }
    if(isWorkspaceReadonly()) return
    const id = await createModule(data)
    if (id) navigateToEditPage(id)
  }

  const saveSegment = async (data: any) => {
    data.avatar = `/media/avatars/${Math.floor(Math.random() * 10) + 3}.png`
    if (template?.description) data.description = template.description
    if (template?.ruleGroups) data.ruleGroups = template.ruleGroups
    const segmentData = await createSegment(data)
    if (segmentData?._id) navigateToEditPage(segmentData._id)
  }

  const saveCampaign = async (data: any) => {
    if (template?.emailTemplate) data.emailTemplate = template.emailTemplate
    if (template?.schedule) data.schedule = template.schedule
    if (template?.audience) data.audience = template.audience
    if (template?.status) data.status = template.status
    data.schedule = {type: 'campaign'}
    const campaignData = await createCampaign(data)
    if (campaignData?._id) navigateToEditPage(campaignData._id)
  }

  const saveTrigger = async (data: any) => {
    if (template?.emailTemplate) data.emailTemplate = template.emailTemplate
    if (template?.schedule) data.schedule = template.schedule
    if (template?.audience) data.audience = template.audience
    if (template?.status) data.status = template.status
    if (modalId !== 'gw_modal_duplicate_name') data.schedule = {type: 'triggered'}
    const triggerData = await createTrigger(data)
    if (triggerData?._id) navigateToEditPage(triggerData._id)
  }

  const saveNotification = async (data: any) => {
    if (template?.emailTemplate) data.emailTemplate = template.emailTemplate
    if (template?.schedule) data.schedule = template.schedule
    if (template?.audience) data.audience = template.audience
    if (template?.status) data.status = template.status
    if (modalId !== 'gw_modal_duplicate_name') data.schedule = {type: 'notification'}
    const notificationData = await createNotification(data)
    if (notificationData?._id) navigateToEditPage(notificationData._id)
  }

  return (
    <div className='modal fade' tabIndex={-1} id={modalId}>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>
              {t('CREATE')} {title !== 'SEGMENT' ? t(title, {count: 0}) : t('ADD_SEGMENT')}
            </h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='row'>
              {!from && modalId !== 'gw_modal_duplicate_name' && (
                <div className='col-6'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>
                      {t('CHOOSE_APP_TYPE')}
                    </span>
                  </label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    value={app}
                    onChange={(e) => setApp(e.target.value)}
                  >
                    {getAppTypesOption(t).map((item: any, i: number) => (
                      <option key={`d_${i}`} value={item.value} disabled={item.value === 'bot' && disableBot}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className='col-6'>
                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                  <span className='required'>
                   { ['App','CAMPAIGN','SEGMENT','TRIGGER', 'NOTIFICATION'].indexOf(title)<0? 
                     t(title, {count: 0})+ ' ' + t('APP_MODAL_NAME', {count: 0}).toLowerCase()
                     : title === 'App' ? t('APP_NAME', {count: 0}) : t(title+'_NAME', {count: 0})
                    }
                  </span>
                </label>
                <input
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
            </div>
            <div className='fv-row mt-5'>
              {modalId !== `gw_modal_duplicate_name` && isWorkspaceGroweoAdmin() && (
                <>
                  <ModuleLibraryCard
                    from={from}
                    templateList={templateList}
                    template={template}
                    setTemplate={setTemplate}
                    onSearch={onSearch}
                  />
                </>
              )}
              <div className='d-flex flex-stack pt-10 justify-content-end'>
                <button
                  type='submit'
                  data-bs-dismiss='modal'
                  onClick={onCreateModule}
                  className='c-button c-button--type-solid'
                >
                  <span className='indicator-label'>
                    {t('CONTINUE')}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppName
