import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import TextInputFeild from '../../../shared/TextInputFeild'
import SelectInputFeild from '../../../shared/SelectInputFeild'
import { getSubscriptionStatus } from '../../../../utils/utils'
import {createSubscription, getSubscriptionPlan,updateSubscriptionPlan} from '../../../../service/subscription.service'
import { useHistory } from 'react-router-dom'

type SubscriptionModalProps = {
  subscription?: any
  setSubscription?:any
  setSubscriptionLists?: any
}

interface Subscription {
  name: string,
  price?: string,
  description: string,
  accessConfig: {
    limits: {
        publish_app: number
        total_app: number
      },
      modulesPermission:string[]
  }
  status?: string,
}

const subscriptionSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(`${t('REQUIRED', { field: t('NAME', { count: 0 }) })}`),
    description: Yup.string().required(`${t('REQUIRED', { field: t('DESCRIPTION') })}`),
  })

const initialValues: Subscription = {
  name: '',
    accessConfig: {
        limits: {
            publish_app: 10,
            total_app: 10
        },
        modulesPermission: []
    },
  description: '',
  
}

const SubscriptionModal: FC<SubscriptionModalProps> = ({subscription,setSubscription, setSubscriptionLists }) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
    const history = useHistory()

  const formik = useFormik({
    initialValues,
    validationSchema: subscriptionSchema(t),
    onSubmit: (values) => {
      if (subscription) {
        const { name, description,price, status} = values
        updateSubscriptionPlan(subscription._id, { name, description,price, status}).then((data) => {
          setSubscription(data)
        })
      } else {
          createSubscription(values).then((newData) => {
            getSubscriptionPlan().then((data)=> {
                setSubscriptionLists(data)
              
            })
        
            history.push(`/subscriptions/${newData._id}`)
            }
          )
          formik.resetForm()
      }
      
      
    }
  })

  useEffect(() => {
    const elEditBtn = document.getElementById('gw_subscription_edit_modal_button')
    if (elEditBtn) {
      elEditBtn.addEventListener('click', () => {
        formik.resetForm()
        formik.setValues(subscription)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription])

  const renderLabel = (label: string) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
      <span className='required'>{label}</span>
    </label>
  )

  return (
    <div className='modal fade' tabIndex={-1} id='gw_subscription_modal'>
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>
            {t(subscription ? 'EDIT' : 'CREATE')} {t('SUBSCRIPTION', { count: 0 })}
            </h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label={t('CLOSE')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              
              noValidate
              id='gw_workspace_modal_form'
            >
              <div className='fv-row mb-5'>
                {renderLabel(`${t('NAME', { count: 0 })}`)}
                <TextInputFeild formik={formik} name='name' type='text'  />
              </div>
              {
                subscription && (
                  <div className='fv-row mb-5'>
                    {renderLabel(`${t('PRICE')}`)}
                    <TextInputFeild formik={formik} name='price' type='number' />
                  </div>
                )
              }
              
              <div className='fv-row mb-5'>
                {renderLabel(`${t('DESCRIPTION')}`)}
                <TextInputFeild formik={formik} name='description' type='text' />
              </div>
              {subscription && (
                <div className='fv-row mb-5'>
                  {renderLabel(`${t('STATUS')}`)}
                  <SelectInputFeild formik={formik} name='status' options={getSubscriptionStatus(t)} />
                </div>
              )}
        
              <div className='fv-row'>
                <div className='text-center '>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    onClick={() => formik.resetForm()}
                  >
                    {t('RESET')}
                  </button>
                  <button
                    type='submit'
                    data-bs-dismiss={`${formik.isSubmitting || !formik.isValid ? '' : 'modal'}`}
                    className='c-button c-button--type-solid'
                  >
                    <span className='indicator-label'>
                      {t('SUBMIT')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionModal
