/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, FC} from 'react'
import {useIntl} from 'react-intl'
import GrowthEngineBreadCrumb from './GrowthEngineBreadCrumb'
import {getGlobalItem, isWorkspaceReadonly} from '../../../utils/utils'
import {useStore} from '../../../store'
import {
  getActivity,
  getAudiences,
  getChannels,
  // getCustomerServiceLeads,
  getSessionsData,
  // getFacebookCompaigns,
  getSuccessfullConversationData,
  getMostUserApps,
  getPopularApps,
  getRecentAudiences,
  getActiveAppsData,
  getTotalSessions,
  getUniqueVisitorsData,
  getAllCampaigns,
  mockApi,
  getAppPerformance,
} from '../../../service/dashboard'
import ActivityList from '../../modules/widgets/lists/ActivityList'
import ActivityChart from '../../modules/widgets/charts/ActivityChart'
import TotalSessionChart from '../../modules/widgets/charts/TotalSessionChart'
import ApplicationStatisticsChart from '../../modules/widgets/charts/ApplicationStatisticsChart'
import LeadsGeneratedTable from '../../modules/widgets/tables/LeadsGeneratedTable'
import AudienceChart from '../../modules/widgets/charts/AudienceChart'
import RecentAudienceList from '../../modules/widgets/lists/RecentAudienceList'
import ChannelsTable from '../../modules/widgets/tables/ChannelsTable'
import SectionHeading from '../../modules/widgets/others/SectionHeading'
import AllCampaignTable from '../../modules/widgets/tables/AllCampaignTable'
import DashboardCarda from '../../modules/widgets/statistics/DashboardCard2'
import {MutedToolbar} from '../../../_metronic/layout/components/toolbar/MutedToolbar'
import AppTable from '../../modules/widgets/tables/AppTable'
import OverviewList from '../../modules/widgets/lists/OverviewList'
import moment from 'moment'
import { getWorkspaceReportById } from '../../../service/performanceReport.service'
type Dashboard = {
  activity: any,
  activeApps:number
  visitorsData: {totalItemCount: number; records: {date: string; count: number}[]}
  sessionData: {totalItemCount: number; records: {date: string; count: number}[]}
  successfullConversationData: {totalItemCount: number; records: {date: string; count: number}[]}
  totalSessions: {totalItemCount: number; records: {date: string; count: number}[]}
  mostUsedApps: {
    totalItemCount: number
    records: {
      moduleId: string
      moduleName: string
      moduleType: string
      description?: string
      count: number
    }[]
  }
  popularApps: {
    totalItemCount: number
    records: {
      moduleId: string
      moduleName: string
      moduleType: string
      description?: string
      count: number
    }[]
  }
  applicationStatistics: {value: number; category: string}[]
  customerServiceLeads: {tag: string; count: {visitors: number; leads: number; sessions: number}}[]
  audience: {totalContacts: number; datas: number[]}
  recentAudience: {title: string; subTitle: string; average: number}[]
  facebookCompaign: {newFollowers: number; followersGoal: number; datas: number[]}
  channels: {image: string; title: string; subTitle: string; average: number}[]
  allComaigns: {
    title: string
    subTitle: string
    startDate: string
    endDate: string
    status: string
    channel: string
    audience: string
    statistics: {sent: number; delivered: number; opened: number; clicked: number}
    chartData: number[]
  }[]
  email: string
  setSessionType: any
}

const DashboardPage: FC<Dashboard> = ({
  activity,
  activeApps,
  visitorsData,
  sessionData,
  successfullConversationData,
  totalSessions,
  mostUsedApps,
  popularApps,
  applicationStatistics,
  audience,
  recentAudience,
  channels,
  allComaigns,
  email,
  setSessionType,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const [filter, setFilter]: any = useState({})

  return (
    <>
      <MutedToolbar title='GROWTH_APPS' subTitle='GROWTH_APPS_SUB_TITLE' />
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <ActivityList
            className='card-xxl-stretch'
            activity={activity}
            title={t('ACTIVITY', {count: 1})}
            subTitle={t('ACTIVITY_SUBTITLE', {count: 0})}
          />
        </div>
        <div className='col-xxl-4'>
          <ActivityChart
            className='card-xxl-stretch-100 mb-5 mb-xl-8'
            chartColor='danger'
            chartHeight='150px'
            averageCount={visitorsData.totalItemCount}
            records={visitorsData.records}
            title={t('VISITOR_COLUMN', {count: 1})}
            subTitle={t('VISITOR_SUBTITLE', {count: 0})}
          />
          <ActivityChart
            className='card-xxl-stretch-100 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            title={t('STARTED_CONVERSATION', {count: 1})}
            subTitle={t('DASHBOARD_STARTED_CONVERSATION_SUBTITLE', {count: 1})}
            averageCount={sessionData.totalItemCount}
            records={sessionData.records}
          />
        </div>
        <div className='col-xxl-4'>
          <OverviewList
            title={t('DASHBOARD_OVERVIEW', {count: 1})}
            subTitle={t('DASHBOARD_OVERVIEW_SUBTITLE', {count: 1})}
            averageVisitor={visitorsData.totalItemCount/visitorsData.records.length}
            averageConverstation={sessionData.totalItemCount/sessionData.records.length}
            totalApps={activeApps}
          />
          <ActivityChart
            className='card-xxl-stretch-100 mb-5 mb-xl-8'
            chartColor='success'
            chartHeight='150px'
            title={t('SUCCESSFULL_CONVERSATION', {count: 1})}
            subTitle={t('DASHBOARD_SUCCESSFULL_CONVERSATION_SUBTITLE', {count: 0})}
            averageCount={successfullConversationData.totalItemCount}
            records={successfullConversationData.records}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-8'>
          <TotalSessionChart
            className='card-xxl-stretch mb-5 mb-xl-8'
            title={t('TOTAL_SESSION', {count: 1})}
            subTitle={t('TOTAL_SESSION_SUBTITLE', {count: 0})}
            setSessionType={setSessionType}
            datas={totalSessions.records.map((record) => record.count)}
            chartDate={totalSessions.records.map((record) => record.date)}
            tooltip={t('SESSION', {count: 1})}
          />
        </div>
        <div className='col-xxl-4'>
          <AppTable
            title={t('POPULAR_APP', {count: 1})}
            subTitle={t('POPULAR_APP_SUBTITLE', {count: 0})}
            records={mostUsedApps.records}
            metric='SESSION'
          />
        </div>
      </div>
      {/* end::Row */}
      {/* end::Row */}
      <br />
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <AppTable
            title={t('PERFORMED_APP', {count: 1})}
            subTitle={t('PERFORMED_APP_SUBTITLE', {count: 0})}
            records={popularApps.records}
            metric='SUCCESSFULL_CONVERSATION'
          />
        </div>
        <div className='col-xl-8'>
          {applicationStatistics[0].value !== 0 && (
            <ApplicationStatisticsChart
              className='card-xxl-stretch h-xl-100 mb-5 mb-xl-8'
              title={t('POPULARITY_APP_BY_TYPE', {count: 1})}
              subTitle={t('POPULARITY_APP__BY_TYPE_SUBTITLE', {count: 1})}
              datas={applicationStatistics}
            />
          )}
          {applicationStatistics[0].value === 0 && (
            <ApplicationStatisticsChart
              className='card-xxl-stretch h-xl-100 mb-5 mb-xl-8'
              title={t('POPULARITY_APP_BY_TYPE', {count: 1})}
              subTitle={t('POPULARITY_APP__BY_TYPE_SUBTITLE', {count: 1})}
              datas={applicationStatistics}
            />
          )}
        </div>
      </div>

      {/* end::Row */}

      <SectionHeading
        title={t('CUSTOMER_PLATFORM', {count: 1})}
        subTitle={t('CUSTOMER_PLATFORM_SUBTITLE', {count: 1})}
      />

      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          {<LeadsGeneratedTable filter={filter} setFilter={setFilter} hideColumns={{actions: !!isWorkspaceReadonly()}} />}
        </div>
      </div>
      <br />
      <br />
      {email === 'demo@groweo.com' && (
        <>
          <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-8'>
              <AudienceChart
                className='card-xxl-stretch mb-5 mb-xl-8'
                title={t('PERFORMANCE_GROWTH', {count: 1})}
                subTitle={t('PERFORMANCE_GROWTH_SUBTITLE', {count: 1})}
                audienceData={audience}
              />
            </div>
            <div className='col-xxl-4'>
              <RecentAudienceList
                className='card-xxl-stretch mb-xl-3'
                title={t('PERFORMED_APP', {count: 1})}
                datas={recentAudience}
              />
            </div>
          </div>

          {/* // end::Row  */}

          <SectionHeading
            title={t('AUTOMATION', {count: 1})}
            subTitle={t('AUTOMATION_SUBTITLE', {count: 1})}
          />

          <div className='row g-5 gx-xxl-8'>
            <div className='col-xxl-4'>
              <DashboardCarda
                className='card-xl-stretch mb-xl-8'
                color={'white'}
                svgIcon={'/media/avatars/4.png'}
                iconColor={'primary'}
                title={'Uudet kontaktit'}
                subTitle={'Ensimmäistä kertaa kiinnostuneet'}
                description={
                  'Uudet mahdollisuudet myynnin hyödynnettäväksi. Näistä tiketit automaattisesti Teemulle.'
                }
                trendText={'-19 muutos tällä viikolla'}
                chartHeight={'80'}
                chartColor={'#F7D9E3'}
                count={245}
                data={[2, 5, 10, 5, 30, 25, 20, 30, 55, 10, 4, 10]}
              />

              <ChannelsTable
                className='card-xxl-stretch-100 mb-2 mb-xl-8'
                title={t('NOTIFICATION', {count: 1})}
                subTitle={t('NOTIFICATION_SUBTITLE')}
                channels={channels}
              />
            </div>
            <div className='col-xxl-8'>
              <AllCampaignTable datas={allComaigns} />
            </div>
          </div>
          <br />
        </>
      )}
    </>
  )
}

const Home: FC = () => {
  const [sessionType, setSessionType] = useState('')
  const dateRange = {
    startDate: moment().subtract(30, 'days').format('ll'),
    endDate:moment().format('ll'),
  }
  const user = getGlobalItem('user')
  const email = user.email
  const {
    authStore: {getUser},
  } = useStore()
  useEffect(() => {
    getUser()
  }, [getUser])

  useEffect(() => {
    getTotalSession()
  }, [sessionType])

  if (email === 'demo@groweo.com') {
    mockApi()
  }

  const [activity, setActivity] = useState([])
  const [visitorsData, setVistorsData] = useState({totalItemCount: 0, records: []})
  const [sessionData, setSessionData] = useState({totalItemCount: 0, records: []})
  const [successfullConversationData, setSuccessfullConversationData] = useState({totalItemCount: 0, records: []})
  const [activeApps, setActiveApps] = useState(0)
  const [totalSessions, setTotalSessions] = useState({totalItemCount: 0, records: []})
  const [mostUsedApps, setMostUsedApps] = useState({totalItemCount: 0, records: []})
  const [popularApps, setPopularApps] = useState({totalItemCount: 0, records: []})

  const [applicationStatistics, setApplicationStatistics] = useState([{value: 0, category: ''}])
  const [customerServiceLeads, setCustomerServiceLeads] = useState([
    {tag: '', count: {visitors: 0, leads: 0, sessions: 0}},
  ])
  const [audience, setAudience] = useState({totalContacts: 0, datas: []})
  const [recentAudience, setRecentAudience] = useState([])
  const [facebookCompaign, setFacebookCompaign] = useState({
    newFollowers: 0,
    followersGoal: 0,
    datas: [],
  })
  const [channels, setChannels] = useState([])
  const [allComaigns, setAllCompaigns] = useState([])

  const getActivityData = async () => {
    let response = await getActivity()
    setActivity(response)
  }

  const getUniqueVisitors = async () => {
    let response = await getUniqueVisitorsData(dateRange)
    setVistorsData(response)
  }


  const getActiveAppData = async () => {
    let response = await getActiveAppsData()
    setActiveApps(response)
  }
  const getSessionData = async () => {
    let response = await getSessionsData(dateRange)
    setSessionData(response)
  }

  const getSuccessfullConversations = async () => {
    let response = await getSuccessfullConversationData(dateRange)
    setSuccessfullConversationData(response)
  }

  const getMostUserApp = async () => {
    let response = await getMostUserApps()
    setMostUsedApps(response)
  }

  const getPopularApp = async () => {
    let response = await getPopularApps()
    setPopularApps(response)
  }

  const applicationStatistic = async () => {
    const bot = await getAppPerformance({type: 'bot', ...dateRange})
    const form = await getAppPerformance({type: 'form', ...dateRange})
    const smartBlock = await getAppPerformance({type: 'smartblock', ...dateRange})
    const smartPopup = await getAppPerformance({type: 'smartpopup', ...dateRange})
    const smartFlow = await getAppPerformance({type: 'smartflow', ...dateRange})
    const smartBar = await getAppPerformance({type: 'smartbar', ...dateRange})
    const file = await getAppPerformance({type: 'file', ...dateRange})
    const apps = [
      {value: bot, category: 'SmartChat'},
      {value: form, category: 'Smart Forms (V1)'},
      {value: smartBlock, category: 'Smart Bots & Forms'},
      {value: smartPopup, category: 'Smart Popups'},
      {value: smartFlow, category: 'Smart Flows'},
      {value: smartBar, category: 'Smart Bars'},
      {value: file, category: 'Download Tools'},
    ]
    const filterData = apps.filter(({value}) => !!value)
    setApplicationStatistics(filterData.length ? filterData : [{value: 0, category: ''}])
  }

  const getTotalSession = async () => {
    let response = await getTotalSessions(sessionType,dateRange)
    setTotalSessions(response)
  }

  // const getCustomerServiceLead = async () => {
  //   let response = await getCustomerServiceLeads()
  //   setCustomerServiceLeads(response)
  // }

  const getAudience = async () => {
    let response = await getAudiences()
    setAudience(response)
  }

  const getRecentAudience = async () => {
    let response = await getRecentAudiences()
    setRecentAudience(response)
  }

  // const getFacebookCompaign = async () => {
  //   let response = await getFacebookCompaigns()
  //   setFacebookCompaign(response)
  // }

  const getChannel = async () => {
    let response = await getChannels()
    setChannels(response)
  }

  const getAllCompaign = async () => {
    let response = await getAllCampaigns()
    setAllCompaigns(response)
  }

  useEffect(() => {
    getActivityData()
    getUniqueVisitors()
    getSessionData()
    getActiveAppData()
    getSuccessfullConversations()
    getMostUserApp()
    getPopularApp()
    getTotalSession()
    // getCustomerServiceLead()
    getAudience()
    getRecentAudience()
    // getFacebookCompaign()
    getChannel()
    getAllCompaign()
    applicationStatistic()
  }, [])

  return (
    <>
      <GrowthEngineBreadCrumb />
      <DashboardPage
        activity={activity}
        activeApps={activeApps}
        visitorsData={visitorsData}
        sessionData={sessionData}
        successfullConversationData={successfullConversationData}
        totalSessions={totalSessions}
        mostUsedApps={mostUsedApps}
        popularApps={popularApps}
        applicationStatistics={applicationStatistics}
        customerServiceLeads={customerServiceLeads}
        audience={audience}
        recentAudience={recentAudience}
        facebookCompaign={facebookCompaign}
        channels={channels}
        allComaigns={allComaigns}
        email={email}
        setSessionType={setSessionType}
      />
    </>
  )
}

export default Home
