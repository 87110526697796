import { useEffect } from 'react'
import {useIntl} from 'react-intl'
const unlayer = window.unlayer

const EmailBuilderJs = (props) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const { template, onChange, customFields, styles, smartpopup } = props

  useEffect(() => {
    unlayer.init({
      id: 'editor-container',
      projectId: 82129,
      displayMode: smartpopup ? 'popup' : 'web',
      mergeTags: customFields,
      customCSS: [`${process.env.REACT_APP_FRONTEND_URL}/unlayer.css`],
      customJS: [`${process.env.REACT_APP_FRONTEND_URL}/sdk/unlayerTool/unlayer-custom-tool.js`],
      locale: t('UNLAYER_LANGUAGE'),
      translations: {
        'fi-FI': {
          "fields.email": t('EMAIL', {count:0}),
          "content_tools.form": t('CHAT_FORM', {count:0}),
          "tools.form.field_name": t('FIELD_NAME', {count:0}),
          "buttons.delete": t('DELETE', {count:0}),
          "buttons.cancel": t('CANCEL', {count:0})
        }
      },
      tools: {
        form: {
          properties: {
            buttonText: {
              value: t('SUBMIT')
            },
            fields: {
              value: [{
                "name": "email",
                "type": "email",
                "label": t('EMAIL', {count:0}),
                "placeholder_text": t('EMAIL', {count:0}),
                "show_label": true,
                "required": true 
              }]
            }
          }
        }
      },
      fonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            label: "Axiforma",
            value: "axiforma",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/axiforma/axiforma.css`
          },
          {
            label: "Divenire Mono",
            value: "'divenire-mono',serif",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/divenire-mono/divenire-mono.css`
          },
          {
            label: "Baskerville",
            value: "baskerville",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/baskerville/baskerville.css`
          },
          {
            label: "Luminari",
            value: "Luminari",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/luminari/luminari.css`
          },
          {
            label: "Monaco",
            value: "monaco",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/monaco/monaco.css`
          },
          {
            label: "Symbol",
            value: "symbol",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/symbol/symbol.css`
          },
          {
            label: "Terminal",
            value: "Terminal",
            url: `${process.env.REACT_APP_FRONTEND_URL}/unlayer-fonts/terminal/terminal.css`
          },
          {
            label: "Poppins",
            value: "Poppins",
            url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'
          },
          {
            label: "Exo 2",
            value: "'Exo 2'",
            url: 'https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap'
          },
          {
            label: "Roboto",
            value: "Roboto",
            url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'
          },
          {
            label: "Inter",
            value: "Inter",
            url: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap'
          },
          {
            label: "Oswald",
            value: "Oswald",
            url: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap'
          },
          {
            label: "Noto sans",
            value: "'Noto sans'",
            url: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap'
          },
          {
            label: "Nunito",
            value: "Nunito",
            url: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap'
          },
          {
            label: "Nunito Sans",
            value: "'Nunito Sans'",
            url: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap'
          },
          {
            label: "Merriweather",
            value: "Merriweather",
            url: 'https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap'
          },
          {
            label: "Maven Pro",
            value: "'Maven Pro'",
            url: 'https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700&display=swap'
          },
          {
            label: "Abril fatface",
            value: "'Abril fatface'",
            url: 'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap'
          },
          {
            label: "Oleo Script",
            value: "'Oleo Script'",
            url: 'https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap'
          },
          {
            label: "Luckiest Guy",
            value: "'Luckiest Guy'",
            url: 'https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap'
          },
          {
            label: "Shadows Into Light",
            value: "'Shadows Into Light'",
            url: 'https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap'
          },
        ]
      }
    })
    unlayer.setLinkTypesSharedConfig({
      attrs: {
        'data-tracking': '{{className}}'
      },
      fields: [
        {
          name: 'className',
          label: 'Class',
          defaultValue: '',
          inputType: 'text',
          placeholderText: null,
          options: [],
        },
      ],
    });
    unlayer.addEventListener('design:updated', function(updates) {
      // Design is updated by the user
      unlayer.exportHtml(function(data) {
        const { html, design } = data
        onChange({ ...template, code: JSON.stringify(design), appearence: { ...template?.appearence, html: JSON.stringify(html) } })
      })
    })
    const design = template?.code
    if (design) unlayer.loadDesign(JSON.parse(design));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="editor-container" style={styles || {height: '90vh'}}></div>
  )
}

export default EmailBuilderJs
