/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import moment from 'moment'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {useHistory, useLocation} from 'react-router-dom'
import {getModulePath} from '../../../../utils/module.utils'
import {getDateOptions} from '../../../../utils/utils'
import SelectInputFeild from '../../../../app/shared/SelectInputFeild'

type AutomationPageToolbarProps = {
  from?: any
  title?: any
  active?: any
  showModal?: any
  dateRange: any
  setDateRange: any
  selected: any
  setSelected: any
  isHideBack: boolean
}

const PerformanceExplorerToolbar: FC<AutomationPageToolbarProps> = ({
  from,
  title,
  active,
  showModal,
  setDateRange,
  selected,
  setSelected,
  isHideBack
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  const history = useHistory()
  const {state}: any = useLocation()

  const onSelectDateRange = (_name: any, days: any) => {
    const startDate = moment().subtract(days, 'd').format('ll')
    const endDate = Number(days) === 1 ? moment().format('ll') : moment().add(1, 'd').format('ll')
    setSelected(days)
    setDateRange(days === 'all' ? {} : {startDate, endDate})
  }

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack p-0')}
      >
        <div>
          <SectionHeading title={`${t(title)}`} subTitle={t(`${title}_SUBTITLE`)} />
        </div>
        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center position-relative mx-2'>
            <SelectInputFeild
              hideDefault={true}
              name='date'
              classes='bg-white'
              value={selected}
              options={getDateOptions(t)}
              onChange={onSelectDateRange}
            />
          </div>
          {!isHideBack && (<a
            className='btn btn-primary text-uppercase'
            onClick={() => {
              active
                ? showModal(true)
                : history.push({
                    pathname: `${getModulePath(from)}`,
                    state,
                  })
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon svg-icon-2' />
            {t('BACK')}
          </a>)}
        </div>
      </div>
    </div>
  )
}

export {PerformanceExplorerToolbar}
