import {useEffect, useState} from 'react'
import List from '../../modules/common/shared/sessions/List'
import DataPlatformBreadCrumb from './DataPlatformBreadCrumb'
import {SessionToolbar} from '../../../_metronic/layout/components/toolbar/SessionToolbar'

const Sessions = () => {
  const [sessions, setSessions]: any = useState([])

  useEffect(() => {
    setSessions([])
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <DataPlatformBreadCrumb />
      <SessionToolbar setSessions={setSessions} />
      <List sessionList={sessions} />
    </>
  )
}

export default Sessions
