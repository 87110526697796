export const inputTypes = [
  'autocomplete',
  'button',
  'checkbox-group',
  'date',
  'file',
  'header',
  'hidden',
  'number',
  'paragraph',
  'radio-group',
  'select',
  'text',
  'textarea',
]
