import {FC, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Accordion from 'react-bootstrap/Accordion';
import Collapsible from 'react-collapsible'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import ImageInputFeild from '../../../../../../shared/ImageInputFeild'
import {getFileData} from '../../../../../../../utils/utils'
import {updateImage} from '../../../../../../../service/module.service'
import TextInputFeild from '../../../../../../shared/TextInputFeild'
import {KTSVG} from '../../../../../../../_metronic/helpers'

type OptionSlideProps = {
  selectedSlide?: any
  setSelectedSlide?: any
  onSave?: any
  onFieldSave?: any
}
interface RouteParams {
  moduleId: string
  step: string
}

const Option: FC<OptionSlideProps> = ({
  selectedSlide,
  setSelectedSlide,
  onSave,
  onFieldSave,
}) => {
  const intl = useIntl()
  const {moduleId} = useParams<RouteParams>()
  const t = (id: any, values: any) => intl.formatMessage({id}, values || {})

  const onOptionUpdate = (index: any, data: any) => {
    selectedSlide.data.content.options[index][data.key] = data.value
    setSelectedSlide({...selectedSlide})
    if (data.key === 'url') onSave()
  }

  const onOptionAdd = () => {
    if (!selectedSlide?.data.content?.options?.length) selectedSlide.data.content.options = []
    selectedSlide.data.content.options.push({
      title: `Title`,
      subTitle: `Subtitle`,
    })
    setSelectedSlide({...selectedSlide})
    onSave()
  }

  const onFileUpload = async (e: any, index: any) => {
    if (!e.target.files.length) return
    const data = await updateImage(moduleId, getFileData(e.target.files))
    if (data) onOptionUpdate(index, {key: 'url', value: data})
  }

  const CollapsibleTrigger = (props: any) => (
    <div className='w-100 p-2 bg-secondary'>
      <b className='fs-4 fw-semibold mb-2'>{props.text}</b>
    </div>
  )

  const renderOptions = () => (
    <div>
      {selectedSlide?.data?.content?.options?.map((option: any, optIndex: any) => (
        <Draggable key={optIndex} draggableId={String(optIndex)} index={optIndex}>
          {(fieldData: any, checkItem: any) => {
            return (
              <div
                ref={fieldData.innerRef}
                {...fieldData.draggableProps}
                style={
                  checkItem.isDragging
                    ? {background: 'white', ...fieldData.draggableProps.style}
                    : fieldData.draggableProps.style
                }
              >
                <Accordion defaultActiveKey='1'>
                  <div
                    className='mb-4 d-flex align-items-center w-100'
                    data-index={optIndex}
                    key={'Accordion-' + optIndex}
                  >
                    <Accordion.Item eventKey={optIndex} className='w-100'>
                      <Accordion.Button className='field-label p-4'>
                        {`${t('OPTION', {count: 0})} ${optIndex + 1}`}
                      </Accordion.Button>
                      <Accordion.Body>
                        <div className='mb-5' data-index={optIndex} key={'option-' + optIndex}>
                          <label>{t('IMAGE', {count: 0})}</label>
                          <div>
                            <ImageInputFeild
                              size={{icon: 20, img: 75}}
                              type={'rounded'}
                              url={option.url}
                              title={t('IMAGE', {count: 0})}
                              placeholder={'url(/media/svg/avatars/optionplaceholder.svg)'}
                              element={'background'}
                              onUpload={(e: any, name: any) => onFileUpload(e, optIndex)}
                              onRemove={(name: any) =>
                                onOptionUpdate(optIndex, {key: 'url', value: ''})
                              }
                            />
                          </div>
                          <label className='mt-4'>{t('TITLE', {count: 0})}</label>
                          <input
                            className='form-control'
                            value={option?.title}
                            onChange={(e) =>
                              onOptionUpdate(optIndex, {key: 'title', value: e.target.value})
                            }
                            onBlur={onSave}
                          />
                          <label className='mt-4'>{t('SPOTIFY_SUBTITLE', {count: 0})}</label>
                          <input
                            className='form-control'
                            value={option?.subTitle}
                            onChange={(e) =>
                              onOptionUpdate(optIndex, {key: 'subTitle', value: e.target.value})
                            }
                            onBlur={onSave}
                          />
                          <div className='justify-content-end d-flex '></div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <button
                      type='button'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
                      name='delete'
                      onClick={() => {
                        selectedSlide.data.content.options.splice(optIndex, 1)
                        setSelectedSlide({...selectedSlide})
                        onSave()
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </button>
                    <div
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      {...fieldData.dragHandleProps}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr032.svg'
                        className='svg-icon-2x svg-icon-success'
                      />
                    </div>
                  </div>
                </Accordion>
              </div>
            )
          }}
        </Draggable>
      ))}
    </div>
  )

  const handleDragEnd = useCallback(({source, destination}) => {
    const newItems = selectedSlide?.data?.content?.options
    const [temp] = newItems.splice(source.index, 1)
    destination?.index === 0
      ? newItems.splice(0, 0, temp)
      : destination?.index
      ? newItems.splice(destination.index, 0, temp)
      : newItems.splice(source.index, 0, temp)
    setSelectedSlide({
      ...selectedSlide,
      data: {...selectedSlide.data, content: {...selectedSlide.data.content, options: newItems}},
    })
    onSave()
  }, [])

  return (
    <>
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('OPTION', {count: 1})} />}
      >
        <div className='p-0'>
          <div className='panel-content'>
            <label className='fs-5 fw-semibold mb-2'>{t('MIN_OPTION', {count: 0})}</label>
            <TextInputFeild
              type={'number'}
              name='minOption'
              value={selectedSlide.data.content?.minOption || ''}
              onChange={(name: any, value: any) => onFieldSave(name, value)}
              onBlur={onSave}
            />
          </div>
          <div className='panel-content mb-4'>
            <label className='fs-5 fw-semibold mb-2'>{t('MAX_OPTION', {count: 0})}</label>
            <TextInputFeild
              type={'number'}
              name='maxOption'
              value={selectedSlide.data.content?.maxOption || ''}
              onChange={(name: any, value: any) => onFieldSave(name, value)}
              onBlur={onSave}
            />
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='root'>
              {(fieldData: any) => {
                return (
                  <div ref={fieldData.innerRef} {...fieldData.droppableProps}>
                    {renderOptions()}
                    {fieldData.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
          <button type='button' className='btn btn-sm btn-primary' onClick={() => onOptionAdd()}>
            {t('ADD_CHOICE', {count: 0})}
          </button>
        </div>
      </Collapsible>
      <br />
    </>
  )
}
export default Option
