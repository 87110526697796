/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {getRoles, rolesText} from '../../../../../utils/utils'
import UserModal from './UserModal'
import {deleteUser, getUser} from '../../../../../service/user.service'
import ConfirmModal from '../../../common/modal/ConfirmModal'
import SetPasswordModal from './SetPasswordModal'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { useStore } from '../../../../../store'
import TextModal from '../../../common/modal/TextModal'

type Props = {
  workspace: any
}
interface RouteParams {
  workspaceId: string
}

const UserList: FC<Props> = ({workspace}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {
    authStore: { resendTokenEmail },
  } = useStore()
  const {workspaceId} = useParams<RouteParams>()
  const [user, setUser] = useState({})
  const [userList, setUserList] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [showSwalModal, setShowSwalModal] = useState(false)

  const getUsers = async () => {
    if (!workspaceId) return
    const data = await getUser(workspaceId)
    setUserList(data)
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // eslint-disable-next-line
  }, [userList])

  useEffect(() => {
    if (workspaceId) getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId])

  const onDelete = async () => {
    if (deleteId) {
      const data = await deleteUser(deleteId)
      if (data) getUsers()
    }
  }

  const showDeleteModel = async (id: string) => {
    setDeleteId(id)
    setShowConfirmModal(true)
  }

  const renderRole = (role: string) => {
    const roles: any = rolesText(t)
    return roles[role] || ''
  }

  const resendEmail = async (data: any) => {
    const { email } = data
    if (!email) return
    const id = await resendTokenEmail({ email })
    if (id) setShowSwalModal(true)
  }

  return (
    <>
     {showSwalModal ? (
        <TextModal
          message={t('EMAIL_SEND_MODAL_TEXT')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <div className='tab-content'>
        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table
                id='kt_project_users_table'
                className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder'
              >
                <thead className='fs-7 text-gray-400 text-uppercase'>
                  <tr>
                    <th className='min-w-250px'>{t('NAME', {count: 0})}</th>
                    <th className='min-w-90px'>{t('ROLE', {count: 0})}</th>
                    <th className='min-w-90px'>{t('STATUS', {count: 0})}</th>
                    <th className='min-w-90px text-end'>{t('ACTION', {count: 1})}</th>
                  </tr>
                </thead>
                <tbody className='fs-6'>
                  {userList.length ? (
                    userList.map((l: any) => (
                      <tr key={`userList${l._id}`}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column justify-content-center'>
                              <a
                                className='mb-1 text-gray-800 text-hover-primary text-capitalize'
                              >
                                {l?.name}
                              </a>
                              <div className='fw-bold fs-6 text-gray-400'>{l?.email}</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex flex-column justify-content-center'>
                              <a className='mb-1 text-gray-800 text-hover-primary'>
                                {renderRole(l?.role)}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span
                            className={`badge ${
                              l?.status ? 'badge-light-success' : 'badge-light-danger'
                            } fw-bolder px-4 py-3`}
                          >
                            {t(l?.status ? 'ACTIVE' : 'INACTIVE', {count: 0})}
                          </span>
                        </td>
                        <td className='text-end'>
                          <div className='card-toolbar m-0'>
                            <button
                              className='btn btn-light btn-active-light-primary btn-sm'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              {t('ACTION', {count: 1})}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr072.svg'
                                className='svg-icon svg-icon-5 m-0'
                              />
                            </button>

                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                              data-kt-menu='true'
                            >
                              <div className='menu-item px-3'>
                                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4 d-flex'>
                                  {t('QUICK_ACTIONS')}
                                </div>
                              </div>
                              <div className='separator mb-3 opacity-75'></div>
                              {l?.role !== "groweo-admin" && <div className='menu-item px-3'>
                                <a
                                  onClick={() => setUser(l)}
                                  className='menu-link px-3'
                                  data-bs-toggle='modal'
                                  data-bs-target='#gw_edit_user_modal'
                                >
                                  {t('EDIT')}
                                </a>
                              </div>}
                              <div className='menu-item px-3'>
                                <a
                                  onClick={() => setUser(l)}
                                  className='menu-link px-3'
                                  data-bs-toggle='modal'
                                  data-bs-target='#gw_set_password_modal'
                                >
                                  {t('SET')} {t('PASSWORD')}
                                </a>
                              </div>
                              <div className='menu-item px-3'>
                                <a
                                  onClick={() => resendEmail(l)}
                                  className='menu-link px-3'
                                >
                                  {t('SEND_ACTIVATION_EMAIL', { count: 0 })}
                                </a>
                              </div>
                              {l?.role !== "groweo-admin" && <div className='menu-item px-3'>
                                <a
                                  onClick={() => showDeleteModel(l?._id)}
                                  className='menu-link px-3'
                                >
                                  {t('DELETE')}
                                </a>
                              </div>}

                              <div className='separator mt-3 opacity-75'></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className='fw-bolder fs-5 text-center'>
                        {t('EMPTY_TEXT', {field: t('USER', {count: 1})})}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <UserModal
        modalId={'gw_edit_user_modal'}
        user={user}
        setUser={setUser}
        setUserList={setUserList}
        workspace={workspace}
      />
      <UserModal modalId={'gw_create_user_modal'} setUserList={setUserList} workspace={workspace} />
      <SetPasswordModal
        modalId={'gw_set_password_modal'}
        user={user}
        setUserList={setUserList}
      />
      {showConfirmModal && (
        <ConfirmModal
          icon={''}
          show={showConfirmModal}
          close={setShowConfirmModal}
          onConfirm={onDelete}
        />
      )}
    </>
  )
}

export default UserList
