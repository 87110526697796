import axios from 'axios'
import { handleError,qs } from '../utils/utils'


export const createWorkspace = async (data: any) => {
  try {
    const response = await axios.post(`/api/workspace`, data)
    return response.data?.data

  } catch ({ response }) {
    handleError(response)
  }
}

export const getWorkspace = async (params:any) => {
  try {
    const response = await axios.get(`/api/workspace?${qs(params)}`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const getWorkspaceById = async (id: string) => {
  try {
    const response = await axios.get(`/api/workspace/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const loginWorkspace = async (workspaceId: string) => {
  try {
    const response = await axios.post(`/api/workspace/login`, { workspaceId })
    return response.data.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getSubscriptionPlan = async () => {
  try {
    const response = await axios.get(`/api/subscriptionPlan?status=active`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const updateWorkspace = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/workspace/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

