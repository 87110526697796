/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

type Props = {
    datas : {title:string,
		subTitle:string,
		startDate:string,
		endDate:string,
		status:string,
		channel:string,
		audience:string,
		statistics: { sent: number, delivered: number, opened: number, clicked: number },
		chartData: number[]
		}[]
}
const AllCampaignTable: React.FC<Props> = ({datas}) => {

	const intl = useIntl()
	const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  return (
    <div className="card card-flush ">
											
											<div className="card-header py-7">
												
												<div className="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">
													
													<div className="fs-4 fw-bolder pb-3 border-bottom border-3 border-primary cursor-pointer" data-kt-table-widget-3="tab" data-kt-table-widget-3-value="Show All">{t('ACTIVE', {count:1})} {t('CAMPAIGN', {count:1})} ({datas.length})</div>
													
													
													<div className="fs-4 fw-bolder text-muted pb-3 cursor-pointer" data-kt-table-widget-3="tab" data-kt-table-widget-3-value="Pending">{t('PENDING', {count:1})}  (7)</div>
													
													
													<div className="fs-4 fw-bolder text-muted pb-3 cursor-pointer" data-kt-table-widget-3="tab" data-kt-table-widget-3-value="Completed">{t('COMPLETED', {count:0})}  (20)</div>
													<div className="fs-4 fw-bolder text-muted pb-3 cursor-pointer" data-kt-table-widget-3="tab" data-kt-table-widget-3-value="Completed">{t('ALL', {count:0})}  (45)</div>
													
												</div>
												
												
												
												
											</div>
											
											
											<div className="card-body pt-1">
												
											
												<div className="separator separator-dashed my-5"></div>
												
												
												<div id="kt_widget_table_3_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="table-responsive"><table id="kt_widget_table_3" className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer" data-kt-table-widget-3="all">
													<thead className="d-none">
														<tr>
															<th className="sorting" tabIndex={0} aria-controls="kt_widget_table_3" aria-label="Campaign: activate to sort column ascending" style={{width: '0px'}}>Campaign</th>
															<th className="sorting" tabIndex={0} aria-controls="kt_widget_table_3"   aria-label="Status: activate to sort column ascending" style={{width: '0px'}}>Status</th>
															<th className="sorting" tabIndex={0} aria-controls="kt_widget_table_3"   aria-label="Team: activate to sort column ascending" style={{width: '0px'}}>Team</th>
															<th className="sorting" tabIndex={0} aria-controls="kt_widget_table_3"   aria-label="Date: activate to sort column ascending" style={{width: '0px'}}>Date</th>
						
															<th className="sorting" tabIndex={0} aria-controls="kt_widget_table_3"   aria-label="Action: activate to sort column ascending" style={{width: '0px'}}>Action</th>
														</tr>
													</thead>
													<tbody>
													
                                                    {datas.map((data,i)=>{

                                                        // status color
                                                        let statusColor = "";
                                                        if(data.status==="Live")
                                                            statusColor = "success"
                                                        else if(data.status==="Past")
                                                            statusColor = "primary"
                                                        else    
                                                            statusColor = "warning"

                                                        // side line color
                                                        let color = "";
                                                        if(i%5===0)
                                                            color = "info"
                                                        else if(i%5 === 1)
                                                            color = "warning"
                                                        else if(i%5 === 2)
                                                            color = "success"
                                                        else if(i%5 === 3)
                                                            color = "danger"
                                                        else
                                                            color = "primary"
                                                        return (
                                                            <tr className="odd">
															<td className="min-w-175px">
																<div className="position-relative ps-6 pe-3 py-2">
																	<div className={`position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-${color}`}></div>
																	<a href="#" className="mb-1 text-dark text-hover-primary fw-bolder">{data.title}</a>
																	<div className="fs-7 text-muted fw-bolder">{data.subTitle}</div>
																</div>
															</td>
															
															<td>
																<span className={`badge badge-light-${statusColor}`}>{data.status}</span>
															</td>
															<td className="min-w-125px">
																
																<div className="symbol-group symbol-hover mb-1">
																	
																	
						
																	<div className="symbol symbol-circle symbol-25px">
																		<div className="symbol-label bg-light-danger">
																			<span className="fs-7 text-danger">{data.statistics.sent}</span>
																		</div>
																	</div>
																	
																	
																	
																</div>
																
															</td>
															<td className="min-w-150px">
																<div className="mb-2 fw-bolder">{data.startDate} - {data.endDate}</div>
																
															</td>
															
															<td className="text-end">
																<button type="button" className="btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px">
																	
																	<span className="svg-icon">
																		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																			<path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="black"></path>
																			<path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="black"></path>
																		</svg>
																	</span>
																	
																</button>
															</td>
														</tr>
                                                        )
                                                    })}
														
													
                                                        </tbody>
												</table></div><div className="row"><div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div><div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div></div></div>
												
											</div>
											
										</div>
  )
}

export default AllCampaignTable

