import axios from 'axios'
import {handleError, qs} from '../utils/utils'

export const getSession = async (params: any) => {
  try {
    const {data} = await axios.get(`/api/session?${qs(params)}`)
    return data?.data?.records
  } catch ({response}) {
    handleError(response)
  }
}

export const updateSessionSeen = async (id: string) => {
  try {
    const response = await axios.put(`/api/session/seen/${id}`)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}
