/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState, useCallback} from 'react'
import { useSmartFlowData } from '../../../StepperContext'
import {useIntl} from 'react-intl'
import Accordion from 'react-bootstrap/Accordion';
import Collapsible from 'react-collapsible'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import SelectInputFeild from '../../../../../../shared/SelectInputFeild'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'

type FieldsProps = {
  selectedSlide?: any
  setSelectedSlide?: any
  onSave?: any
  onFieldSave?: any
}

const Fields: FC<FieldsProps> = ({selectedSlide, setSelectedSlide, onSave, onFieldSave}) => {
  const intl = useIntl()
  const {smartFlow, setSmartFlow}: any = useSmartFlowData()
  const [formColumn, setFormColumn]: any = useState(1)
  const {formMultiColumn} = smartFlow?.moduleCode?.appearance?.style
  const t: any = (id: any, values: any) => intl.formatMessage({id}, values || {})

  const columnAlignment = [
    {value: 'center', text: t('CENTER')},
    {value: 'end', text: t('END')},
  ]

  const fieldTypes = [
    {value: 'text', text: t('TEXT_ONE_LINE')},
    {value: 'textarea', text: t('TEXT_BOX_MULTIPLE_LINE')},
    {value: 'email', text: t('EMAIL', {count: 0})},
    {value: 'number', text: t('NUMBER')},
    {value: 'date', text: t('DATE')},
    {value: 'password', text: t('PASSWORD')},
    {value: 'tel', text: t('PHONE_NUMBER')},
    {value: 'url', text: t('WEBSITE')},
    {value: 'radio', text: t('RADIO_BUTTON')},
    {value: 'checkbox', text: t('CHECKBOX')},
    {value: 'dropdwon', text: t('DROPDWON')},
    {value: 'file', text: t('FILE_UPLOAD')},
  ]

  const defaultField = [
    {
      type: 'text',
      name: 'name',
      label: t('NAME'),
      placeholder: t('NAME'),
      require: false,
      showlabel: false,
    },
    {
      type: 'email',
      name: 'email',
      label: t('EMAIL', {count: 0}),
      placeholder: t('EMAIL', {count: 0}),
      require: false,
      showlabel: false,
    },
    {
      type: 'tel',
      name: 'phone',
      label: t('PHONE_NUMBER'),
      placeholder: t('PHONE_NUMBER'),
      require: false,
      showlabel: false,
    },
    {type: 'date', name: 'birthday', label: t('BIRTHDAY'), require: false, showlabel: false},
    {
      type: 'text',
      name: 'company',
      label: t('COMPANY'),
      placeholder: t('COMPANY'),
      require: false,
      showlabel: false,
    },
    {
      type: 'url',
      name: 'website',
      label: t('WEBSITE'),
      placeholder: t('WEBSITE'),
      require: false,
      showlabel: false,
    },
    {
      type: 'text',
      name: 'zipcode',
      label: t('ZIPCODE'),
      placeholder: t('ZIPCODE'),
      require: false,
      showlabel: false,
    },
    {type: 'text', name: '', label: '', placeholder: '', require: false, showlabel: false},
  ]

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // eslint-disable-next-line
  }, [])

  const renderInput = (
    Label: any,
    name: any,
    fieldIndex: any,
    value: any,
    type?: any,
    checked?: any,
    required?: any
  ) => (
    <div className='m-2 p-0'>
      {type !== 'checkbox' ? (
        <>
          <div className='d-flex justify-content-between'>
            <label>{Label}</label>
            {Label === t('FIELD_LABEL') && (
              <label htmlFor={`showlabel${fieldIndex}`} className='d-flex align-items-center'>
                <input
                  type='checkbox'
                  className='form-check m-0 mx-2'
                  name='showlabel'
                  id={`showlabel${fieldIndex}`}
                  onChange={(e) => onChangeInput(e, fieldIndex)}
                  checked={checked}
                />
                {t('SHOW_LABEL')}
              </label>
            )}
          </div>
          <input
            type={type || 'text'}
            className='form-control'
            name={name}
            value={value}
            required={required}
            onChange={(e) => onChangeInput(e, fieldIndex)}
          />
        </>
      ) : (
        <div className='d-flex justify-content-between'>
          <label className='d-flex align-items-center' htmlFor={name + fieldIndex}>
            <input
              type='checkbox'
              className='form-check m-0'
              name={name}
              id={name + fieldIndex}
              onChange={(e) => onChangeInput(e, fieldIndex)}
              checked={checked}
            ></input>
            <span className='mx-2'>{Label}</span>
          </label>
        </div>
      )}
    </div>
  )

  const renderTextArea = (Label: any, name: any, fieldIndex: any, value: any) => (
    <div className='m-2 p-0'>
      <div className='d-flex justify-content-between'>
        <label>{Label}</label>
      </div>
      <textarea
        className='form-control'
        style={{height: '100px'}}
        name={name}
        value={value}
        onChange={(e) => onChangeInput(e, fieldIndex)}
      ></textarea>
    </div>
  )

  const onChangeInput = (e: any, fieldIndex: any) => {
    const {target} = e
    const currentFieldObj = selectedSlide.data.content.fields[fieldIndex]
    const fieldValue = !['require', 'showlabel'].includes(target.name)
      ? target.value
      : target.checked
    selectedSlide.data.content.fields.splice(fieldIndex, 1, {
      ...currentFieldObj,
      [target.name]: fieldValue,
    })
    setSelectedSlide({...selectedSlide})
    onSave()
  }

  const onChnageType = (name: any, value: any, fieldIndex: any) => {
    if (!value) return
    if (name === 'column') {
      selectedSlide.data.content.fields[fieldIndex][name] = value
      const column1 = selectedSlide.data.content.fields.filter((fld: any) => fld.column === 1);
      const column2 = selectedSlide.data.content.fields.filter((fld: any) => fld.column === 2);
      selectedSlide.data.content.fields = [...column1, ...column2]
    } else selectedSlide.data.content.fields[fieldIndex][name] = value
    setSelectedSlide({...selectedSlide})
    onSave()
  }

  const CollapsibleTrigger = (props: any) => (
    <div className='w-100 p-2 bg-secondary'>
      <b className='fs-4 fw-semibold mb-2'>{props.text}</b>
    </div>
  )

  const onStyleSave = (name: string, value: any) => {
    if (formMultiColumn !== value && value === 100) {
      const foundIndex = smartFlow?.moduleCode?.elements?.findIndex(
        (el: any) => selectedSlide?.id === el.id
      )
      selectedSlide.data.content?.fields.map((fld: any) => {
        fld.column = 1
      })
      if (foundIndex !== -1) {
        smartFlow.moduleCode.elements[foundIndex] = selectedSlide
      }
      setFormColumn(1)
    }
    setSmartFlow({
      ...smartFlow,
      moduleCode: {
        ...smartFlow.moduleCode,
        appearance: {
          ...smartFlow?.moduleCode.appearance,
          style: {...smartFlow?.moduleCode?.appearance?.style, [name]: value},
        },
      },
    })
  }

  const renderColumnSelection = (label: string) => {
    const style =
      'h-35px border border-2 border-secondary d-flex justify-content-center align-items-center text-muted'
    const {formMultiColumn} = smartFlow?.moduleCode?.appearance?.style
    return (
      <div className='panel-content'>
        <label className='fs-5 fw-semibold mb-2'>{label}</label>
        <div className='row'>
          <div className='col-6 cursor-pointer' onClick={() => onStyleSave('formMultiColumn', 100)}>
            <div className={`${style} ${formMultiColumn === 100 ? 'bg-white' : 'bg-light'}`}>1</div>
          </div>
          <div
            className='col-6 d-flex cursor-pointer'
            onClick={() => onStyleSave('formMultiColumn', 50)}
          >
            <div className={`w-50 ${style} ${formMultiColumn === 50 ? 'bg-white' : 'bg-light'}`}>
              1
            </div>
            <div
              className={`w-50 ${style} ${
                formMultiColumn === 50 ? 'bg-white' : 'bg-light'
              } border-start-0`}
            >
              2
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTab = (label: any, column: any) => (
    <li className='nav-item' onClick={() => setFormColumn(column)}>
      <a
        className={`nav-link text-active-primary m-0 me-6 fs-4 cursor-pointer ${
          formColumn === column ? 'active' : ''
        }`}
      >
        {label} {column}
      </a>
    </li>
  )

  const renderFields = () => (
    <div>
      {selectedSlide?.data?.content?.fields?.map(
        (field: any, fieldIndex: any) =>
          field?.column === formColumn && (
            <Draggable key={fieldIndex} draggableId={String(fieldIndex)} index={fieldIndex}>
              {(fieldData, checkItem) => {
                return (
                  <div
                    ref={fieldData.innerRef}
                    {...fieldData.draggableProps}
                    style={
                      checkItem.isDragging
                        ? {background: 'white', ...fieldData.draggableProps.style}
                        : fieldData.draggableProps.style
                    }
                  >
                    <Accordion defaultActiveKey='0'>
                      <div
                        className='mb-4 d-flex align-items-center w-100'
                        data-index={fieldIndex}
                        key={'Accordion-' + fieldIndex}
                      >
                        <Accordion.Item eventKey={fieldIndex} className='w-100'>
                          <Accordion.Button className='p-4'>
                            {field.label || t('FIELD', {count: 0})}
                          </Accordion.Button>
                          <Accordion.Body>
                            <div className='row mx-2'>
                              {formMultiColumn === 50 && (
                                <div className='text-left p-0'>
                                  <label>{t('COLUMN')}</label>
                                  <SelectInputFeild
                                    name='column'
                                    value={field?.column}
                                    appendOption={{value: 1, name: 1}}
                                    options={[{value: 2, text: 2}]}
                                    onChange={(name: any, value: any) =>
                                      onChnageType(name, Number(value), fieldIndex)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className='text-left m-2 p-0'>
                              <label>{t('FIELD_TYPE')}</label>
                              <SelectInputFeild
                                name='type'
                                value={field?.type}
                                options={fieldTypes}
                                onChange={(name: any, value: any) =>
                                  onChnageType(name, value, fieldIndex)
                                }
                              />
                            </div>
                            {renderInput(
                              t('FIELD_NAME'),
                              'name',
                              fieldIndex,
                              field?.name,
                              '',
                              '',
                              true
                            )}
                            {renderInput(
                              t('FIELD_LABEL'),
                              'label',
                              fieldIndex,
                              field?.label,
                              '',
                              field?.showlabel,
                              true
                            )}
                            {!['checkbox', 'dropdwon', 'hidden', 'file', 'radio', 'date'].includes(
                              field?.type
                            ) &&
                              renderInput(
                                t('PLACEHOLDER_TEXT'),
                                'placeholder',
                                fieldIndex,
                                field?.placeholder
                              )}
                            {['checkbox', 'dropdwon', 'radio'].includes(field?.type) &&
                              renderTextArea(
                                t('OPTIONS_ONE_PER_LINE'),
                                'options',
                                fieldIndex,
                                field?.options
                              )}
                            {!['hidden'].includes(field?.type) &&
                              renderInput(
                                t('REQUIRED'),
                                'require',
                                fieldIndex,
                                '',
                                'checkbox',
                                field?.require
                              )}
                          </Accordion.Body>
                        </Accordion.Item>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
                          name='delete'
                          onClick={() => {
                            selectedSlide.data.content.fields.splice(fieldIndex, 1)
                            setSelectedSlide({...selectedSlide})
                            onSave()
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </button>
                        <div
                          className='btn btn-sm btn-icon btn-active-light-primary'
                          {...fieldData.dragHandleProps}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr032.svg'
                            className='svg-icon-2x svg-icon-success'
                          />
                        </div>
                      </div>
                    </Accordion>
                  </div>
                )
              }}
            </Draggable>
          )
      )}
    </div>
  )

  const handleDragEnd = ({source, destination}: any) => {
    const newItems = selectedSlide?.data?.content?.fields
    const [temp] = newItems.splice(source.index, 1)
    destination?.index === 0
      ? newItems.splice(0, 0, temp)
      : destination?.index
      ? newItems.splice(destination.index, 0, temp)
      : newItems.splice(source.index, 0, temp)
    selectedSlide.data.content = {...selectedSlide.data.content, fields: newItems, isDragAndDrop: true};
    setSelectedSlide({...selectedSlide})
    onSave()
  }

  return (
    <>
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('CHAT_FORM')} />}
      >
        {renderColumnSelection(t('COLUMN'))}
        <div className='panel-content'>
          <div className='w-100 d-flex align-items-center justify-content-between'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap'>
              {renderTab(t('COLUMN'), 1)}
              {formMultiColumn === 50 && renderTab(t('COLUMN'), 2)}
            </ul>
          </div>
          <br />
          <div className='text-left d-flex align-items-center p-0'>
            <label className='col-md-6'>{t('ALIGNMENT')}</label>
            <SelectInputFeild
              name={`col${formColumn}Alignment`}
              className={'col-md-6'}
              value={smartFlow?.moduleCode?.appearance?.style[`col${formColumn}Alignment`]}
              appendOption={{value: 'start', name: t('TOP')}}
              options={columnAlignment}
              onChange={(name: any, value: any) => onStyleSave(name, value)}
            />
          </div>
          <br />
          <div className='d-flex align-items-center justify-content-between'>
            <label className='fs-5 fw-bolder'>{t('FIELD', {count: -1})}</label>
            <div className='card-toolbar mb-3'>
              <button
                className='btn btn-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                {t('ADD_NEW_FIELD')}
                <KTSVG
                  path='/media/icons/duotune/arrows/arr072.svg'
                  className='svg-icon svg-icon-5 m-0'
                />
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4 d-flex'>
                    {t('FIELD', {count: 1})}
                  </div>
                </div>
                <div className='separator mb-2 opacity-75'></div>
                {defaultField.map((field: any, index: any) => (
                  <div key={`dropdwon${index}`}>
                    {!field.label && <div className='separator mb-2 opacity-75'></div>}
                    <div className='menu-item px-3'>
                      <a
                        onClick={() => {
                          const getColumnLength = selectedSlide.data.content?.fields?.filter(
                            (field: any) => field.column === formColumn
                          )
                          field['column'] = formColumn
                          selectedSlide.data.content?.fields.push(field)
                          onFieldSave('fields', selectedSlide.data.content?.fields)
                        }}
                        className='menu-link px-3'
                      >
                        {field.label || t('ADD_NEW_FIELD')}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='root'>
              {(fieldData) => {
                return (
                  <div ref={fieldData.innerRef} {...fieldData.droppableProps}>
                    {renderFields()}
                    {fieldData.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </Collapsible>
      <br />
    </>
  )
}
export default Fields
