import React, {useState} from 'react'
import {SketchPicker} from 'react-color'
import {KTSVG} from '../../_metronic/helpers'

const ColorPickerInput = (props: any) => {
  const [togglePicker, setTogglePicker] = useState(false)

  const onChange = (name: string, value: string) => {
    props.onChange(name, value)
  }

  return (
    <div
      className='d-flex align-items-baseline position-relative border border-dark p-1'
      style={{borderRadius: 8}}
    >
      <button
        className='btn btn-default w-25px h-25px p-5'
        style={{background: props.value}}
        onClick={() => setTogglePicker(!togglePicker)}
      />
      {togglePicker && (
        <div className='position-absolute' style={{top: 0, zIndex: 5, right: 41}}>
          <div className='position-relative'>
            <SketchPicker
              width='210px'
              color={props.value}
              onChange={(color) => {
                onChange(
                  props.name,
                  `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                )
              }}
            />
            <div
              onClick={() => setTogglePicker(false)}
              className='cursor-pointer position-absolute text-center bg-light'
              style={{top: -6, right: -6, borderRadius: '50%'}}
            >
              <KTSVG className='svg-icon' path='/media/icons/custom/close_icon.svg' />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ColorPickerInput
