import clsx from 'clsx'
import {observer} from 'mobx-react'
import {FC} from 'react'
import {useStore} from '../../../../store'
import {toAbsoluteUrl} from '../../../helpers'
import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {
    authStore: {user},
  } = useStore()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0 gap-4'>
      {/* begin::User */}
      {user?.workspace?.domain !== 'engine.groweo.com' && (
        <div className='d-flex flex-column justify-content-center d-inline-block' style={{ maxWidth: '300px' }}>
          <div className='d-flex mb-1'>
            <h1 
              className='m-0 text-gray-800 text-hover-primary fs-3 fw-bolder text-capitalize overflow-hidden text-nowrap'
              style={{textOverflow: 'ellipsis'}}
            >
              {user?.workspace?.name}
            </h1>
            <span className='badge badge-light-success ms-2 text-capitalize'>
              {user?.workspace?.subscriptionPlan?.name}
            </span>
          </div>
          <a
            href={`${user?.workspace?.domain.startsWith('http') ? '' : '//'}${user?.workspace?.domain}`}
            target='_blank'
            className='m-0 text-primary fs-6 fw-bold overflow-hidden text-nowrap'
            style={{textOverflow: 'ellipsis'}}
          >
            {user?.workspace?.domain}
          </a>
        </div>
      )}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            src={user?.avatar || toAbsoluteUrl('/favicon/mstile-144x144.png')}
            alt='metronic'
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export default observer(Topbar)
