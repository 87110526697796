/* eslint-disable jsx-a11y/anchor-is-valid */
import {MenuItem} from './MenuItem'
import {useLayout} from '../../core/LayoutProvider'
import {usePageData} from '../../core/PageData'
import {useState} from 'react'
import {KTSVG} from '../../../helpers'

export function MenuInner(props: any) {
  const {pageTitle, setPageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config} = useLayout()
  const [toggleInput, setToggleInput] = useState(false)

  const onEditTitle = () => {
    if (!props.editable) return
    setToggleInput(!toggleInput)
  }

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter' && e.target.value) {
      props.setName(pageTitle)
      setToggleInput(!toggleInput)
    }
  }

  return (
    <>
      {pageTitle && !toggleInput && (
        <h1
          className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-8'
          onDoubleClick={onEditTitle}
        >
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
          {props.editable && (
            <a onClick={onEditTitle} className='btn btn-icon btn-sm mx-1'>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </a>
          )}
        </h1>
      )}

      {toggleInput && (
        <input
          value={pageTitle}
          onChange={(e: any) => setPageTitle(e.target.value)}
          onKeyUp={onKeyUp}
        />
      )}
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs &&
        Array.from(pageBreadcrumbs).map((item, index) => (
          <MenuItem title={item.title} to={item.path} key={`menu_${index}`} />
        ))}
    </>
  )
}
