import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import PerformanceView from '../../modules/admin/performanceReport/view/PerformanceView'
import CustomerReportPageView from '../../modules/admin/performanceReport/view/CustomerReportPageView';

const PerformanceReportIndex: FC = () => {
  return (
    <Switch>
      <Route path='/performance-reports/:workspaceId' render={() => (<PerformanceView isHideBack={true} />)} exact />
      <Route path='/performance-reports/:workspaceId/customer-report/:workspaceName' render={() => (<CustomerReportPageView isHideBack={true} />)} exact />
    </Switch>
  )
}

export default PerformanceReportIndex
