import axios from 'axios'
import { handleError} from '../utils/utils'

export const createCampaign = async (data: any) => {
  try {
    const response = await axios.post(`/api/campaign`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const updateCampaign = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/campaign/${id}`, data)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const deleteCampaign = async (id: string) => {
  try {
    const response = await axios.delete(`/api/campaign/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getCampaign = async () => {
  try {
    const response = await axios.get(`/api/campaign`)
    const campaigns = response?.data?.data?.records || []
    const campaignStats = await getCampaignStats()
    campaigns.map( (trigger: any) => {
      if(campaignStats.hasOwnProperty(trigger._id)) {
        trigger.statistics = campaignStats[trigger._id]
      }
    })
    return campaigns
  } catch ({response}) {
    handleError(response)
  }
}

export const getCampaignStats = async () => {
  try {
    const response = await axios.get(`/api/campaign/stats`)
    const raw_stats = response?.data?.data || [] 
    let campaignStats:any = {}
    raw_stats.map( (item : any) => {
      const campaignId=item._id.campaign
      const status = item._id.status
      if(!campaignStats.hasOwnProperty(campaignId)) {
        campaignStats[campaignId] = {}
      }
      campaignStats[campaignId][status] = item.count
    })
    return campaignStats
  } catch ({response}) {
    handleError(response)
  }
}

export const getNotifcationStats = async () => {
  try {
    const response = await axios.get(`/api/notification/stats`)
    const raw_stats = response?.data?.data || [] 
    let campaignStats:any = {}
    raw_stats.map( (item : any) => {
      const campaignId=item.name
      campaignStats[campaignId] = {}
      campaignStats[campaignId]['SENT'] = item.count
    })
    return campaignStats
  } catch ({response}) {
    handleError(response)
  }
}



export const getCampaignById = async (id: string) => {
  try {
    const response = await axios.get(`/api/campaign/${id}`)
    return response?.data?.data
  } catch ({response}) {
    handleError(response)
  }
}

export const launchCampaign = async (type: string, id: string) => {
  try {
    if(type === 'now') {
      const response = await axios.post(`/api/campaign/launch/${id}`)
      return true
    }
  } catch ({response}) {
    handleError(response)
  }
}
