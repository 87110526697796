import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ConfirmModal: FC<any> = ({close, label, icon, onConfirm, message, from}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const ConfirmSwal = withReactContent(Swal)

  useEffect(() => {
    ConfirmSwal.fire({
      html: message || t('DELETE.TEXT', {label: t(`${label || 'remove'}`.toUpperCase()).toLowerCase()}),
      icon: icon || 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: t('YES'),
      cancelButtonText: t('NO'),
      customClass: {
        confirmButton: 'btn btn-primary mx-2',
        cancelButton: 'btn btn-danger mx-2',
      },
    }).then(({isConfirmed}) => {
      if (isConfirmed) onConfirm()
      close(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default ConfirmModal
