/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {TYPE_NAMES} from '../../../../../../utils/module.utils'

export const NodeDropdown = ({changeType}) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  return (
    <div
      className='dropdown-menu w-175px d-block'
      aria-labelledby='dropdownMenuButton'
      style={{top: 0}}
    >
      <li>
        <h6 className='dropdown-header font-title fs-3 text-center'>{t('NODE')}</h6>
      </li>
      <li>
        <hr className='dropdown-divider'></hr>
      </li>
      {Object.keys(TYPE_NAMES).map((key, i) => (
        <a
          className={`dropdown-item cursor-pointer font-title fs-4 text-center select-node select-node-${key} mx-6 my-4`}
          key={`type${i}`}
          onClick={(e) => changeType(e, key)}
        >
          {TYPE_NAMES[key]}
        </a>
      ))}
    </div>
  )
}
