/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {updateSessionSeen} from '../../../../../service/session.service'
import { getIcon, isWorkspaceReadonly } from '../../../../../utils/utils'
import {KTSVG} from '../../../../../_metronic/helpers'

const SessionList: FC<any> = ({session, list, setCurrentSession, from, setFilteredList}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const idMatched = (item: any) => item._id === session._id

  const labelColor = (item: any) => (item.seen ? 'text-gray-800' : 'primary')

  const relativeTimeColor = (item: any) => (item.seen ? '' : 'text-primary')

  const onSessionClick = async (item: any, index: number) => {
    setCurrentSession(item)
    if(isWorkspaceReadonly()) return
    if (list[index].seen) return
    const data = await updateSessionSeen(item._id)
    if (data) {
      list[index].seen = data.seen
      setFilteredList([...list])
    }
  }

  const renderIcon = (item: any) => {
    return `/media/icons/custom/${getIcon(item.type)}`
  }

  return (
    <>
      {list.length ? (
        <div className='mt-5'>
          {list.map((item: any, index: number) => (
            <div
              key={`list_${index}`}
              className={`d-flex flex-stack mb-5 ${idMatched(item) && 'bg-light'}`}
              onClick={() => onSessionClick(item, index)}
            >
              <div className='d-flex align-items-center me-2 cursor-pointer'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light'>
                    <KTSVG
                      path={`${renderIcon(item)}`}
                      className='svg-icon svg-icon-2x svg-icon-primary'
                    />
                  </div>
                </div>

                <div>
                  <a className={`fs-6 text-${labelColor(item)} text-hover-primary fw-bolder`}>
                    {item.label}{' '}
                  </a>
                  <div
                    /* onClick={() => navigateToModule(item)} */
                    className='fs-7 text-muted fw-bold mt-1 cursor-pointer '
                  >
                    {from ? item.relativeTime : item.moduleId?.name}
                  </div>
                </div>
              </div>

              {!from && (
                <div className={`badge badge-light fw-bold py-4 px-3 ${relativeTimeColor(item)}`}>
                  {item.relativeTime}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className='text-muted text-center'>{t('EMPTY.TEXT')}</p>
      )}
    </>
  )
}

export default SessionList
