import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {observer} from 'mobx-react'
import {useParams} from 'react-router-dom'
import $ from 'jquery'
import {
  codeForSmartBar,
  codeForSmartFlow,
  inPageCodeForBot,
  inPageCodeForForm,
  inPageCodeForSmartBlock,
  inPageCodeForSmartPopup,
  inPopupCodeForBot,
} from '../../../utils/code.utils'
import TextModal from '../common/modal/TextModal'
import {getModuleForPreview} from '../../../service/module.service'

const View = () => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const params = useParams()
  const [showSwalModal, setShowSwalModal] = useState(false)
  const [module, setModule] = useState({})
  const {type, moduleId, workspaceId} = params
  const splitArr = type.split('-')
  const app = splitArr[0]
  const appType = splitArr[1]

  useEffect(() => {
    const {content} = getContent(true, true)
    console.log('content:', content)
    let data = content
    if (typeof content === 'string') {
      data = content.split('\n')
    }
    if (['form', 'file', 'smartblock', 'smartpopup', 'smartbar', 'smartflow'].includes(app)) $('#preview-id').append(data)
    if (app === 'bot') data.forEach((item) => $('#preview-id').append($(item)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    if (app === 'file') renderEmailTemplate()
    if (app === 'smartblock') getModule()
  }, [params])

  const getModule = async () => {
    const data = await getModuleForPreview(workspaceId, moduleId, {isPreview: true})
    if(!data) return;
    setModule(data)
  }

  const renderEmailTemplate = async () => {
    const data = await getModuleForPreview(workspaceId, moduleId, {isPreview: true})
    if (!data?.emailTemplate?.html) return
    const {
      emailTemplate: {html},
    } = data
    const templateHTML = new DOMParser().parseFromString(html, 'text/html')
    const el = document.getElementById('email-template-preview-id')
    const h1Elem = document.createElement('h1')
    h1Elem.innerText = t('EMAIL_TEMPLATES', {count: 0})
    el.parentNode.insertBefore(h1Elem, el)
    el.innerHTML = templateHTML.body.innerHTML
  }

  const getContent = (isIframe, isPreview) => {
    if (!['inpage', 'popup'].includes(appType) || !moduleId) return
    const active = appType === 'popup' ? 1 : 0

    let content
    if (['form', 'file'].includes(app))
      content = inPageCodeForForm(moduleId, workspaceId, isPreview, active === 0, isIframe)
    if (['smartblock'].includes(app)){
      const isCustomTool = module?.content ? JSON.parse(module?.content).includes('inpage-bot-block')  : false;
      content = inPageCodeForSmartBlock(moduleId, workspaceId, isPreview, active === 0, 'fi', isCustomTool)
    }
    if (['smartpopup'].includes(app))
      content = inPageCodeForSmartPopup(moduleId, workspaceId, isPreview, isIframe)
    if (['smartbar'].includes(app))
      content = codeForSmartBar(moduleId, workspaceId, isPreview)  
    if (['smartflow'].includes(app))
      content = codeForSmartFlow(moduleId, workspaceId, isPreview, active === 0, isIframe)
    if (app === 'bot')
      content =
        appType === 'inpage'
          ? inPageCodeForBot(moduleId, workspaceId, isPreview, !isIframe)
          : inPopupCodeForBot(moduleId, workspaceId, isPreview, !isIframe)
    if (active !== 0 && ['form', 'file', 'smartblock', 'smartpopup'].includes(app))
      document.body.style.height = window.innerHeight * 2 + 'px'
    return {content}
  }

  const onCopy = async (isIframe) => {
    const {content} = getContent(isIframe, false)

    if (navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(content)
      setShowSwalModal(true)
    }
  }

  return (
    <>
      {showSwalModal ? (
        <TextModal
          message={t('COPIED_MSG')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <div className='d-flex justify-content-end m-4'>
        <button className='btn btn-sm btn-primary mt-4 me-2' onClick={() => onCopy(true)}>
          {t('COPY_CODE_IFRAME')}
        </button>
      </div>
      <div id='preview-id' style={{display: 'flex', justifyContent: 'center'}}></div>
      {app === 'file' && (
        <>
          <div className='m-2' style={{textAlign: 'center'}}>
            <div id='email-template-preview-id'></div>
          </div>
        </>
      )}
    </>
  )
}

export default observer(View)
