/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import SubscriptionLists from './SubscriptionLists'
import SubscriptionView from './view/SubscriptionView'

const WorkspaceContainer: FC = () => {
  return (
    <Switch>
      <Route path='/subscriptions' component={SubscriptionLists} exact />
      <Route path='/subscriptions/:subscriptionId' component={SubscriptionView} exact />
    </Switch>
  )
}

export default WorkspaceContainer
