
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
import UserList from './UserList'

type TabProps = {
  workspace: any
}

const Tabs: FC<TabProps> = ({workspace}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [active, setActive] = useState<Number>(0)

  return (
    <>
      <ul className='nav nav-tabs nav-line-tabs my-5 fs-6'>
        <li className={`nav-item  ${active === 0 && 'active'}`} onClick={() => setActive(0)}>
          <a
            className={`nav-link text-active-primary pb-4 ${active === 0 && 'active'}`}
            data-bs-toggle='tab'
            href='#kt_contact_overview'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com014.svg'
              className='svg-icon svg-icon-4 me-1'
            />
            {t('USER', { count: 1 })}
          </a>
        </li>
      </ul>

      <div className='tab-content row'>
        {active === 0 && (
          <div className='tab-pane active' id='kt_users_overview' role='tabpanel'>
             <UserList workspace={workspace} />
          </div>
        )}
      </div>
    </>
  )
}

export default Tabs
