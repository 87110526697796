import {FC, useEffect, useState} from 'react'
import TemplatesList from './TemplatesList'
import {getTemplates} from '../../../service/template.service'

const BotTemplate: FC = () => {
  const [templateList, setTemplateList] = useState([])

  const fetchData = async (params: any) => {
    const data = await getTemplates(params)
    if (data) setTemplateList(data)
  }

  useEffect(() => {
    fetchData({type: 'bot'})
  }, [])

  return (
    <>
      <TemplatesList
        from='bot'
        templateList={templateList || []}
        setTemplateList={setTemplateList}
      />
    </>
  )
}

export default BotTemplate
