import {FC} from 'react'
import SmartBarAppearance from './SmartBarAppearance'

type Step1Props = {}

const Step1: FC<Step1Props> = () => {
  return <SmartBarAppearance />
}

export default Step1
