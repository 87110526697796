/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import WorkspaceModal from './WorkspaceModal'
import {getGlobalItem, isGroweoPartner, isGroweoSubadmin, setGlobalItem, tableDisplayDate} from '../../../../utils/utils'
import {KTSVG} from '../../../../_metronic/helpers'
import {WorkspacesToolbar} from '../../../../_metronic/layout/components/toolbar/WorkspacesToolbar'
import {useHistory} from 'react-router-dom'
import {getWorkspace, loginWorkspace} from '../../../../service/workspace.service'

const WorkspaceLists: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const history = useHistory()
  const [workspaceList, setWorkspaceList] = useState([])
  const [filterData, setFilterData]: any = useState({})
  const [sortData, setSortData]: any = useState({})
  const [mapWorkspace, setMapWorkspace]: any = useState([]);

  useEffect(() => {
    const getData =async () => {
      const data:any = await getWorkspace({})
      if (data){
        setWorkspaceList(data)
        setMapWorkspace(data)
      }
    }
    getData();
  },[])

  useEffect(() => {
    if(filterData) {
      const filteredSegment = workspaceList.filter((val:any) => {
        return val.domain.toLowerCase().includes(filterData?.toLowerCase()) || val.name.toLowerCase().includes(filterData?.toLowerCase())
      })
      setMapWorkspace(filteredSegment)
    }
    else {
      setMapWorkspace(workspaceList)
    }
  }, [filterData])

  useEffect(() => {
    let sortedData = mapWorkspace.sort((a:any, b:any) => {
      if(sortData.name) {
        return sortData.name === 1?
          (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1)
      }
      if(sortData.created) {
        return sortData.created === 1?
          (a.created < b.created ? 1 : -1) : (a.created > b.created ? 1 : -1)
      }
    });
    setMapWorkspace([...sortedData])
  }, [sortData])

  const onLogin = async (workspaceId: string) => {
    const userData = await loginWorkspace(workspaceId)
    const userSessionData = getGlobalItem('user')
    userData.contentAdminRole = userSessionData['role']
    setGlobalItem('user', userData)
    document.location.reload()
  }

  const renderValue = (value: any, link?: any, admin?: boolean) => (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <a
          onClick={(isGroweoPartner() || isGroweoSubadmin())?()=>{} : () => history.push(link)}
          className={`text-dark fw-bolder text-muted text-hover-primary fs-4 ${
            (link&&!isGroweoPartner()) ? 'cursor-pointer' : ''
          }`}
        >
          {value}
          {admin && (<span className="badge badge-success mx-1">{t('G_ADMIN', {count:0})}</span>)}
        </a>
      </div>
    </div>
  )

  const onSort = (column: string) => {
    const sortObj = {
      [column]: 1
    }
    if (sortData[column]) {
      sortObj[column] = sortData[column] === 1 ? -1 : 1
    }
    setSortData(sortObj)
  }

  const renderSortIcon = () => (
    <KTSVG path='/media/icons/duotune/arrows/arr032.svg' className='svg-icon-3 mx-1' />
  )

  return (
    <>
      <WorkspacesToolbar filterData={filterData} setFilterData={setFilterData} />
      <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder'>
                  <th className='min-w-125px fs-4 cursor-pointer' onClick={() => onSort('name')}>
                    {t('CUSTOMER', {count: 0})} 
                    {renderSortIcon()}
                  </th>
                  <th className='min-w-125px fs-4'>{t('DOMAIN')}</th>
                  <th className='min-w-125px fs-4 cursor-pointer' onClick={() => onSort('created')}>
                    {t('CREATED')}
                    {renderSortIcon()}
                    </th>
                  <th className='min-w-100px text-end fs-4'>{t('ACTION', {count: 1})}</th>
                </tr>
              </thead>
              <tbody>
                {mapWorkspace?.map((l: any) => (
                  <tr key={`workspaceList_${l._id}`}>
                    <td>{renderValue(l.name, `/workspaces/${l._id}`, l.domain === 'engine.groweo.com')}</td>
                    <td>{renderValue(l.domain)}</td>
                    <td>{renderValue(tableDisplayDate(l.created))}</td>
                      <td className='text-end'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          {l.domain !== 'engine.groweo.com' && (
                            <a
                              onClick={() => onLogin(l._id)}
                              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1`}
                              title={t('LOGIN_BTN')}
                            >
                              <KTSVG path='/media/icons/duotune/arrows/arr076.svg' className='svg-icon-3' />
                            </a>
                          )}
                        </div>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <WorkspaceModal setMapWorkspace={setMapWorkspace}/>
    </>
  )
}

export default observer(WorkspaceLists)
