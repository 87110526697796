import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import LibraryList from './LibraryList'

const TriggerIndex: FC = () => {
  return (
    <Switch>
      <Route path='/libraries' component={LibraryList} exact />
    </Switch>
  )
}

export default TriggerIndex
