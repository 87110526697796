import {FC} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import TextareaInputFeild from '../../../shared/TextareaInputFeild'
import { getLimitConfig } from '../../../../utils/utils'
import { useStore } from '../../../../store'

type Props = {
  onPublish: any
}

interface publishModal {
  text: string
}

const publishModalSchema = (t: any) =>
  Yup.object().shape({
    text: Yup.string().required(`${t('REQUIRED', {field: t('DESCRIPTION')})}`),
  })

const initialValues: publishModal = {
  text: '',
}

const AppPublishModal: FC<Props> = ({onPublish}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {authStore} = useStore()
  const {
    moduleAccessLimit: {publishCount},
  } = authStore

  const formik = useFormik({
    initialValues,
    validationSchema: publishModalSchema(t),
    onSubmit: (values) => {
      onPublish({
        time: moment().utc(),
        text: values.text,
      })
      formik.resetForm()
      document.getElementById('gw_close_publish_modal')?.click()
    },
  })

  const renderLabel = (label: string, required: boolean) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
      <span className={`${required && 'required'}`}>{label}</span>
    </label>
  )

  return (
    <>
      <div
        className='modal fade'
        tabIndex={-1}
        id='kt_publish_event_modal'
        style={{background: 'rgba(0,0,0,.4)'}}
      >
        <div className='modal-dialog modal-dialog-centered mw-416px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>
                {t('PUBLISH', {count: 0})}
              </h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                id='gw_close_publish_modal'
                onClick={() => formik.resetForm()}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <form
                id='kt_modal_publish_event_form'
                className='form'
                onSubmit={formik.handleSubmit}
              >
                <div className='fv-row mb-5'>
                  {renderLabel(`${t('DESCRIPTION', {count: 1})}`, true)}
                  <TextareaInputFeild formik={formik} name='text' />
                </div>
                <div className='fv-row mb-5 text-center'>
                  <button
                    type='submit'
                    className={`btn btn-primary  ${
                      getLimitConfig('publish_app', publishCount) ? 'disabled' : ''
                    }`}
                    data-bs-toggle='tooltip'
                    title={`${
                      getLimitConfig('publish_app', publishCount) ? t('PUBLISH_TITLE') : ''
                    }`}
                  >
                    <span className='indicator-label text-white'>{t('SUBMIT', {count: 0})}</span>
                  </button>
                  {getLimitConfig('publish_app', publishCount) ? (
                    <p className='text-danger'>{t('PUBLISH_TITLE')}</p>
                  ) : ''}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppPublishModal
