import ChangeLogBreadCrumb from './ChangeLogBreadCrumb'
import ChangeLogIndex from '../../modules/changeLog'

const ChangeLog = () => {
  return (
    <>
      <ChangeLogBreadCrumb />
      <ChangeLogIndex />
    </>
  )
}

export default ChangeLog
