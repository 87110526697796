import {FC} from 'react'
import BotAppearance from './BotAppearance'
import BotAppearance3 from './BotAppearance3'
import FormAppearance from './FormAppearance'

type Step3Props = {
  from: string
  template?: any
  setTemplate?: any
}

const Step3: FC<Step3Props> = ({from, template, setTemplate}) => {
  if (from === 'bot') {
    return 1 === 1 ? <BotAppearance3 template={template} setTemplate={setTemplate}></BotAppearance3>
    : <BotAppearance template={template} setTemplate={setTemplate}></BotAppearance>
  } else return <FormAppearance template={template} setTemplate={setTemplate}></FormAppearance>
}

export default Step3
