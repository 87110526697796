/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

type Props = {
  label: string
  value: string
  onSave: any
  isBorderRadius?: boolean
}

const ContentSpacing: FC<Props> = (props) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [moreOption, setMoreOption] = useState(false)
  const [spacing, setSpacing]: any = useState({})

  useEffect(() => {
    if (!props?.value) return
    const space = props.value.split('px')
    if (!space || space.length === 1) return
    if (space.length > 2) {
      setMoreOption(true)
      setSpacing({
        top: Number(space[0]),
        right: Number(space[1]),
        bottom: Number(space[2]),
        left: Number(space[3]),
      })
    } else {
      setMoreOption(false)
      setSpacing({padding: Number(space[0])})
    }
  }, [])

  useEffect(() => {
    if (moreOption) {
      if (!spacing?.padding) return;
      setSpacing({ 
        top: spacing.padding,
        right: spacing.padding,
        bottom: spacing.padding,
        left: spacing.padding
      })
      
    } else {
      if (!spacing?.top) return;
      setSpacing({ padding: spacing?.top })
    }
  }, [moreOption])

  useEffect(() => {
    onSave(moreOption)
  }, [spacing])

  const onSave = (isMoreOption: boolean) => {
    const isSame = [spacing.right, spacing.bottom, spacing.left].every(value => {
      return value === spacing.top;
    });
  
    if (isMoreOption && isSame) {
      props.onSave(
        `${spacing.top || 0}px`
      )
    } else if (isMoreOption) {
      props.onSave(
        `${spacing.top || 0}px ${spacing.right || 0}px ${spacing.bottom || 0}px ${spacing.left || 0}px`
      )
    } else {
      props.onSave(`${spacing.padding || 0}px`)
    }
  }

  const onChange = (e: any) => {
    spacing[e.target.name] = e.target.value ? Number(e.target.value) : ''
    setSpacing({...spacing})
  }

  const renderInput = (label: string, name: string) => (
    <div className='col-6'>
      <label className='fs-5 fw-semibold my-2'>{label}</label>
      <div className='input-group input-group-sm'>
        <input
          type='number'
          name={name}
          className='form-control'
          value={spacing[name]}
          aria-describedby='basic-addon2'
          onChange={onChange}
        />
        <div className='input-group-append'>
          <span className='input-group-text'>px</span>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <label className='fs-5 fw-semibold'>{props?.label}</label>
        <label className='form-check form-switch form-switch-sm form-check-custom'>
          <span className='form-check-label text-muted mx-3'>{t('MORE_OPTIONS')}</span>
          <input
            className='form-check-input'
            type='checkbox'
            checked={moreOption}
            onChange={(ev) => {
              setMoreOption(ev.target.checked ? true : false)
            }}
          />
        </label>
      </div>
      <div>
        <div className='row'>
          {moreOption ? (
            <>
              {renderInput(props?.isBorderRadius ? `${t('TOP')} ${t('LEFT')}` : t('TOP'), 'top')}
              {renderInput(props?.isBorderRadius ? `${t('TOP')} ${t('RIGHT')}` : t('RIGHT'), 'right')}
              {renderInput(props?.isBorderRadius ? `${t('BOTTOM')} ${t('LEFT')}` : t('LEFT'), 'left')}
              {renderInput(props?.isBorderRadius ? `${t('BOTTOM')} ${t('RIGHT')}` : t('BOTTOM'), 'bottom')}
            </>
          ) : (
            renderInput(t('ALL_SIDES'), 'padding')
          )}
        </div>
      </div>
    </div>
  )
}
export default ContentSpacing
