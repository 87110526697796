/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import { getIcon } from '../../../../utils/utils'
import {KTSVG} from '../../../../_metronic/helpers'

type LibraryCardProps = {
  data: any
  onEdit: any
  archive: any
}

const LibraryCard: FC<LibraryCardProps> = ({data, onEdit, archive}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {name, description, status, type} = data

  const renderStatus = () => {
    return status === 'draft' ? t('DRAFT') : t(`${status?.toUpperCase()}`, {count: 1})
  }

  const badgeColor = () => {
    if (status === 'draft') return 'badge-light'
    if (status === 'unpublish') return 'badge-light-primary'
    if (status === 'active') return 'badge-light-success'
  }

  const renderIcon = () => {
    return `/media/icons/custom/${getIcon(type)}`
  }

  return (
    <div className='card h-100'>
      <div className='card-header flex-nowrap align-items-start border-0 pt-9'>
        <div className='card-title align-items-start m-0'>
          <div className='symbol symbol-45px w-45px bg-light me-5'>
            <img src={renderIcon()} alt='app' className='p-3' />
          </div>
          <div className='d-flex flex-column'>
            <span className='fs-4 fw-bold fw-bolder text-dark m-0'>{name}</span>
            <span className="text-muted text-truncate-2 fw-bold fs-7" style={{height: '37px'}}>{description}</span>
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen024.svg'
              className='svg-icon-3 svg-icon-primary'
            />
          </button>

          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
            data-kt-menu='true'
          >
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                {t('QUICK_ACTIONS')}
              </div>
            </div>
            <div className='separator mb-3 opacity-75'></div>
            <div className='menu-item px-3'>
              <a
                onClick={onEdit}
                data-bs-toggle='modal'
                data-bs-target='#gw_library_modal'
                className='menu-link px-3'
              >
                {t('EDIT')}
              </a>
            </div>
            <div className='menu-item px-3'>
              <a onClick={() => archive()} className='menu-link px-3'>
                {t('DELETE')}
              </a>
            </div>
            <div className='separator mt-3 opacity-75'></div>
          </div>
        </div>
      </div>

      <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
        <div className='d-flex align-items-center fw-bold'>
          {status && <span className={`badge badge-light ${badgeColor()}`}>{renderStatus()}</span>}
        </div>
      </div>
    </div>
  )
}

export default LibraryCard
