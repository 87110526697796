/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useContext} from 'react'
import { BAR_APPEARANCE } from '../../../../utils/module.utils'

export interface PublishEvent {
  time: Date
  text: string
}

export interface SmartBar {
  _id?: string
  name?: string
  type?: string
  status?: string
  moduleCode?: {
    code: object,
    appearance?: {
      width : string,
      height: string,
      direction: string,
      barTitle: string,
      barFontColor : string,
      barBgColor: string,
      barTitleFontSize: number,
      contentTitle: string,
      contentSubTitle: string,
      contentBgColor: string,
      contentTitleFontColor : string,
      contentSubTitleFontColor : string,
      footerFontColor: string,
      barFormSubmitBtnText : string,
      barFieldBGPercentage: number,
      barFormPadding: string,
      barFormLabelColor: string,
      barFormSubmitBtnUrl: string,
      barFormSubmitBtnPhone: string,
      barFormSubmitBtnEmail: string,
      barFormSubmitBtnTextUrl: string,
      barFormSubmitBtnTextEmail: string,
      barFormSubmitBtnTextPhone: string,
      barFormInputTextColor: string,
      barFormInputBgColor: string,
      barFormInputPlaceholderColor: string,
      barFormButtonTextColor: string,
      barFormButtonBgColor: string,
      barFormButtonHoverTextColor: string,
      barFormButtonHoverBgColor: string,
      invertBarColor: boolean,
      autoBarHeight: boolean,
      successMsgTitle: string,
      successMsgSubTitle: string,
      successTitleFontColor : string,
      successSubTitleFontColor : string,
      closeBgColor: string,
      closeIconColor: string,
      closeBorderRadius: string,
      closeMargin: string,
      includeInpage?: Array<[]>,
      excludeInpage?: Array<[]>,
      barFormAutoWidth: boolean,
      barFormAutoWidthRange: number,
      barFormButtonPosition: string,
    }
  }
  content?: string
  publishEvent?: Array<PublishEvent>
  workspaceId?: string
}

export const DefaultSmartBar: SmartBar = {
  name: '',
  type: '',
  status: '',
  moduleCode: {
    code: {},
    appearance: BAR_APPEARANCE,
  },
  content: '',
  publishEvent: []
}

export interface StepperContextModel {
  smartBar?: SmartBar,
  setSmartBar: (_smartBar: SmartBar) => void
}

const StepperContext = createContext<StepperContextModel>({
  smartBar: DefaultSmartBar,
  setSmartBar: function (_smartBar: SmartBar): void {
    throw new Error('Function not implemented.')
  }
})

const StepperProvider: React.FC<any>  = ({children, smartBar, setSmartBar}) => {
  const value: StepperContextModel = {
    smartBar,
    setSmartBar,
  }
  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
}

function useSmartBarData() {
  return useContext(StepperContext)
}

export {StepperProvider, useSmartBarData}
