import {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { getModuleById } from '../../../../service/module.service'
import { StepperProvider } from '../stepper/StepperContext'
import Stepper from '../stepper'

interface RouteParams {
  moduleId: string
  step: string
}

let smartFlowData: any = {}

const SmartFlowCreate: FC = () => {
  const {moduleId, step} = useParams<RouteParams>()
  const [smartFlow, setSmartFlow]: any = useState({})

  useEffect(() => {
    if (smartFlow && smartFlow?._id === moduleId) smartFlowData = smartFlow
  }, [smartFlow])

  const getTemplateData = async (type: string) => {
    let data
    if (type === 'module') data = await getModuleById(moduleId)
    if (data) {
      setSmartFlow(data)
      smartFlowData = data
    }
  }

  useEffect(() => {
    if (smartFlowData?._id !== moduleId) getTemplateData('module')
    else setSmartFlow(smartFlowData)
    // eslint-disable-next-line
  }, [moduleId])

  return (
    <>
      <StepperProvider smartFlow={smartFlow} setSmartFlow={setSmartFlow}>
        <Stepper
          from={'smartflow'}
          moduleId={moduleId}
          step={step}
        />
      </StepperProvider>
    </>
  )
}

export default SmartFlowCreate
