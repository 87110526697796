import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import {CustomerReportToolbar} from '../../../../../_metronic/layout/components/toolbar/CustomerReportToolbar'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ChartsWidget11} from '../../../../../_metronic/partials/widgets/charts/ChartsWidget11'
import {ChartsWidget12} from '../../../../../_metronic/partials/widgets/charts/ChartsWidget12'
import { getCustomerReportPdf } from '../../../../../service/performanceReport.service'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getDecimal } from '../../../../../utils/utils'
import { getWorkspaceById } from './../../../../../service/workspace.service';
import { getCountByDateWise } from '../../../../../utils/module.utils'

interface RouteParams {
  workspaceId: string
  workspaceName: string
}

interface CustomerReportPageProps {
  isHideBack: boolean;
}

const CustomerReportPageView: FC<CustomerReportPageProps> = ({ isHideBack }) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {workspaceId, workspaceName} = useParams<RouteParams>()
  const [successfulConversationCount, setSuccessfulConversationCount]: any = useState([])
  const [uniqueVistorCount, setUniqueVistorCount]: any = useState([])
  const [sessionCount, setSessionCount]: any = useState([])
  const [visitorVsStartedDate, setVisitorVsStartedDate]: any = useState([])
  const [startedVsSuccessDate, setStartedVsSuccessDate]: any = useState([])
  const [customerReportData, setCustomerReportData]: any = useState([])
  const [lastOneYearData, setLastOneYearData]: any = useState([])
  const [domainName, setDomainName]: any = useState('')
  const [loader, setLoader]: any = useState(false)
  const defaultRange = {
    startDate: moment().subtract(30, 'days').format('ll'),
    endDate: moment().format('ll'),
  }
  const previousMonthRange = {
    startDate: moment().subtract(1,'months').startOf('month').format('ll'),
    endDate: moment().subtract(1,'months').endOf('month').format('ll')
  }
  const [dateRange, setDateRange]: any = useState(defaultRange)
  const [previousRange, setPreviousRange]: any = useState(previousMonthRange)
  /* download customer report page in pdf */
  async function downloadPdf () {
    var element: any = document.querySelector('#Customer_report_page')
    await html2canvas(element, {
      allowTaint: true,
      scale: 3,
      backgroundColor: '#eff2f5',
      scrollX: 0,
      scrollY: -window.scrollY,
    }).then((canvas: any)=> {
      var doc = new jsPDF('landscape', 'mm', 'a4', true)
      var imgData = canvas.toDataURL('image/jpeg', 1.0);
      var imgWidth = doc.internal.pageSize.getWidth();
      var imgHeight = doc.internal.pageSize.getHeight();
      // var imgHeight = canvas.height * imgWidth / canvas.width;
      // var heightLeft = imgHeight;
      // var position = 0;
      doc.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, 'FAST');
      doc.save("Asiakasraportti")
      // heightLeft -= pageHeight;
      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   doc.addPage();
      //   doc.addImage(imgData, 0, position, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;
      // }
    })
  }


function comparePercentage(oldValue: number = 0, newValue: number = 0): any {
  var difference = newValue - oldValue;
  if (oldValue < 1) {
    oldValue = 1;
  }

  var percentage = (difference / oldValue) * 100;
  if (percentage < 0) {
    return {trend: "down", percentage: Math.abs(percentage|0)}
  }
  else {
    return {trend: "up", percentage: Math.abs(percentage|0)}
  }
}
  // const reverseDate = (data: any) => {
  //   return moment(data).format('MMM D');
  // }

  useEffect(()=>{
    const monthList = [];
    const start = moment().startOf('month');
    const last30Days = moment().subtract(30, 'days').format('DD MMMM YYYY')
    monthList.push({value: last30Days, text: t('LAST_30_DAYS')})
    monthList.push({value: start.format('DD MMMM YYYY'), text: `${t(start.format('MMMM').toUpperCase())} ${start.format('YYYY')}`});
    for (let i = 0; i < 11; i++) {
      const date = start.subtract(1, 'month');
      monthList.push({value: date.format('DD MMMM YYYY'), text: `${t(date.format('MMMM').toUpperCase())} ${date.format('YYYY')}`});
    }
    setLastOneYearData(monthList)
  }, [])

  useEffect(()=>{
    const getCustomerDomain = async () => {
      let data = await getWorkspaceById(workspaceId)
      setDomainName(data['domain'])
    }
    getCustomerDomain()
  }, [])

  useEffect(() => {
    const getCustomerReportData = async () => {
      let reports = await getCustomerReportPdf(workspaceId, dateRange)
      let previousReports = await getCustomerReportPdf(workspaceId, previousRange)
      /* const PrevioussuccessfulConversationData = reports['SUCCESSFULL-CONVERSATION']['count']
        ? getDecimal((reports['SUCCESSFULL-CONVERSATION']['count'] / previousReports['SUCCESSFULL-CONVERSATION']['count']) * 100 || 0)
        : 0 */
      const PrevioussuccessfulConversationData = comparePercentage(previousReports['SUCCESSFULL-CONVERSATION']['count'], reports['SUCCESSFULL-CONVERSATION']['count'])
      const uniqueVisitorData = reports['UNIQUE-VISITOR']
      const successfulConversationData = reports['SUCCESSFULL-CONVERSATION']
      const sessionData = reports['SESSION']
      const handledContactData = reports['handledContact']
      const newContactData = reports['newContact']
      const totalAppData = reports['totalApp']
      const totalContactData = reports['totalContact']
      const unhandledContactData = reports['unhandledContact']
      setVisitorVsStartedDate([])
      setStartedVsSuccessDate([])
      setUniqueVistorCount([])
      setSuccessfulConversationCount([])
      setSessionCount([])

      const uniqueVisitorDataCount =  getCountByDateWise(uniqueVisitorData?.data, dateRange.startDate, dateRange.endDate);
      const sessionDataCount =  getCountByDateWise(sessionData?.data, dateRange.startDate, dateRange.endDate);
      const successfulConversationDataCount =  getCountByDateWise(successfulConversationData?.data, dateRange.startDate, dateRange.endDate);

      uniqueVisitorDataCount.map((data: any) => {
        setUniqueVistorCount((previousUniqueCount: any) => {
          return [ ...previousUniqueCount, data.count ]
        })
        setVisitorVsStartedDate((previousUniqueDate: any) => {
          return [ ...previousUniqueDate, (data.date)];
        })
      })
      sessionDataCount.map((data: any) => {
        setSessionCount((previousSessionCount: any) => {
          return [ ...previousSessionCount, data.count ]
        })
      })
      successfulConversationDataCount.map((data: any) => {
        setSuccessfulConversationCount((previousSuccessCount: any) => {
          return [ ...previousSuccessCount, data.count ]
        })
        setStartedVsSuccessDate((previousSuccessDate: any) => {
          return [ ...previousSuccessDate, (data.date) ];
        })
      })  
      setCustomerReportData({
        PrevioussuccessfulConversationData,
        uniqueVisitorData, 
        successfulConversationData, 
        sessionData, 
        handledContactData, 
        newContactData, 
        totalAppData, 
        totalContactData, 
        unhandledContactData
      })
    }
    setLoader(true)
    getCustomerReportData()
    setTimeout(() => {
      setLoader(false)
    }, 5000)
  }, [dateRange])
  
  return (
    <>
      <CustomerReportToolbar
        from='performance-report'
        title='PERFORMANCE_EXPLORER'
        setDateRange={setDateRange}
        setPreviousRange={setPreviousRange}
        lastOneYearData={lastOneYearData}
        downloadPdf={downloadPdf}
        isHideBack={isHideBack}
      />
      {!loader ? (
      <div className='row' id='Customer_report_page' style={{width:'1920px', height:'auto', margin: 'auto'}}>
        <div className='col-sm-1 ps-0'>
          <img src='/media/icons/duotune/abstract/growth_engine_img.png' className='img-fluid h-100'/>
        </div>
        <div className='col-sm-11 gy-5'>
          <div className='row'>
            <div className='col-7'>
              <div className='row'>
                <div className=''>
                  <span className='fw-100 fs-2x' style={{color:'#f1416c'}}>{workspaceName}</span>
                  <span className='fw-400 fs-2 ps-5 text-decoration-underline'>{domainName}</span>
                </div>
              </div>
            </div>
            <div className='col-4 d-flex align-items-center justify-content-end'>
              <span className='fw-400 fs-2'>{`${moment(dateRange.startDate).format('DD.MM.YYYY')} - ${moment(dateRange.endDate).format('DD.MM.YYYY')}`} (30pv)</span>
            </div>
          </div>
          <div className='row my-5 d-flex align-items-center justify-content-start'>
            <span className='fw-bolder fs-2x text-gray-800'>{t('CSR_GENERAL_DESCRIPTION')}</span>
          </div>
          <div className='row'>
            <div className='col-9'>
              <div className='row gy-5 g-xl-10'>
              <div className='col-sm-6 col-xl-2 mb-xl-10'>
                <div className='card h-lg-100'>
                  <div className='card-body d-flex flex-column'>
                    <div className='m-0'>
                      <span className='svg-icon svg-icon-2hx svg-icon-gray-600'>
                        <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-hx' />
                      </span>
                    </div>

                    <div className='d-flex flex-column mt-7'>
                      <span className='fw-bolder fs-3x text-gray-800 lh-1 ls-n2'>{customerReportData?.uniqueVisitorData?.count ? customerReportData?.uniqueVisitorData?.count : 0}</span>

                      <div className='m-0'>
                        <span className='fw-bolder fs-6 text-gray-400'>{t('CSR_VISITOR_COLUMN')} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-xl-2 mb-xl-10'>
                <div className='card h-lg-100'>
                  <div className='card-body d-flex flex-column'>
                    <div className='m-0'>
                      <span className='svg-icon svg-icon-2hx svg-icon-gray-600'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs048.svg'
                          className='svg-icon-hx'
                        />
                      </span>
                    </div>

                    <div className='d-flex flex-column mt-7'>
                      <span className='fw-bolder fs-3x text-gray-800 lh-1 ls-n2'>{customerReportData?.uniqueVisitorData?.count ? customerReportData?.uniqueVisitorData?.count/30|0 : 0}</span>

                      <div className='m-0'>
                        <span className='fw-bolder fs-6 text-gray-400'>{t('CSR_AVERAGE_VISITOR')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-xl-2 mb-xl-10'>
                <div className='card h-lg-100'>
                  <div className='card-body d-flex flex-column'>
                    <div className='m-0'>
                      <span className='svg-icon svg-icon-2hx svg-icon-gray-600'>
                        <KTSVG path='/media/icons/duotune/graphs/gra001.svg' className='svg-icon-hx' />
                      </span>
                    </div>

                    <div className='d-flex flex-column mt-7'>
                      <span className='fw-bolder fs-3x text-gray-800 lh-1 ls-n2'>{customerReportData?.totalAppData ? customerReportData?.totalAppData : 0}</span>

                      <div className='m-0'>
                        <span className='fw-bolder fs-6 text-gray-400'>{t('CSR_APP')} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-xl-2 mb-xl-10'>
                <div className='card h-lg-100'>
                  <div className='card-body d-flex flex-column'>
                    <div className='m-0'>
                      <span className='svg-icon svg-icon-2hx svg-icon-gray-600'>
                        <KTSVG path='/media/icons/duotune/maps/map002.svg' className='svg-icon-hx' />
                      </span>
                    </div>

                    <div className='d-flex flex-column mt-7'>
                      <span className='fw-bolder fs-3x text-gray-800 lh-1 ls-n2'>{customerReportData?.sessionData?.count ? customerReportData?.sessionData?.count : 0}</span>
                      <div className='m-0'>
                        <span className='fw-bolder fs-6 text-gray-400'>
                          {t('CSR_STARTED_CONVERSATION')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-xl-2 mb-5 mb-xl-10'>
                <div className='card h-lg-100' style={{backgroundColor:'#50cd89'}}>
                  <div className='card-body d-flex flex-column'>
                    <div className='m-0'>
                      <span className='svg-icon svg-icon-2hx svg-icon-white'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs037.svg'
                          className='svg-icon-hx'
                        />
                      </span>
                    </div>

                    <div className='d-flex flex-column mt-7'>
                      <span className='fw-bolder fs-3x text-white lh-1 ls-n2'>{customerReportData?.successfulConversationData?.count ? customerReportData?.successfulConversationData?.count : 0}</span>
                      <div className='m-0'>
                        <span className='fw-bolder fs-6 text-white'>
                          {t('SUCCESSFULL_CONVERSATION')}{' '}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-6 col-xl-2 mb-5 mb-xl-10'>
                <div className='card h-lg-100'>
                  <div className='card-body d-flex flex-column'>
                    <div className='m-0'>
                      <span className='svg-icon svg-icon-2hx svg-icon-gray-600'>
                          <KTSVG path={`/media/icons/custom/stats_${customerReportData?.PrevioussuccessfulConversationData?.trend}_icon.svg`}  className='svg-icon-hx' /> 
                      </span>
                    </div>
                    <div className='d-flex flex-column mt-7'>
                      <span className='fw-bolder fs-3x text-gray-800 lh-1 ls-n2'>
                          {`${customerReportData?.PrevioussuccessfulConversationData?.percentage || 0}%`}
                        </span>
                      <div className='m-0'>
                        <span className='fw-bolder fs-6 text-gray-400'>
                          {t('COMPARED_PREVIOUS_TIME')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className='row g-5 g-xl-10 mb-10 mb-xl-10'>
                <div className='col-xl-6 pb-0'>
                  <ChartsWidget11 className='card-flush overflow-hidden h-lg-100' startedConversationsCount={sessionCount} visitorsCount={uniqueVistorCount} visitorVsStartedDate={visitorVsStartedDate} />
                </div>
                <div className='col-xl-6'>
                  <ChartsWidget12 className='card-flush overflow-hidden h-lg-100' startedConversationsCount={sessionCount} successfulConversationCount={successfulConversationCount} startedVsSuccessDate={startedVsSuccessDate} />
                </div>
              </div>
              <div className='row mb-3'>
                <span className='fw-bolder fs-2x text-gray-700 text-uppercase'>{t('PLATFORM')}</span>
              </div>
              <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-xl-3'>
                  <div
                    className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100'
                    style={{backgroundColor: '#F1416C', backgroundImage: "url('/media/svg/shapes/wave-bg-red.svg')"}}
                  >
                    <div className='card-header pt-5 mb-3'>
                      <div
                        className='d-flex flex-center rounded-circle h-80px w-80px'
                        style={{ border: '1px dashed rgba(255, 255, 255, 0.4)', backgroundColor: '#F1416C' }}
                      >
                        <i className='bi bi-telephone-inbound-fill text-white fs-2qx lh-0'></i>
                      </div>
                    </div>

                    <div className='card-body d-flex align-items-end mb-0 pb-0'>
                      <div className='d-flex align-items-center'>
                        <span className='fs-4hx text-white fw-bolder me-6'>{customerReportData?.totalContactData}</span>

                        <div className='fs-5 text-white'>
                          <span className='d-block'>{t('TOTAL_CONTACT', {count: 1})}</span>
                        </div>
                      </div>
                    </div>

                    <div className='card-footer py-3 pb-4'>
                      <div className='fs-5 text-white'>
                        <span>{t('CSR_CONTACTS')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3'>
                  <div
                    className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100'
                    style={{backgroundColor: '#7239EA', backgroundImage: "url('/media/svg/shapes/wave-bg-purple.svg')"}}
                  >
                    <div className='card-header pt-5 mb-3'>
                      <div
                        className='d-flex flex-center rounded-circle h-80px w-80px'
                        style={{ border: '1px dashed rgba(255, 255, 255, 0.4)', backgroundColor: '#7239EA' }}
                      >
                        <i className='bi bi-telephone-inbound-fill text-white fs-2qx lh-0'></i>
                      </div>
                    </div>

                    <div className='card-body d-flex align-items-end mb-0 pb-0'>
                      <div className='d-flex align-items-center'>
                        <span className='fs-4hx text-white fw-bolder me-6'>{customerReportData?.newContactData}</span>

                        <div className='fs-5 text-white'>
                          <span className='d-block'>{t('NEW_CONTACT', {count: 1})}</span>
                          {/* <span className=''>Calls</span> */}
                        </div>
                      </div>
                    </div>

                    <div className='card-footer py-3 pb-4'>
                      <div className='fs-5 text-white'>
                        <span>{t('CSR_NEW_CONTACTS')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3'>
                  <div
                    className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100'
                    style={{backgroundColor: '#009ef7', backgroundImage: "url('/media/svg/shapes/wave-bg-blue.svg')"}}
                  >
                    <div className='card-header pt-5 mb-3'>
                      <div
                        className='d-flex flex-center rounded-circle h-80px w-80px'
                        style={{ border: '1px dashed rgba(255, 255, 255, 0.4)', backgroundColor: '#009ef7' }}
                      >
                        <i className='bi bi-telephone-inbound-fill text-white fs-2qx lh-0'></i>
                      </div>
                    </div>

                    <div className='card-body d-flex align-items-end mb-0 pb-0'>
                      <div className='d-flex align-items-center'>
                        <span className='fs-4hx text-white fw-bolder me-6'>{customerReportData?.handledContactData}</span>

                        <div className='fs-5 text-white'>
                          <span className='d-block'>{t('HANDLED_TASK',{count: 1})}</span>
                          {/* <span className=''>Calls</span> */}
                        </div>
                      </div>
                    </div>

                    <div className='card-footer py-3 pb-4'>
                      <div className='fs-5 text-white'>
                        <span>{t('CSR_PROCESSED_CONTACTS')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3'>
                  <div
                    className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-xl-100'
                    style={{backgroundColor: '#FF8A00', backgroundImage: "url('/media/svg/shapes/wave-bg-orange.svg')"}}
                  >
                    <div className='card-header pt-5 mb-3'>
                      <div
                        className='d-flex flex-center rounded-circle h-80px w-80px'
                        style={{ border: '1px dashed rgba(255, 255, 255, 0.4)', backgroundColor: '#FF8A00' }}
                      >
                        <i className='bi bi-telephone-inbound-fill text-white fs-2qx lh-0'></i>
                      </div>
                    </div>

                    <div className='card-body d-flex align-items-end mb-0 pb-0'>
                      <div className='d-flex align-items-center'>
                        <span className='fs-4hx text-white fw-bolder me-6'>{customerReportData?.unhandledContactData}</span>

                        <div className='fs-5 text-white'>
                          <span className='d-block'>{t('UNHANDLED_TASK', { count: 1 })}</span>
                          {/* <span className=''>Calls</span> */}
                        </div>
                      </div>
                    </div>

                    <div className='card-footer py-3 pb-4'>
                      <div className='fs-5 text-white'>
                        <span>{t('CSR_UNPROCESSED_CONTACTS')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div className='row d-flex flex-column align-items-center justify-content-evenly'>
                <div className='card bg-transparent'>
                  <div contentEditable={true} suppressContentEditableWarning={true} className='card-header align-items-center justify-content-start border-0'>
                    <span className='fw-bold fs-2x text-gray-900'>{t('CSR_GENERAL_DESCRIPTION')}</span>
                  </div>
                  <div contentEditable={true} suppressContentEditableWarning={true} className='card-body pt-0 fw-400 fs-4 text-gray-800'>
                    <span className='fw-400 fs-4 text-gray-800'>
                    Yleiskuvaus näkymästä näet kävijämäärän raportointijaksolla sekä
                    keskimääräiset kävijät vuorokaudessa. Aktiiviset sovellukset inforuutuun
                    lasketaan raportointihetkellä olevien, erilaisten aktiivisten sovellusten
                    lukumäärä. Aloitetut keskustelut inforuutuun tallentuvat kaikki aloitetut
                    keskustelut ja onnistuneet keskustelut ruutuun ne, jotka ovat täyttäneet
                    asetutut tavoitteet onnistuneesti.
                    </span>
                  </div>
                </div>
              </div>
              <div className='row d-flex align-items-center justify-content-evenly'>
                <div className='card bg-transparent'>
                  <div contentEditable={true} suppressContentEditableWarning={true} className='card-header align-items-center justify-content-start border-0'>
                    <span className='fw-bold fs-2x text-gray-900'>{t('INFO_GRAPHICS')}</span>
                  </div>
                  <div contentEditable={true} suppressContentEditableWarning={true} className='card-body pt-0 fw-400 fs-4 text-gray-800'>
                    <span className='fw-400 fs-4 text-gray-800'>
                    Vierailut / Aloitetut keskustelut - grafiikka vertaa verkkopalvelusi kävijämäärän
                    ja aloitettujen keskustelujen välistä suhdetta. Punainen jana esittää
                    verkkopalvelusi kokonaiskävijät päivätasolla ja sininen jana näiden kävijöiden
                    aloittaman vuorovaikutuksen määrää.

                    Aloitetut Keskustelut / Onnistuneet keskustelut - grafiikka vertaa aloitettujen
                    keskustelujen ja onnistuneiden keskustelujen välistä suhdetta.
                    </span>
                  </div>
                </div>
              </div>
              <div className='row d-flex align-items-center justify-content-evenly'>
                <div className='card bg-transparent'>
                  <div contentEditable={true} suppressContentEditableWarning={true} className='card-header align-items-center justify-content-start border-0'>
                    <span className='fw-bold fs-2x text-gray-900'>{t('PLATFORM')}</span>
                  </div>
                  <div contentEditable={true} suppressContentEditableWarning={true} className='card-body pt-0'>
                    <span className='fw-400 fs-4 text-gray-800'>
                    Neljä inforuutua esittävät Groweo Growth Engine - järjestelmässä olevien
                    yksittäisten kontaktien määrää kussakin ruudussa käsitellyn informaation
                    mukaisesti. Asetuksistasi riippuen saat ilmoitukset valitsemaasi / valitsemiisi
                    sähköpostiosoitteisiin uusista kontakteista.
                    </span>
                  </div>
                </div>
              </div>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='card bg-transparent'>
                  <div className='card-body pt-0 text-center'>
                    <span className='fw-bold fs-2 text-gray-900'>{t('ENDING_TEXT')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) : (
      <div className="d-flex justify-content-center align-items-center" style={{width:'100%', height:'100%'}}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      )}
    </>
  )
}

export default CustomerReportPageView
