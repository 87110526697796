import {FC,useEffect} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'



import languageSheet from './sheets/lang.json' //English

const allMessages = {
  fi : {},
  en : {}
}

const extractLanguageFromSheet = () => {
   /* cell structure
  [
       0 {
          "v":  "Key" 
        },
        1{
          "v": "ENSingular"
        },
        2{
          "v": "EN Plural" //value or null
        },
        3{
          "v": "FISingular" //value or null
        },
        4{
          "v": "FIPlural" //value or null
        }
      ]
  */
  let en_messages: any = {}
  let fi_messages: any = {}
  languageSheet.table.rows.slice(1).forEach( (item: any) => {
    const cell = item.c
    // console.log(cell);
    if(!cell[1]) return; 
    en_messages[cell[0].v] = fi_messages[cell[0].v] = cell[2] && cell[2].v !== null ?  `{count, plural, =0 {${cell[1].v}} other {${cell[2].v}}}` : cell[1].v ;

    if(cell[3]&& cell[3].v !== null) //if finnish is not empty
      fi_messages[cell[0].v] =  cell[4] && cell[4].v !== null ? `{count, plural, =0 {${cell[3].v}} other {${cell[4].v}}}` : cell[3].v ;
  })
  allMessages.en = en_messages;
  allMessages.fi = fi_messages;
}

extractLanguageFromSheet();


const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]


  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
