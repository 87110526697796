/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {Editor} from '@tinymce/tinymce-react'
import {SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import SmartBar from 'groweo-smartbar'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import SelectInputFeild from '../../../../shared/SelectInputFeild'
import TextInputFeild from '../../../../shared/TextInputFeild'
import {BAR_APPEARANCE, STYLE_LIST} from '../../../../../utils/module.utils'
import {SketchPicker} from 'react-color'
import ImageInputFeild from '../../../../shared/ImageInputFeild'
import {updateImage} from '../../../../../service/module.service'
import {getFileData} from '../../../../../utils/utils'
import {useSmartBarData} from '../StepperContext'
import TextModal from '../../../common/modal/TextModal'
import SmartBarCode from './SmartBarCode'
import './SmartBar.css'
import ContentSpacing from '../../../common/ContentSpacing'
import Accordion from 'react-bootstrap/Accordion'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const option = [
  {value: '400px', text: '400'},
  {value: '500px', text: '500'},
  {value: '600px', text: '600'},
  {value: '700px', text: '700'},
]

const togglePicker = {
  barFontColor: false,
  barBgColor: false,
  contentBgColor: false,
  contentTitleFontColor: false,
  contentSubTitleFontColor: false,
  footerFontColor: false,
  barFormLabelColor: false,
  successTitleFontColor: false,
  successSubTitleFontColor: false,
  barFormButtonTextColor: false,
  barFormButtonBgColor: false,
  barFormButtonHoverTextColor: false,
  barFormButtonHoverBgColor: false,
  iconBgColor: false,
  iconColor: false,
  closeBgColor: false,
  closeIconColor: false,
  barFormInputTextColor: false,
  barFormInputBgColor: false,
  barFormInputPlaceholderColor: false,
  formButtonType: false
}

const SmartBarAppearance = () => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const {smartBar, setSmartBar} = useSmartBarData()
  const [formFields, setFormFields] = useState([])
  const [activeStep, setActiveStep] = useState(1)
  const [appearance, setAppearance] = useState(BAR_APPEARANCE)
  const [fieldChange, setFieldChange] = useState(false)
  const [toggleColorPicker, setToggleColorPicker] = useState(togglePicker)
  const [showSwalModal, setShowSwalModal] = useState(false)
  const [fontSizeDropdownOpen, setFontSizeDropdownOpen] = useState(false)
  const barTitleFontSize = appearance.barTitleFontSize
  const [editorContent, setEditorContent] = useState();
  const { iconBgImage, iconBgType, iconColorPosition, iconImagePosition, formButtonType, barFormButtonPosition } = appearance;

  const buttonTypes = [
    {value: 'Form Submit', text: 'Form Submit'},
    {value: 'Website', text: t('WEBSITE')},
    {value: 'Email', text: t('EMAIL', {count: 0})},
    {value: 'Phone Number', text: `${t('PHONE')} ${t('NUMBER')}`},
  ]

  useEffect(() => {
    if (smartBar?.moduleCode?.code) {
      const {fields} = smartBar.moduleCode.code
      setFormFields(fields?.length ? fields : [...defaultField.slice(0, 3)])
    }
    // eslint-disable-next-line
  }, [smartBar?.moduleCode?.code])

  useEffect(() => {
    if (smartBar?.moduleCode?.appearance) {
      setAppearance({...appearance, ...smartBar.moduleCode.appearance})
    }
    // eslint-disable-next-line
  }, [smartBar?.moduleCode?.appearance])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // eslint-disable-next-line
  }, [activeStep])

  useEffect(() => {
    if (formFields.length)
      setSmartBar({...smartBar, moduleCode: {...smartBar.moduleCode, code: {fields: formFields}}})
    // eslint-disable-next-line
  }, [formFields])

  const renderSteps = (step, title, desc) => (
    <div className={`d-flex ${activeStep === step ? 'current' : ''} stepper-item`}>
      <div className='d-flex align-items-center cursor-pointer' onClick={() => setActiveStep(step)}>
        <div className='stepper-icon w-40px h-40px'>
          <span className='stepper-number'>{step}</span>
        </div>
        <div className='stepper-label'>
          <h3 className='stepper-title'>{title}</h3>
          <div className='stepper-desc'>{desc}</div>
        </div>
      </div>
    </div>
  )

  const onChnageType = (selectedType, fieldIndex) => {
    if (!selectedType) return
    formFields.splice(fieldIndex, 1, {
      type: selectedType,
      name: '',
      label: '',
      placeholder: '',
      require: false,
      showlabel: false,
    })
    setFormFields([...formFields])
  }
  const onChangeInput = (e, fieldIndex) => {
    const {target} = e
    const currentFieldObj = formFields[fieldIndex]
    const fieldValue = !['require', 'showlabel'].includes(target.name)
      ? target.value
      : target.checked
    formFields.splice(fieldIndex, 1, {...currentFieldObj, [target.name]: fieldValue})
    setFormFields([...formFields])
  }
  const renderTextArea = (Label, name, fieldIndex, value) => (
    <div className='m-2 p-0'>
      <div className='d-flex justify-content-between'>
        <label>{Label}</label>
      </div>
      <textarea
        className='form-control'
        style={{height: '100px'}}
        name={name}
        value={value}
        onChange={(e) => onChangeInput(e, fieldIndex)}
      ></textarea>
    </div>
  )
  const renderInput = (Label, name, fieldIndex, value, type, checked, required) => (
    <div className='m-2 p-0'>
      {type !== 'checkbox' ? (
        <>
          <div className='d-flex justify-content-between'>
            <label>{Label}</label>
            {Label === t('FIELD_LABEL') && (
              <label htmlFor={`showlabel${fieldIndex}`} className='d-flex align-items-center'>
                <input
                  type='checkbox'
                  className='form-check m-0 mx-2'
                  name='showlabel'
                  id={`showlabel${fieldIndex}`}
                  onChange={(e) => onChangeInput(e, fieldIndex)}
                  checked={checked}
                />
                {t('SHOW_LABEL')}
              </label>
            )}
          </div>
          <input
            type={type || 'text'}
            className='form-control'
            name={name}
            value={value}
            required={required}
            onChange={(e) => onChangeInput(e, fieldIndex)}
          />
        </>
      ) : (
        <div className='d-flex justify-content-between'>
          <label className='d-flex align-items-center' htmlFor={name + fieldIndex}>
            <input
              type='checkbox'
              className='form-check m-0'
              name={name}
              id={name + fieldIndex}
              onChange={(e) => onChangeInput(e, fieldIndex)}
              checked={checked}
            ></input>
            <span className='mx-2'>{Label}</span>
          </label>
        </div>
      )}
    </div>
  )
  const fieldTypes = [
    {value: 'text', text: t('TEXT_ONE_LINE')},
    {value: 'textarea', text: t('TEXT_BOX_MULTIPLE_LINE')},
    {value: 'email', text: t('EMAIL', {count: 0})},
    {value: 'number', text: t('NUMBER')},
    {value: 'date', text: t('DATE')},
    {value: 'password', text: t('PASSWORD')},
    {value: 'tel', text: t('PHONE_NUMBER')},
    {value: 'url', text: t('WEBSITE')},
    {value: 'radio', text: t('RADIO_BUTTON')},
    {value: 'checkbox', text: t('CHECKBOX')},
    {value: 'dropdwon', text: t('DROPDWON')},
    {value: 'file', text: t('FILE_UPLOAD')},
  ]
  const defaultField = [
    {
      type: 'text',
      name: 'name',
      label: t('NAME'),
      placeholder: t('NAME'),
      require: false,
      showlabel: true,
    },
    {
      type: 'email',
      name: 'email',
      label: t('EMAIL', {count: 0}),
      placeholder: t('EMAIL', {count: 0}),
      require: false,
      showlabel: true,
    },
    {
      type: 'tel',
      name: 'phone',
      label: t('PHONE_NUMBER'),
      placeholder: t('PHONE_NUMBER'),
      require: false,
      showlabel: true,
    },
    {type: 'date', name: 'birthday', label: t('BIRTHDAY'), require: false, showlabel: false},
    {
      type: 'text',
      name: 'company',
      label: t('COMPANY'),
      placeholder: t('COMPANY'),
      require: false,
      showlabel: false,
    },
    {
      type: 'url',
      name: 'website',
      label: t('WEBSITE'),
      placeholder: t('WEBSITE'),
      require: false,
      showlabel: false,
    },
    {
      type: 'text',
      name: 'zipcode',
      label: t('ZIPCODE'),
      placeholder: t('ZIPCODE'),
      require: false,
      showlabel: false,
    },
    {type: 'text', name: '', label: '', placeholder: '', require: false, showlabel: false},
  ]

  const editorChange = (value) => {
    setEditorContent(value)
  }

  const setBarAppearance = (data) => {
    setFieldChange(true)
    setAppearance({...appearance, ...data})
  }
  const onSave = () => {
    if (fieldChange) {
      setSmartBar({...smartBar, moduleCode: {...smartBar.moduleCode, appearance}})
    }
    setFieldChange(false)
  }

  const onCheckBoxSave = (data) => {
    const appearanceData = {...appearance, ...data}
    setSmartBar({...smartBar, moduleCode: {...smartBar.moduleCode, appearance: appearanceData}})
    setAppearance(appearanceData)
  }

  const onDirectSave = (name, value) => {
    setEditorContent();
    appearance[name] = value;
    setAppearance({...appearance})
    setSmartBar({...smartBar, moduleCode: {...smartBar.moduleCode, appearance}})
  }
  const renderColorPicker = (label, key) => (
    <div className='d-flex flex-column position-relative'>
      <label className='fs-5 fw-semibold my-2'>{t(label)}</label>
      <div className='d-flex align-items-baseline'>
        <button
          className={`btn btn-default w-250 w-md-250px text-left h-50px border border-dark`}
          style={{background: appearance[key]}}
          onClick={() => {
            setToggleColorPicker({...toggleColorPicker, [key]: !toggleColorPicker[key]})
            onSave()
          }}
        />
        {toggleColorPicker[key] && (
          <div
            onClick={() => {
              setToggleColorPicker({...toggleColorPicker, [key]: false})
              onSave()
            }}
            className='cursor-pointer m-3 text-center'
          >
            <KTSVG className='svg-icon-2x' path='/media/icons/custom/close_icon.svg' />
          </div>
        )}
      </div>
      {toggleColorPicker[key] && (
        <div className='position-absolute' style={{top: 95, zIndex: 5}}>
          <SketchPicker
            width={250}
            color={appearance[key]}
            onChange={(color) => {
              setBarAppearance({
                [key]: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
              })
            }}
          />
        </div>
      )}
    </div>
  )

  const renderColorPickerNew = (label, key) => (
    <div className='panel-content d-flex flex-row align-items-center justify-content-between'>
      <label className='fs-5 fw-semibold my-2'>{t(label)}</label>
      <div className='d-flex align-items-baseline position-relative border border-dark p-1' style={{borderRadius: 8}}>
        <button
          className={`btn btn-default w-25 w-md-25px text-left h-25px p-5`}
          style={{background: appearance[key]}}
          onClick={() => {
            setToggleColorPicker({...toggleColorPicker, [key]: !toggleColorPicker[key]})
            onSave()
          }}
        />
        {toggleColorPicker[key] && (
        <div className='position-absolute' style={{top: 0, zIndex: 5, right: 45}}>
          <div className='position-relative'>
            <SketchPicker
              width={250}
              color={appearance[key]}
              onChange={(color) => {
                setBarAppearance({
                  [key]: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                })
              }}
            />
            <div
              onClick={() => {
                setToggleColorPicker({...toggleColorPicker, [key]: false})
                onSave()
              }}
              className='cursor-pointer position-absolute text-center bg-light'
              style={{top: -6, right: -6, borderRadius: '50%'}}
            >
              <KTSVG className='svg-icon' path='/media/icons/custom/close_icon.svg' />
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  )

  const onFileUpload = async (e, name) => {
    if (!e.target.files.length) return
    const data = await updateImage(smartBar._id, getFileData(e.target.files))
    onDirectSave(name, data)
  }
  const onFileRemove = (name) => onDirectSave(name, '')

  const renderAppereanceTextArea = (name, label) => (
    <>
      <label className='fs-5 fw-semibold mt-5 mb-2'>{label}</label>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={appearance[name]}
        icons='small'
        onBlur={() =>
          onDirectSave(name, editorContent)
        }
        init={{
          // height: 300,
          min_height: 200,
          menubar: false,
          // inline: true,
          // statusbar: false, // hide status bar
          branding: false, // hide only brand logo
          setup: function (editor) {
            editor.on('focusout', () => editorChange(editor.getContent()))
          },
          //display all buttons
          toolbar: [
            'blocks | undo redo | bold italic | lists charmap |link image | bullist numlist | outdent indent | blockquote subscript superscript | alignleft aligncenter alignright alignjustify | preview  code',
          ],
          //wrap buttons
          // toolbar:"blocks | undo redo | bold italic | lists charmap | link image | bullist numlist | outdent indent | alignleft aligncenter alignright alignjustify | blockquote subscript superscript | preview  code ",
          plugins: 'link image lists charmap preview code autoresize paste',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          paste_as_text: true,
        }}
      />
    </>
  )

  const renderFontSizeInput = (barTitleFontSize, label) => (
    <a
      style={{userSelect: 'none', cursor: 'pointer'}}
      onClick={() => {
        setFontSizeDropdownOpen(false)
        onDirectSave('barTitleFontSize', barTitleFontSize)
      }}
      className='dropdown-item'
    >
      {label}
    </a>
  )

  const handleDragEnd = useCallback(({ source, destination }) => {
    setFormFields((oldItems) => {
      const newItems = oldItems.slice(); // Duplicate
      const [temp] = newItems.splice(source.index, 1);
      destination?.index === 0 ? newItems.splice(0, 0, temp) : destination?.index ? newItems.splice(destination.index, 0, temp) : newItems.splice(source.index, 0, temp);
      return newItems;
    });
  });

  const renderFields = () => (
    <div className='fv-row mt-7'>
      {formFields?.map((field, fieldIndex) => (
        <Draggable key={fieldIndex} draggableId={String(fieldIndex)} index={fieldIndex}>
          {(fieldData, checkItem) => {
            return (
              <div
                ref={fieldData.innerRef}
                {...fieldData.draggableProps}
                style={
                  checkItem.isDragging ? { background: "white", ...fieldData.draggableProps.style }
                    : fieldData.draggableProps.style
                }
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item key={fieldIndex} className='mb-4 border-0' >
                    <div
                      data-index={fieldIndex}
                      key={'Accordion-' + fieldIndex}
                    >
                      <div className='d-flex align-items-center'>
                        <div
                          className='m-0 w-100 border border-secondary rounded'
                          key={fieldIndex}
                        >
                          <Accordion.Header className='mt-0 p-1'>
                            <h2 className='m-0 form-control-lg d-flex align-items-center'>
                              <label className='field-label'>
                                {field.label || t('FIELD', { count: 0 })}
                              </label>
                            </h2>
                          </Accordion.Header>
                          <Accordion.Body key={fieldIndex}>
                            <div>
                              <div className='text-left m-2 p-0'>
                                <label>{t('FIELD_TYPE')}</label>
                                <SelectInputFeild
                                  name='language'
                                  value={field?.type}
                                  options={fieldTypes}
                                  onChange={(name, value) =>
                                    onChnageType(value, fieldIndex)
                                  }
                                />
                              </div>
                              {renderInput(
                                t('FIELD_NAME'),
                                'name',
                                fieldIndex,
                                field?.name,
                                '',
                                '',
                                true
                              )}
                              {renderInput(
                                t('FIELD_LABEL'),
                                'label',
                                fieldIndex,
                                field?.label,
                                '',
                                field?.showlabel,
                                true
                              )}
                              {![
                                'checkbox',
                                'dropdwon',
                                'hidden',
                                'file',
                                'radio',
                                'date',
                              ].includes(field?.type) &&
                                renderInput(
                                  t('PLACEHOLDER_TEXT'),
                                  'placeholder',
                                  fieldIndex,
                                  field?.placeholder
                                )}
                              {['checkbox', 'dropdwon', 'radio'].includes(
                                field?.type
                              ) &&
                                renderTextArea(
                                  t('OPTIONS_ONE_PER_LINE'),
                                  'options',
                                  fieldIndex,
                                  field?.options
                                )}
                              {/* {['hidden'].includes(field?.type) && renderInput("Hidden value", "hiddenvalue", fieldIndex, obj.hiddenvalue)} */}
                              {!['hidden'].includes(field?.type) &&
                                renderInput(
                                  t('REQUIRED'),
                                  'require',
                                  fieldIndex,
                                  '',
                                  'checkbox',
                                  field?.require
                                )}
                            </div>
                          </Accordion.Body>
                        </div>
                        <button
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-2'
                          name='delete'
                          onClick={() => {
                            formFields.splice(fieldIndex, 1)
                            setFormFields([...formFields])
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </button>
                        <div className='btn btn-sm btn-icon btn-active-light-primary' {...fieldData.dragHandleProps}>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr032.svg'
                            className='svg-icon-2x svg-icon-success'
                          />
                        </div>                    
                      </div>
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            );
          }}
        </Draggable>
      ))}
    </div>
  )

  const renderInputName = (name, value) => (
    <>
      <label className="fs-5 fw-semibold mt-5 mb-2">{t('BUTTON', {count: 0})} {t('TEXT', {count: 0})}</label>
      <TextInputFeild
        name={name}
        className='form-control form-control-solid'
        value={value}
        onChange={(name, value) =>setBarAppearance({[name]: value})}
        onBlur={onSave}
      />
    </>
  )

  const renderInputValue = (name, value, type) => (
    <>
      <label className="fs-5 fw-semibold mt-5 mb-2">{`Enter ${type}`}</label>
      <TextInputFeild
        name={name}
        className='form-control form-control-solid'
        value={value}
        onChange={(name, value) =>setBarAppearance({[name]: value})}
        onBlur={onSave}
      />
    </>
  )

  return (
    <>
      <div className='container h-100'>
        <div className='row g-5 g-xl-8 h-100'>
          <div className={`card col-xl-7 ${appearance?.direction === 'left' && 'position-absolute end-0 me-9'}`} style={{minHeight: '88vh', width:'800px'}}>
            <div className='py-lg-10 px-lg-10 h-100'>
              <div className='stepper custom-stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid h-100'>
                <div className='my-auto' style={{height: 'fit-content'}}>
                  <div className='w-100 w-xl-300px'>
                    {renderSteps(1, t('BAR_GENERAL_SETTINGS'), t('BAR_GENERAL_SETTINGS_SUBTITLE'))}
                    {renderSteps(2, t('BAR_SETTINGS'), t('BAR_SETTINGS_SUBTITLE'))}
                    {renderSteps(3, t('BAR_CONTENT'), t('BAR_CONTENT_SUBTITLE'))}
                    {renderSteps(4, t('BAR_FORM_FIELDS'), t('BAR_FORM_FIELDS_SUBTITLE'))}
                    {renderSteps(5, t('BAR_FORM_SETTINGS'), t('BAR_FORM_SETTINGS_SUBTITLE'))}
                    {renderSteps(6, t('SUCCESS_MESSAGE'), t('SUCCESS_MESSAGE_SUBTITLE'))}
                    {renderSteps(7, t('MOBILE_SETTINGS'), t('MOBILE_SETTINGS_SUB_TITLE'))}
                    {renderSteps(8, t('CLOSE_BUTTON'), t('CLOSE_BUTTON_SUB_TITLE'))}
                    {renderSteps(9, t('INSTALLATION'), t('INSTALLATION_SUB_TITLE'))}
                  </div>
                </div>
                <div className='border-start border-3 h-100 d-flex w-100 align-items-center'>
                  <div className='flex-row-fluid py-lg-5 ps-15'>
                    {activeStep === 1 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-0'>{t('BAR_GENERAL_SETTINGS')}</h4>
                        <label className='fs-5 fw-semibold mt-5 mb-2'>{t('WIDTH')}</label>
                        <SelectInputFeild
                          name='width'
                          value={appearance?.width}
                          hideDefault
                          options={option}
                          onChange={(name, value) => setBarAppearance({[name]: value})}
                          onBlur={onSave}
                        />
                        <label className='fs-5 fw-semibold mt-5 mb-2'>{t('HEIGHT')}</label>
                        <SelectInputFeild
                          name='height'
                          value={appearance?.height}
                          hideDefault
                          options={option}
                          onChange={(name, value) => setBarAppearance({[name]: value})}
                          onBlur={onSave}
                        />
                        {renderColorPicker('BACKGROUND_COLOR', 'contentBgColor')}
                        <label className='fs-5 fw-semibold mt-5 mb-2'>
                          {t('BACKGROUND_IMAGE')}
                        </label>
                        <div>
                          <ImageInputFeild
                            size={{icon: 15, img: 75}}
                            url={appearance?.contentBgImage}
                            element={'contentBgImage'}
                            onUpload={(e, name) => onFileUpload(e, name)}
                            onRemove={(name) => onFileRemove(name)}
                          />
                        </div>
                        {renderColorPicker('FOOTER_COLOR', 'footerFontColor')}
                      </div>
                    )}
                    {activeStep === 2 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-3'>{t('BAR_SETTINGS')}</h4>
                        {renderAppereanceTextArea('barTitle', t('BAR_TITLE', {count: 0}))}
                        <label className='fs-5 fw-semibold my-2'>
                          {t('FONT_SIZE', {count: 0})}
                        </label>
                        <div className='dropdown'>
                          <button
                            onClick={() => setFontSizeDropdownOpen(!fontSizeDropdownOpen)}
                            className='btn btn-secondary dropdown-toggle'
                            type='button'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            {Math.floor(barTitleFontSize * 100) + '%'}
                          </button>
                          <div
                            className='dropdown-menu'
                            style={{display: fontSizeDropdownOpen ? 'block' : 'none'}}
                            aria-labelledby='dropdownMenuButton'
                          >
                            {renderFontSizeInput(0.8, '80%')}
                            {renderFontSizeInput(0.9, '90%')}
                            {renderFontSizeInput(1, '100%')}
                            {renderFontSizeInput(1.1, '110%')}
                            {renderFontSizeInput(1.2, '120%')}
                            {renderFontSizeInput(1.3, '130%')}
                            {renderFontSizeInput(1.4, '140%')}
                            {renderFontSizeInput(1.5, '150%')}
                          </div>
                        </div>
                        {renderColorPicker('TEXT_COLOR', 'barFontColor')}
                        {renderColorPicker('BACKGROUND_COLOR', 'barBgColor')}
                        <label className='fs-5 fw-semibold mt-5 mb-2'>
                          {t('INVERT_BAR_COLORS')}
                        </label>
                        <label className='form-check form-check-custom form-check-solid form-switch m-1' style={{width: 'fit-content'}}>
                          <span className='form-check-label text-muted mx-3'>{t('OFF')}</span>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={appearance?.invertBarColor}
                            onChange={(ev) => onCheckBoxSave({invertBarColor: ev.target.checked})}
                          />
                          <span className='form-check-label text-muted mx-3'>{t('ON')}</span>
                        </label>
                      </div>
                    )}
                    {activeStep === 3 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-0'>{t('BAR_CONTENT')}</h4>
                        {renderAppereanceTextArea('contentTitle', t('TITLE', {count: 0}))}
                        {renderAppereanceTextArea('contentSubTitle', t('SUB_TITLE', {count: 0}))}
                        {renderColorPicker('TITLE_TEXT_COLOR', 'contentTitleFontColor')}
                        {renderColorPicker('SUBTITLE_TEXT_COLOR', 'contentSubTitleFontColor')}
                      </div>
                    )}
                    {activeStep === 4 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-5'>{t('BAR_FORM_FIELDS')}</h4>
                        <div className='d-flex align-items-center justify-content-between'>
                          <label className='fs-5 fw-bolder'>{t('FIELD', {count: -1})}</label>
                          <div className='card-toolbar m-0'>
                            <button
                              className='btn btn-primary btn-sm'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              {t('ADD_NEW_FIELD')}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr072.svg'
                                className='svg-icon svg-icon-5 m-0'
                              />
                            </button>

                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                              data-kt-menu='true'
                            >
                              <div className='menu-item px-3'>
                                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4 d-flex'>
                                  {t('FIELD', {count: 1})}
                                </div>
                              </div>
                              <div className='separator mb-2 opacity-75'></div>
                              {defaultField.map((field, index) => (
                                <div key={`dropdwon${index}`}>
                                  {!field.label && (
                                    <div className='separator mb-2 opacity-75'></div>
                                  )}
                                  <div className='menu-item px-3'>
                                    <a
                                      onClick={() => {
                                        formFields.push(field)
                                        setFormFields([...formFields])
                                      }}
                                      className='menu-link px-3'
                                    >
                                      {field.label || t('ADD_NEW_FIELD')}
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="root">
                            {(fieldData) => {
                              return (
                                <div ref={fieldData.innerRef} {...fieldData.droppableProps}>
                                    {renderFields()}
                                    {fieldData.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </DragDropContext>

                        <label className='fs-5 my-3 fw-bolder'>
                          {t('ACTION', {count: 0})}
                        </label>
                        <SelectInputFeild
                          name='formButtonType'
                          value={formButtonType}
                          hideDefault
                          options={buttonTypes}
                          onChange={(name, value) => setBarAppearance({[name]: value})}
                          onBlur={onSave}
                        />
                        {formButtonType === 'Form Submit' && 
                        <div className='mt-3'>
                          {renderInputName('barFormSubmitBtnText', appearance?.barFormSubmitBtnText)}
                        </div>}
                        {formButtonType === 'Website' && 
                          <div className='mt-3'>
                            {renderInputName('barFormSubmitBtnTextUrl', appearance?.barFormSubmitBtnTextUrl)}
                            {renderInputValue('barFormSubmitBtnUrl', appearance?.barFormSubmitBtnUrl, appearance?.formButtonType)}
                        </div>}
                        {formButtonType === 'Email' && 
                          <div className='mt-3'>
                            {renderInputName('barFormSubmitBtnTextEmail', appearance?.barFormSubmitBtnTextEmail)}
                            {renderInputValue('barFormSubmitBtnEmail', appearance?.barFormSubmitBtnEmail, appearance?.formButtonType)}
                        </div>}
                        {formButtonType === 'Phone Number' && 
                          <div className='mt-3'>
                            {renderInputName('barFormSubmitBtnTextPhone', appearance?.barFormSubmitBtnTextPhone)}
                            {renderInputValue('barFormSubmitBtnPhone', appearance?.barFormSubmitBtnPhone, appearance?.formButtonType)}
                        </div>}
                        <div className="d-flex justify-content-between pt-7 pb-5">
                          <label className="fs-5 fw-semibold">Auto Width</label>
                          <label className="form-check form-switch form-switch-sm form-check-custom">
                            <TextInputFeild
                              name='barFormAutoWidth' 
                              className="form-check-input" 
                              type={"checkbox"}
                              checked={appearance?.barFormAutoWidth}
                              value={appearance?.barFormAutoWidth}
                              onChange={(name)=> onCheckBoxSave({[name]: !appearance?.barFormAutoWidth})}
                            />
                          </label>
                        </div>
                        {!appearance?.barFormAutoWidth && <fieldset>
                          <div className="d-flex w-100 pt-2 align-items-center justify-content-center">
                            <div className="w-100 d-flex align-items-center justify-content-center">
                                <TextInputFeild
                                  type={'range'}
                                  name={'barFormAutoWidthRange'}
                                  className='form-check-input'
                                  style={{width: '100%'}}
                                  min="0"
                                  max="100"
                                  value={appearance?.barFormAutoWidthRange}
                                  onChange={(name, value)=> setBarAppearance({[name]: value})}
                                  onBlur={onSave}
                                />
                                <span className='ps-5'>{appearance?.barFormAutoWidthRange}%</span>
                            </div>
                          </div>
                        </fieldset>}
                        <div className='fs-5 fw-semibold mt-5 mb-2'>{t('ALIGNMENT')}</div>
                        <SelectInputFeild
                          name='barFormButtonPosition'
                          classes='bg-light'
                          value={barFormButtonPosition}
                          appendOption={{value: 'left', name: t('LEFT')}}
                          options={[{value: 'right', text: t('RIGHT')}, {value: 'center', text: t('CENTER')}]}
                          onChange={(name, value) => setBarAppearance({[name] : value})}
                          onBlur={onSave}
                        />
                      </div>
                    )}
                    {activeStep === 5 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-5'>{t('BAR_FORM_SETTINGS')}</h4>
                        <ContentSpacing
                          label={t('FORM_PADDING', {count: 0})}
                          value={appearance?.barFormPadding}
                          onSave={(barFormPadding) => onDirectSave('barFormPadding', barFormPadding)}
                        />
                        <label className='fs-5 mt-5 fw-bolder'>{t('FORM_FIELD_SETTINGS')}</label>
                        <br />
                        <label className='fs-5 fw-semibold my-2'>
                          {t('BACKGROUND_COLOR_PERCENTAGE', {count: 0})}
                        </label>
                        <TextInputFeild
                          type={'number'}
                          name={'barFieldBGPercentage'}
                          className='form-control form-control-solid'
                          placeholder={'%'}
                          value={appearance?.barFieldBGPercentage}
                          min={0}
                          max={100}
                          onChange={(name, value) => setBarAppearance({[name]: value})}
                          onBlur={onSave}
                        />

                        <label className='fs-5 mt-5 fw-bolder'>
                          {t('BOT_FORM_LABEL_SETTINGS')}
                        </label>
                        {renderColorPickerNew('LABEL_TEXT_COLOR', 'barFormLabelColor')}
                        {renderColorPickerNew('FORM_FIELD_TEXT_COLOR', 'barFormInputTextColor')}
                        {renderColorPickerNew('FORM_FIELD_COLOR', 'barFormInputBgColor')}
                        {renderColorPickerNew('FORM_FIELD_PLACEHOLDER_COLOR', 'barFormInputPlaceholderColor')}
                        <label className='fs-5 mt-5 fw-bolder'>
                          {t('BOT_FORM_BUTTON_SETTINGS')}
                        </label>
                        {renderColorPickerNew('TEXT_COLOR', 'barFormButtonTextColor')}
                        {renderColorPickerNew('BACKGROUND_COLOR', 'barFormButtonBgColor')}
                        {renderColorPickerNew(
                          'OPTION_HOVER_TEXT_COLOR',
                          'barFormButtonHoverTextColor'
                        )}
                        {renderColorPickerNew(
                          'OPTION_HOVER_BACKGROUND_COLOR',
                          'barFormButtonHoverBgColor'
                        )}
                      </div>
                    )}
                    {activeStep === 6 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-0'>{t('SUCCESS_MESSAGE')}</h4>
                        {renderAppereanceTextArea('successMsgTitle', t('TITLE', {count: 0}))}
                        {renderAppereanceTextArea('successMsgSubTitle', t('SUB_TITLE', {count: 0}))}
                        {renderColorPicker('TITLE_TEXT_COLOR', 'successTitleFontColor')}
                        {renderColorPicker('SUBTITLE_TEXT_COLOR', 'successSubTitleFontColor')}
                      </div>
                    )}
                    {activeStep === 7 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-0'>{t('MOBILE_SETTINGS')}</h4>
                        <label className='form-check form-check-custom form-check-solid form-switch m-1 mt-5'>
                          <TextInputFeild
                          type={'checkbox'}
                          name={'SwitchToMobileMode'}
                          className='form-control form-control-solid'
                          checked={appearance?.SwitchToMobileMode}
                          value={appearance?.SwitchToMobileMode}
                          onChange={(name) => onCheckBoxSave({[name]: !appearance?.SwitchToMobileMode})}
                        />
                          <label className='fs-5 fw-semibold'>{t('SWITCH_MOBILE_MODE')}</label>
                        </label>
                        <label className='fs-5 fw-bolder mt-5 mb-2'>{t('ICON_SETTINGS')}</label>
                        <div className='fs-5 fw-semibold mt-5 mb-2'>{t('TYPE')}</div>
                        <SelectInputFeild
                          name='iconBgType'
                          classes='bg-light'
                          value={iconBgType}
                          appendOption={{value: 'bar', name: t('SMART_BAR',{count: 0})}}
                          options={[{value: 'color', text: t('ICON')}, {value: 'image', text: t('IMAGE')}]}
                          onChange={(name, value) => setBarAppearance({[name] : value})}
                          onBlur={onSave}
                        />
                        {iconBgType === 'color' && (
                          <>
                            {renderColorPicker('ICON_COLOR', 'iconColor')}
                            {renderColorPicker('BACKGROUND_COLOR', 'iconBgColor')}
                            <div className='fs-5 fw-semibold mt-5 mb-2'>{t('POSITION')}</div>
                            <SelectInputFeild
                              name='iconColorPosition'
                              classes='bg-light'
                              value={iconColorPosition}
                              appendOption={{value: 'bottom', name: t('BOTTOM')}}
                              options={[{value: 'middle', text: t('MIDDLE')}]}
                              onChange={(name, value) => setBarAppearance({[name] : value})}
                              onBlur={onSave}
                            />
                          </>
                        )}
                        {iconBgType === 'image' && (
                          <>
                            <label className='fs-5 fw-semibold mt-5 mb-5'>
                              {t('UPLOAD_ICON_IMAGE')}
                            </label>
                            <div>
                              <ImageInputFeild
                                size={{icon: 15, img: 75}}
                                type='rounded'
                                url={iconBgImage}
                                placeholder={'url(/media/svg/avatars/imageBarPlaceholder.svg)'}
                                element={'iconBgImage'}
                                onUpload={(e, name) => onFileUpload(e, name)}
                                onRemove={(name) => onFileRemove(name)}
                              />
                            </div>
                            {renderColorPicker('BACKGROUND_COLOR', 'imageBgColor')}
                            <div className='fs-5 fw-semibold mt-5 mb-2'>{t('POSITION')}</div>
                            <SelectInputFeild
                              name='iconImagePosition'
                              classes='bg-light'
                              value={iconImagePosition}
                              appendOption={{value: 'bottom', name: t('BOTTOM')}}
                              options={[{value: 'middle', text: t('MIDDLE')}]}
                              onChange={(name, value) => setBarAppearance({[name] : value})}
                              onBlur={onSave}
                            />
                          </>
                        )}
                      </div>
                    )}
                    {activeStep === 8 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-0'>{t('CLOSE_BUTTON')}</h4>
                        <div>
                          {renderColorPicker(t('BACKGROUND_COLOR'), 'closeBgColor')}
                          {renderColorPicker(t('ICON_COLOR'), 'closeIconColor')}
                          <div className='my-2'>
                            <ContentSpacing
                              label={t('ROUNDED_BORDER')}
                              value={appearance?.closeBorderRadius}
                              onSave={(value) => onDirectSave('closeBorderRadius', value)}
                              isBorderRadius={true}
                            />
                          </div>
                          <div className='my-2'>
                            <ContentSpacing
                              label={t('MARGIN')}
                              value={appearance?.closeMargin}
                              onSave={(value) => onDirectSave('closeMargin', value)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 9 && (
                      <div className='sidebar-content'>
                        <h4 className='mb-0'>{t('INSTALLATION')}</h4>
                        <SmartBarCode />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {activeStep !== 7 && <div>
            <div className='card'>
              {/* <ThemeProvider theme={themes[mode]}> */}
              <SmartBar
                code={{fields: formFields}}
                appearance={appearance}
                isPreview={true}
                activeStep={activeStep}
              />
              {/* </ThemeProvider> */}
            </div>
          </div>}
        </div>
      </div>
      {showSwalModal ? (
        <TextModal
          message={t('COPIED_MSG')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
    </>
  )
}

export default SmartBarAppearance
