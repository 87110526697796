/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import ChatBot from 'react-simple-chatbot'
import {ThemeProvider} from 'styled-components'
import $ from 'jquery'
import {SketchPicker} from 'react-color'
import ImageInputFeild from '../../../../shared/ImageInputFeild'
import {getFileData} from '../../../../../utils/utils'
import {BOT_APPEARANCE3, BOT_COLORS, getTheme, getTheme3, STEPS} from '../../../../../utils/module.utils'
import {updateImage} from '../../../../../service/module.service'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import TextInputFeild from '../../../../shared/TextInputFeild'
import {Editor} from '@tinymce/tinymce-react'
import {generateSimpleChatbotData} from '../../../../../utils/template.utils'
import SelectInputFeild from '../../../../shared/SelectInputFeild'
import {inPopupCodeForBot} from '../../../../../utils/code.utils'
import { KTSVG } from '../../../../../_metronic/helpers'

const getSmallAvatarSize = (t) => {
  return [
    {value: 30, text: t('SMALL')},
    {value: 38, text: t('MEDIUM')},
    {value: 45, text: t('LARGE')},
  ]
}

const togglePicker = {
  iconColor: false,
  iconBgColor: false,
  headerFontColor: false,
  headerBgColor: false,
  smallAvatarColor: false,
  background: false,
  botFontColor: false,
  botBubbleColor: false,
  userFontColor: false,
  userBubbleColor: false,
  optionFontColor: false,
  optionBubbleColor: false,
  botFormButtonTextColor: false,
  botFormButtonBgColor: false,
  chatNotificationTextColor: false,
  chatNotificationBgColor: false,
  cardBtnTextColor: false,
  cardBtnBgColor: false,
  cardBtnHoverBgColor: false,
  cardBtnHoverTextColor: false,
  botFormButtonHoverTextColor: false,
  botFormButtonHoverBgColor: false
}

const BotAppearance3 = ({template, setTemplate}) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})
  const [fieldChange, setFieldChange] = useState(false)
  const [fontSizeDropdownOpen, setFontSizeDropdownOpen] = useState(false)
  const [botCode, setBotCode] = useState({appearance3: BOT_APPEARANCE3})
  const fontSizeMultiplier = botCode.appearance3.fontSizeMultiplier || 1
  const {avatar, contentBgType, smallBotAvatar, smallAvatarSize, background, iconBgImage, iconBgType, botType} = botCode.appearance3
  const [getEditorContent, setGetEditorContent] = useState()
  const [activeStep, setActiveStep] = useState(1)
  const [toggleColorPicker, setToggleColorPicker] = useState(togglePicker)
  // const themes = getTheme(botCode.appearance.primaryColor)
  // const [botSteps, setBotSteps] = useState([])

  useEffect(() => {
    if (template._id) getContent()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
    // eslint-disable-next-line
  }, [activeStep])

  useEffect(() => {
    if (!template.botCode && template.code) {
      const code = JSON.parse(template.code)
      setBotCode({...code, appearance3: {...botCode.appearance3, ...code.appearance}})
    }
    // eslint-disable-next-line
  }, [template.code])

  useEffect(() => {
    if (!getEditorContent) return
    const {preNotification} = botCode.appearance3
    const {notification} = botCode.appearance3.preNotification
    const {text, index, delay} = getEditorContent
    notification[index] = {delay, text}
    onNotificationFieldChange({...preNotification, notification}, 'text')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditorContent])

  const convert = () => {
    const validators = {
      email: (value) => {
        if (value && value.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+/)) {
          return true
        }
        return t('VALID.EMAIL')
      },
    }

    const templateCode = template.botCode
    const steps = generateSimpleChatbotData(templateCode.elements)
    for (const step of steps) {
      if (!step.trigger && !step.options) step.end = true
      if (step.validator) step.validator = validators[step.validator]
    }
    // setBotSteps(steps)
  }

  useEffect(() => {
    if (template.botCode) {
      const code = template.botCode
      setBotCode({...code, appearance3: {...botCode.appearance3, ...code.appearance3}})
      convert()
    }
    // eslint-disable-next-line
  }, [template.botCode])

  const setBotAppearance = (data) => {
    setFieldChange(true)
    setBotCode({...botCode, appearance3: {...botCode.appearance3, ...data}})
  }

  const onSave = () => {
    if (fieldChange) {
      setTemplate({...template, botCode}, true)
    }
    setFieldChange(false)
  }

  const onCheckBoxSave = (data) => {
    const botCodeData = {...botCode, appearance3: {...botCode.appearance3, ...data}}
    setTemplate({...template, botCode: botCodeData}, true)
    setBotCode(botCodeData)
  }

  const renderInput = (name, label) => {
    const value =
      name === 'headerTitle'
        ? botCode.appearance3.headerTitle || template.name
        : botCode.appearance3[name]

    return (
      <>
        <label className='fs-5 fw-semibold mt-5 mb-2'>{label}</label>
        <input
          value={value}
          className='form-control form-control-solid'
          onChange={(e) => setBotAppearance({[name]: e.target.value})}
          onBlur={onSave}
        />
      </>
    )
  }

  const onChangeNotificationCount = (e) => {
    const {notification} = botCode.appearance3?.preNotification || []
    const {preNotification} = botCode.appearance3 || []
    setBotAppearance({
      preNotification: {
        ...preNotification,
        count: e.target.value,
        notification: [...(notification?.splice(0, e.target.value) || '')],
      },
    })
  }

  const onNotificationFieldChange = (data, name) => {
    setFieldChange(true)
    setBotCode({...botCode, appearance3: {...botCode.appearance3, preNotification: data}})
    if (name === 'text') {
      setTemplate({...template, botCode}, true)
    }
  }

  const onUploadImage = async (e) => {
    const data = await updateImage(template._id, getFileData(e.target.files))
    botCode.appearance3.background = data
    setBotCode(botCode)
    setTemplate({...template, botCode}, true)
  }

  const onFileUpload = async (e, name) => {
    if (!e.target.files.length) return
    const data = await updateImage(template._id, getFileData(e.target.files))
    updateAvatar(data, name)
  }

  const onFileRemove = (name) => updateAvatar('', name)

  const updateAvatar = (data, name) => {
    botCode.appearance3[name] = data
    setBotCode(botCode)
    setTemplate({...template, botCode}, true)
  }

  const renderFontSizeInput = (fontSizeMultiplier, label) => (
    <a
      style={{userSelect: 'none', cursor: 'pointer'}}
      onClick={() => {
        setFontSizeDropdownOpen(false)
        //setBotAppearance({fontSizeMultiplier})
        //const code = {...botCode, appearance: {...botCode.appearance, fontSizeMultiplier}}
        botCode.appearance3.fontSizeMultiplier = fontSizeMultiplier
        setBotCode(botCode)
        setTemplate({...template, botCode}, true)
      }}
      className='dropdown-item'
    >
      {label}
    </a>
  )

  const renderSteps = (step, title, desc) => (
    <div
      className={`d-flex ${
        botType === 'inPage' && title === t('BOT_DESIGN_HEADER')
          ? 'pe-none'
          : activeStep === step
          ? 'current'
          : ''
      } stepper-item`}
    >
      <div className='d-flex align-items-center cursor-pointer' onClick={() => setActiveStep(step)}>
        <div className='stepper-icon w-40px h-40px'>
          <span className='stepper-number'>{step}</span>
        </div>
        <div className='stepper-label'>
          <h3 className='stepper-title'>{title}</h3>
          <div className='stepper-desc'>{desc}</div>
        </div>
      </div>
    </div>
  )

  const renderColorPicker = (label, key) => (
    <div className='d-flex flex-column position-relative'>
      <label className='fs-5 fw-semibold my-2'>{t(label)}</label>
      <div className='d-flex align-items-baseline'>
        <button
          className={`btn btn-default w-250 w-md-250px text-left h-50px border border-dark`}
          style={{background: botCode.appearance3[key]}}
          onClick={() => {
            setToggleColorPicker({...toggleColorPicker, [key]: !toggleColorPicker[key]})
            onSave()
          }}
        />
        {toggleColorPicker[key] && <div 
          onClick={()=> {
            setToggleColorPicker({...toggleColorPicker, [key]: false})
            onSave()
          }}
          className='cursor-pointer m-3 text-center'
        >
          <KTSVG
            className='svg-icon-2x'
            path='/media/icons/custom/close_icon.svg'
          />
        </div>}
      </div>
      {toggleColorPicker[key] && (
        <div className='position-absolute' style={{top: 95, zIndex: 5}}>
          <SketchPicker
            width={250}
            color={botCode.appearance3[key]}
            onChange={(color) => {
              setBotAppearance({[key]: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`})
            }}
          />
        </div>
      )}
    </div>
  )

  const getContent = () => {
    let content = inPopupCodeForBot(template._id, template.workspaceId, true, false)
    if (typeof content === 'string') {
      content = content.split('\n')
    }
    $('#bot-preview').empty()
    $('#bot-preview').append(content)
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='card col-xl-8'>
          <div className='py-lg-10 px-lg-10'>
            <div className='stepper custom-stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
              <div className='w-100 w-xl-300px'>
                {renderSteps(1, t('BOT_DESIGN_GENERAL'), t('BOT_DESIGN_GENERAL_SUBTITLE'))}
                {renderSteps(2, t('BOT_DESIGN_ICON'), t('BOT_DESIGN_ICON_SUBTITLE'))}
                {renderSteps(3, t('BOT_DESIGN_HEADER'), t('BOT_DESIGN_HEADER_SUBTITLE'))}
                {renderSteps(4, t('BOT_DESIGN_AVATAR'), t('BOT_DESIGN_AVATAR_SUBTITLE'))}
                {renderSteps(5, t('BOT_DESIGN_CONTENT'), t('BOT_DESIGN_CONTENT_SUBTITLE'))}
                {renderSteps(6, t('BOT_DESIGN_TEXT_BUBBLE'), t('BOT_DESIGN_TEXT_BUBBLE_SUBTITLE'))}
                {renderSteps(
                  7,
                  t('BOT_DESIGN_OPTION_BUBBLE'),
                  t('BOT_DESIGN_OPTION_BUBBLE_SUBTITLE')
                )}
                {renderSteps(8, t('BOT_DESIGN_USER_BUBBLE'), t('BOT_DESIGN_USER_BUBBLE_SUBTITLE'))}
                {renderSteps(9, t('BOT_FORM_SETTINGS'), t('BOT_FORM_SETTINGS_SUBTITLE'))}
                {renderSteps(10, t('BOT_CARD_SETTINGS'), t('BOT_CARD_SETTINGS_SUBTITLE'))}
                {renderSteps(
                  11,
                  t('BOT_DESIGN_NOTIFICATION'),
                  t('BOT_DESIGN_NOTIFICATION_SUBTITLE')
                )}
              </div>
              <div className='flex-row-fluid py-lg-5 px-lg-15 border-start border-3'>
                {activeStep === 1 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('BOT_DESIGN_GENERAL')}</h4>
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('BOT_TYPE')}</label>
                    <SelectInputFeild
                      name='botType'
                      value={botCode.appearance3.botType}
                      appendOption={{value: 'inPage',name: 'IN_PAGE'}}
                      options={[{value: 'popup', text: t('POPUP')}]}
                      onChange={(name, value) => setBotAppearance({[name]: value})}
                      onBlur={onSave}
                    />
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('WIDTH')}</label>
                    <TextInputFeild
                      name={botType === 'inPage' ? 'inPageWidth' : 'width'}
                      className='form-control form-control-solid'
                      value={botType === 'inPage' ? botCode.appearance3.inPageWidth : 400}
                      disabled={botType !== 'inPage'}
                      onChange={(name, value) => setBotAppearance({[name]: value})}
                      onBlur={onSave}
                    />
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('HEIGHT')}</label>
                    <TextInputFeild
                      name={botType === 'inPage' ? 'inPageHeight' : 'height'}
                      className='form-control form-control-solid'
                      value={botType === 'inPage' ? botCode.appearance3.inPageHeight : 700}
                      disabled={botType !== 'inPage'}
                      onChange={(name, value) => setBotAppearance({[name]: value})}
                      onBlur={onSave}
                    />
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('SHOW_TIMESTAMP')}</label>
                    <label className='form-check form-check-custom form-check-solid form-switch m-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='layout-builder'
                        checked={botCode.appearance3.showTimestamp}
                        style={{height: '1.75rem'}}
                        onChange={() =>
                          onCheckBoxSave({
                            showTimestamp: !botCode.appearance3.showTimestamp,
                          })
                        }
                      />
                    </label>
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('FONT_SIZE')}</label>
                    <div className='dropdown'>
                      <button
                        onClick={() => setFontSizeDropdownOpen(!fontSizeDropdownOpen)}
                        className='btn btn-secondary dropdown-toggle'
                        type='button'
                        id='dropdownMenuButton'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        {Math.floor(fontSizeMultiplier * 100) + '%'}
                      </button>
                      <div
                        className='dropdown-menu'
                        style={{display: fontSizeDropdownOpen ? 'block' : 'none'}}
                        aria-labelledby='dropdownMenuButton'
                      >
                        {renderFontSizeInput(1, '100%')}
                        {renderFontSizeInput(1.1, '110%')}
                        {renderFontSizeInput(1.2, '120%')}
                        {renderFontSizeInput(1.3, '130%')}
                        {renderFontSizeInput(1.4, '140%')}
                        {renderFontSizeInput(1.5, '150%')}
                      </div>
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('BOT_DESIGN_ICON')}</h4>
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('SELECT')}</label>
                    <SelectInputFeild
                      name='iconBgType'
                      classes='bg-light'
                      value={iconBgType}
                      appendOption={{value: 'color', name: 'COLOR'}}
                      options={[{value: 'image', text: t('IMAGE')}]}
                      onChange={(name, value) => setBotAppearance({[name]: value})}
                      onBlur={onSave}
                    />
                    {iconBgType === 'color' && (
                      <>
                        {renderColorPicker('ICON_COLOR', 'iconColor')}
                        {renderColorPicker('BACKGROUND_COLOR', 'iconBgColor')}
                      </>
                    )}
                    {iconBgType === 'image' && (
                      <>
                        <label className='fs-5 fw-semibold mt-5 mb-2'>
                          {t('UPLOAD_ICON_IMAGE')}
                        </label>
                        <div>
                          <ImageInputFeild
                            size={{icon: 15, img: 75}}
                            url={iconBgImage}
                            element={'iconBgImage'}
                            onUpload={(e, name) => onFileUpload(e, name)}
                            onRemove={(name) => onFileRemove(name)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
                {activeStep === 3 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('BOT_DESIGN_HEADER')}</h4>
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('BOT_HEADER_ICON')}</label>
                    <div>
                      <ImageInputFeild
                        size={{icon: 15, img: 75}}
                        url={avatar}
                        element={'avatar'}
                        onUpload={(e, name) => onFileUpload(e, name)}
                        onRemove={(name) => onFileRemove(name)}
                      />
                    </div>
                    {renderInput('headerTitle', t('HEADER_TITLE'))}
                    {renderColorPicker('TEXT_COLOR', 'headerFontColor')}
                    {renderColorPicker('HEADER_BACKGROUND_COLOR', 'headerBgColor')}
                    <label className='form-check form-check-custom form-check-solid form-switch mt-5 mb-2'>
                      <span className='fs-5 fw-semibold me-2'>{t('SHOW_HEADER')}</span>
                      <TextInputFeild
                        className='form-check-input'
                        type='checkbox'
                        name='layout-builder'
                        checked={!botCode.appearance3.hideHeader}
                        style={{height: '1.75rem'}}
                        onChange={() =>
                          onCheckBoxSave({hideHeader: !botCode.appearance3.hideHeader})
                        }
                      />
                    </label>
                  </div>
                )}
                {activeStep === 4 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('BOT_DESIGN_AVATAR')}</h4>
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('BOT_HEADER_ICON')}</label>
                    <div>
                      <ImageInputFeild
                        size={{icon: 15, img: 75}}
                        url={smallBotAvatar}
                        element={'smallBotAvatar'}
                        onUpload={(e, name) => onFileUpload(e, name)}
                        onRemove={(name) => onFileRemove(name)}
                      />
                    </div>
                    {renderInput('smallAvatartitle', t('SMALL_AVATAR_TITLE'))}
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('SMALL_AVATAR_SIZE')}</label>
                    <SelectInputFeild
                      name='smallAvatarSize'
                      classes='bg-light'
                      value={smallAvatarSize}
                      options={getSmallAvatarSize(t)}
                      onChange={(name, value) => setBotAppearance({[name]: value})}
                      onBlur={onSave}
                    />
                    {renderColorPicker('SMALL_AVATAR_TEXT_COLOR', 'smallAvatarTextColor')}
                    {renderColorPicker('SMALL_AVATAR_COLOR', 'smallAvatarColor')}
                    <label className='form-check form-check-custom form-check-solid form-switch mt-5 mb-2'>
                      <span className='fs-5 fw-semibold me-2'>{t('SHOW_BOT_AVATAR')}</span>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='layout-builder'
                        checked={!botCode.appearance3.hideBotAvatar}
                        style={{height: '1.75rem'}}
                        onChange={() =>
                          onCheckBoxSave({hideBotAvatar: !botCode.appearance3.hideBotAvatar})
                        }
                      />
                    </label>
                  </div>
                )}
                {activeStep === 5 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('BOT_DESIGN_CONTENT')}</h4>
                    <label className='fs-5 fw-semibold mt-5 mb-2'>{t('CONTENT_CONTAINER')}</label>
                    <SelectInputFeild
                      name='contentBgType'
                      classes='bg-light'
                      value={contentBgType}
                      appendOption={{value: 'color', name: 'COLOR'}}
                      options={[{value: 'image', text: t('IMAGE')}]}
                      onChange={(name, value) => setBotAppearance({[name]: value, background: ''})}
                      onBlur={onSave}
                    />
                    <div className='mt-5'>
                      {contentBgType === 'color' &&
                        renderColorPicker('BACKGROUND_COLOR', 'background')}
                      {contentBgType === 'image' && (
                        <>
                          <label className='fs-5 fw-semibold mt-5 mb-2'>
                            {t('CONTENT_BACKGROUND_IMAGE')}
                          </label>
                          <ImageInputFeild
                            size={{icon: 25, img: 250}}
                            type={'rounded'}
                            url={background}
                            placeholder={'url(/media/svg/avatars/placeholder.svg)'}
                            element={'background'}
                            onUpload={(e, name) => onFileUpload(e, name)}
                            onRemove={(name) => onFileRemove(name)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
                {activeStep === 6 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-3'>{t('BOT_DESIGN_TEXT_BUBBLE')}</h4>
                    {renderColorPicker('TEXT_COLOR', 'botFontColor')}
                    {renderColorPicker('BUBBLE_BACKGROUND_COLOR', 'botBubbleColor')}
                  </div>
                )}
                {activeStep === 7 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('BOT_DESIGN_OPTION_BUBBLE')}</h4>
                    <label className='mt-5 fw-bolder'>{t('BOT_OPTION_SETTINGS')}</label>
                    {renderColorPicker('TEXT_COLOR', 'optionFontColor')}
                    {renderColorPicker('BUBBLE_BACKGROUND_COLOR', 'optionBubbleColor')}
                    {renderColorPicker('OPTION_HOVER_TEXT_COLOR', 'onOptionHoverTextColor')}
                    {renderColorPicker('OPTION_HOVER_BACKGROUND_COLOR', 'onOptionHoverBgColor')}
                    <label className='mt-5 mb-2 fw-bolder'>{t('BOT_OPTION_LAYOUT_SETTINGS')}</label>
                    <SelectInputFeild
                      name='optionLayout'
                      classes='bg-light'
                      value={botCode?.appearance3?.optionLayout}
                      appendOption={{value: '', name: 'SIDE_BY_SIDE'}}
                      options={[{value: 'step', text: t('STEP')}]}
                      onChange={(name, value) => setBotAppearance({[name]: value})}
                      onBlur={onSave}
                    />
                  </div>
                )}
                {activeStep === 8 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-3'>{t('BOT_DESIGN_USER_BUBBLE')}</h4>
                    {renderColorPicker('TEXT_COLOR', 'userFontColor')}
                    {renderColorPicker('BACKGROUND_COLOR', 'userBubbleColor')}
                  </div>
                )}
                {activeStep === 9 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-5'>{t('BOT_FORM_SETTINGS')}</h4>
                    <label className='mb-0 fw-bolder'>{t('BOT_FORM_LABEL_SETTINGS')}</label>
                    {renderColorPicker('LABEL_TEXT_COLOR', 'botFormLabelColor')}
                    <label className='mt-5 fw-bolder'>{t('BOT_FORM_BUTTON_SETTINGS')}</label>
                    {renderColorPicker('TEXT_COLOR', 'botFormButtonTextColor')}
                    {renderColorPicker('BACKGROUND_COLOR', 'botFormButtonBgColor')}
                    {renderColorPicker('OPTION_HOVER_TEXT_COLOR', 'botFormButtonHoverTextColor')}
                    {renderColorPicker('OPTION_HOVER_BACKGROUND_COLOR', 'botFormButtonHoverBgColor')}
                  </div>
                )}
                 {activeStep === 10 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-5'>{t('BOT_CARD_SETTINGS')}</h4>
                    <label className='mb-0 fw-bolder'>{t('BOT_CARD_BUTTON_SETTINGS')}</label>
                    {renderColorPicker('TEXT_COLOR', 'cardBtnTextColor')}
                    {renderColorPicker('BACKGROUND_COLOR', 'cardBtnBgColor')}
                    {renderColorPicker('OPTION_HOVER_TEXT_COLOR', 'cardBtnHoverTextColor')}
                    {renderColorPicker('OPTION_HOVER_BACKGROUND_COLOR', 'cardBtnHoverBgColor')}
                  </div>
                )}
                {activeStep === 11 && (
                  <div className='sidebar-content'>
                    <h4 className='mb-0'>{t('CHAT_NOTIFICATION')}</h4>
                    <p className='text-muted fw-bold fs-7'>{t('CHAT_NOTIFICATION_SUBTITLE')}</p>
                    <>
                      <div className='mb-4'>
                        <label className='form-check form-check-custom form-check-solid form-switch m-1 mt-5'>
                          <TextInputFeild
                            type={'checkbox'}
                            name={'showMobileNotification'}
                            className='form-control form-control-solid'
                            checked={botCode.appearance3?.showMobileNotification === undefined ? true : botCode.appearance3?.showMobileNotification}
                            value={botCode.appearance3?.showMobileNotification === undefined ? true : botCode.appearance3?.showMobileNotification}
                            onChange={(name) => onCheckBoxSave({[name]: !botCode.appearance3?.showMobileNotification})}
                          />
                          <label className='fs-5 fw-semibold'>{t('SWITCH_MOBILE_MODE')}</label>
                        </label>
                      </div>
                      <div className='mb-4'>
                        <label className='mb-0 fw-bolder'>{t('CHAT_NOTIFICATION_COUNT')}</label>
                        <p className='text-muted fw-bold fs-7'>
                          {t('CHAT_NOTIFICATION_SUBTITLE_COUNT')}
                        </p>
                        <select
                          className='form-select form-select-solid form-select-lg'
                          value={botCode.appearance3.preNotification?.count}
                          onChange={(e) => onChangeNotificationCount(e)}
                          onBlur={onSave}
                          required
                        >
                          <option value=''>{t('SELECT')}</option>
                          {[...Array(5)].map((u, index) => (
                            <option key={index.toString()} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='mb-4'>
                        <label className='mb-0 fw-bolder'>{t('CHAT_NOTIFICATION_TITLE')}</label>
                        <p className='text-muted fw-bold fs-7 mb-2'>
                          {t('CHAT_NOTIFICATION_SUBTITLE_TITLE')}
                        </p>
                        <TextInputFeild
                          name='title'
                          value={botCode.appearance3.preNotification?.title}
                          onBlur={onSave}
                          onChange={(name, value) => {
                            const {preNotification} = botCode.appearance3
                            onNotificationFieldChange({...preNotification, [name]: value})
                          }}
                        />
                      </div>
                      <div className='mb-4'>
                        {renderColorPicker('TEXT_COLOR', 'chatNotificationTextColor')}
                        {renderColorPicker('BACKGROUND_COLOR', 'chatNotificationBgColor')}
                      </div>
                    </>
                    <div className='accordion my-2' id='kt_accordion_1'>
                      {botCode.appearance3?.preNotification?.count &&
                        [...Array(Number(botCode.appearance3?.preNotification?.count))].map(
                          (u, index) => {
                            const {notification} = botCode.appearance3?.preNotification
                            return (
                              <div className='accordion-item my-2' key={`acd_${index}`}>
                                <div
                                  className='accordion-header'
                                  id={`kt_accordion_1_header_${index}`}
                                >
                                  <button
                                    className='form-control form-control-solid accordion-button fs-4 fw-semibold collapsed'
                                    type='button'
                                    data-bs-toggle='collapse'
                                    data-bs-target={`#kt_accordion_1_body_${index}`}
                                    aria-expanded='false'
                                    aria-controls={`kt_accordion_1_body_${index}`}
                                  >
                                    {t('CHAT_NOTIFICATION_COUNT')}-{index + 1}
                                  </button>
                                </div>
                                <div
                                  id={`kt_accordion_1_body_${index}`}
                                  className='accordion-collapse collapse'
                                  aria-labelledby={`kt_accordion_1_header_${index}`}
                                  data-bs-parent='#kt_accordion_1'
                                >
                                  <div className='accordion-body'>
                                    <div className='mb-4'>
                                      <label className='mb-0 fw-bolder'>
                                        {t('CHAT_NOTIFICATION_DELAY')}
                                      </label>
                                      <p className='text-muted fw-bold fs-7 mb-2'>
                                        {t('CHAT_NOTIFICATION_SUBTITLE_DELAY')}
                                      </p>
                                      <TextInputFeild
                                        name='delay'
                                        defaultValue={
                                          botCode.appearance3?.preNotification?.delay || 30
                                        }
                                        type={'number'}
                                        value={notification[index]?.delay}
                                        required={true}
                                        onBlur={(e) => {
                                          const {value} = e.target
                                          setGetEditorContent({
                                            ...notification[index],
                                            delay: value < 0 || !value ? '30' : value,
                                            index,
                                          })
                                          onSave();
                                        }}
                                        onChange={(name, value) => {
                                          setGetEditorContent({
                                            ...notification[index],
                                            [name]: value,
                                            index,
                                          })
                                        }}
                                      />
                                    </div>
                                    <div className='mb-4'>
                                      <form
                                        className={`notification-validation ${
                                          !notification[index]?.text ? 'was-validated' : ''
                                        }`}
                                      >
                                        <label className='mb-2 fw-bolder'>
                                          {t('CHAT_NOTIFICATION_TEXT')}
                                        </label>
                                        <Editor
                                          tinymceScriptSrc={
                                            process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                                          }
                                          initialValue={notification[index]?.text}
                                          icons='small'
                                          init={{
                                            min_height: 200,
                                            menubar: false,
                                            branding: false, // hide only brand logo
                                            setup: function (editor) {
                                              editor.on('blur', function (event) {
                                                setGetEditorContent({
                                                  ...notification[index],
                                                  text: editor.getContent(),
                                                  index,
                                                })
                                              })
                                            },
                                            toolbar: [
                                              'blocks | undo redo | bold italic | lists charmap |link image | bullist numlist | outdent indent | blockquote subscript superscript | alignleft aligncenter alignright alignjustify | preview  code',
                                            ],
                                            plugins:
                                              'link image lists charmap preview code autoresize',
                                            content_style:
                                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                          }}
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {botSteps.length ? (
          <div className='col-3'>
            <ThemeProvider theme={themes['dark']}>
              <ChatBot
                fontSizeMultiplier={fontSizeMultiplier}
                botAvatar={avatar}
                smallAvatarColor={themes['dark'].smallAvatarColor}
                avatarColor={themes['dark'].avatarColor}
                hideBotAvatar={botCode.appearance.hideBotAvatar}
                hideHeader={botCode.appearance.hideHeader}
                showTimestamp={botCode.appearance.showTimestamp}
                headerTitle={botCode.appearance.headerTitle}
                steps={botSteps}
                height={botCode.appearance.height}
                width={botCode.appearance.width}
                isPreview={true}
              ></ChatBot>
            </ThemeProvider>
          </div>
        ) : (
          ''
        )} */}
      </div>
    </div>
  )
}

export default BotAppearance3
