import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'

import {I18nProvider} from '../_metronic/i18n/i18nProvider'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <I18nProvider>
          <LayoutProvider>
            <Routes />
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
