import { FC } from 'react'
import { useIntl } from 'react-intl'
import { isWorkspaceGroweoAdmin } from '../../../utils/utils'
import { PageTitle, PageLink } from '../../../_metronic/layout/core'

const ChangeLogBreadCrumb: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})

  const ChangeLogBreadCrumb: Array<PageLink> = [
    {
      title: t('CHANGE_LOG', { count: 1 }),
      path: '/changelog',
      isSeparator: false,
      isActive: false,
      isShow: isWorkspaceGroweoAdmin()
    },
  ]
  const filteredData = ChangeLogBreadCrumb.filter((b) => b.isShow)

  return <PageTitle breadcrumbs={filteredData}>{`${t('GROWTH')} ${t('ENGINE')}`}</PageTitle>

}

export default ChangeLogBreadCrumb