import { KTSVG } from '../../../helpers'

const ChangeLogFlyout = (props: any) => {
    return (
        <div id="kt_app_layout_builder" className="bg-body drawer drawer-end" data-kt-drawer="true" data-kt-drawer-name="app-settings" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'lg': '50%'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_layout_builder_toggle" data-kt-drawer-close="#kt_app_layout_builder_close" style={{ width: "50%" }}>
            <div className="card border-0 shadow-none rounded-0 w-100">
                <div className="card-header bgi-position-y-bottom bgi-position-x-end bgi-size-cover bgi-no-repeat rounded-0 border-0 py-4" id="kt_app_layout_builder_header" style={{ background: "#171452", height: '10%' }}>

                    <h3 className="card-title fs-3 fw-bold text-white flex-column m-0">
                        Change Log Docs

                        <small className="text-white opacity-50 fs-7 fw-semibold pt-1">
                            Get your change log as Docs
                        </small>
                    </h3>
                    <div className="card-toolbar">
                        <button type="button" className="btn btn-sm btn-icon btn-color-white p-0 w-20px h-20px rounded-1" id="kt_app_layout_builder_close">
                            <KTSVG
                                className='svg-icon-2x'
                                path='/media/icons/custom/close_icon.svg'
                            />
                        </button>
                    </div>
                </div>
                <div style={{ height: "90%" }}>
                    <iframe className='w-100 h-100' src={props.pdfLink}></iframe>
                </div>
            </div>
        </div>
    )
}

export default ChangeLogFlyout