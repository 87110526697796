import axios from 'axios'
import { handleError, qs } from '../utils/utils'

let lastSaved = 0;
let lastSavedInS3 = 0;
const AUTO_SAVE_INTERVAL = 1000*60*1
const AUTO_SAVE_INTERVAL_S3 = 1000*60*5

export const createModule = async (data: any) => {
  try {
    const response = await axios.post(`/api/module`, data)
    return response.data?.data?._id

  } catch ({ response }) {
    handleError(response)
  }
}

export const autoSaveModule =  (id: string, module: any) => {
  try {
    const now = Date.now();
    const lastSavedDiff = now-lastSaved;
    const lastSavedS3Diff = now-lastSavedInS3;  

    const data:any = {}
    if( module.type=== 'form') data.code = module.code
    if (module?.name) data.name = module.name 
    if (module?.settings) data.settings = module.settings
    if (module?.documents && (module?.documents.length > 0)) data.documents = module.documents
    if (module?.emailTemplate) data.emailTemplate = module.emailTemplate
    if (module?.appearence) data.appearence = module.appearence
    if (module?.botCode) data.botCode = module.botCode
    if (module?.publishEvent) data.publishEvent = module.publishEvent

    if(lastSavedDiff > AUTO_SAVE_INTERVAL) {
      axios.put(`/api/module/${id}`, data)
      lastSaved=now;
    }

    if(lastSavedS3Diff > AUTO_SAVE_INTERVAL_S3) {
      axios.post(`/api/module/${id}/store`, data)
      lastSavedInS3=now;
    }

    console.log(` Autosaved ${lastSaved === now}`)
    return lastSaved===now // return if saved to backend or not
  } catch (error) {
    console.log(error)
  }

}


export const updateModule = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/module/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const deleteModule = async (id: string) => {
  try {
    const response = await axios.delete(`/api/module/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModule = async (params: any) => {
  try {
    const response = await axios.get(`/api/module?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleDetails = async (params: any) => {
  try {
    const response = await axios.get(`/api/module_details?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleById = async (id: string) => {
  try {
    const response = await axios.get(`/api/module/${id}`)
    return response.data?.data
  } catch ({ response }) {
    window.history.back()
    handleError(response)
  }
}

export const updateImage = async (id: string, data: any) => {
  try {
    const response = await axios.post(`/api/module/upload/${id}`, data)
    return response.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const uploadCsvFile = async (id: string, data: any) => {
  try {
    const response = await axios.post(`/api/module/list/csv/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleForPreview = async (workspaceId: string, id: string, params: any) => {
  try {
    const response = await axios.get(`/api/${workspaceId}/${id}?${qs(params)}`,)
    return response?.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleStats = async (id: string) => {
  try {
    const response = await axios.get(`/api/module/${id}/stats`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleCount = async () => {
  try {
    const response = await axios.get(`/api/module/count`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

