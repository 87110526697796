import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import SelectInputFeild from '../../shared/SelectInputFeild'
import TextInputFeild from '../../shared/TextInputFeild'
import { LANGUAGES } from '../../../utils/utils'

type ProfileProps = {
  user: any
  onFieldSave: any
  onUserSave: any
}

const ProfileModal: FC<ProfileProps> = ({user, onFieldSave, onUserSave}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const renderLabel = (label: string, required: boolean) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
      <span className={`${required && 'required'}`}>{label}</span>
    </label>
  )

  return (
    <div className='modal fade' tabIndex={-1} id='gw_profile_modal'>
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>
              {t('EDIT_PROFILE')}
            </h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label={t('CLOSE')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            <div className='fv-row mb-5'>
              {renderLabel(`${t('NAME')}`, true)}
              <TextInputFeild
                name='name'
                value={user.name}
                onChange={onFieldSave}
                onBlur={onUserSave}
              />
            </div>
            <div className='fv-row mb-5'>
              {renderLabel(`${t('LANGUAGE')}`, true)}
              <SelectInputFeild
                name='language'
                value={user.language}
                onChange={onFieldSave}
                onBlur={onUserSave}
                options={LANGUAGES}
              />
            </div>
            <div className='fv-row'>
              <div className='text-center '>
                <button data-bs-dismiss='modal' className='c-button c-button--type-solid'>
                  <span className='indicator-label'>
                    {t('SUBMIT')}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileModal
