/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import BotsDetailContainer from '../bots/BotsDetailContainer'
import FileCreate from '../files/fileCreate'
import FormCreate from '../formBuilder/formCreate'
import SmartBlockCreate from '../smartBlock/smartBlockCreate'
import smartPopupCreate from '../smartPopup/smartPopupCreate'
import List from './List'
import SmartBarCreate from '../smartBar/smartBarCreate'
import SmartFlowCreate from '../smartFlow/smartFlowCreate'
import '../smartFlow/css/smartflow.css'

const AppIndex: FC = () => {
  return (
    <Switch>
      <Redirect from='/gapps/bots' to='/gapps' exact /> 
      <Redirect from='/gapps/forms' to='/gapps' exact />
      <Redirect from='/gapps/smartblock' to='/gapps' exact />
      <Redirect from='/gapps/smartpopup' to='/gapps' exact />
      <Redirect from='/gapps/smartflows' to='/gapps' exact />
      <Redirect from='/gapps/smartbars' to='/gapps' exact />
      <Redirect from='/gapps/files' to='/gapps' exact /> 
      <Route path='/gapps' component={List} exact />
      <Route path='/gapps/forms/:moduleId/:step' component={FormCreate} exact />
      <Route path='/gapps/smartblock/:moduleId/:step' component={SmartBlockCreate} exact />
      <Route path='/gapps/smartpopup/:moduleId/:step' component={smartPopupCreate} exact />
      <Route path='/gapps/smartbars/:moduleId/:step' component={SmartBarCreate} exact />
      <Route path='/gapps/smartflows/:moduleId/:step' component={SmartFlowCreate} exact />
      <Route path='/gapps/bots/:moduleId/:step' component={BotsDetailContainer} exact />
      <Route path='/gapps/files/:moduleId/:step' component={FileCreate} exact />
    </Switch>
  )
}

export default AppIndex
