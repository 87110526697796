import axios from 'axios'
import { handleError, qs } from '../utils/utils'

export const createModuleLibrary = async (data: any) => {
  try {
    const response = await axios.post(`/api/moduleLibrary`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleLibrary = async (params: any) => {
  try {
    const response = await axios.get(`/api/moduleLibrary?${qs(params)}`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const getModuleLibraryById = async (id: string) => {
  try {
    const response = await axios.get(`/api/moduleLibrary/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const updateModuleLibrary = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/moduleLibrary/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const deleteModuleLibrary = async (id: string) => {
  try {
    const response = await axios.delete(`/api/moduleLibrary/${id}`)
    return response.data?.data?._id
  } catch ({ response }) {
    handleError(response)
  }
}
