import React from 'react'
import ReactDOM from 'react-dom'

// Apps
import {App} from './app/App'
import 'bootstrap'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './assets/styles/main.scss'
import './modules/buttons/assets/styles/buttons.scss'
import './modules/growth-modules/assets/styles/growth-modules.scss'
import './interceptor'

ReactDOM.render(<App />, document.getElementById('root'))
