/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import SlideDesignTab from '../Slides/SlideDesignTab'
import SlideTextTab from '../Slides/SlideTextTab'

export const SettingsFlyout = ({
  selectedSlide,
  setSelectedSlide,
  onSave,
  setModuleCode,
  activeTab,
  setActiveTab,
  moduleCode,
  setFlag,
  toggleEditor,
  slides
}) => {
  const intl = useIntl()
  const t = (id, values) => intl.formatMessage({id}, values || {})

  const onAppearanceSave = () => {
    setModuleCode({...moduleCode, appearance: {...moduleCode.appearance}})
  }

  const onAppearanceBlur = () => setFlag(true)

  const renderTab = (label, index) => (
    <li className='nav-item' onClick={() => setActiveTab(index)}>
      <a
        className={`nav-link text-active-primary m-0 me-6 fs-4 cursor-pointer ${
          activeTab === index ? 'active' : ''
        }`}
      >
        {label}
      </a>
    </li>
  )
  
  return (
    <>
      <div className='card' style={{width: '400px', margin: '0 10px', zIndex: 100}}>
        <div className='card-header'>
          <div className='w-100 d-flex align-items-center'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder flex-nowrap'>
              {selectedSlide.type === 'text' && renderTab(t('TEXT'), 0)}
              {selectedSlide.type === 'options' && renderTab(t('OPTION', {count: 1}), 0)}
              {selectedSlide.type === 'content' && renderTab(t('CHAT_FORM'), 0)}
              {selectedSlide.type === 'end' && renderTab(t('END'), 0)}
              {renderTab(t('DESIGN', {count: 0}), 1)}
            </ul>
          </div>
        </div>
        <div className='card-body pt-3 overflow-auto'>
          {toggleEditor && activeTab === 0 && (
            <SlideTextTab
              selectedSlide={selectedSlide}
              setSelectedSlide={setSelectedSlide}
              onSave={onSave}
              moduleCode={moduleCode}
            />
          )}
          {activeTab === 1 && (
            <SlideDesignTab
              moduleCode={moduleCode}
              onAppearanceSave={onAppearanceSave}
              onAppearanceBlur={onAppearanceBlur}
            />
          )}
        </div>
      </div>
    </>
  )
}
