import {FC, useEffect} from 'react'
import {Redirect, Switch, Route,useHistory, useLocation} from 'react-router-dom'
import { observer } from 'mobx-react'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {AdminRoutes} from './AdminRoutes'

import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {AuthPage} from '../modules/auth/AuthPage'
import {getGlobalItem} from '../../utils/utils'
import {useStore} from '../../store'
import { FallbackView } from '../../_metronic/partials'
import View from '../modules/preview/View'

const LoaderComponent = observer(() => {
  const {listLoading} = useStore()
  
  return <>{listLoading && <FallbackView />}</>
})

const Routes: FC = () => {

  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      history.replace(location.hash.replace('#', ''))
    }
  }, [history, location.hash, location.pathname])

  const user = getGlobalItem('user')
  const {
    authStore: {getUser},
  } = useStore()
  useEffect(() => {
    getUser()
  }, [getUser])

  return (
    <>
      <Switch>
        <Route path='/preview/:type/:moduleId/:workspaceId' component={View} exact />
        {!user ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />

        {!user ? (
          <Redirect to='/auth/login' />
        ) : (
          <>
            <LoaderComponent />
            <MasterLayout user={user}>
              {(['groweo-admin', 'partner-admin', 'groweo-subadmin'].includes(user?.role)) ? <AdminRoutes /> : <PrivateRoutes />}
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
