/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {SubscriptionToolbar} from '../../../../_metronic/layout/components/toolbar/SubscriptionToolbar'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {useHistory} from 'react-router-dom'
import {getSubscriptionPlan, deleteSubscriptionPlan} from '../../../../service/subscription.service'
import SubscriptionModal from './SubscriptionModal'
import ConfirmModal from '../../common/modal/ConfirmModal'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'

const SubscriptionLists: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const history = useHistory()
  const [subscriptionLists, setSubscriptionLists] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [subscriptionId, setSubscriptionId] = useState('')
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const data = await getSubscriptionPlan()
    if (data) setSubscriptionLists(data)
  }

  const onEdit = (link?: any) => history.push(link)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [subscriptionLists])

  const onModalOpen = (id: string, type: string) => {
    setShowConfirmModal(true)
    setModalType(type)
    setSubscriptionId(id)
  }
  const onDelete = () => {
    deleteSubscriptionPlan(subscriptionId).then((data) => {
      if(data?._id) fetchData()
    })
  }
  const onConfirm = async () => {
    if (modalType === 'delete') onDelete()
  }
  return (
    <>
      <SubscriptionToolbar />
      <div className='row g-5 g-xl-8'>
        {subscriptionLists?.map((l: any, index: any) => {
          return (
            <div key={`subscription${index}`} className='col-xl-4'>
              <div className='card card-xl-stretch mb-xl-8'>
                <div className='card-header flex-nowrap p-0'>
                  <div className='card-title m-0'>
                    <div className='symbol'></div>
                  </div>

                  <div className='card-toolbar m-3'>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen024.svg'
                        className='svg-icon-3 svg-icon-primary'
                      />
                    </button>

                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                          {t('QUICK_ACTIONS')}
                        </div>
                      </div>
                      <div className='separator mb-3 opacity-75'></div>

                      <div className='menu-item px-3'>
                        <a
                          className='menu-link px-3'
                          onClick={() => onEdit(`/subscriptions/${l._id}`)}
                        >
                          {t('EDIT')}
                        </a>
                      </div>
                      <div className='menu-item px-3'>
                        <a
                          className='menu-link px-3'
                          onClick={() => {
                            onModalOpen(l._id, 'delete')
                          }}
                        >
                          {t('DELETE')}
                        </a>
                      </div>
                      <div className='separator mt-3 opacity-75'></div>
                    </div>
                  </div>
                </div>
                <div className='card-body d-flex align-items-center pt-0 pb-0'>
                  <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
                    <a
                      onClick={() => onEdit(`/subscriptions/${l._id}`)}
                      className='fw-bolder text-dark fs-4 mb-2 text-hover-primary cursor-pointer'
                    >
                      {l.name}
                    </a>
                    <span className='fw-bold text-muted fs-5'>{l.description}</span>
                  </div>
                  <img
                    src='/media/svg/avatars/029-boy-11.svg'
                    alt=''
                    className='align-self-end h-100px'
                  />
                </div>
              </div>
            </div>
          )
        })}
        {showConfirmModal && (
          <ConfirmModal
            label={modalType}
            show={showConfirmModal}
            close={setShowConfirmModal}
            onConfirm={onConfirm}
          />
        )}
      </div>

      <SubscriptionModal setSubscriptionLists={setSubscriptionLists} />
    </>
  )
}

export default observer(SubscriptionLists)
