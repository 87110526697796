/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import { useHistory, useParams } from 'react-router-dom'
import { loginWorkspace } from '../../../../service/workspace.service'
import { getGlobalItem, setGlobalItem } from '../../../../utils/utils'

interface RouteParams {
  workspaceId: string,
}

type Props = {
  workspace: any
}

const WorkspaceDetailsToolbar: FC<Props> = ({workspace}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const { classes } = useLayout()
  const history = useHistory()
  const { workspaceId } = useParams<RouteParams>();

  const onLogin = async () => {
    if (!workspaceId) return
    const userData = await loginWorkspace(workspaceId)
    const userSessionData = getGlobalItem('user')
    userData.contentAdminRole = userSessionData['role']
    setGlobalItem('user', userData)
    document.location.reload()
  }

  return (
    <div className='toolbar toolbar-pg-bg' style={{ position: 'inherit', border: 'none' }} id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')} style={{ padding: '0px' }}
      >
        <div>
          <SectionHeading title={`${t('CUSTOMER', { count: 0 })} ${t('DETAILS_HEADER')}`} subTitle={t('CUSTOMER_DETAILS_SUBTITLE', { count: 1 })} />
        </div>
        <div className='d-flex align-items-center py-1'>
          <div className='me-2'>
            <a
              id='gw_workspace_edit_modal_button'
              className='btn btn-flex btn-light btn-success fw-bolder text-uppercase'
              data-bs-toggle="modal"
              data-bs-target="#gw_workspace_modal"
            >
              <KTSVG
                path='/media/icons/duotune/art/art005.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1 '
              />
              {t('EDIT')}
            </a>
          </div>
          <div className='me-2'>
            {workspace?.domain !== 'engine.groweo.com' && (
              <a
                className='btn btn-flex btn-light btn-success fw-bolder text-uppercase'
                onClick={onLogin}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr076.svg'
                  className='svg-icon-5 svg-icon-gray-500 me-1 '
                />
                {t('LOGIN_BTN')}
              </a>
            )}
          </div>
          <div className='me-2'>
            <a
              className='btn btn-primary text-uppercase'
              data-bs-toggle='modal'
              data-bs-target={`#gw_create_user_modal`}
              id='gw_user_modal_button'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-hx' />
              {t('ADD')} {t('USER', { count: 0 })}
            </a>
          </div>
          <div className='me-2'>
            <a className='btn btn-primary text-uppercase' onClick={() => history.push('/workspaces')}>
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon svg-icon-2' />
              {t('BACK')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export { WorkspaceDetailsToolbar }
