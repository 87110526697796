import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory, useParams} from 'react-router-dom'
import BotTemplate from './BotTemplate'
import FormTemplate from './FormTemplate'

const TemplateContainer: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [tab, setTab] = useState(0)
  const history = useHistory()
  const params: any = useParams()

  useEffect(() => {
    if (params?.module === 'bots') setTab(0)
    if (params?.module === 'forms') setTab(1)
  }, [params])

  const onTabChange = (index: number, module: string) => {
    setTab(index)
    history.push(`/templates/${module}`)
  }

  return (
    <>
      <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
        <div className='d-flex overflow-auto'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <p
                onClick={() => onTabChange(0, 'bots')}
                className={
                  `nav-link text-active-primary me-6 cursor-pointer ` + (tab === 0 && 'active')
                }
              >
                {t('BOT')} {t('TEMPLATE', {count: 1})}
              </p>
            </li>
            <li className='nav-item'>
              <p
                onClick={() => onTabChange(1, 'forms')}
                className={
                  `nav-link text-active-primary me-6 cursor-pointer ` + (tab === 1 && 'active')
                }
              >
                {t('FORM')} {t('TEMPLATE', {count: 1})}
              </p>
            </li>
          </ul>
        </div>
      </div>
      {tab === 0 ? <BotTemplate /> : <FormTemplate />}
    </>
  )
}

export default TemplateContainer
