import axios from 'axios'
import { handleError, qs } from '../utils/utils'


export const createTemplate = async (data: any) => {
  try {
    const response = await axios.post(`/api/template`, data)
    return response.data?.data?._id

  } catch ({ response }) {
    handleError(response)
  }
}

export const updateTemplate = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/template/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const deleteTemplate = async (id: string) => {
  try {
    const response = await axios.delete(`/api/template/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getTemplates = async (params: any) => {
  try {
    const response = await axios.get(`/api/template?${qs(params)}`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const getTemplateById = async (id: string) => {
  try {
    const response = await axios.get(`/api/template/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}
