/* eslint-disable jsx-a11y/anchor-is-valid */
import {observer} from 'mobx-react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useStore} from '../../../../../store'
import {
  getLimitConfig,
  isWorkspaceGroweoAdmin,
  isWorkspaceReadonly,
} from '../../../../../utils/utils'

type AppActionsDropdownProps = {
  edit: any
  archive: any
  duplicate: any
  published: any
  unpublished?: any
  saveAsLibrary: any
}

const AppActionsDropdown: FC<AppActionsDropdownProps> = ({
  edit,
  archive,
  duplicate,
  published,
  unpublished,
  saveAsLibrary,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {authStore} = useStore()
  const {
    moduleAccessLimit: {publishCount, totalCount},
  } = authStore

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>{t('QUICK_ACTIONS')}</div>
      </div>
      <div className='separator mb-3 opacity-75'></div>

      {isWorkspaceReadonly() ? (
        <div className='menu-item px-3'>
          <a onClick={() => edit()} className='menu-link px-3'>
            {t('VIEW', {count: 0})}
          </a>
        </div>
      ) : (
        <>
          {isWorkspaceGroweoAdmin() && (
            <div className='menu-item px-3'>
              <a
                onClick={() => saveAsLibrary()}
                className='menu-link px-3'
                data-bs-toggle='modal'
                data-bs-target='#gw_library_modal'
              >
                {t('SAVE_AS_LIBRARY', {count:0})}
              </a>
            </div>
          )}
          <div className='menu-item px-3'>
            <a
              onClick={published}
              className={`menu-link px-3 ${
                getLimitConfig('publish_app', publishCount) ? 'disabled' : ''
              }`}
              title={`${getLimitConfig('publish_app', publishCount) ? t('PUBLISH_TITLE') : ''}`}
              data-bs-toggle='modal'
              data-bs-target='#kt_publish_event_modal'
            >
              {t('PUBLISH')}
            </a>
          </div>
          <div className='menu-item px-3'>
            <a onClick={() => unpublished()} className='menu-link px-3'>
              {t('UNPUBLISH')}
            </a>
          </div>
          <div className='menu-item px-3'>
            <a
              onClick={() => duplicate()}
              className={`menu-link px-3 ${
                getLimitConfig('total_app', totalCount) ? 'disabled' : ''
              }`}
              data-bs-toggle='modal'
              data-bs-target={`#gw_modal_duplicate_name`}
              title={`${getLimitConfig('total_app', totalCount) ? t('PUBLISH_TITLE') : ''}`}
            >
              {t('DUPLICATE')}
            </a>
          </div>
          <div className='menu-item px-3'>
            <a onClick={() => edit()} className='menu-link px-3'>
              {t('EDIT')}
            </a>
          </div>
          <div className='menu-item px-3'>
            <a onClick={() => archive()} className='menu-link px-3'>
              {t('DELETE')}
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title={`${t('ARCHIVE_BOT')}`}
              ></i>
            </a>
          </div>
        </>
      )}

      <div className='separator mt-3 opacity-75'></div>
    </div>
  )
}

export default observer(AppActionsDropdown)
