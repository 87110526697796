import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  setOperationType?: any
  operationType?: any
  copyTreeElements?: any
  removeTreeElements?: any
}

const BotCopyModal: FC<Props> = ({
  setOperationType,
  operationType,
  copyTreeElements,
  removeTreeElements,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const renderType = (value: string) => (
    <input
      className='form-check-input me-4'
      type='checkbox'
      name='type'
      disabled={operationType.disable}
      value={value}
      checked={value === operationType?.type}
      onChange={(e) => setOperationType({...operationType, type: e.target.checked ? "multiple" : "singular"})}
    />
  )

  return (
    <>
      <div
        className='modal fade'
        tabIndex={-1}
        id='kt_bot_operation_modal'
        style={{background: 'rgba(0,0,0,.4)'}}
      >
        <div className='modal-dialog modal-dialog-centered mw-350px'>
          <div className='modal-content'>
            <div className='modal-header py-4 px-7'>
              <h4 className='modal-title'>{t(operationType?.operation === "copy" ? "COPY" : "DELETE")}</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                id='gw_close_operation_modal'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <form
                id='kt_copynode_form'
                onSubmit={(e) => {
                  e.preventDefault()
                  if (!operationType.type) return
                  if (operationType.operation === 'copy') copyTreeElements()
                  else removeTreeElements()
                }}
              >
                <div className='fv-row mb-5'>
                  <div className='mb-10 mt-5'>
                    <div className='d-flex justify-content-center'>
                      <label className='form-check form-check-sm form-check-custom form-check-solid'>
                        {renderType('multiple')}
                        <span className='fs-5'> {t('INCLUDE_CHILD_NODE')}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='fv-row text-center'>
                  <button type='submit' className={`btn btn-primary`} data-bs-dismiss='modal'>
                    <span className='indicator-label text-white'>{t(operationType?.operation === "copy" ? "COPY" : "DELETE")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BotCopyModal
