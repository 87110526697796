import {FC, useEffect, useState, useRef} from 'react'
import {useIntl} from 'react-intl'
import Tags from '@yaireo/tagify/dist/react.tagify'
import '@yaireo/tagify/dist/tagify.css'
import {LANGUAGES, getGlobalItem} from '../../../../../utils/utils'
import {codeForSmartFlow} from '../../../../../utils/code.utils'
import TextModal from '../../../common/modal/TextModal'
import SelectInputFeild from '../../../../shared/SelectInputFeild'
import {useSmartFlowData} from '../StepperContext'
import { getPopupList, getShowingList } from '../../../../../utils/module.utils'
import TextInputFeild from '../../../../shared/TextInputFeild'

type Step3Props = {}

const Step3: FC<Step3Props> = () => {
  const intl = useIntl()
  const {smartFlow, setSmartFlow}: any = useSmartFlowData()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [active, setActive] = useState<Number>(0)
  const [moduleId, setModuleId] = useState('')
  const workspaceId = getGlobalItem('user')?.workspaceId
  const [selected, setSelected]: any = useState({})
  const [showSwalModal, setShowSwalModal] = useState(false)
  const [language, setLanguage]: any = useState('fi')
  const [includeInpage, setIncludeInPage]: any = useState()
  const [excludeInpage, setExcludeInPage]: any = useState()
  const tagifyIncludeInpageRef: any = useRef()
  const tagifyExcludeInpageRef: any = useRef()

  useEffect(() => {
    if (smartFlow?._id) setModuleId(smartFlow?._id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartFlow?._id])

  useEffect(() => {
    onAppearanceLoad(smartFlow?.moduleCode?.appearance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    smartFlow?.moduleCode?.appearance?.includeInpage,
    smartFlow?.moduleCode?.appearance?.excludeInpage,
  ])

  useEffect(() => {
    const appearance = smartFlow?.moduleCode?.appearance
    if (appearance && !appearance.includeInpage) onAppearanceLoad(smartFlow?.moduleCode?.appearance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartFlow?.moduleCode?.appearance])

  useEffect(() => {
    if (includeInpage) onInputChange('includeInpage', includeInpage)
    // eslint-disable-next-line
  }, [includeInpage])

  useEffect(() => {
    if (excludeInpage) onInputChange('excludeInpage', excludeInpage)
    // eslint-disable-next-line
  }, [excludeInpage])

  const onAppearanceLoad = (appearance: any) => {
    if (!appearance) return
    const {delay, time_after_submit, time_before_submit, popup, includeInpage, excludeInpage, repeatedType} = appearance
    if (!appearance.hasOwnProperty('includeInpage')) setIncludeInPage(["/*"])
    setSelected({popup: popup || 'click', delay, time_after_submit, time_before_submit, includeInpage, excludeInpage, repeatedType})
  }

  const onInputChange = (name: string, value: any) => {
    const appearance = {...selected, [name]: value}
    smartFlow.moduleCode.appearance = {...smartFlow.moduleCode.appearance, ...appearance}
    if (value === 'click' && appearance?.repeatedType) delete  smartFlow.moduleCode.appearance.repeatedType
    setSelected(appearance)
  }

  const onBlur = () => setSmartFlow(smartFlow)

  const onCopy = async (id: string) => {
    if (navigator.clipboard.writeText) {
      const el: any = document.getElementById(id)
      await navigator.clipboard.writeText(el.value)
      setShowSwalModal(true)
    }
  }

  const onTagifyInputChange = (name: string, e: any) => {
    let data: any = []
    const arr = e.detail.value ? JSON.parse(e.detail.value) : []
    arr.forEach((item: any) => data.push(item.value))
    name === 'includeInpage' ? setIncludeInPage(data) : setExcludeInPage(data)
  }

  const getScriptCode = () => {
    return codeForSmartFlow(moduleId, workspaceId, false, active === 0, language)
  }

  const renderCodeInput = (id: string) => (
    <>
      <label className='form-label '>{t('INSTALLATION_CODE')}</label>
      <div>
        <textarea
          style={{
            height: '250px',
            resize: 'none',
            whiteSpace: 'pre',
            overflowWrap: 'normal',
            overflowX: 'scroll',
            border: 0,
          }}
          spellCheck='false'
          value={getScriptCode()}
          id={id}
          className='form-control'
          data-kt-autosize='true'
          readOnly
        ></textarea>
        <button onClick={() => onCopy(id)} className='btn btn-sm btn-primary mt-4'>
          {t('COPY_CODE')}
        </button>
      </div>
    </>
  )

  const renderTagifyInput = (name: string) => {
    if (name === 'includeInpage' && tagifyIncludeInpageRef && tagifyIncludeInpageRef.current) {
      tagifyIncludeInpageRef.current.settings.blacklist = selected.excludeInpage || []
    } else if (
      name === 'excludeInpage' &&
      tagifyExcludeInpageRef &&
      tagifyExcludeInpageRef.current
    ) {
      tagifyExcludeInpageRef.current.settings.blacklist = selected.includeInpage || []
    }

    return (
      <Tags
        className='form-control form-control-solid'
        tagifyRef={name === 'includeInpage' ? tagifyIncludeInpageRef : tagifyExcludeInpageRef}
        settings={{
          trim: true,
          pasteAsTags: true,
          pattern: /[/]/,
        }}
        value={selected[name]}
        onChange={(e) => onTagifyInputChange(name, e)}
      />
    )
  }

  const renderLabel = (label: string) => (
    <label className='d-flex align-items-center fs-5 fw-bolder mb-2'>{label}</label>
  )


  return (
    <div className='container'>
      {showSwalModal ? (
        <TextModal
          message={t('COPIED_MSG')}
          close={() => {
            setShowSwalModal(false)
          }}
        ></TextModal>
      ) : (
        ''
      )}
      <h3 className='card-title align-items-start flex-column'>
        <span className='fw-bolder mb-2 text-dark'>{t('INSTALL')}</span>
        <p className='text-muted fw-bold fs-7'>{t('INSTALL_SUB_TITLE')}</p>
      </h3>
      <div className='rounded border d-flex flex-column'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
          <li className='nav-item' onClick={() => setActive(0)}>
            <a
              className={`nav-link ${active === 0 ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_1'
            >
              {t('IN_PAGE')}
            </a>
          </li>
          <li className='nav-item' onClick={() => setActive(1)}>
            <a
              className={`nav-link ${active === 1 ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#kt_tab_pane_2'
            >
              {t('POPUP')}
            </a>
          </li>
        </ul>
        <div className='tab-content row' id='myTabContent'>
          <div className='card p-8 mb-2 col-4'>
            <h4>{active === 1 ? t('POPUP_SETTINGS') : t('INPAGE_SETTINGS')}</h4>
            <SelectInputFeild
              name='language'
              value={language}
              options={LANGUAGES}
              onChange={(_name: string, value: any) => setLanguage(value)}
            />
            <br />
            <label className='fs-7 fw-bolder'>{t('INCLUDE_INPAGE')}</label>
            {renderTagifyInput('includeInpage')}
            <br />
            <label className='fs-7 fw-bolder'>{t('EXCLUDE_INPAGE')}</label>
            {renderTagifyInput('excludeInpage')}
            <br />
            {active === 1 && (
              <>
                <SelectInputFeild
                  name='popup'
                  value={selected.popup}
                  options={getPopupList(t)}
                  onChange={(name: string, value: any) => onInputChange(name, value)}
                  onBlur={onBlur}
                />
                <hr />
                {selected.popup === 'click' && (
                  <>
                    {renderLabel(t('BUTTON_ID'))}
                    <textarea
                      style={{
                        height: '115px',
                        resize: 'none',
                        whiteSpace: 'pre-wrap',
                        border: 0,
                      }}
                      spellCheck='false'
                      value={`button-${moduleId}-${workspaceId}-smartflow-popup`}
                      id='copyButtonId'
                      className='form-control form-control-lg form-control-solid'
                      data-kt-autosize='true'
                      readOnly
                    ></textarea>
                    <button
                      onClick={() => onCopy('copyButtonId')}
                      className='btn btn-sm btn-primary mt-4'
                    >
                      {t('COPY')}
                    </button>
                  </>
                )}
                {['scroll', 'load'].includes(selected.popup) && (
                  <>
                    <div className='fv-row mb-5'>
                      {renderLabel(
                        selected.popup === 'scroll' ? t('HEIGHT_INFO') : t('TIME_INFO')
                      )}
                      <TextInputFeild
                        type='number'
                        name='delay'
                        value={selected.delay}
                        onChange={(name: string, value: any) => onInputChange(name, value)}
                        onBlur={onBlur}
                      />
                    </div>
                  </>
                )}
                {['scroll', 'load'].includes(selected.popup) &&
                <>
                  {renderLabel(t("REPEATED_SHOWING"))}
                  <SelectInputFeild
                    name='repeatedType'
                    value={selected.repeatedType}
                    appendOption={{name: 'ONCE_A_SESSION', value: ''}}
                    options={getShowingList(t)}
                    onChange={(name: string, value: any) => onInputChange(name, value)}
                    onBlur={onBlur}
                  />
                </>
                }
              </>
            )}
          </div>
          <div
            className={`tab-pane fade position-relative col-8 ${active === 0 ? 'show active' : ''}`}
            id='kt_tab_pane_1'
            role='tabpanel'
          >
            {renderCodeInput('code-area')}
          </div>
          <div
            className={`tab-pane fade position-relative col-8 ${active === 1 ? 'show active' : ''}`}
            id='kt_tab_pane_2'
            role='tabpanel'
          >
            {renderCodeInput('popup-area')}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Step3
