import {FC} from 'react'
import {useIntl} from 'react-intl'
import {getModulePath} from '../../../../utils/module.utils'
import { isWorkspaceReadonly } from '../../../../utils/utils'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

type Props = {
  data?: any
}

const StepperBreadCrumb: FC<Props> = ({data}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {name, from, moduleId, templateId} = data
  const updateStep = (step: string) => {
    if (!templateId) return `${getModulePath(from)}/${moduleId}/${step}`
    return `/templates/${from}s/${templateId}/${step}`
  }

  const stepperBreadCrumb: Array<PageLink> = []
  const builder = {
    title: from === 'bot' ? t('FLOW') : from === 'smartflow' ? t('FLOW_BUILDER') : t('BUILDER'),
    path: `${updateStep('design')}`,
    isActive: true,
  }
  const slides = {
    title: t('SLIDE', {count: 1}),
    path: `${updateStep('slides')}`,
    isActive: false,
  }
  const design = {
    title: t('DESIGN'),
    path: `${updateStep('appearance')}`,
    isActive: false,
  }
  const settings = {
    title: t('SETTINGS'),
    path: `${updateStep('settings')}`,
    isActive: false,
  }
  const install = {
    title: t('INSTALL'),
    path: `${updateStep('code')}`,
    isActive: false,
  }
  const emails = {
    title: `${t('EMAIL_TEMPLATE', {count: 0})}`,
    path: `${updateStep('emailTemplate')}`,
    isActive: false,
  }

  stepperBreadCrumb.push(builder)
  if (['smartflow'].includes(from)) stepperBreadCrumb.push(slides)
  if (!['smartblock', 'smartpopup', 'smartflow'].includes(from)) stepperBreadCrumb.push(design)
  if (!templateId) {
    if (['file'].includes(from)) stepperBreadCrumb.push(emails)
    if (!isWorkspaceReadonly()){
      if (!['smartflow'].includes(from)) stepperBreadCrumb.push(settings)
      stepperBreadCrumb.push(install)
    }
  }

  return <PageTitle breadcrumbs={stepperBreadCrumb}>{name}</PageTitle>
}

export default StepperBreadCrumb
