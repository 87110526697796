import axios from 'axios'
import { handleError } from '../utils/utils'


export const createPartner = async (data: any) => {
  try {
    const response = await axios.post(`/api/partner`, data)
    return response.data?.data

  } catch ({ response }) {
    handleError(response)
  }
}

export const getAllPartner = async () => {
  try {
    const response = await axios.get(`/api/partner`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const getPartnerById = async (id: string) => {
  try {
    const response = await axios.get(`/api/partner/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}


export const deletePartner = async (id: string) => {
  try {
    const response = await axios.delete(`/api/partner/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const updatePartner = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/partner/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

