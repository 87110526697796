import { makeObservable, action, observable } from "mobx";

class BotStore {

  contextMenu = {
    open: false,
    ev: null,
    position: {
      left: '0px',
      top: '0px'
    }
  }

  copyMessage = {
    show: false,
    position: {
      left: '0px',
      top: '0px'
    }
  }

  constructor() {
    makeObservable(this, {
      contextMenu: observable,
      copyMessage: observable,
      setCopyMessage: action,
      setContextMenu: action
    })
  }

  setContextMenu({ open, position }) {
    this.contextMenu = Object.assign({}, this.contextMenu, arguments[0])
  }

  setCopyMessage({ show, position }) {
    this.copyMessage = Object.assign({}, this.copyMessage, arguments[0])
  }
}

const botStore = new BotStore

export default botStore
