/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import Collapsible from 'react-collapsible'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import {FONT_FAMILY, FONT_STYLE, FONT_WEIGHT, getFileData} from '../../../../../../../utils/utils'
import { FLOW_BTN_STYLE, FORM_STYLE } from '../../../../../../../utils/module.utils'
import {updateImage} from '../../../../../../../service/module.service'
import ImageInputFeild from '../../../../../../shared/ImageInputFeild'
import SelectInputFeild from '../../../../../../shared/SelectInputFeild'
import TextInputFeild from '../../../../../../shared/TextInputFeild'
import ContentSpacing from '../../../../../common/ContentSpacing'
import ColorPickerInput from '../../../../../../shared/ColorPickerInput'

type SlideDesignTabProps = {
  onAppearanceSave?: any
  moduleCode?: any
  onAppearanceBlur?: any
  selectedSlide?: any
  setSelectedSlide?: any
  onSave?: any
}

interface RouteParams {
  moduleId: string
  step: string
}

const SlideDesignTab: FC<SlideDesignTabProps> = ({
  onAppearanceSave,
  moduleCode,
  onAppearanceBlur,
}) => {
  const intl = useIntl()
  const t: any = (id: any, values: any) => intl.formatMessage({id}, values || {})
  const {moduleId} = useParams<RouteParams>()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [moduleCode])

  const onStyleSave = (name: string, value: any) => {
    moduleCode.appearance.style[name] = value;
    onAppearanceSave();
    onAppearanceBlur();
  }

  const onFileRemove = (name: any) => onStyleSave(name, '')

  const onFileUpload = async (e: any, name: any) => {
    if (!e.target.files.length) return
    const data = await updateImage(moduleId, getFileData(e.target.files))
    onStyleSave(name, data)
  }

  const CollapsibleTrigger = (props: any) => (
    <div className='w-100 p-2 bg-secondary'>
      <b className='fs-4 fw-semibold mb-2'>{props.text}</b>
    </div>
  )

  const renderColorPicker = (label: any, name: any) => (
    <div className='panel-content d-flex justify-content-between align-items-center'>
      <label className='fs-5 fw-semibold my-2'>
        <div>{label}</div>
      </label>
      <ColorPickerInput
        name={name}
        value={moduleCode?.appearance.style[name]}
        onChange={(key: string, value: string) => onStyleSave(key, value)}
      />
    </div>
  )

  const renderInput = (type: string, label: any, name: string, plc?: string) => (
    <>
      <label className='fs-5 fw-semibold my-2'>{t(label)}</label>
      <TextInputFeild
        className='form-control form-control-lg form-control-solid mb-2'
        type={type || 'text'}
        name={name}
        placeholder={plc}
        value={moduleCode?.appearance?.style[name]}
        onChange={(name: any, value: any) => onStyleSave(name, value)}
      />
    </>
  )

  const renderSelectInput = (label: any, name: any, options: any, s?: any, appendOption?: any) => {
    return (
      <div className='panel-content'>
        <label className='fs-5 fw-semibold mb-2'>{label}</label>
        <div className='text-center'>
          <SelectInputFeild
            name={name}
            value={moduleCode?.appearance.style[name]}
            options={options}
            appendOption={appendOption}
            onChange={(name: any, value: any) => onStyleSave(name, s ? value : Number(value))}
          />
        </div>
      </div>
    )
  }

  const renderSlider = (label: any, name: any) => (
    <div className='panel-content'>
      <label className='fs-5 fw-semibold mb-2'>{label}</label>
      <div className='d-flex justify-content-between align-items-center'>
        <Slider
          className='mx-5'
          value={moduleCode?.appearance?.style[name]}
          trackStyle={{backgroundColor: '#007bff'}}
          handleStyle={{borderColor: '#007bff'}}
          onChange={(value) => onStyleSave(name, value)}
        />
        <label className='fs-5 fw-semibold'>
          {moduleCode?.appearance?.style[name] || 0}%
        </label>
      </div>
    </div>
  )

  const renderMobileZoomInput = (label: string, name: string) => (
    <div className='panel-content'>
      <div className='col-6'>
        <label className='fs-5 fw-semibold my-2'>{label}</label>
        <div className='input-group input-group-sm'>
          <input
            type='number'
            name={name}
            className='form-control'
            min={0}
            aria-describedby='basic-addon2'
            value={moduleCode?.appearance?.style[name] === undefined ? 100 : moduleCode?.appearance?.style[name]}
            onChange={(e) => onStyleSave(name, e.target.value)}
          />
          <div className='input-group-append'>
            <span className='input-group-text'>%</span>
          </div>
        </div>
      </div>
    </div>
  )

  const onChangeFontType = (name: string, value: any) => {
    onStyleSave(name, value)
    onStyleSave('googleFontUrl', '')
    onStyleSave('wrapperFontFamily', value ? '' : '"Proxima Nova","proxima-nova","proxima-nova-webfont",sans-serif')
  }

  const renderSwitch = (label: string, label1: string, label2: string, name: string) => (
    <div className='panel-content d-flex justify-content-between align-items-center'>
      {label && <label className='fs-5 fw-semibold mt-2'>{label}</label>}
      <label className='form-check form-check-custom form-check-solid form-switch m-1'>
        <label className='fs-5 fw-semibold'>{label1}</label>
        <input
          className='form-check-input mx-3'
          id={name}
          type='checkbox'
          name={name}
          checked={moduleCode?.appearance?.style[name]}
          onChange={(e) =>
            name === 'isGoogleFont'
              ? onChangeFontType(name, e.target.checked)
              : onStyleSave(name, e.target.checked)
          }
        />
        <label className='fs-5 fw-semibold'>{label2}</label>
      </label>
    </div>
  )

  const renderTextHeightInput = (label: string, name: string) => (
    <div className='col-8'>
      <label className='fs-5 fw-semibold my-2'>{label}</label>
      <div className='input-group input-group-sm'>
        <div
          className='input-group-append cursor-pointer'
          onClick={() => onStyleSave(name, Number(moduleCode?.appearance?.style[name] - 10))}
        >
          <span className='input-group-text rounded-0'>-</span>
        </div>
        <input
          type='number'
          name={name}
          className='form-control'
          value={moduleCode?.appearance?.style[name]}
          aria-describedby='basic-addon2'
          onChange={(e) => onStyleSave(name, Number(e.target.value))}
        />
        <div className='input-group-append'>
          <span className='input-group-text rounded-0'>%</span>
        </div>
        <div
          className='input-group-append cursor-pointer'
          onClick={() => onStyleSave(name, Number(moduleCode?.appearance?.style[name] + 10))}
        >
          <span className='input-group-text rounded-0'>+</span>
        </div>
      </div>
    </div>
  )

  const getFontFamilyName = (value: string) => {
    const getFontFamily = FONT_FAMILY.find((font) => font.value === value)
    return getFontFamily?.text
  }

  return (
    <>
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('FLOW_SLIDE', {count: 0})} />}
      >
        <div>
          <div className='panel-content'>
            <ContentSpacing
              label={`${t('FLOW_SLIDE', {count: 0})} ${t('MARGIN')}`}
              value={moduleCode?.appearance.style?.footerMargin}
              onSave={(value: any) => onStyleSave('footerMargin', value)}
            />
          </div>
          {renderSwitch('', t('SYSTEM_FONTS'), t('GOOGLE_FONTS'), 'isGoogleFont')}
          <div className='panel-content'>
            {moduleCode?.appearance.style?.isGoogleFont ? (
              <>
                <label className='fs-5 fw-semibold mb-2'>{t('FONT_FAMILY')}</label>
                <br />
                {renderInput('text', t('NAME'), 'wrapperFontFamily')}
                {renderInput('url', t('URL'), 'googleFontUrl', t('PLACEHOLDER_URL'))}
              </>
            ) : (
              <>
                <label className='fs-5 fw-semibold mb-2'>{t('FONT_FAMILY')}</label>
                <br />
                <button
                  type='button'
                  className='btn btn-default w-100 me-3 text-dark fs-4'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-start'
                >
                  {getFontFamilyName(moduleCode?.appearance?.style?.wrapperFontFamily)}
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg-light-primary w-250 w-md-250px px-7 pt-5 overflow-auto h-50'
                  data-kt-menu='true'
                >
                  {FONT_FAMILY.map((item: any, index: number) => (
                    <div className='menu-item' key={`s_${index}`}>
                      <a
                        className='menu-link fs-5 text-dark fw-bolder px-3 py-3'
                        style={{fontFamily: item.value}}
                        onClick={() => {
                          onStyleSave('wrapperFontFamily', item.value)
                          const checkUrl = FONT_FAMILY.find(
                            (font) => font.value === item.value && font.url
                          )
                          if (checkUrl?.url) onStyleSave('googleFontUrl', checkUrl?.url)
                        }}
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {renderColorPicker(t('BACKGROUND_COLOR'), 'wrapperBgColor')}
          <div className='panel-content'>
            <label className='fs-5 fw-semibold mb-2'>{t('BACKGROUND_IMAGE', {count: 0})}</label>
            <div className='row'>
              <div  className='col-6'>
                <label className='fs-5 fw-semibold mb-2'>{t('DESKTOP', {count: 0})}</label>
                <div>
                  <ImageInputFeild
                    size={{icon: 15, img: 75}}
                    type='rounded'
                    title={t('BACKGROUND_IMAGE')}
                    url={moduleCode.appearance?.style?.wrapperBgImage}
                    placeholder={'url(/media/svg/avatars/bgplaceholder.svg)'}
                    element='wrapperBgImage'
                    onUpload={(e: any, name: any) => onFileUpload(e, name)}
                    onRemove={(name: any) => onFileRemove(name)}
                  />
                </div>
              </div>
              <div  className='col-6'>
                <label className='fs-5 fw-semibold mb-2'>{t('MOBILE', {count: 0})}</label>
                <div>
                  <ImageInputFeild
                    size={{icon: 15, img: 75}}
                    type='rounded'
                    title={t('BACKGROUND_IMAGE')}
                    url={moduleCode.appearance?.style?.mobileWrapperBgImage}
                    placeholder={'url(/media/svg/avatars/mobileBgplaceholder.svg)'}
                    element='mobileWrapperBgImage'
                    onUpload={(e: any, name: any) => onFileUpload(e, name)}
                    onRemove={(name: any) => onFileRemove(name)}
                  />
                </div>
              </div>
            </div>
          </div>
          {renderMobileZoomInput(t('MOBILE_ZOOM', {count: 0}), 'mobileZoom')}
        </div>
      </Collapsible>
      <br />
      <Collapsible trigger={<CollapsibleTrigger text={t('TITLE')} overflowWhenOpen={'visible'} />}>
        <div>
          {renderColorPicker(t('FONT_COLOR'), 'titleColor')}
          {/* <div className='panel-content'>{renderInput('FONT_SIZE', 'titleFontSize')}</div> */}
          {renderSelectInput(t('FONT_WEIGHT'), 'titleFontWeight', FONT_WEIGHT)}
          {/* <div className='panel-content'>
            {renderTextHeightInput(t('LINE_HEIGHT'), 'titleLineHeight')}
          </div> */}
          {renderSelectInput(t('FONT_STYLE'), 'titleFontStyle', FONT_STYLE(t), 'string', {
            name: t('HEADING'),
            value: 'heading',
          })}
          {/* {renderSlider(t('POSITION'), `titleTop`)} */}
          <div className='panel-content'>
            <ContentSpacing
              label={t('CONTAINER')}
              value={moduleCode?.appearance.style?.titleWrapPadding}
              onSave={(value: any) => onStyleSave('titleWrapPadding', value)}
            />
          </div>
        </div>
      </Collapsible>
      <br />
      <Collapsible
        trigger={<CollapsibleTrigger text={t('SUB_TITLE')} overflowWhenOpen={'visible'} />}
      >
        <div>
          {renderColorPicker(t('FONT_COLOR'), 'subtitleColor')}
          {/* <div className='panel-content'>
            {renderInput('FONT_SIZE', 'subtitleFontSize')}
          </div> */}
          {renderSelectInput(t('FONT_WEIGHT'), 'subtitleFontWeight', FONT_WEIGHT)}
          <div className='panel-content'>
            {/* {renderTextHeightInput(t('LINE_HEIGHT'), 'subtitleLineHeight')} */}
          </div>
          {renderSelectInput(t('FONT_STYLE'), 'subtitleFontStyle', FONT_STYLE(t), 'string', {
            name: t('HEADING'),
            value: 'heading',
          })}
          {/* {renderSlider(t('POSITION'), `subtitleTop`)} */}
          <div className='panel-content'>
            <ContentSpacing
              label={t('CONTAINER')}
              value={moduleCode?.appearance.style?.subtitleWrapPadding}
              onSave={(value: any) => onStyleSave('subtitleWrapPadding', value)}
            />
          </div>
        </div>
      </Collapsible>
      <br />
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('CONTENT')} />}
      >
        <div>
          <div className='panel-content'>
            <ContentSpacing
              label={t('CONTAINER')}
              value={moduleCode?.appearance?.style?.contentWrapPadding}
              onSave={(value: any) => onStyleSave('contentWrapPadding', value)}
            />
          </div>
          <div className='panel-content'>{renderColorPicker(t('FONT_COLOR'), 'contentColor')}</div>
          {/* <div className='panel-content'>
            {renderInput('FONT_SIZE', 'contentFontSize')}
          </div> */}
          {renderSelectInput(t('FONT_WEIGHT'), 'contentFontWeight', FONT_WEIGHT)}
          {/* <div className='panel-content'>
            {renderTextHeightInput(t('LINE_HEIGHT'), 'contentLineHeight')}
          </div> */}
          {renderSelectInput(t('FONT_STYLE'), 'contentFontStyle', FONT_STYLE(t), 'string', {
            name: t('HEADING'),
            value: 'heading',
          })}
          {/* {renderSlider(t('POSITION'), 'contentTop')} */}
          {/* {renderSlider(t('HEIGHT'), 'height')} */}
        </div>
      </Collapsible>
      <br />
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('OPTION', {count: 1})} />}
      >
        <div className='p-0'>
          <div className='panel-content'>
            <label className='fs-4 fw-bolder'>{t('TITLE')}</label>
            <br />
            {/* {renderInput('FONT_SIZE', 'opTitleFontSize')} */}
            {/* {renderTextHeightInput(t('LINE_HEIGHT'), 'opTitleLineHeight')} */}
            {renderSelectInput(t('FONT_STYLE'), 'opTitleFontStyle', FONT_STYLE(t), 'string', {
              name: t('HEADING'),
              value: 'heading',
            })}
            {renderSelectInput(t('FONT_WEIGHT'), 'opTitleFontWeight', FONT_WEIGHT)}
            {renderColorPicker(t('OPTION_TITLE_COLOR'), 'opTitleColor')}
          </div>
          <div className='panel-content'>
            <label className='fs-5 fw-bolder'>{t('SUB_TITLE')}</label>
            <br />
            {/* {renderInput('FONT_SIZE', 'opSubtitleFontSize')} */}
            {/* {renderTextHeightInput(t('LINE_HEIGHT'), 'opSubtitleLineHeight')} */}
            {renderSelectInput(t('FONT_STYLE'), 'opSubtitleFontStyle', FONT_STYLE(t), 'string', {
              name: t('HEADING'),
              value: 'heading',
            })}
            {renderSelectInput(t('FONT_WEIGHT'), 'opSubtitleFontWeight', FONT_WEIGHT)}
            {renderColorPicker(t('OPTION_SUBTITLE_COLOR'), 'opSubtitleColor')}
          </div>
          {/* {renderColorPicker(t('OPTION_BORDER_COLOR'), 'opImgOutlineColor')}
          {renderColorPicker(
            t('OPTION_HOVER_BORDER_COLOR'),
            'opImgHoverOutlineColor',
          )} */}
          <div className='panel-content'>
            <label className='fs-5 fw-semibold'>{t('SELECTION')}</label>
            {renderColorPicker(t('OPTION_BG_COLOR'), 'optionBgColor')}
            {renderColorPicker(t('OPTION_SELECTION_COLOR'), 'opSelectionColor')}
            {renderColorPicker(t('OPTION_CHECKMARK_COLOR'), 'optionCheckmarkColor')}
            {renderColorPicker(t('NAVIGATION_BTN_COLOR'), 'navigationBtnColor')}
            {renderColorPicker(t('BADGE_COLOR'), 'badgeColor')}
            {renderColorPicker(t('BADGE_DISABLE_COLOR'), 'badgeDisableColor')}
            {renderColorPicker(t('DOTS_COLOR'), 'dotsColor')}
            {renderColorPicker(t('DOTS_DISABLE_COLOR'), 'dotsDisableColor')}
          </div>
        </div>
      </Collapsible>
      <br />
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('CHAT_FORM')} />}
      >
        <div className='p-0'>
          <div className='panel-content'>
            <ContentSpacing
              label={t('CONTAINER')}
              value={moduleCode?.appearance?.style?.contentWrapPadding}
              onSave={(value: any) => onStyleSave('formWrapPadding', value)}
            />
          </div>
          {/* {renderSlider(
            t('BACKGROUND_COLOR_PERCENTAGE', {count: 0}),
            'formFieldBGPercentage'
          )} */}
          {renderSelectInput(t('FORM_STYLE'), 'formStyle', FORM_STYLE(t), 'string', {
            value: 'underline-style',
            name: t('UNDERLINE'),
          })}
          <div className='panel-content'>
            <label className='fs-5 fw-semibold'>{t('BOT_FORM_LABEL_SETTINGS')}</label>
            <br />
            {/* {renderInput('FONT_SIZE', 'formLabelFontSize')} */}
            {/* {renderTextHeightInput(t('LINE_HEIGHT'), 'formLabelLineHeight')} */}
            {renderSelectInput(t('FONT_STYLE'), 'formLabelFontStyle', FONT_STYLE(t), 'string', {
              name: t('HEADING'),
              value: 'heading',
            })}
            {renderSelectInput(t('FONT_WEIGHT'), 'formLabelFontWeight', FONT_WEIGHT)}
            {renderColorPicker(t('LABEL_TEXT_COLOR'), 'formLabelColor')}
            {renderColorPicker(t('FORM_FIELD_TEXT_COLOR'), 'fieldTextColor')}
            {renderColorPicker(t('FORM_FIELD_COLOR'), 'fieldBGColor')}
            {renderColorPicker(t('FORM_FIELD_PLACEHOLDER_COLOR'), 'fieldPlaceholderColor')}
          </div>
        </div>
      </Collapsible>
      <br />
      <Collapsible overflowWhenOpen={'visible'} trigger={<CollapsibleTrigger text={t('FOOTER')} />}>
        <div>
          {renderSelectInput(t('BUTTON_STYLE'), 'buttonStyle', FLOW_BTN_STYLE(t), 'string', {
            value: 'classic-btn',
            name: t('CLASSIC'),
          })}
          {renderColorPicker(t('BUTTON_BG_COLOR'), 'submitBgColor')}
          {renderColorPicker(t('BUTTON_BG_HOVER_COLOR'), 'submitHoverBgColor')}
          {renderColorPicker(t('BUTTON_TEXT_COLOR'), 'submitColor')}
          {renderColorPicker(t('BUTTON_TEXT_HOVER_COLOR'), 'submitHoverColor')}
          {renderColorPicker(t('SUBMIT_DISABLE_COLOR'), 'submitDisableColor')}
          {renderSwitch(t('FOOTER_MODE'), t('DARK'), t('LIGHT'), 'footerMode')}
          {/* {renderBorderInput(t('BUTTON_BORDER'), 'buttonBorder')} */}
          {renderColorPicker(t('TERMS_AND_CONDITION_TEXT'), 'groweoFontColor')}
          {renderColorPicker(t('GROWEO_LOGO_COLOR'), 'groweoIconColor')}
        </div>
      </Collapsible>
      <br />
      <Collapsible
        overflowWhenOpen={'visible'}
        trigger={<CollapsibleTrigger text={t('CLOSE_BUTTON')} />}
      >
        <div>
          {renderColorPicker(t('BACKGROUND_COLOR'), 'closeBgColor')}
          {renderColorPicker(t('ICON_COLOR'), 'closeIconColor')}
          <div className='panel-content'>
            <ContentSpacing
              label={t('ROUNDED_BORDER')}
              value={moduleCode?.appearance?.style?.closeBorderRadius}
              onSave={(value: any) => onStyleSave('closeBorderRadius', value)}
              isBorderRadius={true}
            />
          </div>
          <div className='panel-content'>
            <ContentSpacing
              label={t('MARGIN')}
              value={moduleCode?.appearance?.style?.closeMargin}
              onSave={(value: any) => onStyleSave('closeMargin', value)}
            />
          </div>
        </div>
      </Collapsible>
    </>
  )
}
export default SlideDesignTab
