const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL

export function inPageCodeForForm(moduleId, workspaceId, isPreview, inPage, isIframe, language) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const type = inPage ? 'inpage' : 'popup'
  const divContent = [
    `<div id="${rootElementId}-${type}"></div>`,
    `<script>
  if(window.__formData === undefined) window.__formData=[];
  window.__formData.push({
    url: '${scriptUrl}?isPreview=${isPreview || false}',
    publishUrl: '${FRONTEND_URL}/api/session/response',
    workspaceId: '${workspaceId}',
    moduleId: '${moduleId}',
    rootId: "${rootElementId}-${type}",
    inPage: ${inPage},
    trackingId: '',
    language: '${language}'
  })
</script>`,
    `<script src="${FRONTEND_URL}/sdk/formScript.js"></script>`,
  ]
  const iframeContent = [
    `  <script src="${FRONTEND_URL}/form/script.js"></script>`,
    `
  <iframe id="${rootElementId}-${type}"
   src="${FRONTEND_URL}/form/index.html?url=${scriptUrl}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&rootId=${rootElementId}-${type}&inPage=${inPage}&isPreview=${isPreview || false}&language=${language || 'fi'}&isIframe=true" 
   height="0" width="100%" frameborder="0" scrolling="auto">
   </iframe>`
  ]
  const content = isIframe ? iframeContent : divContent
  return isPreview ? content : content.join('')
}

export function inPageCodeForSmartBlock(moduleId, workspaceId, isPreview, inPage, language, isCustomTool) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const content = [
    `${isCustomTool && !isPreview ? `<script src="${FRONTEND_URL}/bot/script4.js"></script>\n` : ''}`,
    `<script src="${FRONTEND_URL}/smartBlock/script.js"></script>\n`,
    `<iframe id="${rootElementId}-${inPage ? 'inpage' : 'popup'}" 
    src="${FRONTEND_URL}/smartBlock/?url=${scriptUrl}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&inPage=${inPage}&isPreview=${isPreview || false}&language=${language || 'fi'}" 
    height=${inPage ? '100%' : '0'} width="100%" frameborder="0" scrolling="auto">
    </iframe>`
  ]
  return isPreview ? content : content.join('')
}

export function inPageCodeForSmartPopup(moduleId, workspaceId, isPreview, language) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const content = [
    `<script src="${FRONTEND_URL}/smartPopup/script.js"></script>\n`,
    `<iframe id="${rootElementId}-popup" 
    src="${FRONTEND_URL}/smartPopup/?url=${scriptUrl}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&inPage=false&isPreview=${isPreview || false}&language=${language || 'fi'}" 
    height='0' width="100%" frameborder="0" scrolling="auto">
    </iframe>`
  ]
  return isPreview ? content : content.join('')
}

export function inPageCodeForBot(moduleId, workspaceId, isPreview, old, language) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const contentOld = [
    `<div id="${rootElementId}-inpage" style="${
      isPreview ? 'height: 100%;display: flex;justify-content: center;align-items: center;' : ''
    }"></div>`,
`<script>
  if(window.__botData === undefined) window.__botData=[];
    window.__botData.push({
      url: '${scriptUrl}?isPreview=${isPreview || false}',
      publishUrl: '${FRONTEND_URL}/api/session/response',
      workspaceId: '${workspaceId}',
      moduleId: '${moduleId}',
      rootId: "${rootElementId}-inpage",
      inPage: true,
      trackingId: '',
      language: '${language}',
      isPreview: ${isPreview || false},
    })
    const script = document.createElement('script');
    script.src = "${FRONTEND_URL}/sdk/botScript.js";
    document.head.appendChild(script);
</script>`,
  ]
  const contentNew = [
`<script src="${FRONTEND_URL}/bot/script4.js"></script>`,
`<iframe style="display: none"
 class="gw-ifstyle-${moduleId}"
  src="${FRONTEND_URL}/bot/index.html?url=${scriptUrl}&isPreview=${isPreview || false}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&inPage=true&language=${language}">
  </iframe>`    
  ]
  const content = old ? contentOld : contentNew;
  return isPreview ? content : content.join('\n');
}

export function inPopupCodeForBot(moduleId, workspaceId, isPreview, old, language) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const contentOld = [
`<div id="${rootElementId}-popup" style="position: fixed; right: 50px; top: 100px;"></div>`,
`<script>
  if(window.__botData === undefined) window.__botData=[];
  window.__botData.push({
    url: '${scriptUrl}?isPreview=${isPreview || false}',
    publishUrl: '${FRONTEND_URL}/api/session/response',
    workspaceId: '${workspaceId}',
    moduleId: '${moduleId}',
    rootId: '${rootElementId}-popup',
    trackingId: '',
    language: '${language}'
  })
</script>`,
`<script src="${FRONTEND_URL}/sdk/botScript.js" crossorigin></script>`,
  ]
  const contentNew = [
    `<script src="${FRONTEND_URL}/bot/script4.js"></script>`,
    `<iframe style="display: none" 
    class="gw-ifstyle-${moduleId}" 
    src="${FRONTEND_URL}/bot/index.html?url=${scriptUrl}&isPreview=${isPreview || false}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&inPage=false&language=${language}">
    </iframe>`    
  ]
  const content = old ? contentOld : contentNew;
  return isPreview ? content : content.join('\n');
}

export function codeForSmartBar(moduleId, workspaceId, isPreview, language) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const content = [
    `<script src="${FRONTEND_URL}/smartBar/script.js"></script>\n`,
    `<iframe id="${rootElementId}-smartbar" 
    src="${FRONTEND_URL}/smartBar/?url=${scriptUrl}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&isPreview=${isPreview || false}&language=${language || 'fi'}" 
    height='100%' width="100%" frameborder="0" scrolling="auto">
    </iframe>`
  ]
  return isPreview ? content : content.join('')
}

export function codeForSmartFlow(moduleId, workspaceId, isPreview, inPage, language) {
  const rootElementId = `${moduleId}-${workspaceId}`
  const scriptUrl = `${FRONTEND_URL}/api/${workspaceId}/${moduleId}`
  const content = [
    `<script src="${FRONTEND_URL}/smartFlow/script.js"></script>\n`,
    `<iframe id="${rootElementId}-smartflow-${inPage ? 'inpage' : 'popup'}" 
    src="${FRONTEND_URL}/smartFlow/?url=${scriptUrl}&publishUrl=${FRONTEND_URL}/api/session/response&workspaceId=${workspaceId}&moduleId=${moduleId}&inPage=${inPage}&isPreview=${
      isPreview || false
    }&language=${language || 'fi'}" 
    height=${inPage ? '100%' : '0'} width="100%" frameborder="0" scrolling="auto">
    </iframe>`,
  ]
  return isPreview ? content : content.join('')
}