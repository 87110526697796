/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getWorkspaceStatus } from '../../../../../utils/utils'
import WorkspaceModal from '../WorkspaceModal'
import { WorkspaceDetailsToolbar } from '../../../../../_metronic/layout/components/toolbar/WorkspaceDetailsToolbar'
import Tabs from './Tabs'
import { getWorkspaceById } from '../../../../../service/workspace.service'
interface RouteParams {
  workspaceId: string,
}
interface Workspace {
  name?: string,
  status?: string,
  domain?: string,
  language?: string,
  _id?: string,
  subscriptionPlanId?: string,
  created?: Date
}


const WorkspaceView: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const { workspaceId } = useParams<RouteParams>();
  const [workspace, setWorkspace] = useState<Workspace>({})


  useEffect(() => {
    if (workspaceId) {
      getWorkspaceById(workspaceId).then(data => {
        setWorkspace(data)
      })
    }
  }, [workspaceId])

  const renderWorkspaceStatus = (status: any) => {
    const statusList = getWorkspaceStatus(t)
    return statusList.find((item) => item.value === status)?.text
  }

  return (
    <>
      <WorkspaceDetailsToolbar workspace={workspace} />
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-flush h-lg-100' id='kt_contacts_main'>
            <div className='card-body pt-5'>
              <div className='d-flex gap-7 align-items-center'>
                <div className='symbol symbol-circle symbol-100px'>
                  <img src='/media/svg/avatars/blank.svg' alt='logo' />
                </div>
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-1">
                      <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-3 text-capitalize">{workspace?.name}</a>
                      <span className={`badge ${workspace?.status === 'active' ? 'badge-light-success' : 'badge-light-danger'} me-auto text-capitalize`}>{renderWorkspaceStatus(workspace?.status)}</span>
                    </div>
                    <div className="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-400">{workspace?.domain}</div>
                  </div>
                </div>
              </div>
              <Tabs workspace={workspace} />
            </div>
          </div>
        </div>
      </div>
      <WorkspaceModal workspace={workspace} setWorkspace={setWorkspace} />
    </>
  )
}



export default WorkspaceView
