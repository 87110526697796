import {FC, useState} from 'react'
import {toJS} from 'mobx'
import Statistics from '../common/stepper/steps/Statistics'
import ConfirmModal from '../common/modal/ConfirmModal'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {deleteTemplate, updateTemplate} from '../../../service/template.service'

type TemplatesListProps = {
  templateList: any
  setTemplateList?: any
  from: any
}

const cardstyle = {
  height: '120px',
}

const TemplatesList: FC<TemplatesListProps> = ({templateList, setTemplateList, from}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [template, setTemplate]: any = useState({})
  const history = useHistory()

  const onClickUse = (e: any) => {
    setTemplate(e)
    history.push(`/templates/${from}s/${e._id}/design`)
  }

  const onCreate = () => {
    setTemplate({})
    history.push(`/templates/${from}s/create/design`)
  }

  const onModalOpen = (e: any, type: string) => {
    setTemplate(e)
    setModalType(type)
    setShowConfirmModal(true)
  }

  const onConfirm = async () => {
    let data
    if (modalType === 'remove') data = await deleteTemplate(template._id)
    else data = await updateTemplate(template._id, {status: modalType, type: from})
    if (!data) return

    const foundIndex = templateList.findIndex((item: any) => item._id === template._id)
    if (foundIndex !== -1 && modalType === 'remove') templateList.splice(foundIndex, 1)
    else if (foundIndex !== -1 && templateList[foundIndex].status !== data.status)
      templateList[foundIndex].status = data.status
    setTemplateList([...templateList])
  }

  const getStatus = (status: string) =>
    status === 'draft' ? 'publish' : status === 'publish' ? 'draft' : ''

  const renderCard = (title: string, img: string) => (
    <div className='overlay-wrapper'>
      <div style={cardstyle}>
        <Statistics image={img} title={title} className='card-xl-stretch mb-xl-8' description='' />
      </div>
    </div>
  )

  return (
    <>
      <div className='w-100 flex row'>
        <div className='row align-items-center ms-5'>
          <div className='col-xl-3 mt-5'>
            <div className='card card-custom overlay overflow-hidden'>
              <div className='card-body p-0'>
                {renderCard(t('BLANK'), 'abstract-2.svg')}
                <div className='overlay-layer bg-dark bg-opacity-10'>
                  <p onClick={onCreate} className='btn btn-light-primary btn-shadow'>
                    {t('EDIT_THIS')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {toJS(templateList).map((l: any) => (
            <div key={l._id} className='col-xl-3 mt-5'>
              <div className='card card-custom overlay overflow-hidden'>
                <div className='card-body p-0'>
                  {renderCard(l.name, 'abstract-4.svg')}
                  <div className='overlay-layer bg-dark bg-opacity-10'>
                    <p
                      onClick={() => onClickUse(l)}
                      className='btn btn-light-primary btn-shadow mx-2'
                    >
                      {t('EDIT')}
                    </p>
                    <p
                      onClick={() => onModalOpen(l, 'remove')}
                      className='btn btn-light-danger btn-shadow mx-2'
                    >
                      {t('DELETE')}
                    </p>
                    {['draft', 'publish'].includes(l.status) && (
                      <p
                        onClick={() => onModalOpen(l, getStatus(l.status))}
                        className='btn btn-light-success btn-shadow mx-2 text-capitalize'
                      >
                        {t(`${getStatus(l.status)?.toUpperCase()}`)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {showConfirmModal && (
            <ConfirmModal
              icon={['draft', 'publish'].includes(modalType) && 'question'}
              label={modalType}
              show={showConfirmModal}
              close={setShowConfirmModal}
              onConfirm={onConfirm}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default TemplatesList
