/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useSmartFlowData} from '../StepperContext'
import { addEdge } from 'react-flow-renderer'
import * as uuid from 'uuid'
import Slider from "react-slick";
import {useIntl} from 'react-intl'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../steps/step1/jscss/slick.css"
import {useLocation} from 'react-router-dom'
import {SlideBuilderElement} from '../../../smartFlow/slideBuilder/SlideBuilder'
import {SettingsFlyout} from './step1/settings/SettingsFlyout'
import {KTSVG} from '../../../../../_metronic/helpers'
import {formFields} from '../../../../../utils/module.utils'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {FallbackView} from '../../../../../_metronic/partials'
import { getElements } from '../../../../../utils/template.utils'
import { errorMessage } from '../../../../../utils/utils'
import ConfirmModal from '../../../common/modal/ConfirmModal';

type Step2Props = {
  isGrid?: boolean
}

const defaultValue: any = {
  title: 'Title',
  paragraph1: 'Subtitle',
  paragraph2: 'Content',
  buttonText: 'Submit',
  maxOption: 1,
  minOption: 1,
  timeDelay: 3,
}

const defaultOpts = [
  {title: 'Title', subTitle: 'Subtitle'},
  {title: 'Title', subTitle: 'Subtitle'},
]

const slideList = [
  {value: 'text', text: 'TEXT_SILDE'},
  {value: 'options', text: 'OPTION_SILDE'},
  {value: 'content', text: 'FORM_SILDE'},
  {value: 'end', text: 'END_SILDE'},
]

const getId = () => uuid.v4()

const Step2: FC<Step2Props> = ({isGrid}) => {
  const intl = useIntl()
  const t = (id: any, values?: any) => intl.formatMessage({id}, values || {})
  const {smartFlow, setSmartFlow}: any = useSmartFlowData()
  const {state}: any = useLocation()
  const [moduleCode, setModuleCode]: any = useState({})
  const [appearance, setAppearance]: any = useState({})
  const [activeTab, setActiveTab] = useState(0)
  const [slides, setSlides]: any = useState([])
  const [selectedSlide, setSelectedSlide]: any = useState()
  const [flag, setFlag]: any = useState(false)
  const [toggleInput, setToggleInput] = useState(false)
  const [toggleEditor, setToggleEditor] = useState(true)
  const [slideView, setSlideView] = useState('desktop')
  const [showPopup, setShowPopup] = useState(false)
  const [slickWidth, setSlickWidth]: any = useState();
  const [deleteSlideIdx , setDeleteSlideIdx] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const settings = {
    slidesToShow: slickWidth?.slideToShow,
    arrows: true,
    infinite: false,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
  };

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [slides.length, selectedSlide])

  useEffect(() => {
    const getWidth = 178 * (slides.length + 1)
    const availWidth = window.innerWidth - 530
    if  (availWidth < getWidth) {
      const slideToShow = Math.floor(availWidth/178)
      setSlickWidth({width: slideToShow*178, slideToShow})
    } else setSlickWidth({width: getWidth, slideToShow: slides.length + 1})
  }, [slides.length])

  useEffect(() => {
    if (!smartFlow?.moduleCode) return
    setModuleCode({...smartFlow.moduleCode})
    setAppearance({...smartFlow.moduleCode.appearance})
    const getSlides: any = smartFlow.moduleCode?.elements.filter(
      (slide: any) => slide.type !== 'smart'
    )
    if (getSlides) setSlides(getSlides)
    if (!state && !selectedSlide) {
      if (!getSlides[0]?.data?.content) {
        if (['text', 'end'].includes(getSlides[0].type)) getSlides[0].data.content = {...defaultValue}
        else if (getSlides[0].type === 'options')
          getSlides[0].data.content = {
            ...defaultValue,
            options: JSON.parse(JSON.stringify(defaultOpts)),
          }
        else
          getSlides[0].data.content = {
            ...defaultValue,
            fields: Object.assign([], formFields),
            flowFieldBGPercentage: 0,
          }
      }
      setSelectedSlide({...getSlides[0]})
    }
  }, [smartFlow])

  useEffect(() => {
    if (toggleEditor) return
    setToggleEditor(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleEditor])

  useEffect(() => {
    if (state) {
      if (!state.data.content) {
        if (['text', 'end'].includes(state.type)) state.data.content = {...defaultValue}
        else if (state.type === 'options')
          state.data.content = {...defaultValue, options: JSON.parse(JSON.stringify(defaultOpts))}
        else state.data.content = {...defaultValue, fields: Object.assign([], formFields)}
      }
      setSelectedSlide({...state})
      window.history.replaceState({}, document.title)
    }
    // eslint-disable-next-line
  }, [])

  const onSave = () => {
    const foundIndex = smartFlow.moduleCode?.elements?.findIndex((el: any) => selectedSlide?.id === el.id)
    if (foundIndex !== -1) {
      smartFlow.moduleCode.elements[foundIndex] = selectedSlide
      setSmartFlow({...smartFlow})
      // setModuleCode(moduleCode)
      // setFlag(true)
    }
  }

  useEffect(() => {
    if (!flag) return
    setSmartFlow({...smartFlow, moduleCode})
    setFlag(false)
    // eslint-disable-next-line
  }, [flag])

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter' && e.target.value) {
      setToggleInput(!toggleInput)
      onSave()
    }
  }

  const findSlideIndex = () => slides.findIndex((item: any) => item.id === selectedSlide.id)

  const slideNode = (type: string, idx: any) => {
    return {
      id: getId(),
      type,
      data: {
        name: `Slide ${slides.length + 1}`,
        content: {},
        start: false,
        end: false,
      },
      position: {
        x: slides[Number.isInteger(idx) && idx !== Number(slides.length - 1) ? idx : slides.length - 1].position.x + 250,
        y: Number.isInteger(idx) && idx !== Number(slides.length - 1)
          ? slides[idx + 1].position.y - 100
          : slides[slides.length - 1].position.y,
      },
    }
  }

  const createEdge = (id: string) => {
    return {
      source: slides[slides.length - 1].id,
      sourceHandle: null,
      target: id,
      targetHandle: null,
      id: `${slides[slides.length - 1].id}_${id}_${getId()}`,
      type: 'smart',
    }
  }

  const onAddSlide = (type: any, idx: any) => {
    const slide: any = slideNode(type, idx)
    const edge: any = createEdge(slide?.id)
    const cloneDefaultValue = Object.assign({}, defaultValue)
    const foundIndex = slides.findIndex((item: any) => item?.id === selectedSlide?.id)
    Object.keys(cloneDefaultValue).forEach((key) => {
      if (!['title', 'paragraph1', 'paragraph2', 'options', 'fields'].includes(key))
        cloneDefaultValue[key] = slides[foundIndex].data.content[key]
    })
    if (['text', 'end'].includes(slide.type)) slide.data.content = {...cloneDefaultValue}
    else if (slide.type === 'options')
      slide.data.content = {
        ...cloneDefaultValue,
        maxOption: 1,
        minOption: 1,
        options: JSON.parse(JSON.stringify(defaultOpts))
      }
    else slide.data.content = {...cloneDefaultValue, fields: Object.assign([], formFields)}
    if (slide.type === 'end') slide.data.content.timeDelay = 3;
    if (!idx?.toString() && slides[slides.length - 1].type === 'options') {
      edge.sourceHandle = [{title: t('DEFAULT'), value: 'default'}]
    }
    const indexAvail =
      typeof idx === 'number' && (idx !== Number(slides.length - 1) || selectedSlide.type === 'end')
    const whenEndSlide = typeof idx === 'undefined' && slides[slides.length - 1].type === 'end'
    if (indexAvail || whenEndSlide) {
      const foundElIndex = moduleCode?.elements.findIndex(
        (item: any) => item.id === selectedSlide.id
      )
      if (foundElIndex !== -1) moduleCode?.elements.splice(foundElIndex + 1, 0, slide)
      slides.splice(idx, 0, slide)
      setModuleCode({...moduleCode})
    } else {
      const elements = getElements((es: any) => {
        return es.concat(slide)
      }, moduleCode.elements)
      const slideEdge = getElements((els: any) => addEdge(edge, els), elements)
      setModuleCode({...moduleCode, elements: slideEdge})
    }
    setFlag(true)
  }

  const onDeleteSlide = (idx: any) => {
    const edges = moduleCode?.elements?.filter(
      (edge: any) => edge?.type === 'smart' && edge?.target === selectedSlide?.id
    )
    
    if (edges.length) {
      edges.forEach((edge: any) => {
        const parent = moduleCode?.elements?.find(
          (ele: any) => ele?.type === 'options' && ele?.id === edge?.source 
        )
        if (parent) {
          // delete target for options when delete node 
          parent?.data?.content?.options.forEach(
            (opt: any) => opt?.target === selectedSlide?.id && delete opt?.target
          )
        } 
      });
    }
  
    if (selectedSlide?.data?.start) {
      const findEdge = moduleCode?.elements?.find(
        (edge: any) => edge?.type === 'smart' && edge?.source === selectedSlide?.id
      )
      if (!findEdge) {
        errorMessage(t('Please Connect 1st slide to other slide'))
        return
      } else {
        const findIndex = moduleCode?.elements?.findIndex(
          (ele: any) => ele?.type !== 'smart' && ele?.id === findEdge?.target
        )
        if (findIndex !== -1) moduleCode.elements[findIndex].data.start = true
      }
    }
    const elements = moduleCode?.elements.filter(
      (item: any) =>
        !(
          item.id === selectedSlide.id ||
          item?.source === selectedSlide.id ||
          item?.target === selectedSlide.id
        )
    )
    if (selectedSlide.id) {
      Object.keys(moduleCode.appearance?.style).forEach((k)=> {
        if (k.search(selectedSlide.id) !== -1) delete moduleCode.appearance.style[k]
      })
    }
    slides.splice(idx, 1)
    setSelectedSlide(slides[idx ? idx - 1 : idx])
    setModuleCode({...moduleCode, elements})
    setToggleEditor(false)
    setFlag(true)
  }

  const renderNewSlideDropdown = (idx?: any) => (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-150px'
      data-kt-menu='true'
    >
      {slideList.map((item: any, id: any) => (
        <div className='menu-item px-3' key={`s_${id}`}>
          <a
            className='menu-link fs-6 text-dark fw-bolder px-3 py-2'
            onClick={() => onAddSlide(item.value, idx)}
          >
            {t(`${item.text}`)}
          </a>
        </div>
      ))}
    </div>
  )

  const renderSlide = (slide: any, idx: any) => (
    <div key={'slide-' + idx}>
      <b>
        {idx + 1}. {slide?.data?.name || slide?.name}
      </b>
      <div
        style={{
          backgroundColor: slide?.data?.style?.wrapperBgColor || appearance?.style?.wrapperBgColor,
          backgroundImage: `url(${slide?.data?.style?.wrapperBgImage || appearance?.style?.wrapperBgImage})`,
          backgroundRepeat: `round`,
          width: 165,
          height: 120,
          marginBottom: 2
        }}
        className={`${
          selectedSlide?.id === slide?.id ? 'border-primary' : 'border-secondary'
        } border rounded-2 border-2 cursor-pointer position-relative`}
      >
        {selectedSlide?.id === slide?.id && (
          <>
            <span
              className='position-absolute'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='left-end'
              style={{right: 0}}
            >
              <KTSVG className='svg-icon-2x' path='/media/icons/duotune/general/gen035.svg' />
            </span>
            {slides?.length !== 1 ? (
              <span
                className='position-absolute'
                style={{right: 0, bottom: 0}}
                onClick={() => {
                  setDeleteSlideIdx(idx)
                  setShowConfirmModal(true)
                }}
              >
                <KTSVG className='svg-icon-2x' path='/media/icons/duotune/general/gen027.svg' />
              </span>
            ) : (
              ''
            )}
            {renderNewSlideDropdown(idx)}
          </>
        )}
        <div
          className='w-100 h-100'
          onClick={() => {
            if (!slide.data.content) {
              if (['text', 'end'].includes(slide.type)) slide.data.content = {...defaultValue}
              else if (slide.type === 'options')
                slide.data.content = {
                  ...defaultValue,
                  options: JSON.parse(JSON.stringify(defaultOpts)),
                }
              else slide.data.content = {...defaultValue, fields: Object.assign([], formFields)}
            }
            setSelectedSlide({...slide})
            setToggleEditor(false)
            setToggleInput(false)
          }}
        />
      </div>
    </div>
  )

  const preBtnEditor = () => {
    if (findSlideIndex()) {
      setSelectedSlide(slides[findSlideIndex() - 1])
      setToggleEditor(false)
    }
  }

  const nxtBtnEditor = () => {
    if (findSlideIndex() + 1 !== slides?.length) { 
      setSelectedSlide(slides[findSlideIndex() + 1])
      setToggleEditor(false)
    }
  }

  return (
    <div className='d-flex h-100 w-100'>
      {selectedSlide && moduleCode?.appearance ? (
        <>
          <div style={{margin: '0 20px', width: 'calc(100vw - 466px)'}} className='py-5'>
            <div className='d-flex align-items-center justify-content-between pb-2'>
              <div className='d-flex'>
                <h3 className='pe-2 m-0'>{findSlideIndex() + 1}.</h3>
                {toggleInput ? (
                  <input
                    className='form-control form-control-lg form-control-solid w-auto mb-3 bg-white'
                    value={selectedSlide?.data?.name || selectedSlide?.name}
                    onChange={(e: any) => {
                      selectedSlide.data.name = e.target.value
                      setSelectedSlide({...selectedSlide})
                    }}
                    onKeyUp={onKeyUp}
                  />
                ) : (
                  <h3 className='text-start m-0'>
                    {selectedSlide?.data?.name || selectedSlide?.name || 'Slide'}
                    <a
                      className='btn btn-icon btn-sm mx-1'
                      onClick={() => setToggleInput(!toggleInput)}
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                  </h3>
                )}
              </div>
              {/* <div className='d-flex'>
                <span
                  className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                  onClick={() => {
                    if (findSlideIndex()) setSelectedSlide(slides[findSlideIndex() - 1])
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr022.svg'
                    className={`svg-icon-dark svg-icon-3x`}
                  />
                </span>
                <span
                  className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                  onClick={() => setShowPopup(!showPopup)}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr017.svg'
                    className={`svg-icon-dark svg-icon-3x`}
                  />
                </span>
                <span
                  className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                  onClick={() => {
                    if (findSlideIndex() + 1 !== slides?.length)
                      setSelectedSlide(slides[findSlideIndex() + 1])
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr023.svg'
                    className={`svg-icon-dark svg-icon-3x`}
                  />
                </span>
              </div> */}
              <div className='d-flex'>
                <span
                  className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                  onClick={() => setSlideView('desktop')}
                >
                  <KTSVG
                    path='/media/icons/duotune/electronics/elc004.svg'
                    className={`svg-icon-${
                      slideView === 'desktop' ? 'primary' : 'dark'
                    } svg-icon-3x`}
                  />
                </span>
                <span
                  className='svg-icon svg-icon svg-icon-1 cursor-pointer me-2'
                  onClick={() => setSlideView('mobile')}
                >
                  <KTSVG
                    path='/media/icons/duotune/electronics/elc002.svg'
                    className={`svg-icon-${
                      slideView === 'mobile' ? 'primary' : 'dark'
                    } svg-icon-3x`}
                  />
                </span>
              </div>
            </div>
            <div
              style={{
                width: slideView === 'mobile' ? 480 : 1000,
                margin: 'auto',
                overflowY: 'scroll',
                height: slideView === 'mobile' ? 750 : 650,
              }}
            >
              <SlideBuilderElement
                steps={[{currentSlide: selectedSlide, type: selectedSlide?.type}]}
                appearance={appearance}
                isGrid={isGrid}
                isPreview={true}
                isEditor={true}
                inPage={true}
                preBtnEditor={preBtnEditor}
                nxtBtnEditor={nxtBtnEditor}
                isMobile={slideView === 'mobile'}
                setSlideContent={(key: any, value: any) => {
                  selectedSlide.data.content[key] = value
                  setSelectedSlide({...selectedSlide})
                  onSave()
                }}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
              ></SlideBuilderElement>
            </div>
            <div style={{width: slickWidth?.width, margin: '15px auto 0 auto'}}>
              <Slider {...settings}>
                {slides?.map((slide: any, idx: any) => renderSlide(slide, idx))}
                <div>
                  <div
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='left-end'
                    className={
                      'border rounded-2 border-2 border-secondary cursor-pointer fs-4qx text-muted d-flex justify-content-center align-items-center'
                    }
                    style={{
                      backgroundColor: 'while',
                      width: 165,
                      height: 120,
                      marginBottom: 2,
                      marginTop: 20,
                    }}
                  >
                    +
                    {renderNewSlideDropdown()}
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <SettingsFlyout
            toggleEditor={toggleEditor}
            selectedSlide={selectedSlide}
            setSelectedSlide={setSelectedSlide}
            onSave={onSave}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            moduleCode={moduleCode}
            setModuleCode={setModuleCode}
            setFlag={setFlag}
            slides={slides}
          />
        </>
      ) : (
        <FallbackView />
      )}
      {/* {showPopup && (
        <SlideBuilderElement
          steps={[{currentSlide: selectedSlide, type: selectedSlide?.type}]}
          appearance={appearance}
          isGrid={isGrid}
          isPreview={true}
          isEditor={true}
          inPage={false}
          isMobile={false}
          setSlideContent={(key: any, value: any) => {
            selectedSlide.data.content[key] = value
            setSelectedSlide({...selectedSlide})
            onSave()
          }}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        ></SlideBuilderElement>
      )} */}

      {showConfirmModal && (
        <ConfirmModal
          message={'Are you sure you want to delete this node?'}
          show={showConfirmModal}
          close={setShowConfirmModal}
          onConfirm={() => onDeleteSlide(deleteSlideIdx)}
        />
      )}
    </div>
  )
}

export default Step2
