import { getGlobalItem } from "../../../../utils/utils"

const language = getGlobalItem('user')?.language || 'fi';
export const getFormLanValue = (value) => {
    const ln = require('./ln')[language.toUpperCase()]
    return ln ? ln[value] || value : value
}

export const getFormLanObj = () => {
    return require('./ln')[language.toUpperCase()] || {}
}
