import {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Highcharts, {chart} from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import Multiselect from 'multiselect-react-dropdown'
import SelectInputFeild from '../../../../shared/SelectInputFeild'

const options: any = {
  title: {
    text: 'Performance Chart',
  },
  series: [
    {
      type: 'flags',
      accessibility: {
        exposeAsGroupOnly: true,
        description: 'Flagged events.',
      },
      data: [
        {
          x: '4',
          // y: '3',
          title: 'A',
          text: 'Some event with a description',
        },
        {
          x: '5',
          // y: '6',
          title: 'B',
          text: 'Some event with a description',
        },
        {
          x: '6',
          // y: '7',
          title: 'C',
          text: 'Some event with a description',
        },
      ],
      onSeries: 'dataseries',
      shape: 'circlepin',
      width: 16,
    },
    {
      data: [1, 2, 1, 4, 3, 6, 7, 3],
    },
  ],
}

const PerformanceExplorer: FC = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [chartData, setChartData]: any = useState(options)

  const trendOption = [
    {value: 'yearly', text: t('YEARLY_TRADE')},
    {value: 'weekly', text: t('WEEKLY_TRADE')},
    {value: 'daily', text: t('DAILY_TRADE')},
  ]
  const appOption = [
    {value: 'App1', text: t('APP1')},
    {value: 'App2', text: t('APP2')},
    {value: 'App3', text: t('APP3')},
  ]
  const measureOption = [
    {
      chart: ` ${t('SUCCESS')}`,
      value: {
        data: [1, 2, 1, 4, 3, 6, 7, 3],
      },
    },
    {
      chart: ` ${t('UNIQUE')}`,
      value: {
        data: [4, 5, 6, 7, 2, 3, 5, 9, 4],
      },
    },
    {
      chart: ` ${t('VISIT')}`,
      value: {
        data: [8, 5, 9, 6, 7, 3, 1, 6, 4, 5],
      },
    },
  ]

  const onRemoveMeasure = (removedItem: any) => {
    const chartIndex = chartData.series.findIndex(
      (item: any) => JSON.stringify(item) === JSON.stringify(removedItem.value)
    )
    if (chartIndex === -1) return
    chartData.series.splice(chartIndex, 1)
    setChartData({...chartData})
  }

  return (
    <div className='tab-content'>
      <div className='card card-flush'>
        <div className='card-body pt-0'>
          <div className='row my-5'>
            <div className='col-4 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                {t('MEASURE', {count: 1})}
              </label>
              <Multiselect
                isObject={true}
                displayValue='chart'
                avoidHighlightFirstOption={true}
                onSelect={(selectedList: any, selectedItem: any) => {
                  chartData.series.push(selectedItem.value)
                  setChartData({...chartData})
                }}
                onRemove={(selectedList: any, removedItem: any) => onRemoveMeasure(removedItem)}
                options={measureOption}
                showCheckbox={true}
                placeholder=''
                selectedValues={[
                  {
                    chart: ` ${t('SUCCESS')}`,
                    value: {
                      data: [1, 2, 1, 4, 3, 6, 7, 3],
                    },
                  },
                ]}
                style={{
                  chips: {
                    background: '#1a175e',
                  },
                }}
              />
            </div>
            <div className='col-4 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                {t('APP', {count: 1})}
              </label>
              <SelectInputFeild name='app' options={appOption} />
            </div>
            <div className='col-4 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                {t('COMPARE_TREND')}
              </label>
              <SelectInputFeild name='compareTrend' options={trendOption} />
            </div>
            <div className='mt-5'>
              <HighchartsReact highcharts={Highcharts} options={chartData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PerformanceExplorer
