const all = {
 chartData: {
  netProfit: [20, 30, 20, 50, 20, 30, 10],
  revenue: [10, 40, 20, 40, 40, 20, 10],
  expenses: [20, 10, 60, 10, 20, 10, 10]
 },
 cardData: [
  {
   status: 'up',
   progress: 0.5,
   description: '807k',
   statusValue: 10.5,
   title: 'Customers',
   statusDesc: 'More customers',
   progressType: 'MRR',
   image: ''
  },
  {
   status: 'up',
   progress: 5,
   description: '2000',
   statusValue: 17.62,
   title: 'New Leads',
   statusDesc: 'Leads growth',
   progressType: 'New trials',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '1,073',
   statusValue: 10.45,
   title: 'Sessions',
   progressType: 'Impressions',
   statusDesc: 'More sessions than usual',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '2008',
   statusValue: 26.1,
   title: 'Served',
   statusDesc: 'More served',
   progressType: 'Spend',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '100',
   statusValue: 32.8,
   title: 'Automation',
   progressType: 'Dispute',
   statusDesc: 'More automation',
   image: ''
  },
  {
   status: 'up',
   progress: 40,
   description: '354',
   statusValue: 29.45,
   title: 'Manual Actions',
   statusDesc: 'More actions',
   progressType: 'Subscribers',
   image: ''
  },
 ]
}

const pdf = {
 chartData: {
  netProfit: [20, 30, 20, 50, 20, 30, 10],
  revenue: [10, 40, 20, 40, 40, 20, 10],
  expenses: [20, 10, 60, 10, 20, 10, 10]
 },
 cardData: [
  {
   status: 'up',
   progress: 0.5,
   description: '80',
   statusValue: 10.5,
   title: 'Customers',
   statusDesc: 'More customers',
   progressType: 'MRR',
   image: ''
  },
  {
   status: 'up',
   progress: 5,
   description: '330',
   statusValue: 17.62,
   title: 'New Leads',
   statusDesc: 'Leads growth',
   progressType: 'New trials',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '5,456k',
   statusValue: 10.45,
   title: 'Sessions',
   progressType: 'Impressions',
   statusDesc: 'More sessions than usual',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '3457',
   statusValue: 26.1,
   title: 'Served',
   statusDesc: 'More served',
   progressType: 'Spend',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '126',
   statusValue: 32.8,
   title: 'Automation',
   progressType: 'Dispute',
   statusDesc: 'More automation',
   image: ''
  },
  {
   status: 'up',
   progress: 40,
   description: '354',
   statusValue: 29.45,
   title: 'Manual Actions',
   statusDesc: 'More actions',
   progressType: 'Subscribers',
   image: ''
  },
 ]
}

const bot = {
 chartData: {
  netProfit: [20, 30, 20, 50, 20, 30, 10],
  revenue: [10, 40, 20, 40, 40, 20, 10],
  expenses: [20, 10, 60, 10, 20, 10, 10]
 },
 cardData: [
  {
   status: 'up',
   progress: 0.5,
   description: '841',
   statusValue: 10.5,
   title: 'Customers',
   statusDesc: 'More customers',
   progressType: 'MRR',
   image: ''
  },
  {
   status: 'up',
   progress: 5,
   description: '3690k',
   statusValue: 17.62,
   title: 'New Leads',
   statusDesc: 'Leads growth',
   progressType: 'New trials',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '306',
   statusValue: 10.45,
   title: 'Sessions',
   progressType: 'Impressions',
   statusDesc: 'More sessions than usual',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '873',
   statusValue: 26.1,
   title: 'Served',
   statusDesc: 'More served',
   progressType: 'Spend',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '230',
   statusValue: 32.8,
   title: 'Automation',
   progressType: 'Dispute',
   statusDesc: 'More automation',
   image: ''
  },
  {
   status: 'up',
   progress: 40,
   description: '$530',
   statusValue: 29.45,
   title: 'Manual Actions',
   statusDesc: 'More actions',
   progressType: 'Subscribers',
   image: ''
  },
 ]
}

const form = {
 chartData: {
  netProfit: [20, 30, 20, 50, 20, 30, 10],
  revenue: [10, 40, 20, 40, 40, 20, 10],
  expenses: [20, 10, 60, 10, 20, 10, 10]
 },
 cardData: [
  {
   status: 'up',
   progress: 0.5,
   description: '$786',
   statusValue: 10.5,
   title: 'Customers',
   statusDesc: 'More customers',
   progressType: 'MRR',
   image: ''
  },
  {
   status: 'up',
   progress: 5,
   description: '5550',
   statusValue: 17.62,
   title: 'New Leads',
   statusDesc: 'Leads growth',
   progressType: 'New trials',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '5,427',
   statusValue: 10.45,
   title: 'Sessions',
   progressType: 'Impressions',
   statusDesc: 'More sessions than usual',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '4578',
   statusValue: 26.1,
   title: 'Served',
   statusDesc: 'More served',
   progressType: 'Spend',
   image: ''
  }, {
   status: 'up',
   progress: 40,
   description: '456k',
   statusValue: 32.8,
   title: 'Automation',
   progressType: 'Dispute',
   statusDesc: 'More automation',
   image: ''
  },
  {
   status: 'up',
   progress: 40,
   description: '8,740',
   statusValue: 29.45,
   title: 'Manual Actions',
   statusDesc: 'More actions',
   progressType: 'Subscribers',
   image: ''
  },
 ]
}

const botList = [
 { id: '1', tittel: "New Bots", sessions: '1', leads: '100', automations: '327' },
 { id: '2', tittel: "New Bots", sessions: '2', leads: '435', automations: '6432' },
 { id: '3', tittel: "New Bots", sessions: '3', leads: '456', automations: '4567' },
 { id: '4', tittel: "New Bots", sessions: '4', leads: '895', automations: '9427' },
]

const formList = [
 { id: '1', tittel: "New Form", sessions: '1', leads: '100', automations: '327' },
 { id: '2', tittel: "New Form", sessions: '2', leads: '435', automations: '6432' },
 { id: '3', tittel: "New Form", sessions: '3', leads: '456', automations: '4567' },
 { id: '4', tittel: "New Form", sessions: '4', leads: '895', automations: '9427' },
]

const bottemplates = [
 { id: '1', tittel: "New Bots", sessions: '1', leads: '100', automations: '327' },
 { id: '2', tittel: "New Bots", sessions: '2', leads: '435', automations: '6432' },
 { id: '3', tittel: "New Bots", sessions: '3', leads: '456', automations: '4567' },
 { id: '4', tittel: "New Bots", sessions: '4', leads: '100', automations: '327' },
 { id: '5', tittel: "New Bots", sessions: '5', leads: '435', automations: '6432' },
 { id: '6', tittel: "New Bots", sessions: '6', leads: '456', automations: '4567' },
 { id: '7', tittel: "New Bots", sessions: '1', leads: '100', automations: '327' },
 { id: '8', tittel: "New Bots", sessions: '2', leads: '435', automations: '6432' },
 { id: '9', tittel: "New Bots", sessions: '3', leads: '456', automations: '4567' },
 { id: '10', tittel: "New Bots", sessions: '4', leads: '100', automations: '327' },
 { id: '11', tittel: "New Bots", sessions: '5', leads: '435', automations: '6432' },
 { id: '12', tittel: "New Bots", sessions: '6', leads: '456', automations: '4567' },
]

export { all, bot, pdf, form, botList, formList, bottemplates }