/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import {isGroweoPartner, isGroweoSubadmin} from '../../../../utils/utils'

type WorkspacesToolbarProps = {
  filterData: any
  setFilterData: any
}

const WorkspacesToolbar: FC<WorkspacesToolbarProps> = ({filterData, setFilterData}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const {classes} = useLayout()
  
  const onSearch = (e: any) => setFilterData(e.target.value)

  return (
    <div
      className='toolbar toolbar-pg-bg'
      style={{position: 'inherit', border: 'none'}}
      id='kt_toolbar'
    >
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        style={{padding: '0px'}}
      >
        <div>
          <SectionHeading
            title={t('CUSTOMER', {count: 1})}
            subTitle={t('CUSTOMER_SUBTITLE', {count: 1})}
          />
        </div>
        <div className='d-flex align-items-center py-1'>
          <div className='d-flex align-items-center position-relative mx-2'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              className='form-control form-control-solid w-300px ps-14 bg-white'
              placeholder={t('SEARCH')}
              onChange={onSearch}
            />
          </div>
          {(!isGroweoPartner() && !isGroweoSubadmin()) && (
            <a
              className='btn btn-primary text-uppercase'
              data-bs-toggle='modal'
              data-bs-target={`#gw_workspace_modal`}
              id='gw_workspace_modal_button'
            >
              <span className='svg-icon svg-icon svg-icon-1'>
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-hx' />
              </span>
              {t('CREATE')} {t('CUSTOMER', {count: 0})}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export {WorkspacesToolbar}
