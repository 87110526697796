/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {getModule} from '../../../../../service/module.service'
import {filterType} from '../../../../../utils/module.utils'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import SelectInputFeild from '../../../../shared/SelectInputFeild'

type AppsProps = {
  type?: any
  setType?: any
  onReset?: any
  onApply?: any
  moduleId?: any
  setModuleId?: any
  appType?: any
}

const GrowthAppFilterMenu: FC<AppsProps> = ({
  type,
  setType,
  onReset,
  onApply,
  moduleId,
  setModuleId,
  appType,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const [moduleList, setModuleList] = useState([])

  useEffect(() => {
    if(setModuleId) fetchModuleList()
  }, [type])

  const fetchModuleList = async () => {
    const types = filterType(type)
    if(!types) {
      setModuleList([])
      return
    }
    const {records} = await getModule({type: types})
    if (records && records.length) setModuleList(records)
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [type])

  const renderInput = (name: any, label: string) => (
    <label className='form-check form-check-sm form-check-custom form-check-solid m-2'>
      <input
        className='form-check-input'
        type='checkbox'
        checked={type[name]}
        onChange={() => setType({...type, [name]: !type[name]})}
      />
      <span className='form-check-label'>{label}</span>
    </label>
  )

  return (
    <div>
      <a
        className='btn btn-flex btn-light btn-success fw-bolder text-uppercase'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen031.svg'
          className='svg-icon-5 svg-icon-gray-500 me-1'
        />
        {t('FILTER')}
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px position-fixed m-0'
        data-kt-menu='true'
        style={{
          zIndex: 105,
          inset: '0px 0px auto auto',
          transform: 'translate3d(-115px, 110px, 0px)',
        }}
        data-popper-placement='bottom-end'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{t('FILTER_CHOICE')}</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>
              {t('SELECT')} {t('GROWTH_APPS', {count: 1})}:
            </label>
            <div className='d-flex flex-column'>
              {appType?.bot &&
                renderInput('bot', t('SMART_BOT', {count: 0}))}
              {appType?.form &&
                renderInput('form', t('SMART_FORM', {count: 0}))}
              {appType?.smartblock &&
                renderInput('smartblock', t('SMART_BLOCK', {count: 0}))}
              {appType?.smartpopup &&
                renderInput('smartpopup', t('SMART_POPUP', {count: 0}))}
              {appType?.smartbar &&
                renderInput('smartbar', t('SMART_BAR', {count: 0}))}
              {appType?.smartflow &&
                renderInput('smartflow', t('SMART_FLOW', {count: 0}))}
              {appType?.file &&
                renderInput('file', t('DOWNLOAD_TOOL', {count: 0}))}
              {appType?.list &&
                renderInput('list', t('LIST', {count: 0}))}
            </div>
            {setModuleId && (
               <><label className='form-label fw-bold mt-5'>
                {t('APP_NAME', { count: 0 })}:
              </label><div className='fv-row'>
                  <div className='col-12'>
                    <SelectInputFeild
                      name={'moduleId'}
                      value={moduleId}
                      options={moduleList}
                      onChange={(name: string, value: string) => setModuleId(value)}
                      format={{ value: '_id', text: 'name' }}
                      appendOption={{ name: 'ALL', value: '' }} />
                  </div>
                </div></>
            )}
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={onReset}
            >
              {t('RESET')}
            </button>
            <button
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={onApply}
              disabled={!filterType(type)}
            >
              {t('APPLY')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GrowthAppFilterMenu
