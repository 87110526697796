import {FC, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import TextInputFeild from '../../../../shared/TextInputFeild'
import {getUser, setUserPassword} from '../../../../../service/user.service'
import { useParams } from 'react-router-dom'

type SetPasswordModalProps = {
  modalId: string
  user?: any
  setUserList?: any
}

interface User {
  password?: string
  cPassword?: string
}
interface Params {
  workspaceId: string,
}
const passwordSchema = (t: any, isEdit: any) =>
  Yup.object().shape({
    password: Yup.string().required(`${t('REQUIRED', {field: t('PASSWORD')})}`),
    cPassword: Yup.string()
      .required(`${t('REQUIRED', {field: `${t('CONFIRM')}  ${t('PASSWORD')}`})}`)
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

const initialValues: User = {
  password: '',
  cPassword: '',
}

const SetPasswordModal: FC<SetPasswordModalProps> = ({modalId, user, setUserList}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})
  const { workspaceId } = useParams<Params>();

  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line
  }, [user])

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema(t, !!user),
    onSubmit: (values) => {
      if (!user?._id) return
      const {password} = values
      setUserPassword(user._id, {password}).then(() => {
        getUser(workspaceId).then(list => {
          setUserList(list)
        })
      })
      formik.resetForm()
    },
  })

  const renderLabel = (label: string) => (
    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
      <span className='required'>{label}</span>
    </label>
  )

  return (
    <div className='modal fade' tabIndex={-1} id={modalId}>
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>
              {t('SET', {count: 0})} {t('PASSWORD', {count: 0})}
            </h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label={t('CLOSE')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 my-7'>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='gw_set_password_modal_form'
            >
              <div className='fv-row mb-5'>
                {renderLabel(`${t('PASSWORD', {count: 0})}`)}
                <TextInputFeild formik={formik} name='password' type='password' />
              </div>
              <div className='fv-row mb-5'>
                {renderLabel(`${t('CONFIRM', {count: 0})} ${t('PASSWORD', {count: 0})}`)}
                <TextInputFeild formik={formik} name='cPassword' type='password' />
              </div>

              <div className='fv-row'>
                <div className='text-center '>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    onClick={() => {
                      formik.resetForm()
                    }}
                  >
                    {t('RESET')}
                  </button>
                  <button
                    type='submit'
                    data-bs-dismiss={`${formik.isSubmitting || !formik.isValid ? '' : 'modal'}`}
                    className='c-button c-button--type-solid'
                  >
                    <span className='indicator-label'>
                      {t('SUBMIT')}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetPasswordModal
