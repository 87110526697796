export const FI = {
addOption: 'Lisää valinta +',
allFieldsRemoved: 'Kaikki kentät poistettiin.',
allowMultipleFiles: 'Salli käyttäjien lähettää useita tiedostoja',
autocomplete: 'Automaattinen täydennys',
button: 'Painike',
cannotBeEmpty: 'Tämä kenttä ei voi olla tyhjä',
checkboxGroup: 'Ryhmä valintaruutuja',
checkbox: 'Valintaruutu',
checkboxes: 'Valintaruudut',
className: 'Luokka',
clearAllMessage: 'Oletko varma että haluat poistaa kaikki kentät?',
clear: 'Tyhjennä',
close: 'Sulje',
content: 'Sisältö',
copy: 'Kopioi leikepöydälle',
copyButton: '&#43;',
copyButtonTooltip: 'Kopioi',
dateField: 'Päivämääräkenttä',
description: 'Aputeksti',
descriptionField: 'Kuvaus',
devMode: 'Kehittäjätila',
editNames: 'Muokkaa nimiä',
editorTitle: 'Lomakkeen kentät',
editXML: 'Muokkaa XML:ää',
enableOther: 'Mahdollista &quot;Muu&quot;',
enableOtherMsg: 'Anna käyttäjien syöttää listaamaton vaihtoehto',
fieldDeleteWarning: 'epätosi',
fieldVars: 'Kentän muuttujat',
fieldNonEditable: 'Tätä kenttää ei voi muokata.',
fieldRemoveWarning: 'Haluatko varmasti poistaa tämän kentän?',
fileUpload: 'Tiedoston lataus',
formUpdated: 'Lomake päivitetty',
getStarted: 'Vedä kenttä oikealta tähän alueeseen',
header: 'Otsikko',
hide: 'Muokkaa',
hidden: 'Piilotettu kenttä',
inline: 'Linjassa',
inlineDesc: 'Näytä kenttä {type} linjassa',
label: 'Kuvaus',
labelEmpty: 'Kentän kuvaus ei voi olla tyhjä',
limitRole: 'Rajoita pääsy yhdelle tai useammalle seuraavista tehtävistä:',
mandatory: 'Pakollinen',
maxlength: 'Maksimipituus',
minOptionMessage: 'Tämä kenttä vaatii vähintään 2 vaihtoehtoa',
multipleFiles: 'Useita tiedostoja',
name: 'Nimi',
no: 'Ei',
noFieldsToClear: 'Ei ole kenttiä tyhjentääksesi',
number: 'Numero',
off: 'Pois päältä',
on: 'Päällä',
option: 'Vaihtoehto',
options: 'Vaihtoehdot',
optional: 'valinnainen',
optionLabelPlaceholder: 'Kuvaus',
optionValuePlaceholder: 'Arvo',
optionEmpty: 'Vaihtoehto vaaditaan',
other: 'Muu',
paragraph: 'Kappale',
placeholder: 'Paikanpitäjä',
placeholders: {
    value: 'Arvo',
    label: 'Kuvaus',
    text: '',
    textarea: '',
    email: 'Syötä sähköpostiosoitteesi',
    placeholder: '',
    className: 'Välilyönnillä erotetut luokat',
    password: 'Syötä salasanasi',
},
preview: 'Esikatselu',
radioGroup: 'Radio-ryhmä',
radio: 'Radio',
removeMessage: 'Poista elementti',
removeOption: 'Poista vaihtoehto',
remove: '&#215;',
required: 'Pakollinen',
richText: 'Muotoillun tekstin editori',
roles: 'Pääsyhallinta',
rows: 'Rivit',
save: 'Tallenna',
selectOptions: 'Asetukset',
select: 'Valintalaatikko',
selectColor: 'Värivalinta',
selectionsMessage: 'Salli useita valintoja',
size: 'Koko',
'size.xs': 'XS',
'size.sm': 'S',
'size.m': 'M (Oletus)',
'size.lg': 'L',
style: 'Tyylimääritykset',
styles: {
    btn: {
        default: 'Default',
        danger: 'Danger',
        info: 'Info',
        primary: 'Primary',
        success: 'Success',
        warning: 'Warning',
    }
},
subtype: 'Tyyppi',
text: 'Tekstikenttä',
textArea: 'Tekstilaatikko',
toggle: 'Vaihtonappi',
warning: 'Varoitus!',
value: 'Arvo',
viewJSON: '[{&hellip;}]',
viewXML: '&lt;/&gt;',
yes: 'Kyllä',
Phone: 'Puhelin',
Email: 'Sähköposti',
Submit: 'Lähetä',
}
