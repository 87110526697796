/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import {useIntl} from 'react-intl'
import axios from 'axios'
import { isWorkspaceGroweoAdmin, isWorkspaceReadonly } from '../../../../utils/utils'

type Props = {
  className: string
  chartHeight: string
  chartColor: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  subTitle: string
  description: string
  count: number
  data: number[]
  trendText?: string
  onEdit?: Function
  onView? : Function
  onDelete?: Function
  onDuplicate?: Function
  id?: string
  executeResults?: any[]
  onSaveAsLibrary?: Function
}

const chartOptions = (chartHeight: string, chartColor: string, data: number[]): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-white')
  const lightColor = getCSSVariableValue('--bs-blue-white')

  return {
    series: [
      {
        name: 'Net Profit',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 1,
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: false
    },
    colors: [baseColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

const DashboardCard2: React.FC<Props> = ({
  className,
  chartHeight,
  chartColor,
  color,
  svgIcon,
  iconColor,
  title,
  subTitle,
  description,
  count,
  data,
  trendText,
  onEdit = () => {},
  onView = () => {},
  onDelete = () => {},
  onDuplicate = () => {},
  executeResults = [],
  id,
  onSaveAsLibrary = () => {},
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor, data))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, data])

  return (
    <>
      <div className='card card-xxl-stretch-100 mb-xl-8'>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column fs-4'>
            <span className='fw-bolder mb-2 text-dark'>{title}</span>
            <span className='text-muted fw-bold fs-7'>{subTitle}</span>
          </h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen024.svg'
                className='svg-icon-3 svg-icon-primary'
              />
            </button>

            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                  {t('QUICK_ACTIONS')}
                </div>
              </div>
              <div className='separator mb-3 opacity-75'></div>
              {(!isWorkspaceReadonly() && isWorkspaceGroweoAdmin()) && <div className='menu-item px-3'>
                <a
                  onClick={() => onSaveAsLibrary()}
                  className='menu-link px-3'
                  data-bs-toggle='modal'
                  data-bs-target='#gw_library_modal'
                >
                  {t('SAVE_AS_LIBRARY', {count:0})}
                </a>
              </div>}
              <div className='menu-item px-3'>
                <a className='menu-link px-3' onClick={() => onEdit()}>
                  {t('EDIT', {count:0})}
                </a>
              </div>
              <div className='menu-item px-3'>
                <a className='menu-link px-3' onClick={() => onView()}>
                   {t('VIEW', {count:0})}
                </a>
              </div>
              <div className='menu-item px-3'>
              <a
                onClick={() => onDuplicate()}
                className='menu-link px-3'
                data-bs-toggle='modal'
                data-bs-target='#gw_modal_duplicate_name'
              >
                {t('DUPLICATE')}
              </a>
            </div>
              {!isWorkspaceReadonly() && <div className='menu-item px-3'>
                <a className='menu-link px-3' onClick={() => onDelete()}>
                  {t('DELETE')}
                </a>
              </div>}
              <div className='separator mt-3 opacity-75'></div>
            </div>
          </div>
        </div>
        <div className='card-body d-flex align-items-center pt-3 pb-0' style={{marginTop: '-30px'}}>
          <div className='d-flex flex-column col-8 flex-grow-5 py-2 py-lg-10 me-2'>
            <p className='mb-0'>{description}</p>
          </div>

          <img src={svgIcon} className='w-100px h-100px float-right' style={{borderRadius: '50%'}} alt='' />
        </div>

        <div className={`card`} style={{backgroundColor: chartColor}}>
          <div className='card-body'>
            <div className='d-flex flex-column flex-grow-1' style={{marginTop: '-20px'}}>
              <div ref={chartRef} className='mixed-widget-3-chart '></div>
            </div>
            <span className='text-dark fw-bolder fs-3x' style={{marginLeft: '20px'}}>
              {count}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardCard2
