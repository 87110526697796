import axios from 'axios'
import { handleError, qs} from '../utils/utils'

export const getPerformanceReport = async (params:any) => {
  try {
    const response = await axios.get(`/api/kpiTrend/performanceReport?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getAppTypePerformanceReport = async (params:any) => {
  try {
    const response = await axios.get(`/api/kpiTrend/performanceReport/appType?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getAllAppPerformanceReport = async (params:any) => {
  try {
    const response = await axios.get(`/api/kpiTrend/performanceReport/apps?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getWorkspaceReportById = async (workspaceId: string, params: any) => {
  try {
    const response = await axios.get(`/api/kpiTrend/performanceReport/${workspaceId}?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getAppSpecificReport = async (workspaceId: string, params: any) => {
  try {
    const response = await axios.get(`/api/kpiTrend/performanceReport/${workspaceId}/apps?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

export const getCustomerReportPdf = async (workspaceId: string, params: any) => {
  try {
    const response = await axios.get(`/api/kpiTrend/performanceReport/${workspaceId}/customerReport?${qs(params)}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

