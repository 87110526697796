import {FC} from 'react'
import {useIntl} from 'react-intl'
import { isBotAccessible } from '../../../../../utils/utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import AppActionsDropdown from '../dropdown/AppActionsDropdown'

type AppCardProps = {
  name: string
  type: string
  sessionCount?: number
  leadCount?: number
  childModule?: any
  edit: any
  archive: any
  duplicate: any
  published: any
  unpublished: any
  status: string
  saveAsLibrary: any
}

const AppCard: FC<AppCardProps> = ({
  status = 'draft',
  name,
  type,
  sessionCount = 0,
  leadCount = 0,
  childModule,
  edit,
  archive,
  duplicate,
  published,
  unpublished,
  saveAsLibrary,
}) => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({id}, values || {})

  const disableBot = type === 'bot' && isBotAccessible()

  const renderIcon = () =>
    `/media/icons/custom/${
      type === 'bot'
        ? 'smart_chat_menu_icon.svg'
        : type === 'form'
        ? 'smart_form_menu_icon.svg'
        :type === "smartblock"
        ?'smart_block_menu_icon.svg'
        :type === "smartpopup"
        ?'smart_popup_menu_icon.svg'
        :type === "smartbar"
        ?'smart_bar_menu_icon.svg'
        :type === "smartflow"
        ?'smart_flow_menu_icon.svg'
        :'download_tools_menu_icon.svg'
        
        
    }`

  const renderStatus = (name: string, label: string) => (
    <div className='mb-0 lh-1'>
      {' '}
      <span className={`badge badge-${name} badge-circle w-10px h-10px me-1`}></span>
      <span className='fs-7 fw-bold text-muted'>{label}</span>
    </div>
  )

  return (
    <div className='card card-flush h-xl-100'>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column' style={{width: '85%',fontSize:"1rem"}}>
          <span className='fw-bolder mb-1 text-truncate w-100' title={name}>
          {name}
          </span>
          {status === 'draft' && <> {renderStatus('secondary', t('IN_PROGRESS'))} </>}
          {status === 'active' && <> {renderStatus('success', t('PUBLISHED'))} </>}
        </h3>
        <div className={`card-toolbar m-0 ${disableBot ? 'pe-none' : ''}`}>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen024.svg'
              className='svg-icon-3 svg-icon-primary'
            />
          </button>
          <AppActionsDropdown
            edit={edit}
            archive={archive}
            duplicate={duplicate}
            published={published}
            unpublished={unpublished}
            saveAsLibrary={saveAsLibrary}
          />
        </div>
      </div>
      <div className='card-body pt-0'>
      <div className='d-flex flex-center' >
          <div className='symbol symbol-100' style={{width:"110px",height:"110px",borderRadius:"50%",padding:"23px",backgroundColor:"#1A175E"}}>
            <span className='menu-icon svg-icon svg-icon-3x'>
              <KTSVG path={renderIcon()} className='svg-icon text-bold text-white svg-icon-5x' svgClassName='text-bold'/>
            </span>
          </div>
        </div>
        <div className='row g-0'>
          <div className='col '>
            <div className='d-flex align-items-center me-2'>
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light-success'>
                  <span className='menu-icon svg-icon svg-icon-1 svg-icon-success'>
                    <KTSVG path='/media/icons/duotune/finance/fin008.svg' className='svg-icon-2x' />
                  </span>
                </div>
              </div>
              <div>
                <div className='fs-4 text-dark fw-bolder'>{ childModule?.length ? childModule[0].sessionCount : sessionCount}</div>
                <div className='fs-7 text-muted fw-bold'>{t('SESSION', {count: 1})}</div>
              </div>
            </div>
          </div>
          <div className='col '>
            <div className='d-flex justify-content-end align-items-center me-2'>
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light-primary'>
                  <span className='menu-icon svg-icon svg-icon-1 svg-icon-primary'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com014.svg'
                      className='svg-icon-2x'
                    />
                  </span>
                </div>
              </div>
              <div>
                <div className='fs-4 text-dark fw-bolder'>{ childModule?.length ? childModule[0].leadCount : leadCount}</div>
                <div className='fs-7 text-muted fw-bold'>{t('LEAD', {count: 1})}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppCard
