/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import SectionHeading from '../../../../app/modules/widgets/others/SectionHeading'
import { useHistory, useParams } from 'react-router-dom'


interface RouteParams {
  workspaceId: string,
}

const SubscriptionDetailsToolbar = () => {
  const intl = useIntl()
  const t = (id: string, values?: any) => intl.formatMessage({ id }, values || {})
  const { classes } = useLayout()
  const history = useHistory()
 


  return (
    <div className='toolbar toolbar-pg-bg' style={{ position: 'inherit', border: 'none' }} id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')} style={{ padding: '0px' }}
      >
        <div>
          <SectionHeading title={`${t('SUBSCRIPTION', { count: 0 })} ${t('DETAILS_HEADER')}`} subTitle={t('SUBSCRIPTION_DETAILS_SUBTITLE', { count: 1 })} />
        </div>
        <div className='d-flex align-items-center py-1'>
          <div className='me-2'>
            <a
              id='gw_subscription_edit_modal_button'
              className='btn btn-flex btn-light btn-success fw-bolder text-uppercase'
              data-bs-toggle="modal"
              data-bs-target={`#gw_subscription_modal`}
            >
              <KTSVG
                path='/media/icons/duotune/art/art005.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1 '
              />
              {t('EDIT')}
            </a>
          </div>
         
          <div className='me-2'>
            <a className='btn btn-primary text-uppercase' onClick={() => history.push('/subscriptions')}>
              <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon svg-icon-2' />
              {t('BACK')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SubscriptionDetailsToolbar }
