import axios from 'axios'
import { errorMessage, handleError } from '../utils/utils'


export const createSubscription = async (data: any) => {
  try {
    const response = await axios.post(`/api/subscriptionPlan`, data)
    return response.data?.data

  } catch ({ response }) {
    handleError(response)
  }
}

export const getSubscriptionPlan = async () => {
  try {
    const response = await axios.get(`/api/subscriptionPlan`)
    return response.data?.data?.records
  } catch ({ response }) {
    handleError(response)
  }
}

export const getSubscriptionPlanById = async (id: string) => {
  try {
    const response = await axios.get(`/api/subscriptionPlan/${id}`)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}


export const deleteSubscriptionPlan = async (id: string) => {
  try {
    const response = await axios.delete(`/api/subscriptionPlan/${id}`)
    return response.data?.data
  } catch (error: any) {
    handleError(error?.response)
    errorMessage(error?.response?.data?.message)
  }
}

export const updateSubscriptionPlan = async (id: string, data: any) => {
  try {
    const response = await axios.put(`/api/subscriptionPlan/${id}`, data)
    return response.data?.data
  } catch ({ response }) {
    handleError(response)
  }
}

