import AdministrationBreadCrumb from './AdministrationBreadCrumb'
import PerformanceContainer from '../../modules/admin/performanceReport/PerformanceContainer'

const Subscriptions = () => {

  
  return (
    <>
      <AdministrationBreadCrumb />
      <PerformanceContainer />    
    </>
  )
}

export default Subscriptions
