 
 import React,{FC} from 'react';
 
 type Props = {
    title: string
    subTitle: string
  }
  
 
 const SectionHeading: FC<Props> = ({title, subTitle = ''}) => {
  
    return (
      <div style={{padding: '15px'}}>
        <h4 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-2 mb-1'>{title}</span>
          <br />
  
          <span className='text-muted fw-bold fs-7'>{subTitle}</span>
        </h4>
      </div>
    )
  }


export default SectionHeading;