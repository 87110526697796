import axios from 'axios'
import {errorMessage} from './utils/utils'

axios.interceptors.response.use(
  (res) => {
    return res
  },
  (error) => {
    const err = error.response?.data
    if (err?.statusCode === 406) {
      errorMessage(err?.message)
    } else {
      return Promise.reject(error)
    }
  }
)
