import {FC, useEffect} from 'react'
import {getTextColor} from '../../../../utils/module.utils'

const CustomizeForm: FC<any> = ({data}) => {
  const {theme, background, width, height, styles, buttonColor, btnBackground} = data

  useEffect(() => {
    if (!buttonColor) return
    updateFormStyle()
    // eslint-disable-next-line
  }, [buttonColor, btnBackground])

  const updateFormStyle = () => {
    const form = document.querySelector('form')
    if (form) {
      const submitBtn: any = form.querySelector('button[type=submit]')
      if (submitBtn) {
        if (buttonColor) submitBtn.className = 'btn ' + buttonColor
        submitBtn.style.background = btnBackground
        let btnTextColor = ['white', 'groweo'].includes(buttonColor) ? '#000' : '#fff'
        if (btnBackground) btnTextColor = getTextColor(btnBackground)
        submitBtn.style.color = btnTextColor
      }
    }
  }

  return (
    <div id='form-appearance-container' className={`overflow-auto ${theme}`}>
      <div className={`form-body m-0 ${theme} ${styles}`} style={{background, width}}>
        <form
          id='form-appearance'
          className={`simple-form ${theme} ${styles}`}
          style={{background: 'transparent', height, overflow: 'auto'}}
        ></form>
      </div>
    </div>
  )
}

export default CustomizeForm
